import {
  IGameResponse,
  IPlayerInGameStatistics,
  ITeamInfoResponse,
  ITeamInGameResponse,
  ITeamInGameStatistics,
} from 'services/game/interfaces/game-response.interface';

import { DEFAULT_TOTAL_STATS } from 'stores/game/game.config';
import {
  IBoxScores,
  IGameStatistics,
  IGameTeamInfo,
  IGameWithStats,
  ITeamInGameInfo,
} from 'stores/game/interfaces/game.interface';
import {
  IBoxScoresPlayerStatistics,
  IBoxScoresTotalStats,
} from 'stores/teams-stats/interfaces/players-stats.interface';

import { formatPastDate } from 'helpers/format/format-past-date.util';
import { convertRGBToHEX } from 'helpers/string/rbg-to-hex.utils';

import { getFormattedPeriod } from './get-formatted-period.util';

export function gameAdapter(gameResponse: IGameResponse): IGameWithStats {
  const {
    id,
    clock_formatted: clockFormatted,
    date_time_start: dateTimeStart,
    current_period: currentPeriod,
    total_periods: totalPeriods,
    status,
    location,
    home,
    visitors,
    season,
  } = gameResponse;

  const homeTeamStartPlayers = home.players.filter((player) => player.is_starter);
  const homeTeamBenchPlayers = home.players.filter((player) => !player.is_starter);
  const visitorsTeamStartPlayers = visitors.players.filter((player) => player.is_starter);
  const visitorsTeamBenchPlayers = visitors.players.filter((player) => !player.is_starter);

  let localHomeTeamStatistics = null;
  let localVisitorsTeamStatistics = null;

  if (home.statistics) {
    localHomeTeamStatistics = inGameTeamTotalStatisticsAdapter(home.statistics);
  }

  if (visitors.statistics) {
    localVisitorsTeamStatistics = inGameTeamTotalStatisticsAdapter(visitors.statistics);
  }

  return {
    id,
    periods: {
      current: currentPeriod,
      total: totalPeriods,
    },
    status,
    clockFormatted,
    formattedPeriod: getFormattedPeriod(status, currentPeriod),
    home: teamGeneralAdapter(home),
    visitors: teamGeneralAdapter(visitors),
    location,
    gameDateStart: formatPastDate(dateTimeStart, 'gameDate'),
    gameDateTimeStart: formatPastDate(dateTimeStart, 'gameDateTime'),
    season: {
      type: season.type,
    },
    scores: {
      q1: [
        home.linescore[0] ? String(home.linescore[0]) : '-',
        visitors.linescore[0] ? String(visitors.linescore[0]) : '-',
      ],
      q2: [
        home.linescore[1] ? String(home.linescore[1]) : '-',
        visitors.linescore[1] ? String(visitors.linescore[1]) : '-',
      ],
      q3: [
        home.linescore[2] ? String(home.linescore[2]) : '-',
        visitors.linescore[2] ? String(visitors.linescore[2]) : '-',
      ],
      q4: [
        home.linescore[3] ? String(home.linescore[3]) : '-',
        visitors.linescore[3] ? String(visitors.linescore[3]) : '-',
      ],
      q5:
        visitors.linescore?.[4] || home.linescore?.[4]
          ? [
              home.linescore[4] ? String(home.linescore[4]) : '-',
              visitors.linescore[4] ? String(visitors.linescore[4]) : '-',
            ]
          : null,
      q6:
        visitors.linescore?.[5] || home.linescore?.[5]
          ? [
              home.linescore[5] ? String(home.linescore[5]) : '-',
              visitors.linescore[5] ? String(visitors.linescore[5]) : '-',
            ]
          : null,
      q7:
        visitors.linescore?.[6] || home.linescore?.[6]
          ? [
              home.linescore[6] ? String(home.linescore[6]) : '-',
              visitors.linescore[6] ? String(visitors.linescore[6]) : '-',
            ]
          : null,
      total: [calculateTotalScores(home.linescore), calculateTotalScores(visitors.linescore)],
    },
    homeTeamStartersStats: inGamePlayerStatisticsAdapter(homeTeamStartPlayers),
    homeTeamBenchStats: inGamePlayerStatisticsAdapter(homeTeamBenchPlayers),
    visitorsTeamStartersStats: inGamePlayerStatisticsAdapter(visitorsTeamStartPlayers),
    visitorsTeamBenchStats: inGamePlayerStatisticsAdapter(visitorsTeamBenchPlayers),
    homeTeamTotalStats: localHomeTeamStatistics,
    visitorsTeamTotalStats: localVisitorsTeamStatistics,
  };
}

export function boxScoresAdapter(players: IPlayerInGameStatistics[]): IBoxScores {
  const start = players.filter((player) => player.is_starter);
  const bench = players.filter((player) => !player.is_starter);

  return {
    startStats: inGamePlayerStatisticsAdapter(start),
    benchStats: inGamePlayerStatisticsAdapter(bench),
    totalStats: DEFAULT_TOTAL_STATS,
  };
}

function calculateTotalScores(scores: Maybe<number>[]): string {
  const totalScore = scores.reduce((acc: number, item) => {
    let result = acc;
    if (item) {
      result += item;
    }

    return result;
  }, 0);

  return String(totalScore);
}

function teamGeneralAdapter(teamInGameResponse: ITeamInGameResponse): IGameTeamInfo {
  const {
    points,
    team_info: teamInfo,
    statistics,
    loss_total: lossTotal,
    win_total: winTotal,
    loss_away: lossAway,
    loss_home: lossHome,
    win_away: winAway,
    win_home: winHome,
    bonus,
    remaining_timeouts: remainingTimeouts,
  } = teamInGameResponse;

  let localStatistics = null;

  if (statistics) {
    localStatistics = teamStatisticsAdapter(statistics);
  }

  return {
    isBonusActive: bonus,
    usedTimeouts: remainingTimeouts,
    teamInfo: teamInfoAdapter(teamInfo),
    statistics: localStatistics,
    lossAway,
    lossTotal,
    winTotal,
    winAway,
    lossHome,
    winHome,
    points: `${points}`,
  };
}

export function teamInfoAdapter(teamInfoResponse: ITeamInfoResponse): ITeamInGameInfo {
  const {
    id,
    name,
    nickname,
    code,
    small_logo_url: smallLogoUrl,
    medium_logo_url: mediumLogoUrl,
    primary_color: primaryColor,
    text_color: textColor,
    secondary_text_color: secondaryTextColor,
    banner_url: bannerUrl,
  } = teamInfoResponse;

  return {
    id: Number(id),
    name,
    nickname,
    code,
    primaryColor: convertRGBToHEX(primaryColor),
    textColor: convertRGBToHEX(textColor),
    secondaryTextColor: convertRGBToHEX(secondaryTextColor),
    mediumLogoUrl,
    smallLogoUrl,
    bannerUrl,
  };
}

function teamStatisticsAdapter(teamStatisticsResponse: ITeamInGameStatistics): IGameStatistics {
  const {
    fgm,
    fga,
    fgp,
    tpm,
    tpa,
    tpp,
    ftm,
    fta,
    ftp,
    assists,
    blocks,
    steals,
    turnovers,
    biggest_lead: biggestLead,
    flagrant_fouls: flagrantFouls,
    personal_fouls: personalFouls,
    points_off_turnovers: pointsOffTurnovers,
    off_reb: offensiveReb,
    def_reb: defensiveReb,
    tot_reb: totalRebounds,
    fast_break_points: fastBreakPoints,
    tech_fouls: technicalFouls,
    points_in_paint: pointsInPaint,
  } = teamStatisticsResponse;
  return {
    mainStats: {
      fieldGoals: {
        made: fgm,
        attempted: fga,
        percentage: fgp,
      },
      threePointsField: {
        made: tpm,
        attempted: tpa,
        percentage: tpp,
      },
      freeThrows: {
        made: ftm,
        attempted: fta,
        percentage: ftp,
      },
    },
    additionalStats: {
      offensiveReb,
      defensiveReb,
      totalRebounds,
      assists,
      blocks,
      steals,
      turnovers,
      pointsOffTurnovers,
      fastBreakPoints,
      pointsInPaint,
      technicalFouls,
      fouls: personalFouls,
      flagrantFouls,
      largesLead: biggestLead,
    },
  };
}

function inGamePlayerStatisticsAdapter(
  players: IPlayerInGameStatistics[],
): IBoxScoresPlayerStatistics[] {
  return players
    .map((player) => {
      const {
        minutes,
        plus_minus: plusMinus,
        points,
        fgm,
        fga,
        tpm,
        tpa,
        ftm,
        fta,
        off_reb: offensiveReb,
        def_reb: defensiveReb,
        tot_reb: totalRebounds,
        assists,
        blocks,
        steals,
        personal_fouls: personalFouls,
        turnovers,
        player_info: playerInfo,
        position,
        is_leader: isLeader,
        not_playing_reason: notPlayingReason,
      } = player;

      const { firstname, lastname, slug, small_logo_url: smallLogoUrl } = playerInfo;

      return {
        player: {
          avatarUrl: smallLogoUrl,
          fullName: `${firstname.charAt(0)}. ${lastname}`,
          slug,
          position,
        },
        minutes: Math.trunc(minutes),
        fieldGoals: `${fgm}-${fga}`,
        threePoints: `${tpm}-${tpa}`,
        freeThrows: `${ftm}-${fta}`,
        offensiveReb,
        defensiveReb,
        turnovers,
        personalFouls,
        plusMinus,
        points,
        gameMissReason: notPlayingReason,
        isLeader,
        blocks,
        totalRebounds,
        assists,
        steals,
        position,
      };
    })
    .sort((currentItem) => {
      if (!currentItem.gameMissReason) {
        return -1;
      }

      return 0;
    });
}

function inGameTeamTotalStatisticsAdapter(
  teamStatistics: ITeamInGameStatistics,
): IBoxScoresTotalStats[] {
  const {
    fgp,
    fga,
    fgm,
    tpp,
    tpa,
    tpm,
    ftp,
    fta,
    ftm,
    steals,
    turnovers,
    assists,
    blocks,
    points,
    off_reb: offensiveReb,
    def_reb: defensiveReb,
    tot_reb: totalRebounds,
    personal_fouls: personalFouls,
  } = teamStatistics;

  return [
    {
      player: 'Team',
      minutes: null,
      plusMinus: null,
      fieldGoals: `${fgm}-${fga}`,
      threePoints: `${tpm}-${tpa}`,
      freeThrows: `${ftm}-${fta}`,
      turnovers: String(turnovers),
      offensiveReb: String(offensiveReb),
      defensiveReb: String(defensiveReb),
      totalRebounds: String(totalRebounds),
      assists: String(assists),
      blocks: String(blocks),
      points: String(points),
      steals: String(steals),
      personalFouls: String(personalFouls),
    },
    {
      player: '',
      fieldGoals: `${fgp}%`,
      threePoints: `${tpp}%`,
      freeThrows: `${ftp}%`,
      turnovers: null,
      minutes: null,
      offensiveReb: null,
      defensiveReb: null,
      totalRebounds: null,
      assists: null,
      blocks: null,
      plusMinus: null,
      points: null,
      steals: null,
      personalFouls: null,
    },
  ];
}
