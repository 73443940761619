import { IUserResponse } from 'services/posts/interfaces/posts-response.interface';

import { IBasePublicationAuthor } from 'stores/entries/interfaces/entries-autor.interface';

import { IFormattedDates } from 'components/posts/post-card/interfaces/post-info.interface';

export enum CollaborationMediaEnum {
  Image = 'image',
  Video = 'video',
}

export interface ICollaborationResponse {
  id: number;
  media?: Maybe<CollaborationMediaEnum>;
  text_allowed: boolean;
  created_at: string;
  updated_at: string;
  expired_at: string;
  collaboration_item_id: Maybe<number>;
  items?: ICollaborationItemResponse[];
  items_total: number;
}

export interface ICollaborationItemResponse {
  id: number;
  image?: ICollaborationImageResponse;
  video?: ICollaborationVideoResponse;
  text_content?: string;
  user: IUserResponse;
  created_at: string;
  updated_at: string;
  likes_total: number;
  bookmarks_total: number;
  shares_total: number;
  comments_total: number;
  is_liked: boolean;
  is_bookmarked: boolean;
  collaboration_id?: number;
}

export interface ICollaborationImageResponse {
  id: number;
  medium_logo_url: string;
  small_logo_url: string;
  large_logo_url: string;
}

export interface ICollaborationVideoResponse {
  id: number;
  url: string;
  small_preview_url: string;
  medium_preview_url: string;
}

export interface ICollaborationItemsResponse {
  items: ICollaborationItemResponse[];
  count: number;
  limit: number;
  page: number;
}

export interface ICollaborationItemsSearchParams {
  limit: string;
  page: string;
}

export interface ICollaborationItemPayload {
  text_content?: string;
  collaboration_item_video_id?: number;
  collaboration_item_image_id?: number;
  id: number;
}

export interface ICollaborationCommentResponse {
  id: number;
  content: string;
  user: IUserResponse;
  created_at: string;
  likes_total: number;
  is_liked: boolean;
}

export interface ICollaborationCommentsResponse {
  items: ICollaborationCommentResponse[];
  count: number;
  limit: number;
  page: number;
}

export interface ICollaboration {
  id: number;
  media: Maybe<CollaborationMediaEnum>;
  textAllowed: boolean;
  selectedItemId: Maybe<number>;
  createdAt: string;
  updatedAt: string;
  expiredAt: string;
  itemsTotal: number;
  items: ICollaborationItem[];
}

export interface ICollaborationItem {
  collaborationParentId: Maybe<number>;
  id: number;
  image: Maybe<ICollaborationImage>;
  video: Maybe<ICollaborationVideo>;
  textContent: Maybe<string>;
  user: IBasePublicationAuthor;
  createdAt: IFormattedDates;
  updatedAt: string;
  likesTotal: number;
  bookmarksTotal: number;
  sharesTotal: number;
  commentsTotal: number;
  isLiked: boolean;
  isBookmarked: boolean;
}

export type CollaborationMediaType = ICollaborationImage | ICollaborationVideo;

export interface ICollaborationImage {
  id: number;
  mediumLogoUrl: string;
  smallLogoUrl: string;
  largeLogoUrl: string;
}

export interface ICollaborationVideo {
  id: number;
  url: string;
  smallPreviewUrl: string;
  mediumPreviewUrl: string;
}

export interface ICollaborationComment {
  id: number;
  content: string;
  user: IBasePublicationAuthor;
  createdAt: IFormattedDates;
  likes: number;
  isLiked: boolean;
}

export interface ICollaborationAdapter {
  <T, N>(res: T): N;
}
