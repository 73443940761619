import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { BucketsStore } from 'stores/buckets/buckets.store';
import { LeaderboardStore } from 'stores/leaderboard/leaderboard.store';
import { SeasonsStore } from 'stores/seasons/seasons.store';

import { TYPES } from 'configs/di-types.config';

import { LeaderboardFilters } from 'components/leaderboard-filters/leaderboard-filters.component';

export const LeaderboardFiltersContainer = observer(() => {
  const leaderboardStore = useInjection<LeaderboardStore>(TYPES.LeaderboardStore);
  const seasonsStore = useInjection<SeasonsStore>(TYPES.SeasonsStore);
  const bucketsStore = useInjection<BucketsStore>(TYPES.BucketsStore);

  return (
    <LeaderboardFilters
      seasonId={leaderboardStore.seasonId ?? ''}
      bucketId={leaderboardStore.bucketId ?? ''}
      seasonsOptions={seasonsStore.reputationSeasonsSelectOptions}
      bucketsOptions={bucketsStore.bucketsSelectOptions}
      onSeasonIdChange={leaderboardStore.setSeasonId}
      onBucketIdChange={leaderboardStore.setBucketId}
    />
  );
});
