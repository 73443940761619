import { memo, MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { ColDef, IDatasource, SortChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import cn from 'classnames';

import { IUserBalance } from 'stores/leaderboard/interfaces/leaderboard.interface';
import { SortParams } from 'stores/leaderboard/leaderboard.store';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { BaseLazyTableGrid } from 'components/base-table/base-lazy-table';
import { RANK_COL_DEF } from 'components/base-table/tables.configs';
import {
  DEFAULT_COL_WIDTH,
  DEFAULT_PARTICIPATION_COL_DEF,
  DEFAULT_PLAYER_COL_DEF,
  DEFAULT_PREDICTIONS_COL_DEF,
  DEFAULT_TOTAL_COL_DEF,
  DEFAULT_USER_COL_DEF,
  THEME_CLASS_NAME,
  USER_DESKTOP_COL_WIDTH,
  USER_MOBILE_COL_WIDTH,
} from 'components/leaderboard/leaderboard.config';
import { LeaderboardRank } from 'components/leaderboard/leaderboard-rank/leaderboard-rank.component';

import './ag-theme-leaderboard.less';
import styles from './leaderboard.module.less';

interface ILeaderboardProps {
  sortParam: SortParams;
  onUserClick: (username: string) => void;
  onSortChanged: (event: SortChangedEvent) => void;
  onSetSortParam: (sortParam: SortParams) => void;
  tableRef: MutableRefObject<AgGridReact<IUserBalance> | undefined>;
  dataSource: IDatasource;
}

const ROWS_COUNT_TABLE_OPTION = 50;

export const Leaderboard = memo((props: ILeaderboardProps) => {
  const { sortParam, onUserClick, onSetSortParam, tableRef } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const { isNativeApp } = useMainProvider();
  const sortParamRef = useRef<SortParams>();

  // A workaround for updating sort param in the LeaderboardRank component
  useEffect(() => {
    sortParamRef.current = sortParam;
  }, [sortParam]);

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        ...RANK_COL_DEF,
        valueGetter: 'node.id',
        cellRenderer: LeaderboardRank,
        cellRendererParams: {
          getSortParam: () => sortParamRef.current,
        },
      },
      {
        ...DEFAULT_USER_COL_DEF,
        maxWidth: isDesktopPlus ? USER_DESKTOP_COL_WIDTH : USER_MOBILE_COL_WIDTH,
        cellRendererParams: {
          onUserClick,
        },
      },
      {
        ...DEFAULT_TOTAL_COL_DEF,
        ...(!isDesktopPlus ? { maxWidth: DEFAULT_COL_WIDTH } : {}),
        headerComponentParams: {
          ...DEFAULT_TOTAL_COL_DEF.headerComponentParams,
          setSortParam: onSetSortParam,
        },
      },
      {
        ...DEFAULT_PLAYER_COL_DEF,
        ...(!isDesktopPlus ? { maxWidth: DEFAULT_COL_WIDTH } : {}),
        headerComponentParams: {
          ...DEFAULT_PLAYER_COL_DEF.headerComponentParams,
          setSortParam: onSetSortParam,
        },
      },
      {
        ...DEFAULT_PARTICIPATION_COL_DEF,
        ...(!isDesktopPlus ? { maxWidth: DEFAULT_COL_WIDTH } : {}),
        headerComponentParams: {
          ...DEFAULT_PARTICIPATION_COL_DEF.headerComponentParams,
          setSortParam: onSetSortParam,
        },
      },
      {
        ...DEFAULT_PREDICTIONS_COL_DEF,
        ...(!isDesktopPlus ? { maxWidth: DEFAULT_COL_WIDTH } : {}),
        headerComponentParams: {
          ...DEFAULT_PREDICTIONS_COL_DEF.headerComponentParams,
          setSortParam: onSetSortParam,
        },
      },
    ];
  }, [isDesktopPlus, onUserClick, onSetSortParam]);

  const leaderboardClassNames = useMemo(() => {
    return cn(styles.Leaderboard, THEME_CLASS_NAME, {
      [styles['Leaderboard--mobileWeb']]: !isNativeApp && !isDesktopPlus,
    });
  }, [isNativeApp, isDesktopPlus]);

  return (
    <div className={leaderboardClassNames}>
      <BaseLazyTableGrid
        ref={(node: AgGridReact) => {
          tableRef.current = node;
        }}
        gridOptions={{
          columnDefs,
          gridId: 'leaderboard',
          rowModelType: 'infinite',
          domLayout: 'normal',
        }}
        infiniteInitialRowCount={ROWS_COUNT_TABLE_OPTION}
        cacheOverflowSize={ROWS_COUNT_TABLE_OPTION}
        maxBlocksInCache={ROWS_COUNT_TABLE_OPTION}
        onSortChanged={props.onSortChanged}
        datasource={props.dataSource}
      />
    </div>
  );
});
