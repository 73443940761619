import { FC } from 'react';

import {
  IRecentSearchItem,
  RecentSearchItem,
} from 'components/global-search/recent-search-item/recent-search-item.component';

import styles from './recent-search.module.less';

interface IRecentSearchProps {
  items: IRecentSearchItem[];
  onItemClick: (value: string) => void;
  onDeleteItem: (value: number) => void;
}

export const RecentSearch: FC<IRecentSearchProps> = (props) => {
  const { items } = props;

  return (
    <div className={styles.RecentSearch}>
      {items.map((item) => (
        <RecentSearchItem
          key={item.id}
          item={item}
          onClick={props.onItemClick}
          onDeleteClick={props.onDeleteItem}
        />
      ))}
    </div>
  );
};
