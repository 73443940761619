import {
  ICollaboration,
  ICollaborationComment,
  ICollaborationCommentResponse,
  ICollaborationImage,
  ICollaborationImageResponse,
  ICollaborationItem,
  ICollaborationItemResponse,
  ICollaborationResponse,
  ICollaborationVideo,
  ICollaborationVideoResponse,
} from 'services/collaboration/interfaces/collaboration.interface';

import { publicationUserAdapter } from 'stores/posts/adapters/publication-adapter.util';

import { formatPastDate } from 'helpers/format/format-past-date.util';

export function collaborationAdapter(response: ICollaborationResponse): ICollaboration {
  const {
    id,
    media,
    created_at: createdAt,
    updated_at: updatedAt,
    expired_at: expiredAt,
    collaboration_item_id: selectedItemId,
    items,
    text_allowed: textAllowed,
    items_total: itemsTotal,
  } = response;

  const convertedItems: ICollaborationItem[] =
    items && items.length ? items.map(collaborationItemAdapter) : [];

  return {
    id,
    items: convertedItems,
    createdAt,
    expiredAt,
    updatedAt,
    media: media || null,
    textAllowed,
    selectedItemId,
    itemsTotal,
  };
}

export function collaborationItemAdapter(item: ICollaborationItemResponse): ICollaborationItem {
  const {
    id,
    image,
    video,
    text_content: textContent,
    user,
    created_at: createdAt,
    updated_at: updatedAt,
    likes_total: likesTotal,
    is_liked: isLiked,
    is_bookmarked: isBookmarked,
    comments_total: commentsTotal,
    bookmarks_total: bookmarksTotal,
    shares_total: sharesTotal,
    collaboration_id: collaborationParentId,
  } = item;

  return {
    id,
    commentsTotal,
    user: publicationUserAdapter(user),
    createdAt: {
      timeOnly: formatPastDate(createdAt, 'timeOnly'),
      relativeLong: formatPastDate(createdAt, 'relativeLong'),
      relativeShort: formatPastDate(createdAt, 'relativeShort'),
      full: formatPastDate(createdAt),
    },
    updatedAt,
    isLiked,
    isBookmarked,
    bookmarksTotal,
    likesTotal,
    sharesTotal,
    image: image ? collaborationImageAdapter(image) : null,
    video: video ? collaborationVideoAdapter(video) : null,
    textContent: textContent ?? null,
    collaborationParentId: collaborationParentId ?? null,
  };
}

export function collaborationVideoAdapter(media: ICollaborationVideoResponse): ICollaborationVideo {
  const {
    id,
    url,
    small_preview_url: smallPreviewUrl,
    medium_preview_url: mediumPreviewUrl,
  } = media;

  return {
    id,
    url,
    smallPreviewUrl,
    mediumPreviewUrl,
  };
}

export function collaborationImageAdapter(media: ICollaborationImageResponse): ICollaborationImage {
  const {
    id,
    medium_logo_url: mediumLogoUrl,
    small_logo_url: smallLogoUrl,
    large_logo_url: largeLogoUrl,
  } = media;

  return {
    id,
    mediumLogoUrl,
    smallLogoUrl,
    largeLogoUrl,
  };
}

export function collaborationCommentAdapter(
  item: ICollaborationCommentResponse,
): ICollaborationComment {
  const {
    id,
    content,
    user,
    created_at: createdAt,
    likes_total: likesTotal,
    is_liked: isLiked,
  } = item;

  return {
    id,
    user: publicationUserAdapter(user),
    createdAt: {
      timeOnly: formatPastDate(createdAt, 'timeOnly'),
      relativeLong: formatPastDate(createdAt, 'relativeLong'),
      relativeShort: formatPastDate(createdAt, 'relativeShort'),
      full: formatPastDate(createdAt),
    },
    content,
    isLiked,
    likes: likesTotal,
  };
}
