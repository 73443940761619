import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import {
  IGameChatEventsResponse,
  IGameChatMessageCreationPayload,
  IGameChatMessageDeletePayload,
  IGameChatMessageLikesDataPayload,
  IGameChatMessageLikesResponse,
  IGameChatMessageResponse,
  IGameChatMessagesResponse,
  IGameChatSearchParams,
} from 'services/game-chat/interfaces/game-chat-messages.interface';
import { HttpService } from 'services/http/http.service';

import { TYPES } from 'configs/di-types.config';
import { GAME_ID_PARAM, MESSAGE_ID_PARAM } from 'routes/paths.constants';

import {
  GAME_CHAT_ITEM_DELETE,
  GAME_CHAT_ITEM_LIKES,
  GAME_MESSAGES,
  GAME_NEW_MESSAGE,
} from '../http/consts/api-endpoints.constants';

@injectable()
export class GameChatService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public async fetchAllGameMessages(
    pagination: IPagination,
    gamedId: number,
    type: string,
  ): Promise<IResponse<IGameChatMessagesResponse>> {
    const { limit, page } = pagination;

    const paramsObject: IGameChatSearchParams = {
      limit: String(limit),
      page: String(page),
      sort: 'DESC',
      type,
    };

    const routeParams = {
      [GAME_ID_PARAM]: String(gamedId),
    };

    return this.httpService.request<IGameChatMessagesResponse>({
      method: 'GET',
      headers: {
        'X-Version': '6',
      },
      url: GAME_MESSAGES,
      params: new URLSearchParams({ ...paramsObject }),
      routeParams,
    });
  }

  public async fetchAllGameEvents(
    pagination: IPagination,
    gamedId: number,
    type: string,
  ): Promise<IResponse<IGameChatEventsResponse>> {
    const { limit, page } = pagination;

    const paramsObject: IGameChatSearchParams = {
      limit: String(limit),
      page: String(page),
      sort: 'DESC',
      type,
    };

    const routeParams = {
      [GAME_ID_PARAM]: String(gamedId),
    };

    return this.httpService.request<IGameChatEventsResponse>({
      method: 'GET',
      headers: {
        'X-Version': '6',
      },
      url: GAME_MESSAGES,
      params: new URLSearchParams({ ...paramsObject }),
      routeParams,
    });
  }

  public async createGameChatMessage(
    payload: IGameChatMessageCreationPayload,
  ): Promise<IResponse<IGameChatMessageResponse>> {
    return this.httpService.request<IGameChatMessageResponse, IGameChatMessageCreationPayload>({
      method: 'POST',
      headers: {
        'X-Version': '6',
      },
      url: GAME_NEW_MESSAGE,
      routeParams: {
        [GAME_ID_PARAM]: String(payload.gameId),
      },
      body: payload,
    });
  }

  public async deleteGameChatMessage(
    payload: IGameChatMessageDeletePayload,
  ): Promise<IResponse<null>> {
    return this.httpService.request<null, IGameChatMessageDeletePayload>({
      method: 'DELETE',
      headers: {
        'X-Version': '6',
      },
      url: GAME_CHAT_ITEM_DELETE,
      routeParams: {
        [GAME_ID_PARAM]: String(payload.gameId),
        [MESSAGE_ID_PARAM]: payload.messageId,
      },
    });
  }

  public async gameChatMessageLikes(
    payload: IGameChatMessageLikesDataPayload,
  ): Promise<IResponse<IGameChatMessageLikesResponse>> {
    return this.httpService.request<
      IGameChatMessageLikesResponse,
      IGameChatMessageLikesDataPayload
    >({
      method: 'POST',
      headers: {
        'X-Version': '6',
      },
      url: GAME_CHAT_ITEM_LIKES,
      routeParams: {
        [GAME_ID_PARAM]: String(payload.gameId),
        [MESSAGE_ID_PARAM]: payload.messageId,
      },
    });
  }
}
