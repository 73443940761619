import { memo, useMemo } from 'react';
import cn from 'classnames';

import { UserActivityStatus } from 'stores/auth/enums/user-activity-status.enum';

import { AvatarStatus } from 'components/ui/avatar/avatar-status/avatar-status.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import {
  VectorIcon,
  VectorIconName,
  VectorIconSize,
} from 'components/ui/vector-icon/vector-icon.component';

import styles from './avatar.module.less';

// MEGA = 144x144
// XXXL = 112x112
// XXL = 80x80
// XL = 64x64
// L = 48x48
// M = 40x40
// S = 32x32
// XS = 24x24
// XXS = 16x16

export enum AvatarSize {
  MEGA = 'MEGA',
  XXXL = 'XXXL',
  XXL = 'XXL',
  XL = 'XL',
  L = 'L',
  M = 'M',
  S = 'S',
  XS = 'XS',
  XXS = 'XXS',
  SIZE_20 = 'SIZE_20',
  SIZE_80 = 'SIZE_80',
  SIZE_144 = 'SIZE_144',
}

export enum AvatarTheme {
  Team = 'team',
  User = 'user',
}

export enum IconAvatarSize {
  S = 'S',
  M = 'M',
}

interface IAvatarProps {
  iconFontName?: Maybe<IconFontName>;
  iconSize?: IconAvatarSize;
  vectorIconName?: Maybe<VectorIconName>;
  vectorIconSize?: VectorIconSize;
  src?: Maybe<string>;
  size: AvatarSize;
  theme?: AvatarTheme;
  username: string;
  userActivityStatus?: UserActivityStatus;
}

export const Avatar = memo((props: IAvatarProps) => {
  const {
    iconFontName,
    size,
    src,
    username,
    userActivityStatus,
    vectorIconName,
    vectorIconSize = VectorIconSize.M,
    theme = AvatarTheme.User,
    iconSize = IconAvatarSize.M,
  } = props;

  const sizeClass = useMemo(
    () =>
      cn({
        [styles['Avatar--size-mega']]: size === AvatarSize.MEGA,
        [styles['Avatar--size-xxxl']]: size === AvatarSize.XXXL,
        [styles['Avatar--size-xxl']]: size === AvatarSize.XXL,
        [styles['Avatar--size-xl']]: size === AvatarSize.XL,
        [styles['Avatar--size-l']]: size === AvatarSize.L,
        [styles['Avatar--size-m']]: size === AvatarSize.M,
        [styles['Avatar--size-s']]: size === AvatarSize.S,
        [styles['Avatar--size-xs']]: size === AvatarSize.XS,
        [styles['Avatar--size-xxs']]: size === AvatarSize.XXS,
        [styles['Avatar--size-20']]: size === AvatarSize.SIZE_20,
        [styles['Avatar--size-80']]: size === AvatarSize.SIZE_80,
        [styles['Avatar--size-144']]: size === AvatarSize.SIZE_144,
      }),
    [size],
  );

  const themeClass = useMemo<string>(
    () =>
      cn({
        [styles['Avatar--theme-team']]: theme === AvatarTheme.Team,
        [styles['Avatar--theme-user']]: theme === AvatarTheme.User,
      }),
    [theme],
  );

  const iconSizeClass = useMemo<string>(
    () =>
      cn(styles.Avatar__FontIcon, {
        [styles['Avatar__FontIcon--s']]: iconSize === IconAvatarSize.S,
        [styles['Avatar__FontIcon--m']]: iconSize === IconAvatarSize.M,
      }),
    [iconSize],
  );

  const rightBottomNode = useMemo<Maybe<JSX.Element>>(() => {
    if ([AvatarSize.XXS].includes(size)) {
      return null;
    }

    if (userActivityStatus) {
      return <AvatarStatus size={size} userActivityStatus={userActivityStatus} />;
    }

    if (iconFontName) {
      return (
        <div className={iconSizeClass}>
          <IconFont name={iconFontName} size={IconFontSize.Small} />
        </div>
      );
    }

    if (vectorIconName) {
      return (
        <div className={styles.Avatar__VectorIcon}>
          <VectorIcon name={vectorIconName} size={vectorIconSize} />
        </div>
      );
    }

    return null;
  }, [size, userActivityStatus, iconFontName, vectorIconName, iconSizeClass, vectorIconSize]);

  const classNames = useMemo(
    () =>
      cn(styles.Avatar, sizeClass, themeClass, {
        [styles['Avatar--without-background']]: !!src,
      }),
    [sizeClass, src, themeClass],
  );

  return (
    <div className={classNames}>
      {src ? (
        <img className={styles.Avatar__Image} src={src} alt={username[0]} />
      ) : (
        <span className={styles.Avatar__Letter}>{username[0]}</span>
      )}
      {rightBottomNode}
    </div>
  );
});
