export const TEAM_ID_PARAM = 'teamId';
export const SEARCH_PARAM = 'searchText';
export const POST_ID_PARAM = 'postId';
export const COLLABORATION_ITEM_ID_PARAM = 'collaborationItemId';
export const GAME_ID_PARAM = 'gameId';
export const MESSAGE_ID_PARAM = 'messageId';
export const GAMES_PART = 'games';
export const POST_COMMENT_ID_PARAM = 'commentId';
export const USER_ID_PARAM = 'user_id';
export const USERNAME_PARAM = 'username';
export const TYPE_PARAM = 'type';
export const POLL_ID_PARAM = 'pollId';
export const POLL_OPTION_ID_PARAM = 'pollOptionId';
export const TEAMS_PART = 'teams';
export const PLAYERS_PART = 'players';
export const PLAYER_SLUG_PARAM = 'playerSlug';
export const CATEGORY_PARAM = 'category';
export const PERFORMANCE_PART = 'performance';
export const FEED_PART = 'feed';
export const COLLABORATION_PART = 'collaboration-item';
export const FAN_ZONE_PART = 'fan-zone';
export const SCORES_PART = 'scores';
export const STATS_PART = 'stats';
export const SPLITS_PART = 'splits';
export const STANDINGS_PART = 'standings';
export const ALL_PART = 'all';
export const SEARCH_PART = 'search';
export const ARENA_PART = 'arena';
export const RANKINGS_PART = 'players-rankings';
export const DETAILS_PART = 'details';
export const PROFILE_PART = 'profile';
export const HEADLINES_PART = 'headlines';
export const USERS_PART = 'users';
export const PREDICTIONS_PART = 'predictions';
export const BOOKMARKS = 'bookmarks';
export const ROSTER_PART = 'roster';

const HOME_PART = 'home';
const SETTINGS_PART = 'settings';
const PRIVACY_POLICY_PART = 'privacy-policy';
const TERMS_OF_USE_PART = 'terms-of-use';
const USER_AGREEMENT_PART = 'user-agreement';
const USER_ACHIEVEMENTS_PART = 'user-achievements';
const CONTACT_US_PART = 'contact-us';
const FOLLOWING_PART = 'following';
const LEADERBOARD_PART = 'leaderboard';

export const POST_ID_PART = `:${POST_ID_PARAM}`;
export const COLLABORATION_ITEM_ID_PART = `:${COLLABORATION_ITEM_ID_PARAM}`;
export const GAME_ID_PART = `:${GAME_ID_PARAM}`;
export const TEAM_ID_PART = `:${TEAM_ID_PARAM}`;
export const PLAYER_SLUG_PART = `:${PLAYER_SLUG_PARAM}`;
export const CATEGORY_PART = `:${CATEGORY_PARAM}`;
export const USERNAME_PART = `:${USERNAME_PARAM}`;

export const ROOT = '/';

export const HOME = `/${HOME_PART}`;

export const HOME_FEED = `/${HOME_PART}/${FEED_PART}`;

export const HOME_HEADLINES = `/${HOME_PART}/${HEADLINES_PART}`;

export const HOME_FEED_FAN_ZONE = `/${HOME_PART}/${FEED_PART}/${FAN_ZONE_PART}`;

export const HOME_FEED_POST = `/${HOME_PART}/${FEED_PART}/${POST_ID_PART}`;

export const HOME_FEED_POST_COLLABORATION = `/${HOME_PART}/${FEED_PART}/${POST_ID_PART}/${COLLABORATION_PART}/${COLLABORATION_ITEM_ID_PART}`;

export const HOME_SCORES = `/${HOME_PART}/${SCORES_PART}`;

export const HOME_STATS = `/${HOME_PART}/${STATS_PART}`;

export const HOME_STATS_TEAMS = `/${HOME_PART}/${STATS_PART}/${TEAMS_PART}`;

export const HOME_STATS_PLAYERS = `/${HOME_PART}/${STATS_PART}/${PLAYERS_PART}`;

export const HOME_STATS_TEAMS_ALL = `/${HOME_PART}/${STATS_PART}/${TEAMS_PART}/${ALL_PART}`;

export const HOME_STATS_PLAYERS_ALL = `/${HOME_PART}/${STATS_PART}/${PLAYERS_PART}/${ALL_PART}`;

export const HOME_STANDINGS = `/${HOME_PART}/${STANDINGS_PART}`;

export const TEAM = `/${TEAMS_PART}/${TEAM_ID_PART}`;

export const TEAM_FEED = `/${TEAMS_PART}/${TEAM_ID_PART}/${FEED_PART}`;

export const TEAM_FEED_FAN_ZONE = `/${TEAMS_PART}/${TEAM_ID_PART}/${FAN_ZONE_PART}`;

export const TEAM_SCORES = `/${TEAMS_PART}/${TEAM_ID_PART}/${SCORES_PART}`;

export const TEAM_PROFILE = `/${TEAMS_PART}/${TEAM_ID_PART}/${PROFILE_PART}`;

export const TEAM_ROSTER = `/${TEAMS_PART}/${TEAM_ID_PART}/${ROSTER_PART}`;

export const TEAM_STATS = `/${TEAMS_PART}/${TEAM_ID_PART}/${STATS_PART}`;

export const PLAYER_PROFILE = `/${PLAYERS_PART}/${PLAYER_SLUG_PART}`;

export const PLAYER_PROFILE_FEED = `/${PLAYERS_PART}/${PLAYER_SLUG_PART}/${FEED_PART}`;

export const PLAYER_PROFILE_FAN_ZONE = `/${PLAYERS_PART}/${PLAYER_SLUG_PART}/${FAN_ZONE_PART}`;

export const PLAYER_PROFILE_GAMES = `/${PLAYERS_PART}/${PLAYER_SLUG_PART}/${GAMES_PART}`;

export const PLAYER_PROFILE_STATS = `/${PLAYERS_PART}/${PLAYER_SLUG_PART}/${STATS_PART}`;

export const PLAYER_PROFILE_SPLITS = `/${PLAYERS_PART}/${PLAYER_SLUG_PART}/${SPLITS_PART}`;

export const PLAYER_PROFILE_OVERVIEW = `/${PLAYERS_PART}/${PLAYER_SLUG_PART}/${PROFILE_PART}`;

export const PLAYER_PERFORMANCE = `/${PLAYERS_PART}/${PLAYER_SLUG_PART}/${GAMES_PART}/${GAME_ID_PART}/${PERFORMANCE_PART}`;

export const SETTINGS = `/${SETTINGS_PART}`;

export const CONTACT_US = `/${CONTACT_US_PART}`;

export const FOLLOWING = `/${FOLLOWING_PART}`;

export const SEARCH = `/${SEARCH_PART}`;

export const ARENA = `/${ARENA_PART}`;

export const ARENA_GAME = `/${ARENA_PART}/${GAME_ID_PART}`;

export const PRIVACY_POLICY = `/${PRIVACY_POLICY_PART}`;

export const TERMS_OF_USE = `/${TERMS_OF_USE_PART}`;

export const USER_AGREEMENT = `/${USER_AGREEMENT_PART}`;

export const USER_ACHIEVEMENTS = `/${USER_ACHIEVEMENTS_PART}`;

export const PLAYERS_RANKINGS = `/${RANKINGS_PART}`;

export const PLAYERS_RANKINGS_DETAILS = `/${RANKINGS_PART}/${DETAILS_PART}/${CATEGORY_PART}`;

export const LEADERBOARD = `/${LEADERBOARD_PART}`;

export const USER_PUBLIC = `/${USERS_PART}/${USERNAME_PART}`;

export const USER_PUBLIC_FEED = `/${USERS_PART}/${USERNAME_PART}/${FEED_PART}`;

export const USER_PUBLIC_PREDICTIONS = `/${USERS_PART}/${USERNAME_PART}/${PREDICTIONS_PART}`;

export const USER_PUBLIC_STATS = `/${USERS_PART}/${USERNAME_PART}/${STATS_PART}`;

export const USER_PUBLIC_PROFILE = `/${USERS_PART}/${USERNAME_PART}/${PROFILE_PART}`;

export const USER_PUBLIC_BOOKMARKS = `/${USERS_PART}/${USERNAME_PART}/${BOOKMARKS}`;
