import {
  IReputationSeasonResponse,
  ISeasonResponse,
} from 'services/seasons/interfaces/season-response.interface';

import { IReputationSeason, ISeason } from '../interfaces/season.interface';

export const seasonAdapter = (seasonResponse: ISeasonResponse): ISeason => {
  const { id, label, is_default: isDefault, key, type } = seasonResponse;

  return {
    id,
    label,
    isDefault,
    key,
    type,
  };
};

export const reputationSeasonAdapter = (
  seasonResponse: IReputationSeasonResponse,
): IReputationSeason => {
  const { id, label, is_current: isCurrent, key } = seasonResponse;

  return {
    id,
    label,
    isCurrent,
    key,
  };
};
