import { inject, injectable } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { HeadlinesService } from 'services/headlines/headlines.service';
import {
  IHeadlineResponse,
  IHeadlinesResponse,
} from 'services/headlines/interfaces/headlines-response.interface';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { PostsStore } from 'stores/posts/posts.store';

import { TYPES } from 'configs/di-types.config';

import { headlineAdapter } from './adapters/headline.adapter';
import { IHeadline } from './interfaces/headline.interface';
import { IHeadlineAdapter } from './interfaces/headline-adapter.interface';

const PAGINATION_LIMIT = 25;
const SHORTCUT_PAGINATION_LIMIT = 4;

@injectable()
export class HeadlinesStore extends AdvancedEntriesStore<
  IHeadline,
  IHeadlineResponse,
  IHeadlinesResponse
> {
  private readonly headlinesService: HeadlinesService;

  private readonly postsStore: PostsStore;

  private readonly layoutStore: LayoutStore;

  public shortcutHeadlines: IHeadline[];

  constructor(
    @inject(TYPES.HeadlinesService) headlinesService: HeadlinesService,
    @inject(TYPES.PostsStore) postsStore: PostsStore,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
  ) {
    super(PAGINATION_LIMIT, false);

    this.headlinesService = headlinesService;

    this.postsStore = postsStore;

    this.layoutStore = layoutStore;

    this.shortcutHeadlines = [];

    makeObservable(this, {
      shortcutHeadlines: observable,

      setShortcutHeadlines: action.bound,
    });

    reaction(() => this.layoutStore.activeFeed, this.listenActiveFeedToFetchHeadlines);
  }

  private listenActiveFeedToFetchHeadlines = () => {
    if (this.layoutStore.activeFeed === FeedTypes.YourFeed) {
      this.fetchShortcutHeadlines();
    }
  };

  public setShortcutHeadlines(shortcutHeadlines: IHeadline[]) {
    this.shortcutHeadlines = shortcutHeadlines;
  }

  public async fetchShortcutHeadlines() {
    const response = await this.headlinesService.fetchHeadlines({
      limit: SHORTCUT_PAGINATION_LIMIT,
      page: 1,
    });

    if (response.success) {
      this.setShortcutHeadlines(response.data.items.map(headlineAdapter));
    }
  }

  public async fetchNext(): Promise<void> {
    await this.retrieveNext(
      this.headlinesService.fetchHeadlines(this.pagination),
      <IHeadlineAdapter>headlineAdapter,
    );
  }

  public async fetchHeadlines() {
    await this.refresh();

    await this.forceRefresh(
      this.headlinesService.fetchHeadlines(this.pagination),
      <IHeadlineAdapter>headlineAdapter,
      this.layoutStore.setPulledRefresher,
    );
  }

  public async refresh() {
    this.setFetched(false);
    this.setFetching(false);

    this.setIsLastPage(false);
    this.setCurrentPage(1);

    await this.initialise();
  }
}
