import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { ShortcutBarContainer } from 'containers/shortcut-bar/shortcut-bar.container';
import { WelcomePopupFeedContainer } from 'containers/welcome-popup/welcome-popup-feed.container';

import * as path from 'routes/paths.constants';

export const FeedParentRoute: FC = () => {
  const params = useParams<{ [path.POST_ID_PARAM]: string }>();

  const { postId } = params;

  if (postId) {
    return <Outlet />;
  }

  return (
    <PageWrapperContainer>
      <WelcomePopupFeedContainer />
      <ShortcutBarContainer />
      <Outlet />
    </PageWrapperContainer>
  );
};
