import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AuthService } from 'services/auth/auth.service';

import { AuthStore } from 'stores/auth/auth.store';
import { IPublicLink } from 'stores/auth/interfaces/custom-link.interface';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';
import * as paths from 'routes/paths.constants';

import { CustomLinksPopup } from 'components/custom-links-popup/custom-links-popup.component';
import { EditablePublicLinks } from 'components/editable-public-links/editable-public-links';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';

import styles from './custom-links.module.less';

export type LinkWithoutIdType = Omit<IPublicLink, 'id' | 'iconSrc' | 'type'>;

export const CustomLinksContainer: FC = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);
  const authService = useInjection<AuthService>(TYPES.AuthService);
  const params = useParams<{
    [paths.USERNAME_PARAM]: string;
  }>();

  const { username } = params;
  const isMyPage = Boolean(authStore.userMe?.username === username);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [selectedLink, setSelectedLink] = useState<Maybe<IPublicLink>>(null);

  const handleCreateNewLinkClick = useCallback(() => {
    setSelectedLink(null);
    setEditModalVisible(true);
  }, []);

  const handleEditLinkClick = useCallback(
    (id: number) => {
      const links = authStore.userMe?.customLinks;

      if (links) {
        const currentLink = links.find((link) => link.id === id);

        if (currentLink) {
          setSelectedLink(currentLink);
          setEditModalVisible(true);
        }
      }
    },
    [authStore.userMe?.customLinks],
  );

  const handleSubmitCreateClick = useCallback(
    async (payload: LinkWithoutIdType) => {
      const response = await authService.createCustomLink(payload);

      if (response.success) {
        setSelectedLink(null);
        setEditModalVisible(false);
        await authStore.retrieveUserMe();
      }
    },
    [authService, authStore],
  );

  const handleSubmitUpdateClick = useCallback(
    async (payload: LinkWithoutIdType) => {
      if (selectedLink) {
        const response = await authService.updateCustomLink(selectedLink.id.toString(), payload);
        if (response.success) {
          setSelectedLink(null);
          setEditModalVisible(false);
          await authStore.retrieveUserMe();
        }
      }
    },
    [authService, authStore, selectedLink],
  );

  const handleDeleteClick = useCallback(() => {
    setIsConfirmModalOpen(true);
  }, []);

  const handleCloseClick = useCallback(() => {
    setEditModalVisible(false);
  }, []);

  const handleOnConfirmDeleteComment = useCallback(async () => {
    if (selectedLink) {
      const response = await authService.deleteCustomLink(selectedLink.id.toString());
      setIsConfirmModalOpen(false);
      setSelectedLink(null);
      setEditModalVisible(false);

      if (response.success) {
        await authStore.retrieveUserMe();
      }
    }
  }, [selectedLink, authService, authStore]);

  const handleOnCloseConfirmation = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, []);

  if (!isMyPage && !userPublicStore.userPublic?.customLinks?.length) {
    return null;
  }

  return (
    <div className={styles.CustomLinksContainer}>
      {isMyPage && (
        <EditablePublicLinks
          isAthlete={Boolean(userPublicStore.userPublic?.athlete)}
          links={authStore.userMe?.customLinks || []}
          onEditClick={handleEditLinkClick}
          onAddNewLink={handleCreateNewLinkClick}
        />
      )}
      {!isMyPage && userPublicStore.userPublic?.customLinks && (
        <EditablePublicLinks
          isAthlete={Boolean(userPublicStore.userPublic?.athlete)}
          links={userPublicStore.userPublic.customLinks}
        />
      )}
      <CustomLinksPopup
        link={selectedLink}
        visible={editModalVisible}
        onClose={handleCloseClick}
        onSubmitClick={handleSubmitCreateClick}
        onUpdateClick={handleSubmitUpdateClick}
        onDeleteClick={handleDeleteClick}
      />
      {isConfirmModalOpen && (
        <ConfirmationModal
          content="This action can’t be undone. Are you sure you want to delete this?"
          onSuccessCallback={handleOnConfirmDeleteComment}
          onClose={handleOnCloseConfirmation}
          title="Please confirm link removal"
          visible
          primaryButtonText="Yes"
          secondaryButtonText="No"
        />
      )}
    </div>
  );
});
