import { useCallback, useEffect, useMemo, useState } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';

import { TeamsStore } from 'stores/teams/teams.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { ISliderItem } from 'containers/posts/config/feed.config';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useGaScreenCustomView } from 'hooks/use-ga-screen-custom-view';
import { useResponsive } from 'hooks/use-responsive';
import { useToggleIgnoredTouchClass } from 'hooks/use-toggle-ignored-touch-class';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { TeamProfileStatsInfo } from 'components/team-profile/team-profile-stats-info/team-profile-stats-info.component';
import { SelectSlider } from 'components/ui/select-slider/select-slider.component';

import { TeamProfileOverviewContainer } from '../team-profile-overview/team-profile-overview.container';
import { TeamProfileRosterContainer } from '../team-profile-roster/team-profile-roster.container';

import styles from './team-profile-info.module.less';

enum TeamProfileTabs {
  Overview = 'Overview',
  Roster = 'Roster',
}

export const TeamProfileInfoContainer = observer(() => {
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const { teamId } = teamsStore;

  const { teamsStats } = teamsStatsStore;

  const { googleAnalyticsTeamFeed } = useGaScreenCustomView();

  const [activeTab, setActiveTab] = useState<TeamProfileTabs>(TeamProfileTabs.Overview);

  const loading = useMemo(
    () => teamsStore.fetching || !teamsStats,
    [teamsStats, teamsStore.fetching],
  );

  useToggleIgnoredTouchClass(loading);

  const handleTabActionClick = useCallback((barAction: BarActionType<string>) => {
    if (barAction.type === BarAction.Click && barAction.payload) {
      setActiveTab(barAction.payload as TeamProfileTabs);
    }
  }, []);

  const handleShowRoster = useCallback(() => {
    setActiveTab(TeamProfileTabs.Roster);
  }, []);

  useEffect(() => {
    if (teamId) {
      googleAnalyticsTeamFeed(ScreenNamesEnum.TeamProfileProfile);
    }
  }, [teamId, googleAnalyticsTeamFeed]);

  const sliderItems = useMemo<ISliderItem[]>(() => {
    return [
      {
        label: 'Overview',
        action: {
          type: BarAction.Click,
          payload: TeamProfileTabs.Overview,
        },
        isDisabled: false,
        value: TeamProfileTabs.Overview,
        isEnableNestedToggle: false,
        isLeftSide: false,
      },
      {
        label: 'Roster',
        action: {
          type: BarAction.Click,
          payload: TeamProfileTabs.Roster,
        },
        isDisabled: false,
        value: TeamProfileTabs.Roster,
        isEnableNestedToggle: false,
        isLeftSide: false,
      },
    ];
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div className={styles.TeamProfileWrapper}>
      {teamsStats && <TeamProfileStatsInfo teamsStats={teamsStats} />}
      {!isDesktopPlus ? (
        <>
          <div className={styles.TabSlider}>
            <SelectSlider
              items={sliderItems}
              onItemClick={handleTabActionClick}
              fullWidth
              selected={activeTab}
            />
          </div>
          {activeTab === TeamProfileTabs.Overview ? (
            <TeamProfileOverviewContainer teamId={teamId} onShowRoster={handleShowRoster} />
          ) : (
            <TeamProfileRosterContainer />
          )}
        </>
      ) : (
        <TeamProfileOverviewContainer teamId={teamId} onShowRoster={handleShowRoster} />
      )}
    </div>
  );
});
