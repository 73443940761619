import { useMemo } from 'react';
import cn from 'classnames';

import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './video-preview.module.less';

interface IVideoPreviewProps {
  url: string;
  type?: string;
  size: VideoPreviewSize;
  forGallery?: boolean;
}

export enum VideoPreviewSize {
  XS,
  S,
  M,
}

export const VideoPreview = (props: IVideoPreviewProps) => {
  const { size, url, type, forGallery = false } = props;

  const videoClassNames = useMemo(
    () =>
      cn(styles.Video, {
        [styles['Video--extra-small']]: size === VideoPreviewSize.XS,
        [styles['Video--small']]: size === VideoPreviewSize.S,
        [styles['Video--medium']]: size === VideoPreviewSize.M,
      }),
    [size],
  );

  const playIconClassNames = useMemo(
    () =>
      cn(styles.PlayIcon, {
        [styles['PlayIcon--for-gallery']]: forGallery,
      }),
    [forGallery],
  );

  return (
    <>
      <div className={playIconClassNames}>
        <IconFont name={IconFontName.PlaySmall} size={IconFontSize.Small} />
      </div>

      <video
        // because of need using unknown-property
        /* eslint-disable-next-line react/no-unknown-property */
        webkit-playsinline={1}
        playsInline
        className={videoClassNames}
        src={`${url}#t=0.1`}
        autoPlay
        muted
        preload="metadata"
      >
        <source type={type} />
        <track src={url} kind="captions" />
      </video>
    </>
  );
};
