import { useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { LayoutStore } from 'stores/layout/layout.store';

import {
  CUSTOM_SCROLL_EVENT,
  TEAM_PROFILE_HEADER_APPEARANCE_DISTANCE,
} from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';

export const TeamProfileScrollControllerContainer = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);

  useEffect(() => {
    const listener = (event: Event) => {
      const detailCustomEvent = (event as CustomEvent).detail;
      const isHeaderVisible =
        detailCustomEvent.target.scrollTop >= TEAM_PROFILE_HEADER_APPEARANCE_DISTANCE;

      layoutStore.setIsMobilePlayerOrTeamHeaderVisible(isHeaderVisible);
    };

    document.addEventListener(CUSTOM_SCROLL_EVENT, listener);

    return () => {
      document.removeEventListener(CUSTOM_SCROLL_EVENT, listener);
    };
  }, [layoutStore]);

  return null;
});
