export interface IPublicLink {
  id: number;
  title: string;
  description?: string;
  url: string;
  type: LinkType;
  iconSrc?: string;
}

export interface ISocialLink {
  id: number;
  url: string;
  type: LinkType;
}

export interface IPublicLinkResponse {
  id: number;
  type: LinkType;
  title: string;
  description?: string;
  url: string;
  icon_src?: string;
  created_at: string;
  updated_at: string;
}

export enum LinkType {
  Twitter = 'twitter',
  Youtube = 'youtube',
  Instagram = 'instagram',
  TikTok = 'tiktok',
  Snapchat = 'snapchat',
  Discord = 'discord',
  Custom = 'custom',
}
