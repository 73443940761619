import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import styles from './tab.module.less';

export enum TabsSize {
  M,
  S,
}

interface ITabProps {
  size?: TabsSize;
  isActive: boolean;
  title: string;
  onClick: () => void;
}

export const Tab: FC<ITabProps> = memo((props: ITabProps) => {
  const { isActive, title, size = TabsSize.M } = props;

  const classNames = useMemo(
    () =>
      cn(styles.Tab, {
        [styles['Tab--active']]: isActive,
        [styles['Tab--size-m']]: size === TabsSize.M,
        [styles['Tab--size-s']]: size === TabsSize.S,
      }),
    [isActive, size],
  );

  return (
    <li className={classNames}>
      <button type="button" onClick={props.onClick}>
        {title}
      </button>
    </li>
  );
});
