import { memo, useMemo } from 'react';
import cn from 'classnames';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { formatBigNumbers } from 'helpers/format/format-big-numbers.utils';

import { useResponsive } from 'hooks/use-responsive';

import { AuthTooltip } from 'components/auth/auth-tooltip/auth-tooltip.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import { PostsTheme } from '../enums/posts-theme.enum';

import styles from './post-card-actions-bar.module.less';

export interface IPostCardActionsBarProps {
  theme: PostsTheme;
  likes: number;
  isLiked: boolean;
  isCommentsDisabled?: boolean;
  isBookmarked: boolean;
  commentsCount: number;
  bookmarksCount: number;
  sharesCount: number;
  onBookmarkClick?: () => void;
  onLikesClick?: () => void;
  onCommentClick?: () => void;
  onShareClick?: () => void;
  isCommentsAllowed: boolean;
}

export const PostCardActionsBar = memo((props: IPostCardActionsBarProps) => {
  const {
    theme,
    isLiked,
    isCommentsAllowed,
    isBookmarked,
    commentsCount,
    bookmarksCount,
    sharesCount,
    likes,
    isCommentsDisabled = false,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const actionIconSize = useMemo(() => {
    if (theme === PostsTheme.Detail || isDesktopPlus) {
      return IconFontSize.Big;
    }

    return IconFontSize.Small;
  }, [theme, isDesktopPlus]);

  const commentsCountClasses = useMemo(
    () =>
      cn(styles.PostCardActionsBar__Number, {
        [styles['PostCardActionsBar__Number--disabled']]: isCommentsDisabled,
      }),
    [isCommentsDisabled],
  );

  return (
    <div className={styles.PostCardActionsBar}>
      <div className={styles.PostCardActionsBar__Actions}>
        <div className={styles.PostCardActionsBar__Item}>
          <AuthTooltip alignLeft={!isDesktopPlus && theme === PostsTheme.Detail}>
            <IconButton
              iconName={isLiked ? IconFontName.LikeFiled : IconFontName.Like}
              theme={IconButtonTheme.Transparent}
              iconSize={actionIconSize}
              onClick={props.onLikesClick}
              active={isLiked}
            />
          </AuthTooltip>
          {!!likes && (
            <span className={styles.PostCardActionsBar__Number}>{formatBigNumbers(likes)}</span>
          )}
        </div>
        {isCommentsAllowed && (
          <div className={styles.PostCardActionsBar__Item}>
            <AuthTooltip>
              <IconButton
                onClick={props.onCommentClick}
                iconName={IconFontName.Chat}
                theme={IconButtonTheme.Transparent}
                iconSize={actionIconSize}
                disabled={isCommentsDisabled}
              />
            </AuthTooltip>
            {!!commentsCount && (
              <span className={commentsCountClasses}>{formatBigNumbers(commentsCount)}</span>
            )}
          </div>
        )}

        <div className={styles.PostCardActionsBar__Item}>
          <AuthTooltip>
            {isBookmarked ? (
              <IconButton
                iconName={IconFontName.BookmarkFilled}
                theme={IconButtonTheme.Transparent}
                onClick={props.onBookmarkClick}
                active={isBookmarked}
                iconSize={actionIconSize}
              />
            ) : (
              <IconButton
                iconName={IconFontName.Bookmark}
                theme={IconButtonTheme.Transparent}
                onClick={props.onBookmarkClick}
                iconSize={actionIconSize}
              />
            )}
          </AuthTooltip>
          {!!bookmarksCount && (
            <span className={styles.PostCardActionsBar__Number}>
              {formatBigNumbers(bookmarksCount)}
            </span>
          )}
        </div>
        <div className={styles.PostCardActionsBar__Item}>
          <IconButton
            onClick={props.onShareClick}
            iconName={IconFontName.Share}
            theme={IconButtonTheme.Transparent}
            iconSize={actionIconSize}
          />
          {!!sharesCount && (
            <span className={styles.PostCardActionsBar__Number}>
              {formatBigNumbers(sharesCount)}
            </span>
          )}
        </div>
      </div>
    </div>
  );
});
