import { inject, injectable } from 'inversify';

import {
  COMMENT_ID_API_PARAM,
  GAME_CHAT_ITEM_ID_PARAM,
  GAME_ID_PARAM,
  POST_ID_API_PARAM,
} from 'services/http/consts/api-endpoint-params.constants';
import {
  REPORT_GAME_CHAT_ITEM,
  REPORT_POST,
  REPORT_POST_COMMENT,
  REPORT_REASONS,
} from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { TYPES } from 'configs/di-types.config';

import { IReportPayload } from './interfaces/report-payload.interface';
import { IReportReason } from './interfaces/report-reason.interface';

@injectable()
export class ReportsService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchReportReasons(): Promise<IResponse<IReportReason[]>> {
    return this.httpService.request<IReportReason[]>({
      method: 'GET',
      url: REPORT_REASONS,
    });
  }

  public async sendPostReport(postId: string, reportReasonId: number) {
    return this.httpService.request<null, IReportPayload>({
      method: 'POST',
      url: REPORT_POST,
      headers: {
        'X-Version': '4',
      },
      routeParams: {
        [POST_ID_API_PARAM]: postId,
      },
      body: {
        report_reason_id: reportReasonId,
      },
    });
  }

  public async sendPostCommentReport(postId: string, commentId: string, reportReasonId: number) {
    return this.httpService.request<null, IReportPayload>({
      method: 'POST',
      url: REPORT_POST_COMMENT,
      headers: {
        'X-Version': '4',
      },
      routeParams: {
        [POST_ID_API_PARAM]: postId,
        [COMMENT_ID_API_PARAM]: commentId,
      },
      body: {
        report_reason_id: reportReasonId,
      },
    });
  }

  public sendGameChatItemReport(
    gameChatItemId: string,
    gameId: number,
    reportReasonId: number,
  ): Promise<IResponse<null>> {
    return this.httpService.request<null, IReportPayload>({
      method: 'POST',
      url: REPORT_GAME_CHAT_ITEM,
      headers: {
        'X-Version': '4',
      },
      routeParams: {
        [GAME_ID_PARAM]: String(gameId),
        [GAME_CHAT_ITEM_ID_PARAM]: gameChatItemId,
      },
      body: {
        report_reason_id: reportReasonId,
      },
    });
  }
}
