import { FC } from 'react';
import { components, MultiValueRemoveProps } from 'react-select';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './custom-multi-value-remove.module.less';

export const CustomMultiValueRemove: FC<MultiValueRemoveProps> = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <div className={styles.Wrapper}>
        <IconButton
          iconName={IconFontName.Close}
          theme={IconButtonTheme.Transparent}
          iconSize={IconFontSize.Small}
        />
      </div>
    </components.MultiValueRemove>
  );
};
