import { FC, memo, PropsWithChildren, ReactNode, useMemo } from 'react';
import cn from 'classnames';

import styles from './column.module.less';

type AvailableColumnType = 1 | 2 | 3;

type DeviceColumnsType =
  | AvailableColumnType
  | {
      start: AvailableColumnType;
      end: AvailableColumnType;
    };

interface IColumnProps {
  desktop?: DeviceColumnsType;
  mobile?: DeviceColumnsType;
  children: ReactNode;
}

export const Column: FC<IColumnProps> = memo((props: PropsWithChildren<IColumnProps>) => {
  const { children, mobile, desktop } = props;

  const desktopClasses = useMemo<string>(
    () =>
      desktop
        ? cn(
            typeof desktop === 'object'
              ? [
                  styles[`Col--desktop-start-${desktop.start}`],
                  styles[`Col--desktop-end-${desktop.end}`],
                ]
              : styles[`Col--desktop-${desktop}`],
          )
        : '',
    [desktop],
  );

  const mobileClasses = useMemo<string>(
    () =>
      mobile
        ? cn(
            typeof mobile === 'object'
              ? [
                  styles[`Col--mobile-start-${mobile.start}`],
                  styles[`Col--mobile-end-${mobile.end}`],
                ]
              : styles[`Col--mobile-${mobile}`],
          )
        : '',
    [mobile],
  );

  const classNames = useMemo<string>(
    () => cn(styles.Col, mobileClasses, desktopClasses),
    [desktopClasses, mobileClasses],
  );

  return <div className={classNames}>{children}</div>;
});
