import { LINK_ID_API_PARAM } from 'services/http/consts/api-endpoint-params.constants';
import { LINKS_FAVICON, LINKS_PREVIEW } from 'services/http/consts/api-endpoints.constants';
import { ILinkAttachmentResponse } from 'services/posts/interfaces/post-attachments-response.interface';

import { getPath } from 'helpers/get-path.util';

import { ILinkAttachment } from '../interfaces/post.interface';

export function linkAdapter(linkResponse: ILinkAttachmentResponse): ILinkAttachment {
  const previewUrl =
    linkResponse.preview_image_url &&
    getPath(LINKS_PREVIEW, {
      [LINK_ID_API_PARAM]: linkResponse.uuid,
    });

  const faviconUrl =
    linkResponse.favicon_url &&
    getPath(LINKS_FAVICON, {
      [LINK_ID_API_PARAM]: linkResponse.uuid,
    });

  return {
    previewImageUrl: previewUrl,
    sourceName: linkResponse.source_name,
    sourceFaviconUrl: faviconUrl,
    url: linkResponse.url,
    title: linkResponse.title,
    uuid: linkResponse.uuid,
    isEmbedded: linkResponse.is_embedded,
    embeddedType: linkResponse.embedded_type,
  };
}
