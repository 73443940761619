import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames';

import { IFollowPayload } from 'services/follow/interfaces/followings-response.interface';
import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ContainerNamesEnum } from 'services/google-analytic/enums/container-names.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { IFollow } from 'stores/follow/interfaces/follow.interface';

import {
  FOLLOWINGS_LEAVE_CONTENT,
  FOLLOWINGS_LEAVE_TITLE,
  FOLLOWINGS_UNSAVED_CONTENT,
  FOLLOWINGS_UNSAVED_TITLE,
} from 'configs/confirmation-content.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';
import { getRandomItemsFromArray } from 'helpers/get-random-items-from-array.utils';
import * as path from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { FollowingItem } from 'components/followings/following-item/following-item.component';
import { createFollowPayload } from 'components/followings/helpers/create-follow-payload.util';
import { filterFollowings } from 'components/followings/helpers/filter-followings.util';
import { toggleFollowItemInArray } from 'components/followings/toggle-follow-item-in-array.util';
import { ISearchFormData, SearchForm } from 'components/forms/search-form/search-form.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import {
  IconButtonWithText,
  IconButtonWithTextSize,
  IconButtonWithTextTheme,
} from 'components/ui/icon-button-with-text/icon-button-with-text.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';

import { ConfirmationPopup } from './confirmation-popup/confirmation-popup.component';

import styles from './followings.module.less';

interface IFollowingsProps {
  isFirstTimeOpened: boolean;
  isLoading: boolean;
  initialFollowings: IFollow[];
  isDisplayedSmartBanner: boolean;
  onFollowClick: (followPayload: IFollowPayload) => Promise<boolean>;
  onOpenSignUpModal: () => void;
  onGoogleAnalyticsHomeFeed: (screenType: ScreenNamesEnum) => void;
}

export const Followings = memo((props: IFollowingsProps) => {
  const {
    isFirstTimeOpened,
    initialFollowings,
    isLoading,
    isDisplayedSmartBanner,
    onFollowClick,
    onOpenSignUpModal,
    onGoogleAnalyticsHomeFeed,
  } = props;

  const navigate = useNavigate();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [allOptions, setAllOptions] = useState<IFollow[]>([]);
  const [showingOptions, setShowingOptions] = useState<IFollow[]>([]);
  const [isExpandedSelection, setIsExpandedSelection] = useState(true);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isTeamsEnable, setIsTeamsEnable] = useState(true);
  const [isPlayersEnable, setIsPlayersEnable] = useState(false);
  const [randomTeams, setRandomTeams] = useState<IFollow[]>([]);
  const [randomPlayers, setRandomPlayers] = useState<IFollow[]>([]);
  const [userFilterText, setUserFilterText] = useState<ISearchFormData>({ searchPhrase: '' });

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  useEffect(() => {
    setAllOptions(initialFollowings);
    setShowingOptions(initialFollowings);
  }, [initialFollowings]);

  useEffect(() => {
    if (isSearchActive) {
      setIsExpandedSelection(false);
    }
  }, [isSearchActive]);

  useEffect(() => {
    if (
      userFilterText.searchPhrase &&
      !showingOptions.length &&
      allOptions.length &&
      !randomTeams.length &&
      !randomPlayers.length
    ) {
      setRandomTeams(
        getRandomItemsFromArray(
          allOptions.filter((item) => item.type === 'team' && !item.isFollow),
          3,
        ),
      );

      setRandomPlayers(
        getRandomItemsFromArray(
          allOptions.filter((item) => item.type === 'player' && !item.isFollow),
          3,
        ),
      );
    }
  }, [randomTeams, randomPlayers, allOptions, showingOptions, userFilterText.searchPhrase]);

  useEffect(() => {
    const searchResult = filterFollowings(allOptions, userFilterText.searchPhrase);
    setShowingOptions(searchResult);
  }, [allOptions, userFilterText]);

  const selectedOptions = useMemo(() => allOptions.filter((item) => item.isFollow), [allOptions]);

  const selectedTeams = useMemo(
    () => selectedOptions.filter((item) => item.type === 'team'),
    [selectedOptions],
  );
  const selectedPlayers = useMemo(
    () => selectedOptions.filter((item) => item.type === 'player'),
    [selectedOptions],
  );

  const showingTeams = useMemo(
    () => showingOptions.filter((item) => item.type === 'team'),
    [showingOptions],
  );

  const showingPlayers = useMemo(
    () => showingOptions.filter((item) => item.type === 'player'),
    [showingOptions],
  );

  const isInitialSelectionChanged: boolean = useMemo(() => {
    for (let i = 0; i < selectedOptions.length; i++) {
      const currentInitialItem = initialFollowings.find(
        (current) => current.id === selectedOptions[i].id,
      );
      if (currentInitialItem && selectedOptions[i].isFollow !== currentInitialItem.isFollow) {
        return true;
      }
    }

    for (let i = 0; i < initialFollowings.length; i++) {
      if (initialFollowings[i].isFollow) {
        const currentSelectedItem = selectedOptions.find(
          (current) => current.id === initialFollowings[i].id,
        );
        if (!currentSelectedItem) {
          return true;
        }
      }
    }

    return false;
  }, [selectedOptions, initialFollowings]);

  const isShowFollowButton: boolean = useMemo(() => {
    if (isFirstTimeOpened) {
      return !!selectedOptions.length;
    }

    return isInitialSelectionChanged;
  }, [isInitialSelectionChanged, isFirstTimeOpened, selectedOptions]);

  const handleResetClick = useCallback(async () => {
    setUserFilterText({ searchPhrase: '' });
    setIsSearchActive(false);
  }, []);

  const handleToggleSectionSection = useCallback(async () => {
    setIsExpandedSelection(!isExpandedSelection);
  }, [isExpandedSelection]);

  const handleMockCallback = useCallback(() => {}, []);

  const handleChangeSearchInput = useCallback(
    (searchPhrase: string) => {
      setUserFilterText({ searchPhrase });
    },
    [setUserFilterText],
  );

  const handleCloseConfirmationModal = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, []);

  const handleSkipClick = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.FollowTeamsPlayersSkip,
      },
    });

    setIsConfirmModalOpen(true);
  }, []);

  const handleConfirmSkip = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.SkipFollowTeamPlayers,
      },
    });

    navigate(path.HOME);
  }, [navigate]);

  const handleBackClick = useCallback(() => {
    if (isInitialSelectionChanged) {
      setIsConfirmModalOpen(true);
    } else {
      navigate(-1);
    }
  }, [navigate, isInitialSelectionChanged]);

  const handleTabClick = useCallback(() => {
    if (isSearchActive) {
      return;
    }

    if (isTeamsEnable && !isPlayersEnable) {
      setIsTeamsEnable(false);
      setIsPlayersEnable(true);
    } else {
      setIsTeamsEnable(true);
      setIsPlayersEnable(false);
    }
  }, [isPlayersEnable, isSearchActive, isTeamsEnable]);

  const handleSearchFocus = useCallback(() => {
    setIsSearchActive(true);
  }, []);

  const handleSearchBlur = useCallback(() => {
    if (!userFilterText.searchPhrase) {
      setIsSearchActive(false);
    }
  }, [userFilterText]);

  const handleToggleFollow = useCallback(
    (id: string) => {
      const newShowingFollowings = toggleFollowItemInArray(allOptions, id);
      setAllOptions(newShowingFollowings);

      const newRandomTeams = toggleFollowItemInArray(randomTeams, id);
      setRandomTeams(newRandomTeams);

      const newRandomPlayers = toggleFollowItemInArray(randomPlayers, id);
      setRandomPlayers(newRandomPlayers);
    },
    [allOptions, randomPlayers, randomTeams],
  );

  const teamsTabClasses = useMemo(
    () =>
      cn(styles.Tab, {
        [styles['Tab--active']]: isTeamsEnable || isSearchActive,
      }),
    [isTeamsEnable, isSearchActive],
  );

  const playersTabClasses = useMemo(
    () =>
      cn(styles.Tab, {
        [styles['Tab--active']]: isPlayersEnable || isSearchActive,
      }),
    [isPlayersEnable, isSearchActive],
  );

  const mainContentClasses = useMemo(
    () =>
      cn(styles.FollowWrapper__Main, {
        [styles['FollowWrapper__Main--with-footer']]: isShowFollowButton,
      }),
    [isShowFollowButton],
  );

  const resultsTeamsClasses = useMemo(
    () =>
      cn(styles.Results__Teams, {
        [styles['Results__Teams--with-border']]: isSearchActive && showingTeams.length,
      }),
    [showingTeams, isSearchActive],
  );

  const wrapperClasses = useMemo<string>(
    () =>
      cn(styles.FollowWrapper, TOUCH_IGNORE_CLASS, {
        [styles['FollowWrapper--has-smart-banner']]: isDisplayedSmartBanner,
      }),
    [isDisplayedSmartBanner],
  );

  const headerClasses = useMemo(
    () =>
      cn(styles.FollowWrapper__Header, {
        [styles['FollowWrapper__Header--with-skip-button']]: isFirstTimeOpened,
      }),
    [isFirstTimeOpened],
  );

  const resultsClasses = useMemo(
    () =>
      cn(styles.Results, {
        [styles['Results--center']]: !showingTeams.length && !showingPlayers.length,
      }),
    [showingTeams, showingPlayers],
  );

  const followText = useMemo(() => (isFirstTimeOpened ? 'Follow' : 'Save'), [isFirstTimeOpened]);

  const handleConfirmChanges = useCallback(async () => {
    const isSuccess = await onFollowClick(
      createFollowPayload(selectedTeams, selectedPlayers, initialFollowings),
    );

    setIsConfirmModalOpen(false);

    if (isSuccess) {
      if (isFirstTimeOpened) {
        navigate(path.HOME_FEED);
        onOpenSignUpModal();
      } else {
        navigate(-1);
      }
    }
  }, [
    isFirstTimeOpened,
    initialFollowings,
    selectedPlayers,
    selectedTeams,
    navigate,
    onFollowClick,
    onOpenSignUpModal,
  ]);

  useEffect(() => {
    if (isTeamsEnable) {
      onGoogleAnalyticsHomeFeed(ScreenNamesEnum.FollowTeamsAndPlayersTeams);
    }
    if (isPlayersEnable) {
      onGoogleAnalyticsHomeFeed(ScreenNamesEnum.FollowTeamsAndPlayersPlayers);
    }
  }, [isTeamsEnable, isPlayersEnable, onGoogleAnalyticsHomeFeed]);

  useEffect(() => {
    if (isConfirmModalOpen) {
      GoogleAnalyticService.event({
        eventName: 'container_custom_view',
        eventParams: {
          container_type: ContainerNamesEnum.SkipFollowTeamPlayers,
        },
      });
    }
  }, [isConfirmModalOpen]);

  return (
    <div className={wrapperClasses}>
      <div className={headerClasses}>
        {!isFirstTimeOpened && !isDesktopPlus && (
          <IconButton
            theme={IconButtonTheme.Transparent}
            iconName={IconFontName.ChevronLeft}
            onClick={handleBackClick}
          />
        )}
        {!isFirstTimeOpened && isDesktopPlus && (
          <IconButtonWithText
            theme={IconButtonWithTextTheme.Tertiary}
            onClick={handleBackClick}
            iconName={IconFontName.ChevronLeft}
            text="Back"
            size={IconButtonWithTextSize.Small}
          />
        )}
        <div className={styles.Title}>Follow Teams & Players</div>
        {isFirstTimeOpened && (
          <button onClick={handleSkipClick} className={styles.Skip}>
            Skip
          </button>
        )}
      </div>
      <div className={styles.FollowWrapper__Details}>
        Receive daily updates and create a more personalized experience.
      </div>
      <div className={styles.FollowWrapper__Filters}>
        <button onClick={handleTabClick} className={teamsTabClasses}>
          Teams {!!selectedTeams.length && `(${selectedTeams.length})`}
        </button>
        <button onClick={handleTabClick} className={playersTabClasses}>
          Players {!!selectedPlayers.length && `(${selectedPlayers.length})`}
        </button>
      </div>
      <div className={styles.FollowWrapper__Search}>
        <SearchForm
          onSubmit={handleMockCallback}
          rightIconName={IconFontName.Close}
          classNames={styles.SearchForm}
          onFocus={handleSearchFocus}
          initialValues={userFilterText}
          value={userFilterText}
          onRightIconClick={handleResetClick}
          onBlur={handleSearchBlur}
          onChange={handleChangeSearchInput}
        />
      </div>
      {!!selectedOptions.length && (
        <div className={styles.FollowWrapper__CollapsedSelection}>
          <div className={styles.Header}>
            <div>Following ({selectedOptions.length})</div>
            <IconButton
              iconName={isExpandedSelection ? IconFontName.ChevronUp : IconFontName.ChevronDown}
              theme={IconButtonTheme.Secondary}
              iconSize={IconFontSize.Big}
              onClick={handleToggleSectionSection}
            />
          </div>
          {isExpandedSelection && (
            <div className={styles.Items}>
              {selectedOptions.map((item) => (
                <FollowingItem
                  key={item.id}
                  id={item.id}
                  imageSrc={item.thumbnailUrl}
                  isSelected={item.isFollow}
                  isShowCheckmark={false}
                  name={item.label}
                  onClick={handleToggleFollow}
                />
              ))}
            </div>
          )}
        </div>
      )}
      {isLoading ? (
        <Loader isShow={isLoading} />
      ) : (
        <div className={mainContentClasses}>
          <div className={resultsClasses}>
            {!!showingTeams.length && (isTeamsEnable || isSearchActive) && (
              <div className={resultsTeamsClasses}>
                {showingTeams.map((item) => (
                  <FollowingItem
                    key={item.id}
                    id={item.id}
                    imageSrc={item.thumbnailUrl}
                    isSelected={item.isFollow}
                    name={item.label}
                    onClick={handleToggleFollow}
                  />
                ))}
              </div>
            )}
            {!!showingPlayers.length && (isPlayersEnable || isSearchActive) && (
              <div className={styles.Results__Players}>
                {showingPlayers.map((item) => (
                  <FollowingItem
                    key={item.id}
                    id={item.id}
                    imageSrc={item.thumbnailUrl}
                    isSelected={item.isFollow}
                    name={item.label}
                    onClick={handleToggleFollow}
                  />
                ))}
              </div>
            )}
            {!showingOptions.length && (
              <div className={styles.EmptyState}>
                <div className={styles.EmptyState__MainText}>No Matches found</div>
                <div className={styles.EmptyState__AdditionalText}>Try these popular</div>
                <div className={styles.EmptyState__RandomItems}>
                  {randomTeams.map((item) => (
                    <FollowingItem
                      key={item.id}
                      id={item.id}
                      imageSrc={item.thumbnailUrl}
                      isSelected={item.isFollow}
                      name={item.label}
                      onClick={handleToggleFollow}
                    />
                  ))}
                  {randomPlayers.map((item) => (
                    <FollowingItem
                      key={item.id}
                      id={item.id}
                      imageSrc={item.thumbnailUrl}
                      isSelected={item.isFollow}
                      name={item.label}
                      onClick={handleToggleFollow}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>

          {isShowFollowButton && (
            <div className={styles.Follow}>
              <Button
                fluid
                theme={ButtonTheme.Primary}
                size={ButtonSize.Big}
                onClick={handleConfirmChanges}
              >
                {followText}
              </Button>
            </div>
          )}
        </div>
      )}
      <ConfirmationPopup
        visible={isFirstTimeOpened && !isInitialSelectionChanged && isConfirmModalOpen}
        content={FOLLOWINGS_LEAVE_CONTENT}
        title={FOLLOWINGS_LEAVE_TITLE}
        primaryButtonText="Skip"
        secondaryButtonText={null}
        onClose={handleCloseConfirmationModal}
        onSuccessCallback={handleConfirmSkip}
      />
      <ConfirmationPopup
        visible={isInitialSelectionChanged && isConfirmModalOpen}
        content={FOLLOWINGS_UNSAVED_CONTENT}
        title={FOLLOWINGS_UNSAVED_TITLE}
        primaryButtonText="Continue Editing"
        secondaryButtonText="Confirm"
        onClose={handleCloseConfirmationModal}
        onSuccessCallback={handleCloseConfirmationModal}
        onRejectCallback={handleConfirmSkip}
      />
    </div>
  );
});
