import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';

import { SeasonsStore } from 'stores/seasons/seasons.store';
import { TeamsStore } from 'stores/teams/teams.store';
import { ITeamsStatsColors } from 'stores/teams-stats/interfaces/teams-stats.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';
import { PositionsType } from 'stores/teams-stats/types/positions-type.type';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useGaScreenCustomView } from 'hooks/use-ga-screen-custom-view';
import { useToggleIgnoredTouchClass } from 'hooks/use-toggle-ignored-touch-class';

import { TeamStats } from 'components/team-stats/team-stats.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './team-stats.module.less';

export const TeamStatsContainer = observer(() => {
  const seasonsStore = useInjection<SeasonsStore>(TYPES.SeasonsStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);

  const { teamId } = teamsStore;
  const { teams } = teamsStatsStore;

  const navigate = useNavigate();

  const { googleAnalyticsTeamFeed } = useGaScreenCustomView();

  useToggleIgnoredTouchClass(!teamsStatsStore.teamsStats);

  const currentStatsTeam = useMemo(
    () => teams.find((team) => teamsStore.teamId === team.id) || null,
    [teamsStore.teamId, teams],
  );

  useEffect(() => {
    return () => {
      teamsStatsStore.resetTeamStats();
    };
  }, [currentStatsTeam, teamsStatsStore]);

  const handleSeasonIdChange = useCallback(
    (seasonId: string) => {
      teamsStatsStore.setSeasonId(seasonId);
    },
    [teamsStatsStore],
  );

  const handlePositionChange = useCallback(
    (position: PositionsType) => {
      teamsStatsStore.setPosition(position);
    },
    [teamsStatsStore],
  );

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(paths.PLAYER_PROFILE, { [paths.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  const teamColors = useMemo<ITeamsStatsColors>(
    () => ({
      main: currentStatsTeam?.color || null,
      text: currentStatsTeam?.textColor || null,
    }),
    [currentStatsTeam],
  );

  const loading = useMemo(() => {
    if (!teamsStore.teamId) {
      return true;
    }

    return teamsStatsStore.fetchingStatsByTeamId;
  }, [teamsStore.teamId, teamsStatsStore.fetchingStatsByTeamId]);

  useEffect(() => {
    if (teamId) {
      googleAnalyticsTeamFeed(ScreenNamesEnum.TeamProfileStats);
    }
  }, [teamId, googleAnalyticsTeamFeed]);

  if (loading) {
    return (
      <div className={styles.Wrapper}>
        <Loader isShow withBackground />
      </div>
    );
  }

  return (
    <TeamStats
      teamStats={teamsStatsStore.formattedTeamStatsRowData}
      teamColors={teamColors}
      seasonsOptions={seasonsStore.seasonsSelectOptions}
      topPerformers={teamsStatsStore.teamsStats?.topPerformers || null}
      playersStats={teamsStatsStore.playersStats}
      team={teamsStatsStore.team}
      position={teamsStatsStore.position}
      seasonId={teamsStatsStore.seasonId}
      onPositionChange={handlePositionChange}
      onSeasonIdChange={handleSeasonIdChange}
      onPlayerClick={handlePlayerClick}
    />
  );
});
