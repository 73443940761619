import Dexie from 'dexie';
import { injectable } from 'inversify';

import { dbLogger } from 'loggers/db.logger';

const DATABASE_NAME = 'DigitsDB';

const POPUPS_TABLE = 'popups';

@injectable()
export class LocalDatabaseService {
  private readonly db: Dexie;

  constructor() {
    this.db = new Dexie(DATABASE_NAME);

    this.initializeDatabase();
  }

  private initializeDatabase() {
    dbLogger.info({ msg: 'initialise Dexie database' });

    // Define the database schema
    this.db.version(3).stores({
      [POPUPS_TABLE]: 'key, value',
    });
  }

  private async checkIfDatabaseExists(): Promise<boolean> {
    try {
      await this.db.open();
      // Open the database to check if it exists
      return true;
    } catch {
      // If an error occurs, the database doesn't exist
      return false;
    } finally {
      // Close the database after checking
      this.db.close();
    }
  }

  public async configureStorage() {
    dbLogger.info({ msg: 'configure Dexie database storage' });

    // Check if the database exists
    const databaseExists = await this.checkIfDatabaseExists();

    // If the database doesn't exist, initialize it
    if (!databaseExists) {
      this.initializeDatabase();
    }

    // Check if the database is closed, then open it
    if (this.db.isOpen()) {
      this.db.close();
    }

    await this.db.open();
  }

  public async getPopupsByKey(key: string) {
    try {
      const popupValue = await this.db.table(POPUPS_TABLE).where('key').equals(key).first();

      dbLogger.debug({ msg: 'get popups by key', key, popupValue });

      return popupValue;
    } catch {
      return null;
    }
  }

  public async updatePopupsTableByKey<T>(key: string, value: T): Promise<void> {
    dbLogger.debug({ msg: 'update popups by key', key, popupValue: value });

    await this.db.table(POPUPS_TABLE).put({ key, value });
  }
}
