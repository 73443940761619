import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import cn from 'classnames';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AuthService } from 'services/auth/auth.service';
import { ISingleSocialLink } from 'services/auth/interfaces/social-links-payload.interface';

import { AuthStore } from 'stores/auth/auth.store';
import { LinkType } from 'stores/auth/interfaces/custom-link.interface';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { SocialLinksFormDataType } from 'components/forms/social-links/social-links-form.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { getLinkValueByKey } from 'components/player-profile/player-profile-overview/player-profile-overview.component';
import { ProfileInfoSocials } from 'components/profile-info-socials/profile-info-socials.component';
import { SocialLinksPopup } from 'components/social-links-popup/social-links-popup.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './social-links.module.less';

export const SocialLinksContainer: FC = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);
  const authService = useInjection<AuthService>(TYPES.AuthService);
  const params = useParams<{
    [paths.USERNAME_PARAM]: string;
  }>();
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const socialLinks = userPublicStore.userPublic?.socialLinks || [];
  const { username } = params;
  const isMyPage = Boolean(authStore.userMe?.username === username);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const handleEditLinksClick = useCallback(() => {
    setEditModalVisible(true);
  }, []);

  const handleSubmitUpdateClick = useCallback(
    async (payload: SocialLinksFormDataType) => {
      const arrayOfLinks: ISingleSocialLink[] = Object.keys(payload).map((key) => ({
        type: key as keyof SocialLinksFormDataType,
        url: payload[key as keyof SocialLinksFormDataType] as string,
      }));

      const filteredLinks = arrayOfLinks.filter((link) => link.url);

      const response = await authService.updateSocialLinks({ links: filteredLinks });
      if (response.success) {
        setEditModalVisible(false);
        await authStore.retrieveUserMe();
      }
    },
    [authService, authStore],
  );

  const handleDeleteAllLinksClick = () => {
    setIsConfirmModalOpen(true);
  };

  const handleCloseClick = () => {
    setEditModalVisible(false);
  };

  const handleOnConfirmDeleteComment = useCallback(async () => {
    const response = await authService.updateSocialLinks({ links: [] });
    setIsConfirmModalOpen(false);
    setEditModalVisible(false);

    if (response.success) {
      await authStore.retrieveUserMe();
    }
  }, [authService, authStore]);

  const handleOnCloseConfirmation = () => {
    setIsConfirmModalOpen(false);
  };

  const socialsClasses = useMemo(
    () =>
      cn(styles.SocialLinksContainer__Socials, {
        [styles['SocialLinksContainer__Socials--personal']]: isMyPage,
      }),
    [isMyPage],
  );

  return (
    <div className={styles.SocialLinksContainer}>
      <div className={socialsClasses}>
        {Boolean(socialLinks.length) && (
          <ProfileInfoSocials
            instagramUrl={getLinkValueByKey(socialLinks, LinkType.Instagram)}
            twitterUrl={getLinkValueByKey(socialLinks, LinkType.Twitter)}
            youtubeUrl={getLinkValueByKey(socialLinks, LinkType.Youtube)}
            tiktokUrl={getLinkValueByKey(socialLinks, LinkType.TikTok)}
            snapchat={getLinkValueByKey(socialLinks, LinkType.Snapchat)}
            discord={getLinkValueByKey(socialLinks, LinkType.Discord)}
          />
        )}
        {isMyPage && Boolean(socialLinks.length) && (
          <IconButton
            iconSize={IconFontSize.ExtraBig}
            iconName={IconFontName.Edit}
            theme={IconButtonTheme.Transparent}
            onClick={handleEditLinksClick}
          />
        )}
        {isMyPage && !socialLinks.length && (
          <Button
            fluid={!isDesktopPlus}
            theme={ButtonTheme.Tertiary2}
            iconName={IconFontName.Add}
            onClick={handleEditLinksClick}
            size={ButtonSize.Big}
          >
            Add Social Link
          </Button>
        )}
      </div>
      <SocialLinksPopup
        links={socialLinks}
        visible={editModalVisible}
        onClose={handleCloseClick}
        onSubmitClick={handleSubmitUpdateClick}
        onDeleteClick={handleDeleteAllLinksClick}
      />
      {isConfirmModalOpen && (
        <ConfirmationModal
          content="This action can’t be undone. Are you sure you want to delete this?"
          onSuccessCallback={handleOnConfirmDeleteComment}
          onClose={handleOnCloseConfirmation}
          title="Please confirm links removal"
          visible
          primaryButtonText="Yes"
          secondaryButtonText="No"
        />
      )}
    </div>
  );
});
