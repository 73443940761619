import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';

import { IPlayerOption } from 'containers/profile/profile-info/interfaces/player-option.interface';
import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';

import { SelectPlayer } from 'components/ui/form-fields/select-player/select-player.component';
import { SelectTeam } from 'components/ui/form-fields/select-team/select-team.component';
import { IInitialTeamAndPlayer } from 'components/user-details/reputation-box-item/interfaces/initial-team-and-player.interface';

import styles from './profile-team-and-player-select-form.module.less';

export interface IProfileTeamAndPlayerSelectFormProps {
  teamsOptions: ITeamOption[];
  playersOptions: IPlayerOption[];
  initialValues: IInitialTeamAndPlayer;
  initialFavPlayerColor: Maybe<string>;
  onSubmit: (data: FieldValues) => void;
  setDisabledScroll: (isDisabledScroll: boolean) => void;
}

const teamFieldName = 'teamId';
const playerFieldName = 'playerId';

export const ProfileTeamAndPlayerSelectForm: FC<IProfileTeamAndPlayerSelectFormProps> = memo(
  (props: IProfileTeamAndPlayerSelectFormProps) => {
    const {
      teamsOptions,
      playersOptions,
      initialValues,
      initialFavPlayerColor,
      onSubmit,
      setDisabledScroll,
    } = props;

    const formSelectorRef = useRef<HTMLDivElement>(null);

    const [formBackgroundColor, setFormBackgroundColor] = useState<string | null>(null);

    const memoizedBackground = useMemo(() => {
      if (formBackgroundColor) {
        return { background: formBackgroundColor };
      }
      return {};
    }, [formBackgroundColor]);

    const { watch, control, handleSubmit, formState, reset } = useForm({
      defaultValues: initialValues,
    });

    const { isDirty } = formState;

    const playerData = watch(playerFieldName);

    useEffect(() => {
      reset(initialValues);
    }, [initialValues, reset]);

    useEffect(() => {
      if (isDirty) {
        handleSubmit(onSubmit)();
      }
    }, [handleSubmit, onSubmit, isDirty]);

    useEffect(() => {
      if (playerData && playersOptions) {
        const currentPlayer = playersOptions.find((player) => player.value === playerData);
        if (currentPlayer) {
          setFormBackgroundColor(currentPlayer?.color);
        }
      } else {
        setFormBackgroundColor(initialFavPlayerColor);
      }
    }, [initialFavPlayerColor, playersOptions, playerData]);

    return (
      <div className={styles.SelectTeamAndPlayerForm} ref={formSelectorRef}>
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className={styles.SelectTeamAndPlayerForm__Form}
          style={memoizedBackground}
        >
          <Controller
            name={teamFieldName}
            control={control}
            render={({ field: { ref, ...restField } }) => (
              <SelectTeam
                {...restField}
                id={teamFieldName}
                name={teamFieldName}
                placeholder="Team"
                options={teamsOptions}
                setDisabledScroll={setDisabledScroll}
              />
            )}
          />
          <Controller
            name={playerFieldName}
            control={control}
            render={({ field: { ref, ...restField } }) => (
              <SelectPlayer
                {...restField}
                placeholder="Player"
                id={playerFieldName}
                name={playerFieldName}
                options={playersOptions}
                setDisabledScroll={setDisabledScroll}
              />
            )}
          />
        </form>
      </div>
    );
  },
);
