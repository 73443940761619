import { FC, useCallback } from 'react';

import { ISearchFormData, SearchForm } from 'components/forms/search-form/search-form.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './bookmarks-search.module.less';

interface IBookmarksSearchProps {
  value: ISearchFormData;
  onCancelButtonClick: () => void;
  onChange: (searchFormData: ISearchFormData) => void;
}

export const BookmarksSearch: FC<IBookmarksSearchProps> = (props) => {
  const { value, onChange } = props;

  const handleResetClick = useCallback(() => {
    onChange({ searchPhrase: '' });
  }, [onChange]);

  return (
    <div className={styles.Search}>
      <div className={styles.Search__Form}>
        <SearchForm
          initialValues={value}
          onSubmit={props.onChange}
          rightIconName={IconFontName.Close}
          onRightIconClick={handleResetClick}
        />
      </div>
      <div className={styles.Search__Button}>
        <Button
          onClick={props.onCancelButtonClick}
          size={ButtonSize.Small}
          theme={ButtonTheme.Text}
          isRandomId
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
