import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AuthStore } from 'stores/auth/auth.store';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { ISliderItem } from 'containers/posts/config/feed.config';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';
import { USERNAME_PARAM } from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { FeedsSelectSlider } from 'components/ui/feeds-select-slider/feeds-select-slider.component';

import styles from './public-user-feed-toggle.module.less';

enum PublicFeedsTabs {
  Feed = 'Feed',
  Predictions = 'Predictions',
  Bookmarks = 'Bookmarks',
}

export const PublicUserFeedToggleContainer = observer(() => {
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const navigate = useNavigate();

  const { username } = useParams<{
    [USERNAME_PARAM]: string;
  }>();

  const sliderItems = useMemo<ISliderItem[]>(() => {
    if (!username) {
      return [];
    }

    const tabs = [
      {
        label: PublicFeedsTabs.Feed,
        action: {
          type: BarAction.Link,
          path: getPath(paths.USER_PUBLIC_FEED, {
            [paths.USERNAME_PARAM]: username,
          }),
        },
        isDisabled: false,
        value: PublicFeedsTabs.Feed,
        isEnableNestedToggle: false,
        isLeftSide: true,
      },
      {
        label: PublicFeedsTabs.Predictions,
        action: {
          type: BarAction.Link,
          path: getPath(paths.USER_PUBLIC_PREDICTIONS, {
            [paths.USERNAME_PARAM]: username,
          }),
        },
        isDisabled: false,
        value: PublicFeedsTabs.Predictions,
        isEnableNestedToggle: false,
        isLeftSide: false,
      },
    ];

    if (
      userPublicStore.userPublic &&
      authStore.userMe &&
      userPublicStore.userPublic.username === authStore.userMe.username
    ) {
      tabs.push({
        label: PublicFeedsTabs.Bookmarks,
        action: {
          type: BarAction.Link,
          path: getPath(paths.USER_PUBLIC_BOOKMARKS, {
            [paths.USERNAME_PARAM]: username,
          }),
        },
        isDisabled: false,
        value: PublicFeedsTabs.Bookmarks,
        isEnableNestedToggle: false,
        isLeftSide: false,
      });

      return tabs;
    }

    return tabs;
  }, [authStore.userMe, userPublicStore.userPublic, username]);

  const handleSliderItemClick = useCallback(
    (action: BarActionType) => {
      if (action.type === BarAction.Link) {
        navigate(action.path, {
          replace: true,
        });
      }
    },
    [navigate],
  );

  return (
    <div className={styles.PublicUserFeedToggle}>
      <FeedsSelectSlider fullWidth items={sliderItems} onItemClick={handleSliderItemClick} />
    </div>
  );
});
