import {
  IPollAttachmentResponse,
  IPollOptionResponse,
} from 'services/posts/interfaces/post-attachments-response.interface';

import { imageAdapter } from 'stores/posts/adapters/image-adapter.util';

import { IPollAttachment, IPollOption } from '../interfaces/post.interface';

export function pollAdapter(pollResponse: IPollAttachmentResponse): IPollAttachment {
  const {
    uuid,
    type,
    votes_total: votesTotal,
    options,
    voted_option_uuid: votedOptionId,
    status,
    date_closed: dateClosed,
    expires_at: expiresAt,
    date_created: dateCreated,
    quote_content: quoteContent,
  } = pollResponse;

  const expirationDate = expiresAt || dateClosed;
  const pollOptions: IPollOption[] = getPollOptions(options);
  const expirationTimestamp: number = new Date(expirationDate).getTime();

  return {
    uuid,
    type,
    votesTotal,
    options: pollOptions,
    votedOptionId,
    status,
    expirationTimestamp,
    dateCreated,
    quoteContent,
  };
}

export const getPollOptions = (options: IPollOptionResponse[]): IPollOption[] => {
  return options.map((option) => ({
    uuid: option.uuid,
    title: option.title,
    attachmentImage: option.attachment_image ? imageAdapter(option.attachment_image) : null,
    votesTotal: option.votes_total,
    percentage: option.votes_percentage,
    order: option.order,
  }));
};
