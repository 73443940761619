import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { biographySchema, realNameSchema, userNameSchema } from 'validation/schemas/name.schema';

import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE, DEFAULT_VALIDATION_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { SubmitSection } from 'components/forms/submit-section/submit-section.component';
import { TextArea } from 'components/ui/form-fields/text-area/text-area.component';
import { TextInput } from 'components/ui/form-fields/text-input/text-input.component';

import formsStyles from '../forms.module.less';
import styles from './edit-profile.module.less';

const editProfileFormSchema = z
  .object({
    username: userNameSchema,
    realname: realNameSchema,
    biography: biographySchema,
  })
  .strict();

export type EditProfileFormDataType = z.infer<typeof editProfileFormSchema>;

const BIOGRAPHY_MAX_LENGTH = 100000;

export const EditProfileForm: FC<IForm<EditProfileFormDataType>> = (props) => {
  const { submitText, initialValues, onSubmit } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const { control, formState, handleSubmit } = useForm({
    defaultValues: initialValues,
    mode: DEFAULT_VALIDATION_MODE,
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(editProfileFormSchema),
  });

  const { isValid, errors } = formState;

  return (
    <Form className={styles.EditForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.EditForm__Label}>Identity Details</div>
      <div className={formsStyles.FormContent}>
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.username?.message}
              id="username"
              label="Username"
              placeholder="Username"
            />
          )}
        />
        <Controller
          name="realname"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.realname?.message}
              id="realname"
              label="Real Name"
              placeholder="Real Name"
            />
          )}
        />
        <Controller
          name="biography"
          control={control}
          render={({ field }) => (
            <TextArea
              {...field}
              isShowCounter={false}
              disabledResize={isDesktopPlus}
              maxLength={BIOGRAPHY_MAX_LENGTH}
              error={errors.biography?.message}
              id="biography"
              label="Biography"
              placeholder="Biography"
              rows={isDesktopPlus ? 3 : 7}
            />
          )}
        />
      </div>
      <div className={styles.SubmitButtonWrapper}>
        <SubmitSection
          disabled={!formState.isDirty}
          isFormValid={isValid}
          buttonText={submitText}
        />
      </div>
    </Form>
  );
};
