import { memo, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Browser } from '@capacitor/browser';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import cn from 'classnames';

import { useMainProvider } from 'hooks/use-main-provider';

import { editorLogger } from 'loggers/editor.logger';

import { ContentEditableFullHeight } from 'components/editor/components/content-editable-full-height/content-editable-full-height.component';
import { ContentEditableSingleLine } from 'components/editor/components/content-editable-single-line/content-editable-single-line';
import { ContentEditableWithReadMore } from 'components/editor/components/content-editable-with-read-more/content-editable-with-read-more.component';
import { ContentEditableWithTruncation } from 'components/editor/components/content-editable-with-truncation/content-editable-with-truncation';
import NodeList from 'components/editor/nodes/nodes.list';
import themeEditor from 'components/editor/themes/basic-theme';

import { useRichTextCollapse } from './use-rich-text-collapse';

import styles from './rich-text-preview.module.less';

function onError(error: Error) {
  editorLogger.error({ msg: error.message });
}

export enum RichTextContentTypes {
  WithReadMore,
  FullHeight,
  Truncated,
  SingleLine,
}

export enum RichTextContentTheme {
  Black,
  Grey,
  LightGrey,
  DarkGrey,
  Highlighted,
}

interface IRichTextPreviewProps {
  id: string;
  editorState: string;
  editorType: RichTextContentTypes;
  theme?: RichTextContentTheme;
  getTextLinesLength?: () => number;
  setCollapsedTitle?: (collapsedTitle: boolean) => void;
}

export const RichTextPreview = memo((props: IRichTextPreviewProps) => {
  const {
    id,
    editorState,
    editorType,
    theme = RichTextContentTheme.Black,
    getTextLinesLength,
    setCollapsedTitle,
  } = props;

  const [expandedRichText, setExpandedRichText] = useState(false);

  const { isNativeApp } = useMainProvider();

  const countTextLines = getTextLinesLength?.() ?? 1;

  const { ref, maxHeight, enabledCollapseButton } = useRichTextCollapse(
    countTextLines,
    editorType === RichTextContentTypes.WithReadMore,
  );

  const contentEditableComponent = useMemo(() => {
    switch (editorType) {
      case RichTextContentTypes.WithReadMore:
        return <ContentEditableWithReadMore />;
      case RichTextContentTypes.FullHeight:
        return <ContentEditableFullHeight />;
      case RichTextContentTypes.Truncated:
        return <ContentEditableWithTruncation />;
      case RichTextContentTypes.SingleLine:
        return <ContentEditableSingleLine />;
      default:
        return <ContentEditableFullHeight />;
    }
  }, [editorType]);

  const richTextContentStyles = useMemo(() => {
    if (expandedRichText || editorType !== RichTextContentTypes.WithReadMore) {
      return {
        maxHeight: 'none',
      };
    }

    return {
      maxHeight: `${maxHeight / 10}rem`,
    };
  }, [expandedRichText, maxHeight, editorType]);

  const richTextContentButtonClasses = useMemo(
    () =>
      cn(styles.RichTextContentButton, {
        [styles['RichTextContentButton--light-grey-theme']]:
          theme === RichTextContentTheme.LightGrey,
        [styles['RichTextContentButton--grey-theme']]: theme === RichTextContentTheme.Grey,
        [styles['RichTextContentButton--dark-grey-theme']]: theme === RichTextContentTheme.DarkGrey,
        [styles['RichTextContentButton--highlighted-theme']]:
          theme === RichTextContentTheme.Highlighted,
        [styles['RichTextContentButton--toggled']]: expandedRichText,
        [styles['RichTextContentButton--shorter']]: maxHeight === 0,
      }),
    [theme, expandedRichText, maxHeight],
  );

  const richTextContentClasses = useMemo(
    () =>
      cn(styles.RichTextContent, {
        [styles['RichTextContent--shorter']]: maxHeight === 0,
      }),
    [maxHeight],
  );

  const handleSeeMoreClick = useCallback((event: SyntheticEvent) => {
    event.preventDefault();

    setExpandedRichText((prevState) => !prevState);
  }, []);

  useEffect(() => {
    const handleLinkClick = (event: MouseEvent) => {
      event.stopPropagation();

      if (isNativeApp) {
        event.preventDefault();

        const reference = event.currentTarget as HTMLAnchorElement;

        Browser.open({ url: reference.href });
      }
    };

    const allLinks = ref.current?.querySelectorAll('a');

    if (allLinks) {
      allLinks.forEach((link) => {
        link.addEventListener('click', handleLinkClick);
      });
    }

    return () => {
      if (allLinks) {
        allLinks.forEach((link) => {
          link.removeEventListener('click', handleLinkClick);
        });
      }
    };
  }, [isNativeApp, ref]);

  useEffect(() => {
    setCollapsedTitle?.(!expandedRichText);
  }, [expandedRichText, setCollapsedTitle]);

  return (
    <LexicalComposer
      key={id}
      initialConfig={{
        editorState,
        editable: false,
        namespace: 'Rich Text Preview',
        onError,
        nodes: [...NodeList],
        theme: themeEditor,
      }}
    >
      <div ref={ref} className={richTextContentClasses} style={richTextContentStyles}>
        <RichTextPlugin
          contentEditable={contentEditableComponent}
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />
        {editorType === RichTextContentTypes.WithReadMore && enabledCollapseButton && (
          <div className={styles.RichTextContent__Button}>
            <button className={richTextContentButtonClasses} onClick={handleSeeMoreClick}>
              {expandedRichText ? 'See less' : 'See more'}
            </button>
          </div>
        )}
      </div>
    </LexicalComposer>
  );
});
