import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';
import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';
import { SortType } from 'types/sort-type.type';

import {
  IUserBalanceResponse,
  IUserBalancesResponse,
} from 'services/leaderboard/interfaces/leaderboard-response.interface';
import { LeaderboardService } from 'services/leaderboard/leaderboard.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { AuthStore } from 'stores/auth/auth.store';
import { BucketsStore } from 'stores/buckets/buckets.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { userBalanceAdapter } from 'stores/leaderboard/adapters/user-balance-adapter.util';
import { IUserBalance } from 'stores/leaderboard/interfaces/leaderboard.interface';
import { PAGINATION_LIMIT } from 'stores/leaderboard/leaderboard.config';
import { SeasonsStore } from 'stores/seasons/seasons.store';

import { TYPES } from 'configs/di-types.config';

export enum SortParams {
  TOTAL_POINTS = 'total_points',
  PLAYER_POINTS = 'player_points',
  PARTICIPATION_POINTS = 'participation_points',
  PREDICTION_POINTS = 'prediction_points',
}

@injectable()
export class LeaderboardStore extends ApiConnectedStore {
  private readonly seasonsStore: SeasonsStore;

  private readonly bucketsStore: BucketsStore;

  private readonly layoutStore: LayoutStore;

  private readonly authStore: AuthStore;

  private readonly leaderboardService: LeaderboardService;

  private readonly paginationLimit: number;

  private currentPage: number;

  public order: SortType;

  public sortParam: SortParams;

  public seasonId: Maybe<string>;

  public bucketId: Maybe<string>;

  public currentUserBalance: Maybe<IUserBalance>;

  constructor(
    @inject<SeasonsStore>(TYPES.SeasonsStore)
    seasonsStore: SeasonsStore,
    @inject<BucketsStore>(TYPES.BucketsStore)
    bucketsStore: BucketsStore,
    @inject<LeaderboardService>(TYPES.LeaderboardService)
    leaderboardService: LeaderboardService,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
    @inject(TYPES.AuthStore) authStore: AuthStore,
  ) {
    super();

    this.seasonsStore = seasonsStore;
    this.bucketsStore = bucketsStore;
    this.layoutStore = layoutStore;
    this.authStore = authStore;

    this.leaderboardService = leaderboardService;

    this.paginationLimit = PAGINATION_LIMIT;
    this.currentPage = 1;
    this.order = 'DESC';
    this.sortParam = SortParams.TOTAL_POINTS;

    this.seasonId = null;
    this.bucketId = null;

    this.currentUserBalance = null;

    makeObservable(this, {
      seasonId: observable,
      bucketId: observable,
      sortParam: observable,
      currentUserBalance: observable,

      pagination: computed,

      setCurrentPage: action.bound,
      setOrder: action.bound,
      setSortParam: action.bound,
      setCurrentUserBalance: action.bound,
      setSeasonId: action.bound,
      setBucketId: action.bound,
      fetchCurrentUserBalance: action.bound,
    });

    autorun(() => this.setSeasonId(this.seasonsStore.currentReputationSeasonOption?.value ?? null));
    autorun(() => this.setBucketId(this.bucketsStore.defaultBucket.value));
    reaction(() => [this.seasonId, this.bucketId], this.fetchCurrentUserBalance);
  }

  public get pagination(): IPagination {
    return {
      limit: this.paginationLimit,
      page: this.currentPage,
    };
  }

  public setCurrentPage(currentPage: number) {
    this.currentPage = currentPage;
  }

  public setOrder(order: SortType) {
    this.order = order;
  }

  public setSortParam(sortParam: SortParams) {
    this.sortParam = sortParam;
  }

  public setSeasonId(seasonId: Maybe<string>) {
    this.seasonId = seasonId;
  }

  public setBucketId(bucketId: Maybe<string>) {
    this.bucketId = bucketId;
  }

  public setCurrentUserBalance(userBalance: Maybe<IUserBalance>) {
    this.currentUserBalance = userBalance;
  }

  public fetchUserBalances(
    seasonId: string,
    bucketId: Maybe<string>,
  ): Promise<IResponse<IUserBalancesResponse>> {
    return this.leaderboardService.fetchUserBalances(
      seasonId,
      bucketId,
      this.pagination,
      this.order,
      this.sortParam,
    );
  }

  public async retrieveUserBalances(): Promise<{ rows: IUserBalance[]; count: number }> {
    if (!this.seasonId) {
      return { rows: [], count: 0 };
    }

    this.setFetched(false);
    this.setFetching(true);

    const response = await this.fetchUserBalances(this.seasonId, this.bucketId);

    if (response.success) {
      const { items, count } = response.data;
      const rows = items.map((item: IUserBalanceResponse) => userBalanceAdapter(item));

      this.setCurrentPage(this.currentPage + 1);

      return { rows, count };
    }

    this.setErrors(response.errors);
    this.setFetching(false);
    this.setFetched(true);

    return { rows: [], count: 0 };
  }

  public async fetchCurrentUserBalance() {
    if (!this.seasonId || !this.authStore.isAuthorised || !this.authStore.userMe) {
      return;
    }

    this.setFetched(false);
    this.setFetching(true);

    const response = await this.leaderboardService.fetchUserBalance(
      this.authStore.userMe.username,
      this.seasonId,
      this.bucketId,
    );

    if (response.success) {
      const userBalance = response.data ? userBalanceAdapter(response.data) : null;

      this.setCurrentUserBalance(userBalance);
    } else {
      this.setErrors(response.errors);
      this.setCurrentUserBalance(null);
    }

    this.setFetching(false);
    this.setFetched(true);
  }

  public refresh() {
    this.setCurrentPage(1);
    this.setSortParam(SortParams.TOTAL_POINTS);
    this.setOrder('DESC');
  }
}
