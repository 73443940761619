import { IInteractedPostResponse } from 'services/posts/interfaces/posts-response.interface';

import { publicationAdapter } from 'stores/posts/adapters/publication-adapter.util';
import { IPost } from 'stores/posts/interfaces/post.interface';

export function interactedPostAdapter(postResponse: IInteractedPostResponse): IPost {
  const { actions, post } = postResponse;

  return publicationAdapter(post, actions);
}
