import { FC, memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { PollStatusEnum } from 'services/posts/enums/post.enum';

import { AuthTooltip } from 'components/auth/auth-tooltip/auth-tooltip.component';

import { IconFont, IconFontName, IconFontSize } from '../../icon-font/icon-font.component';

import styles from './poll-item.module.less';

export enum PollTheme {
  Post,
  Comment,
}

export interface IPollItemProps {
  theme: PollTheme;
  id: string;
  answer: string;
  numberMarker?: string;
  percentage: number;
  votesCount: number;
  userAnswerId: Maybe<string>;
  status: PollStatusEnum;
  expired: boolean;
  isAthletePoll?: boolean;
  onAnswerClick: (uuid: string) => void;
}

export const PollItem: FC<IPollItemProps> = memo((props: IPollItemProps) => {
  const {
    theme,
    id,
    answer,
    numberMarker,
    percentage,
    votesCount,
    userAnswerId,
    status,
    expired,
    isAthletePoll,
    onAnswerClick,
  } = props;

  const isActive = useMemo(() => userAnswerId === id, [userAnswerId, id]);
  const isExpired = useMemo(() => status === PollStatusEnum.Closed || expired, [status, expired]);
  const isVoted = useMemo(() => userAnswerId || isExpired, [userAnswerId, isExpired]);

  const pollItemIconClasses = useMemo(
    () =>
      cn(styles.PollItem__Icon, {
        [styles['PollItem__Icon--expired']]: isExpired && !isActive,
      }),
    [isActive, isExpired],
  );

  const pollItemClasses = useMemo(
    () =>
      cn(styles.PollItem, {
        [styles['PollItem--inactive']]: isExpired || userAnswerId || isAthletePoll,
        [styles['PollItem--comment-theme']]: theme === PollTheme.Comment,
      }),
    [isAthletePoll, isExpired, userAnswerId, theme],
  );

  const pollItemPercentage = useMemo(() => {
    if (!percentage && isAthletePoll) {
      return 1;
    }

    return percentage;
  }, [percentage, isAthletePoll]);

  const handleAnswerClick = useCallback(() => {
    onAnswerClick(id);
  }, [onAnswerClick, id]);

  return (
    <div className={pollItemClasses}>
      {(isVoted || isAthletePoll) && (
        <div className={styles.PollItem__Percentage}>{`${percentage}`}%</div>
      )}
      <AuthTooltip>
        <div
          role="presentation"
          className={styles.PollItem__BarWrapper}
          onClick={handleAnswerClick}
        >
          {numberMarker && <span className={styles.PollItem__NumberMarker}>{numberMarker}</span>}
          <div className={styles.PollItem__Bar}>
            {(isVoted || isAthletePoll) && (
              <span
                className={styles.PollItem__Progress}
                style={{ width: `${pollItemPercentage}%` }}
              />
            )}
            {isActive && userAnswerId && (
              <div className={pollItemIconClasses}>
                <IconFont name={IconFontName.Confirm} size={IconFontSize.Small} />
              </div>
            )}
            <span className={styles.PollItem__Title}>{answer}</span>
            {(isVoted || isAthletePoll) && (
              <div className={styles.PollItem__Counter}>{votesCount ?? 0}</div>
            )}
          </div>
        </div>
      </AuthTooltip>
    </div>
  );
});
