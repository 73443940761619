import { FC, memo, SyntheticEvent, useCallback, useMemo } from 'react';
import { OnChangeValue } from 'react-select';
import cn from 'classnames';

import { SelectItemTheme } from 'components/ui/form-fields/bottom-sheet-select/bottom-sheet-select.component';
import { IHookFormInput } from 'components/ui/form-fields/hook-form-input.interface';

import { ResponsiveSelect } from '../responsive-select/responsive-select.component';

import { SelectDropdownIndicator as DropdownIndicator } from './select-dropdown-indicator/select-dropdown-indicator.component';
import { ISelectOption } from './select-option.interface';

import styles from './select.module.less';

export interface ISelectProps extends IHookFormInput<string> {
  options: ISelectOption[];
  label?: string;
  hasInnerLabel?: boolean;
  isWebSelectOnMobile?: boolean;
  itemTheme?: SelectItemTheme;
}

export const Select: FC<ISelectProps> = memo((props: ISelectProps) => {
  const {
    options,
    id,
    name,
    disabled = false,
    placeholder,
    value,
    hasInnerLabel = false,
    label,
    onBlur,
    onChange,
    isWebSelectOnMobile,
    itemTheme = SelectItemTheme.Text,
  } = props;

  const handleBlur = useCallback(
    (event: SyntheticEvent) => {
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur],
  );

  const handleChange = useCallback(
    (selectedOption: OnChangeValue<ISelectOption, false>) => {
      if (onChange && selectedOption) {
        const newValue = selectedOption.value;

        onChange(newValue);
      }
    },
    [onChange],
  );

  const currentOption = useMemo<Maybe<ISelectOption>>(
    () =>
      options.find(({ value: currentValue }) => (value ? currentValue === value : null)) || null,
    [options, value],
  );

  const selectClassNames = useMemo(
    () =>
      cn({
        'Select--inner-label': hasInnerLabel,
      }),
    [hasInnerLabel],
  );

  const labelClassNames = useMemo(
    () =>
      cn(styles.Label, {
        [styles['Label--inner']]: hasInnerLabel,
      }),
    [hasInnerLabel],
  );

  return (
    <div className={styles.SelectWrapper}>
      {label && <div className={labelClassNames}>{label}</div>}

      <ResponsiveSelect<ISelectOption>
        unstyled
        id={id}
        name={name}
        isSearchable={false}
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
        value={currentOption}
        onBlur={handleBlur}
        onChange={handleChange}
        components={{
          DropdownIndicator,
        }}
        menuPlacement="auto"
        className={selectClassNames}
        classNamePrefix="Select"
        isWebSelectOnMobile={isWebSelectOnMobile}
        itemTheme={itemTheme}
      />
    </div>
  );
});
