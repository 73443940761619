import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';
import { SortType } from 'types/sort-type.type';

import { USER_SLUG_API_PARAM } from 'services/http/consts/api-endpoint-params.constants';
import {
  REPUTATION_USER_BALANCE,
  REPUTATION_USERS_BALANCES,
} from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';
import {
  IUserBalanceResponse,
  IUserBalancesResponse,
} from 'services/leaderboard/interfaces/leaderboard-response.interface';

import { SortParams } from 'stores/leaderboard/leaderboard.store';
import { DEFAULT_SEASONS_PAGINATION } from 'stores/seasons/seasons.config';

import { TYPES } from 'configs/di-types.config';

@injectable()
export class LeaderboardService {
  private readonly httpService: HttpService;

  constructor(@inject<HttpService>(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchUserBalances(
    seasonId: string,
    bucketId: Maybe<string>,
    pagination: IPagination = DEFAULT_SEASONS_PAGINATION,
    order: SortType = 'DESC',
    sortParam: SortParams = SortParams.TOTAL_POINTS,
  ): Promise<IResponse<IUserBalancesResponse>> {
    const { limit, page } = pagination;

    const params: Record<string, string> = {
      limit: String(limit),
      page: String(page),
      season_id: seasonId,
      order,
      sort: sortParam,
    };

    if (bucketId && Number(bucketId)) {
      params.bucket_id = bucketId;
    }

    return this.httpService.request<IUserBalancesResponse>({
      method: 'GET',
      url: REPUTATION_USERS_BALANCES,
      params: new URLSearchParams(params),
    });
  }

  public fetchUserBalance(
    username: string,
    seasonId: string,
    bucketId: Maybe<string>,
  ): Promise<IResponse<IUserBalanceResponse>> {
    const params: Record<string, string> = {
      season_id: seasonId,
    };

    if (bucketId && Number(bucketId)) {
      params.bucket_id = bucketId;
    }

    return this.httpService.request<IUserBalanceResponse>({
      method: 'GET',
      url: REPUTATION_USER_BALANCE,
      routeParams: { [USER_SLUG_API_PARAM]: username },
      params: new URLSearchParams(params),
    });
  }
}
