import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';

import { IS_ENABLE_ATHLETE_POST_CREATION } from 'configs/feature.config';
import * as paths from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';

export interface ISliderItem {
  label: string;
  action: BarActionType;
  value: string;
  isEnableNestedToggle: boolean;
  isLeftSide: boolean;
}

export const GLOBAL_FEED_SLIDER_ITEMS: ISliderItem[] = [
  {
    label: 'NBA Feed',
    action: {
      type: BarAction.Link,
      path: paths.HOME_FEED,
    },
    value: FeedTypes.YourFeed,
    isEnableNestedToggle: true,
    isLeftSide: true,
  },
  {
    label: 'NBA Fan Zone',
    action: {
      type: BarAction.Link,
      path: paths.HOME_FEED_FAN_ZONE,
    },
    value: FeedTypes.GlobalFanZone,
    isEnableNestedToggle: IS_ENABLE_ATHLETE_POST_CREATION,
    isLeftSide: false,
  },
];
