import { inject, injectable } from 'inversify';
import { reaction } from 'mobx';

import { IPostResponse } from 'services/posts/interfaces/posts-response.interface';
import { PostsService } from 'services/posts/posts.service';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { publicationAdapter } from 'stores/posts/adapters/publication-adapter.util';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { IPost, IPostAdapter } from 'stores/posts/interfaces/post.interface';
import { IPostsResponse } from 'stores/posts/interfaces/post-response.inerface';

import { TYPES } from 'configs/di-types.config';

import { PAGINATION_LIMIT } from './feeds.config';

@injectable()
export class GlobalFanZoneFavoritesFeedStore extends AdvancedEntriesStore<
  IPost,
  IPostResponse,
  IPostsResponse
> {
  private readonly postsService: PostsService;

  private readonly layoutStore: LayoutStore;

  constructor(
    @inject(TYPES.PostsService) postsService: PostsService,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
  ) {
    super(PAGINATION_LIMIT);

    this.postsService = postsService;

    this.layoutStore = layoutStore;

    reaction(
      () => [this.layoutStore.activeFeed, this.layoutStore.fanZoneFilter],
      () => this.forceFetchToRefresh(),
    );
  }

  public async fetchNext(): Promise<void> {
    if (this.layoutStore.activeFeed === FeedTypes.GlobalFanZoneFavorites) {
      await this.retrieveNext(
        this.fetchGlobalFanZoneFavoritesFeedPosts(),
        <IPostAdapter>publicationAdapter,
      );
    }
  }

  public async fetchGlobalFanZoneFavoritesFeedPosts(): Promise<IResponse<IPostsResponse>> {
    return this.postsService.fetchPosts(
      this.pagination,
      false,
      true,
      this.layoutStore.fanZoneFilter,
    );
  }

  public async forceFetchToRefresh() {
    if (this.layoutStore.activeFeed === FeedTypes.GlobalFanZoneFavorites) {
      await this.refresh();

      await this.forceRefresh(
        this.fetchGlobalFanZoneFavoritesFeedPosts(),
        <IPostAdapter>publicationAdapter,
        this.layoutStore.setPulledRefresher,
      );
    }
  }

  public async refresh() {
    this.setFetched(false);
    this.setFetching(false);

    this.setIsLastPage(false);
    this.setCurrentPage(1);

    await this.initialise();
  }
}
