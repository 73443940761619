import { FC } from 'react';
import { components, DropdownIndicatorProps } from 'react-select';

import { useToggleIgnoredTouchClass } from 'hooks/use-toggle-ignored-touch-class';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import { ISelectOption } from '../select-option.interface';

interface ISelectDropDownIndicatorProps extends DropdownIndicatorProps<ISelectOption, false> {}

export const SelectDropdownIndicator: FC<ISelectDropDownIndicatorProps> = (
  props: ISelectDropDownIndicatorProps,
) => {
  const { selectProps } = props;

  useToggleIgnoredTouchClass(selectProps.menuIsOpen);

  return (
    <components.DropdownIndicator {...props}>
      <IconButton
        iconName={selectProps.menuIsOpen ? IconFontName.ChevronUp : IconFontName.ChevronDown}
        theme={IconButtonTheme.Transparent}
      />
    </components.DropdownIndicator>
  );
};
