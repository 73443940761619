import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AuthStore } from 'stores/auth/auth.store';
import { BookmarksStore } from 'stores/bookmarks/bookmarks.store';
import { CollaborationStore } from 'stores/collaboration/collaboration.store';
import { CollaborationCommentsStore } from 'stores/collaboration-comments/collaboration-comments.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { MetaTagsStore } from 'stores/meta-tags/meta-tags.store';
import { PostsStore } from 'stores/posts/posts.store';
import { SharedType } from 'stores/share/enums/share-type.enum';
import { ShareStore } from 'stores/share/share.store';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { DEFAULT_SHARE_TITLE } from 'configs/share.config';
import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';
import { useNativeShare } from 'hooks/use-native-share';
import { useTeamClick } from 'hooks/use-team-click';

import { CollaborationItem } from 'components/collaboration-section/collaborattion-item/collaboration-item';
import { ShareModal } from 'components/modals/share-modal/share-modal.component';
import { PostsTheme } from 'components/posts/post-card/enums/posts-theme.enum';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './collaboration-item.module.less';

export const CollaborationItemContainer = observer(() => {
  const collaborationStore = useInjection<CollaborationStore>(TYPES.CollaborationStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const bookmarksStore = useInjection<BookmarksStore>(TYPES.BookmarksStore);
  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);
  const shareStore = useInjection<ShareStore>(TYPES.ShareStore);
  const metaTagsStore = useInjection<MetaTagsStore>(TYPES.MetaTagsService);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const collaborationCommentsStore = useInjection<CollaborationCommentsStore>(
    TYPES.CollaborationCommentsStore,
  );

  const navigate = useNavigate();
  const { shareByNativeModal } = useNativeShare();

  const { isNativeApp } = useMainProvider();

  const params = useParams<{
    [path.POST_ID_PARAM]: string;
    [path.COLLABORATION_ITEM_ID_PARAM]: string;
  }>();

  const { postId, collaborationItemId } = params;

  useEffect(() => {
    if (layoutStore.isPulledRefresher) {
      collaborationStore.handleCollaborationItemIdChange();
      collaborationCommentsStore.initialiseCollaborationComments();
    }
  }, [layoutStore.isPulledRefresher, collaborationStore, collaborationCommentsStore]);

  useEffect(() => {
    if (postId && collaborationItemId) {
      postsStore.setCurrentPostId(postId);
      collaborationStore.setCollaborationItemId(Number(collaborationItemId));
    }

    return () => {
      collaborationStore.setCollaborationId(null);
      postsStore.setCurrentPostId(null);
    };
  }, [collaborationItemId, collaborationStore, postId, postsStore]);

  const handleTeamClick = useTeamClick();

  const getFavoriteTeam = useCallback(
    (id: Maybe<number>): Maybe<ITeamsStats> => {
      return teamsStatsStore.findTeamById(id);
    },
    [teamsStatsStore],
  );

  const getFavoritePlayer = useCallback(
    (id: Maybe<number>): Maybe<IPlayerStats> => {
      return teamsStatsStore.findPlayerById(id);
    },
    [teamsStatsStore],
  );

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(path.PLAYER_PROFILE, { [path.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  const isMyPost = useMemo(
    () => collaborationStore.collaborationItem?.user?.uuid === authStore.userMe?.id,
    [collaborationStore.collaborationItem, authStore.userMe],
  );

  const handleCopySharedLink = useCallback(() => {
    shareStore.copyLink();
  }, [shareStore]);

  const handleResetSharing = useCallback(() => {
    shareStore.reset();
  }, [shareStore]);

  const handleLike = useCallback(() => {
    if (!authStore.isAuthorised) return;

    collaborationStore.toggleLikeCollaborationItem();
  }, [authStore.isAuthorised, collaborationStore]);

  const handleToggleBookmark = useCallback(async () => {
    if (!authStore.isAuthorised) return;

    await collaborationStore.toggleBookmarkCollaborationItem();

    await bookmarksStore.refresh();
  }, [authStore, bookmarksStore, collaborationStore]);

  const handleDeleteItem = useCallback(async () => {
    if (!authStore.isAuthorised) return;

    const isSuccess = await collaborationStore.deleteCollaborationItem();

    if (isSuccess) {
      navigate(-1);
    }
  }, [authStore.isAuthorised, collaborationStore, navigate]);

  const handleShowShareModal = useCallback(async () => {
    if (collaborationStore.collaborationId && collaborationItemId) {
      await shareStore.fetchShareCollaborationItem(
        collaborationStore.collaborationId,
        Number(collaborationItemId),
      );
      await collaborationStore.fetchCollaborationItem(
        collaborationStore.collaborationId,
        Number(collaborationItemId),
      );
      if (isNativeApp && shareStore.shareData?.type === SharedType.CollaborationItem) {
        shareByNativeModal(
          shareStore.shareData.link,
          metaTagsStore.metaTags?.title || DEFAULT_SHARE_TITLE,
        );
      } else {
        shareStore.setIsShareModalVisible(true);
      }
    }
  }, [
    collaborationStore,
    collaborationItemId,
    shareStore,
    isNativeApp,
    shareByNativeModal,
    metaTagsStore.metaTags?.title,
  ]);

  if (!collaborationStore.collaborationId) {
    return (
      <div className={styles.Loader}>
        <Loader isShow withBackground />
      </div>
    );
  }

  if (collaborationStore.collaborationItem) {
    const { user } = collaborationStore.collaborationItem;

    return (
      <>
        <CollaborationItem
          isMyPost={isMyPost}
          item={collaborationStore.collaborationItem}
          theme={PostsTheme.Regular}
          userInfo={user}
          favoriteTeam={getFavoriteTeam(user.favoriteTeamId)}
          favoritePlayer={getFavoritePlayer(user.favoritePlayerId)}
          onShareClick={handleShowShareModal}
          onTeamClick={handleTeamClick}
          onPlayerClick={handlePlayerClick}
          onToggleBookmarks={handleToggleBookmark}
          onDeleteItem={handleDeleteItem}
          onLikesClick={handleLike}
        />
        {!isNativeApp &&
          collaborationStore.collaborationItem &&
          shareStore.shareData?.type === SharedType.CollaborationItem && (
            <ShareModal
              id={collaborationStore.collaborationItem.id.toString()}
              author={collaborationStore.collaborationItem.user}
              onClose={handleResetSharing}
              onCopyLink={handleCopySharedLink}
              shareContent={{
                attachments: {
                  mediaCount: 1,
                  filesCount: 0,
                },
              }}
              visible={shareStore.isShareModalVisible}
            />
          )}
      </>
    );
  }

  return null;
});
