import { memo, useCallback, useMemo, useState } from 'react';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IStopPollType } from 'components/ui/poll/interfaces/poll.interface';
import { getTimeLeft } from 'components/ui/poll/utils/get-time-left.util';

import styles from './poll-management.module.less';

interface IPollManagementProps {
  expirationTimestamp: number;
  onStopPoll: (type: IStopPollType) => void;
}

export const PollManagement = memo((props: IPollManagementProps) => {
  const { expirationTimestamp, onStopPoll } = props;

  const [isStopNowPopupVisible, setIsStopNowPopupVisible] = useState(false);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const isStopInOneHourHidden = useMemo(() => {
    const { timeLeft } = getTimeLeft(expirationTimestamp);

    // Consider 1 as hours, because the function returns time in hours when it is less than 1 day
    return timeLeft < 1;
  }, [expirationTimestamp]);

  const handleStopNow = useCallback(() => {
    onStopPoll(IStopPollType.Now);
    setIsStopNowPopupVisible(false);
  }, [onStopPoll, setIsStopNowPopupVisible]);

  const handleStopInOneHour = useCallback(() => {
    onStopPoll(IStopPollType.InOneHour);
    setIsStopNowPopupVisible(false);
  }, [onStopPoll, setIsStopNowPopupVisible]);

  return (
    <>
      <button className={styles.StopNow} onClick={() => setIsStopNowPopupVisible(true)}>
        Stop Now
      </button>

      {isDesktopPlus && (
        <BaseModalComponent
          title="Are you sure you want to stop Poll?"
          size={ModalWindowSize.M}
          visible={isStopNowPopupVisible}
          onClose={() => setIsStopNowPopupVisible(false)}
          closeOnOverlayClick
        >
          <div className={styles.Popup}>
            <div className={styles.Popup__Body}>
              <p>
                Stopping the survey will prevent further voting, but the current results will still
                be visible.
              </p>
            </div>
            <div className={styles.Popup__Footer}>
              <Button
                onClick={() => setIsStopNowPopupVisible(false)}
                theme={ButtonTheme.Secondary}
                size={ButtonSize.Small}
                fluid
              >
                Cancel
              </Button>
              {!isStopInOneHourHidden && (
                <Button
                  onClick={handleStopInOneHour}
                  theme={ButtonTheme.Secondary}
                  size={ButtonSize.Small}
                  fluid
                >
                  Stop in 1 hour
                </Button>
              )}
              <Button
                onClick={handleStopNow}
                theme={ButtonTheme.Primary}
                size={ButtonSize.Small}
                fluid
              >
                Stop now
              </Button>
            </div>
          </div>
        </BaseModalComponent>
      )}

      {!isDesktopPlus && (
        <BaseIonBottomSheet
          visible={isStopNowPopupVisible}
          isAutoHeight
          safeAreaBottom={0}
          breakpoints={[0, 1]}
          initialBreakpoint={1}
          onClose={() => setIsStopNowPopupVisible(false)}
        >
          <div className={styles.BottomSheet}>
            <div className={styles.BottomSheet__Header}>
              <h4>Are you sure you want to stop Poll?</h4>
            </div>
            <div className={styles.BottomSheet__Body}>
              <p>
                Stopping the survey will prevent further voting, but the current results will still
                be visible.
              </p>
            </div>
            <div className={styles.BottomSheet__Footer}>
              {!isStopInOneHourHidden && (
                <div className={styles.BottomSheet__Footer__Buttons}>
                  <Button
                    onClick={handleStopNow}
                    theme={ButtonTheme.Primary}
                    size={ButtonSize.Big}
                    fluid
                  >
                    Stop now
                  </Button>
                  <Button
                    onClick={handleStopInOneHour}
                    theme={ButtonTheme.Secondary}
                    size={ButtonSize.Big}
                    fluid
                  >
                    Stop in 1 hour
                  </Button>
                </div>
              )}
              <div className={styles.BottomSheet__Footer__Buttons}>
                {isStopInOneHourHidden && (
                  <Button
                    onClick={handleStopNow}
                    theme={ButtonTheme.Primary}
                    size={ButtonSize.Big}
                    fluid
                  >
                    Stop now
                  </Button>
                )}
                <Button
                  onClick={() => setIsStopNowPopupVisible(false)}
                  theme={ButtonTheme.Secondary}
                  size={ButtonSize.Big}
                  fluid
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </BaseIonBottomSheet>
      )}
    </>
  );
});
