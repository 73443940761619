import { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import styles from './switcher-item.module.less';

interface ISwitcherItemProps {
  element: ReactNode;
  active: boolean;
  disabled?: boolean;
  id: string;
  onClick: (id: string) => void;
}

export const SwitcherItem: FC<ISwitcherItemProps> = memo((props: ISwitcherItemProps) => {
  const { disabled, element, active, onClick, id } = props;

  const handleClick = useCallback(() => {
    onClick(id);
  }, [onClick, id]);

  const itemClassNames = useMemo(
    () =>
      classNames(styles.SwitcherItem, {
        [styles['SwitcherItem--active']]: active,
        [styles['SwitcherItem--disabled']]: disabled,
      }),
    [active, disabled],
  );

  return (
    <li className={itemClassNames}>
      <button
        disabled={disabled}
        className={styles.SwitcherItem__Button}
        onClick={handleClick}
        onKeyDown={handleClick}
      >
        {element}
      </button>
    </li>
  );
});
