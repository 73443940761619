import { useCallback, useEffect, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { TeamsStore } from 'stores/teams/teams.store';
import { IPlayerStatsMini } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStatsColors } from 'stores/teams-stats/interfaces/teams-stats.interface';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';

import { TeamProfileOverview } from 'components/team-profile/team-profile-overview/team-profile-overview.component';

interface ITeamProfileOverviewContainerProps {
  teamId: Maybe<number>;
  onShowRoster: () => void;
}

export interface IAboutData {
  title: string;
  content?: Maybe<string>;
  sideContent?: string;
  avatarUrl?: string;
}

export const TeamProfileOverviewContainer = observer(
  (props: ITeamProfileOverviewContainerProps) => {
    const { teamId } = props;

    const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
    const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);

    const { teamsStats } = teamsStatsStore;

    const aboutTheTeam = useMemo<IAboutData[]>(() => {
      return [
        {
          title: 'Location',
          content: teamsStats?.city,
        },
        {
          title: 'Arena',
          content: null,
        },
        {
          title: 'Team Name',
          content: teamsStats?.name,
        },
        {
          title: 'Seasons',
          content: null,
          sideContent: '',
        },
        {
          title: 'Record',
          content: null,
          sideContent: '',
        },
        {
          title: 'Coach',
          content: null,
          avatarUrl: '',
          sideContent: '',
        },
        {
          title: 'Executive',
          content: null,
          avatarUrl: '',
          sideContent: '',
        },
        {
          title: 'Playoff / Championships',
          content: null,
        },
      ];
    }, [teamsStats]);

    const topPlayers = useMemo(() => {
      if (!teamsStats) {
        return null;
      }

      const players = [
        teamsStats.topPerformers.points,
        teamsStats.topPerformers.totalRebounds,
        teamsStats.topPerformers.assists,
        teamsStats.topPerformers.blocks,
      ];

      return players.filter((player): player is IPlayerStatsMini => player !== null);
    }, [teamsStats]);

    const teamColors = useMemo<ITeamsStatsColors>(
      () => ({
        main: teamsStats?.color || null,
        text: teamsStats?.textColor || null,
      }),
      [teamsStats],
    );

    const handleNewsContentClick = useCallback((url: string) => {
      window.open(url, '_blank');
    }, []);

    useEffect(() => {
      (async () => {
        if (teamsStore.teamId) {
          await teamsStatsStore.retrieveRecentGames(teamsStore.teamId);
        }
      })();
    }, [teamsStore.teamId, teamsStatsStore]);

    return (
      <TeamProfileOverview
        teamId={teamId}
        aboutTheTeam={aboutTheTeam}
        teamColors={teamColors}
        topPlayers={topPlayers}
        recentGames={teamsStatsStore.teamsGamesDetail}
        onShowRoster={props.onShowRoster}
        onNewsClick={handleNewsContentClick}
        teamStatsRowData={teamsStatsStore.formattedTeamStatsRowData}
      />
    );
  },
);
