import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IPublicLink } from 'stores/auth/interfaces/custom-link.interface';

import { LinkWithoutIdType } from 'containers/custom-links/custom-links.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useKeyboardTrigger } from 'hooks/use-keyboard-trigger';
import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import { EditLinkForm } from 'components/forms/edit-link/edit-link-form.component';
import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './custom-links-popup.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 0.7;

interface ICustomLinksPopupProps {
  visible: boolean;
  onClose: () => void;
  link: Maybe<IPublicLink>;
  onDeleteClick: (id: number) => void;
  onSubmitClick: (payload: LinkWithoutIdType) => void;
  onUpdateClick: (payload: LinkWithoutIdType) => void;
}

export const CustomLinksPopup: FC<ICustomLinksPopupProps> = (props) => {
  const { visible, link, onDeleteClick, onUpdateClick, onSubmitClick } = props;

  const { isNativeApp } = useMainProvider();
  const { isKeyboardOpen } = useKeyboardTrigger(isNativeApp);

  const initialValues = useMemo(() => {
    if (link) {
      return {
        title: link.title,
        description: link.description,
        url: link.url,
      };
    }

    return null;
  }, [link]);

  const handleDeleteClick = useCallback(() => {
    if (link) {
      onDeleteClick(link.id);
    }
  }, [onDeleteClick, link]);

  const handleSubmitClick = useCallback(
    (payload: LinkWithoutIdType) => {
      if (link) {
        onUpdateClick(payload);
      } else {
        onSubmitClick(payload);
      }
    },
    [onUpdateClick, link, onSubmitClick],
  );

  const wrapperClasses = useMemo<string>(() => {
    return cn(styles.Wrapper, {
      [styles['Wrapper--keyboard']]: isKeyboardOpen,
    });
  }, [isKeyboardOpen]);

  const linkHeaderTitleClasses = useMemo<string>(() => {
    return cn(styles.CustomLinksHeader__Title, {
      [styles['CustomLinksHeader__Title--shiftRight']]: link,
    });
  }, [link]);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  if (isDesktopPlus) {
    return (
      <BaseModalComponent
        visible={visible}
        onClose={props.onClose}
        title={link ? 'Edit Link' : 'Add Link'}
        size={ModalWindowSize.M}
      >
        {link && (
          <div className={styles.CustomLinksHeader}>
            <button className={styles.CustomLinksHeader__Button} onClick={handleDeleteClick}>
              <IconFont name={IconFontName.Remove} size={IconFontSize.Small} />
              <span className={styles.CustomLinksHeader__ButtonTitle}>Delete</span>
            </button>
          </div>
        )}

        <EditLinkForm
          submitText={link ? 'Edit' : 'Add'}
          focusField="title"
          onSubmit={handleSubmitClick}
          {...(initialValues && { initialValues })}
        />
      </BaseModalComponent>
    );
  }

  return (
    <BaseIonBottomSheet
      visible={visible}
      isAutoHeight
      safeAreaBottom={0}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      initialBreakpoint={INITIAL_BREAKPOINT}
      onClose={props.onClose}
    >
      <div className={wrapperClasses}>
        <div className={styles.CustomLinksHeader}>
          <div className={linkHeaderTitleClasses}>{link ? 'Edit Link' : 'Add Link'}</div>
          {link && (
            <button className={styles.CustomLinksHeader__Button} onClick={handleDeleteClick}>
              <IconFont name={IconFontName.Remove} size={IconFontSize.Small} />
              <span className={styles.CustomLinksHeader__ButtonTitle}>Delete</span>
            </button>
          )}
        </div>
        <EditLinkForm
          submitText={link ? 'Edit' : 'Add'}
          focusField="title"
          onSubmit={handleSubmitClick}
          {...(initialValues && { initialValues })}
        />
      </div>
    </BaseIonBottomSheet>
  );
};
