import { IUploadImageData } from 'services/application/interfaces/upload-image.interface';
import {
  CollaborationMediaType,
  ICollaborationImage,
  ICollaborationVideo,
} from 'services/collaboration/interfaces/collaboration.interface';

export function isIUploadImageDataArray(data: any[]): data is IUploadImageData[] {
  if (!Array.isArray(data)) return false;

  return data.every((item) => item && typeof item === 'object' && 'uuid' in item);
}

export function isICollaborationMediaArray(data: any[]): data is ICollaborationImage[] {
  if (!Array.isArray(data)) return false;

  return data.every((item) => item && typeof item === 'object' && 'id' in item);
}

export function isCollaborationImage(media: CollaborationMediaType): media is ICollaborationImage {
  return (media as ICollaborationImage).mediumLogoUrl !== undefined;
}

export function isCollaborationVideo(media: CollaborationMediaType): media is ICollaborationVideo {
  return (media as ICollaborationVideo).url !== undefined;
}
