import { useMemo } from 'react';
import cn from 'classnames';

import { capitalizeFirstLetter } from 'helpers/string/capitalize-first-letter.util';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './slide-arrow.module.less';

interface PrevNextButtonProps {
  isEnabled: boolean;
  direction: 'prev' | 'next';
  onClick: () => void;
  isNeedZIndex?: boolean;
  isVisible?: boolean;
}

export const SlideArrow = (props: PrevNextButtonProps) => {
  const { isEnabled, direction, isNeedZIndex, isVisible = true } = props;

  const slideArrowClasses = useMemo(
    () =>
      cn(styles.SlideArrow, styles[`SlideArrow__${capitalizeFirstLetter(direction)}`], {
        [styles['SlideArrow--withIndex']]: isNeedZIndex,
        [styles['SlideArrow--hidden']]: !isVisible,
      }),
    [direction, isNeedZIndex, isVisible],
  );

  return (
    <div className={slideArrowClasses}>
      {direction === 'prev' ? (
        <IconButton
          iconName={IconFontName.ArrowLeft}
          theme={IconButtonTheme.Navigation}
          onClick={props.onClick}
          disabled={!isEnabled}
        />
      ) : (
        <IconButton
          iconName={IconFontName.ArrowRight}
          theme={IconButtonTheme.Navigation}
          onClick={props.onClick}
          disabled={!isEnabled}
        />
      )}
    </div>
  );
};
