import { inject, injectable } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { CommentsService } from 'services/comments/comments.service';
import {
  ICommentResponse,
  ICommentsResponse,
  IDeleteCommentPayload,
  IGetCommentPayload,
  IPostCommentBookmarkPayload,
} from 'services/comments/interfaces/comments.interface';
import { ICommentCreatePayload } from 'services/posts/interfaces/create-comment-payload.interface';
import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';
import { ReportsService } from 'services/reports/reports.service';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { commentAdapter } from 'stores/comments/adapters/comment-adapter.util';
import {
  findAndReplaceObjectByKey,
  findObjectInArrayByKey,
  removeObjectsInCommentsByKey,
} from 'stores/comments/helpers/comments.helpers';
import { LayoutStore } from 'stores/layout/layout.store';
import { PlayerStore } from 'stores/player/player.store';
import { pollAdapter } from 'stores/posts/adapters/poll-adapter.util';
import { SortEnum } from 'stores/posts/posts.config';
import { PostsStore } from 'stores/posts/posts.store';
import { TeamsStore } from 'stores/teams/teams.store';

import { TYPES } from 'configs/di-types.config';
import { customFormData } from 'helpers/custom-form-data.util';

import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

import {
  IComment,
  ICommentAdapter,
  ICreateCommentData,
  IDeleteCommentData,
  IPostCommentVoteData,
} from './interfaces/comments.interface';

export const COMMENTS_PAGINATION_LIMIT = 5;

@injectable()
export class CommentsStore extends AdvancedEntriesStore<
  IComment,
  ICommentResponse,
  ICommentsResponse
> {
  private readonly commentsService: CommentsService;

  private readonly authStore: AuthStore;

  private readonly postsStore: PostsStore;

  private readonly applicationStore: ApplicationStore;

  private readonly teamsStore: TeamsStore;

  private readonly layoutStore: LayoutStore;

  private readonly playerStore: PlayerStore;

  private readonly reportsService: ReportsService;

  public fetchingCreateComment: boolean;

  public fetchingCreateReply: boolean;

  public commentVideo: Maybe<IVideoResponse>;

  public isCommentVideoLoading: boolean;

  public replyVideo: Maybe<IVideoResponse>;

  public isReplyVideoLoading: boolean;

  public isShowBookmarkedCommentModal: boolean;

  public isSortingComments: boolean;

  public scrollCommentId: Maybe<string>;

  public bookmarkedComment: Maybe<IComment>;

  public comments: IComment[];

  public isSubmittedContentReport: boolean;

  constructor(
    @inject(TYPES.AuthStore) authStore: AuthStore,
    @inject(TYPES.PostsStore) postsStore: PostsStore,
    @inject(TYPES.TeamsStore) teamsStore: TeamsStore,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
    @inject(TYPES.PlayerStore) playerStore: PlayerStore,
    @inject(TYPES.ReportsService) reportsService: ReportsService,
    @inject(TYPES.CommentsService) commentsService: CommentsService,
    @inject(TYPES.ApplicationStore) applicationStore: ApplicationStore,
  ) {
    super(COMMENTS_PAGINATION_LIMIT);

    this.authStore = authStore;

    this.postsStore = postsStore;

    this.applicationStore = applicationStore;

    this.layoutStore = layoutStore;

    this.teamsStore = teamsStore;

    this.playerStore = playerStore;

    this.commentsService = commentsService;

    this.reportsService = reportsService;

    this.fetchingCreateComment = false;

    this.fetchingCreateReply = false;

    this.replyVideo = null;

    this.commentVideo = null;

    this.comments = [];

    this.isCommentVideoLoading = false;

    this.isSortingComments = false;

    this.isReplyVideoLoading = false;

    this.scrollCommentId = null;

    this.isShowBookmarkedCommentModal = false;

    this.bookmarkedComment = null;

    this.isSubmittedContentReport = false;

    makeObservable(this, {
      commentVideo: observable,
      comments: observable,
      replyVideo: observable,
      isSortingComments: observable,
      isReplyVideoLoading: observable,
      isCommentVideoLoading: observable,
      fetchingCreateComment: observable,
      fetchingCreateReply: observable,
      scrollCommentId: observable,
      isShowBookmarkedCommentModal: observable,
      isSubmittedContentReport: observable,

      resetScrollCommentId: action.bound,
      setScrollCommentId: action.bound,
      setReplyVideo: action.bound,
      setCommentVideo: action.bound,
      openBookmarkedComment: action.bound,
      uploadCommentVideo: action.bound,
      uploadReplyVideo: action.bound,
      setFetchingCreateComment: action.bound,
      setFetchingCreateReply: action.bound,
      setIsReplyVideoLoading: action.bound,
      setIsCommentVideoLoading: action.bound,
      setIsShowBookmarkedCommentModal: action.bound,
      setIsSortingComments: action.bound,
      setBookmarkedComment: action.bound,
      setComments: action.bound,
      setSubmittedContentReport: action.bound,
      handleFilterEntries: action.bound,
    });

    reaction(() => this.authStore.isAuthorised, this.handleIsAuthorisedChange);

    reaction(() => this.entries, this.handleFilterEntries);
  }

  public resetScrollCommentId() {
    this.setScrollCommentId(null);
  }

  public setComments(comments: IComment[]) {
    this.comments = comments;
  }

  public setIsSortingComments(value: boolean) {
    this.isSortingComments = value;
  }

  public handleFilterEntries(newEntries: IComment[]) {
    if (newEntries) {
      const newComments = newEntries.filter((obj, index, array) => {
        return array.findIndex((item) => item.uuid === obj.uuid) === index;
      });

      this.setComments(newComments);
    }
  }

  public setScrollCommentId(commentId: Maybe<string>) {
    this.scrollCommentId = commentId;
  }

  public setIsShowBookmarkedCommentModal(value: boolean) {
    this.isShowBookmarkedCommentModal = value;
  }

  public setBookmarkedComment(value: Maybe<IComment>) {
    this.bookmarkedComment = value;
  }

  public setFetchingCreateComment(fetchingCreateComment: boolean) {
    this.fetchingCreateComment = fetchingCreateComment;
  }

  public setFetchingCreateReply(fetchingCreateReply: boolean) {
    this.fetchingCreateReply = fetchingCreateReply;
  }

  public setReplyVideo(value: Maybe<IVideoResponse>) {
    this.replyVideo = value;
  }

  public setCommentVideo(value: Maybe<IVideoResponse>) {
    this.commentVideo = value;
  }

  public setIsCommentVideoLoading(value: boolean) {
    this.isCommentVideoLoading = value;
  }

  public setIsReplyVideoLoading(value: boolean) {
    this.isReplyVideoLoading = value;
  }

  public setSubmittedContentReport(isSubmittedContentReport: boolean) {
    this.isSubmittedContentReport = isSubmittedContentReport;
  }

  public async sendPostCommentReport(
    postId: string,
    commentId: string,
    reasonId: number,
  ): Promise<boolean> {
    this.setErrors([]);
    this.setSubmittedContentReport(false);

    const response = await this.reportsService.sendPostCommentReport(postId, commentId, reasonId);

    if (!response.success) {
      this.setErrors(response.errors);
    }

    this.setSubmittedContentReport(true);

    return response.success;
  }

  public async uploadCommentVideo(payload: File): Promise<void> {
    this.setIsCommentVideoLoading(true);

    const formData = await customFormData(
      payload,
      'file',
      'base64File',
      this.applicationStore.isNativeApp,
    );

    const newVideoData = await this.commentsService.uploadVideo(formData);

    if (newVideoData.success) {
      this.setCommentVideo({
        uuid: newVideoData.data.uuid,
        url: newVideoData.data.url,
      });
    } else {
      this.setErrors(newVideoData.errors);
    }

    this.setIsCommentVideoLoading(false);
  }

  public async uploadReplyVideo(payload: File): Promise<void> {
    this.setIsReplyVideoLoading(true);

    const formData = await customFormData(
      payload,
      'file',
      'base64File',
      this.applicationStore.isNativeApp,
    );

    const newReplyVideoData = await this.commentsService.uploadVideo(formData);

    if (newReplyVideoData.success) {
      this.setReplyVideo({
        uuid: newReplyVideoData.data.uuid,
        url: newReplyVideoData.data.url,
      });
    } else {
      this.setErrors(newReplyVideoData.errors);
    }

    this.setIsReplyVideoLoading(false);
  }

  async initialiseTopLevelComments(postId: string, sort: string) {
    this.reset();
    await super.initialise();
    await this.fetchNextComments(postId, sort);
  }

  public async fetchNextComments(postId: string, sort: string): Promise<void> {
    await this.retrieveNext(
      this.commentsService.fetchComments(this.pagination, postId, null, sort),
      <ICommentAdapter>commentAdapter,
    );
    this.setIsSortingComments(false);
  }

  public async fetchByPullToRefresh(postId: string, sort: string): Promise<void> {
    await super.initialise();
    await this.forceRefresh(
      this.commentsService.fetchComments(this.initialPagination, postId, null, sort),
      <ICommentAdapter>commentAdapter,
      this.layoutStore.setPulledRefresher,
    );
    this.setIsSortingComments(false);
  }

  public async openBookmarkedComment(payload: IGetCommentPayload): Promise<void> {
    const response = await this.commentsService.fetchSingleComment(payload);

    if (response.success) {
      this.setBookmarkedComment(commentAdapter(response.data));
      this.setIsShowBookmarkedCommentModal(true);
    }
  }

  private handleIsAuthorisedChange = async () => {
    await this.refresh();
  };

  public async fetchSingleComment(payload: IGetCommentPayload): Promise<void> {
    const response = await this.commentsService.fetchSingleComment(payload);

    if (response.success) {
      if (payload.isNeedUpdateNestedComments) {
        const newComment = commentAdapter(response.data);
        const newComments = findAndReplaceObjectByKey(
          [...this.comments],
          'uuid',
          payload.commentId,
          newComment,
        );
        this.setComments(newComments);
      } else {
        const currentComment = findObjectInArrayByKey(this.comments, 'uuid', payload.commentId);
        const newComment = commentAdapter(response.data);

        if (currentComment && newComment) {
          newComment.comments = currentComment.comments;
          const newComments = findAndReplaceObjectByKey(
            [...this.comments],
            'uuid',
            payload.commentId,
            newComment,
          );
          this.setComments(newComments);
        }
      }
    } else {
      this.setErrors(response.errors);
    }
  }

  public async commentPollVote(payload: IPollVoteData): Promise<void> {
    const pollResponse = await this.commentsService.commentPollVote(payload);

    if (pollResponse.success) {
      const currentComment = findObjectInArrayByKey(this.comments, 'uuid', payload.entityId);

      const newPoll = pollAdapter(pollResponse.data);

      if (currentComment) {
        currentComment.attachments = {
          ...currentComment.attachments,
          poll: newPoll,
        };

        const newComments = findAndReplaceObjectByKey(
          [...this.comments],
          'uuid',
          payload.entityId,
          currentComment,
        );
        this.setComments(newComments);
      }
    } else {
      this.setErrors(pollResponse.errors);
    }
  }

  public async fetchNextReplies(
    parentCommentId: string,
    page: number,
    sort: string,
  ): Promise<void> {
    if (this.postsStore.currentPostId) {
      const response = await this.commentsService.fetchComments(
        {
          limit: COMMENTS_PAGINATION_LIMIT,
          page,
        },
        this.postsStore.currentPostId,
        parentCommentId,
        sort,
      );

      if (response.success) {
        const currentPage = response.data.page + 1;
        const currentComment = findObjectInArrayByKey(this.comments, 'uuid', parentCommentId);

        const newReplies: IComment[] = [...response.data.items.map(commentAdapter).filter(Boolean)];

        if (currentComment) {
          currentComment.page = currentPage;

          if (currentComment.comments) {
            currentComment.comments = [...currentComment.comments, ...newReplies].filter(
              (obj, index, array) => {
                return array.findIndex((item) => item.uuid === obj.uuid) === index;
              },
            );
          }

          const newComments = findAndReplaceObjectByKey(
            [...this.comments],
            'uuid',
            parentCommentId,
            currentComment,
          );
          this.setComments(newComments);
        }
      }
    }
  }

  public async createComment(
    postId: string,
    comment: ICommentCreatePayload,
    commentId: Maybe<string>,
    teamId: Maybe<number>,
    playerId: Maybe<number>,
    sort: SortEnum,
  ): Promise<Maybe<ICreateCommentData>> {
    if (commentId) {
      this.setFetchingCreateReply(true);
    } else {
      this.setFetchingCreateComment(true);
    }

    const commentResponse = await this.commentsService.createComment(postId, comment, commentId);

    if (commentResponse.success) {
      const newComment = commentAdapter(commentResponse.data);

      if (!commentId) {
        if (sort === SortEnum.NEW) {
          this.setEntries([newComment, ...this.comments]);
        } else {
          this.setEntries([...this.comments, newComment]);
        }
      } else {
        const currentComment = findObjectInArrayByKey(this.comments, 'uuid', commentId);

        if (currentComment && currentComment.comments) {
          if (sort === SortEnum.NEW) {
            currentComment.comments = [newComment, ...currentComment.comments];
          } else {
            currentComment.comments = [...currentComment.comments, newComment];
          }

          const newComments = findAndReplaceObjectByKey(
            [...this.comments],
            'uuid',
            commentId,
            currentComment,
          );
          this.setComments(newComments);
        }
      }

      if (!commentId) {
        this.setCommentVideo(null);
      } else {
        this.setReplyVideo(null);
      }

      this.setScrollCommentId(commentResponse.data.uuid);
      this.setFetchingCreateComment(false);
      this.setFetchingCreateReply(false);

      return {
        success: true,
        postComments: newComment.postCommentsAmount,
      };
    }

    if (commentResponse.code === 500 || commentResponse.code === 504) {
      let message;

      if (commentId) {
        message = 'We were unable to create your reply at this time. Please try again later';
      } else {
        message = 'We were unable to create your comment at this time. Please try again later';
      }
      const error: INotificationMessage = {
        message,
      };
      this.setErrors([error]);
    } else {
      this.setErrors(commentResponse.errors);
    }

    this.setFetchingCreateComment(false);
    this.setFetchingCreateReply(false);

    return null;
  }

  public removeCommentFromEntries(id: string) {
    const newComments = removeObjectsInCommentsByKey([...this.comments], 'uuid', id);

    this.setComments(newComments);
  }

  public async deleteComment(payload: IDeleteCommentPayload): Promise<Maybe<IDeleteCommentData>> {
    const response = await this.commentsService.deleteComment(payload);

    if (response.success) {
      const commentsAmount = response.data.post_comments_amount;

      this.removeCommentFromEntries(payload.commentId);

      return {
        postComments: commentsAmount,
      };
    }

    return null;
  }

  public async togglePostCommentBookmark(payload: IPostCommentBookmarkPayload): Promise<void> {
    const response = await this.commentsService.togglePostCommentBookmark(payload);

    if (response.success) {
      const currentComment = findObjectInArrayByKey(this.comments, 'uuid', payload.commentId);

      if (currentComment) {
        currentComment.bookmarksCount = response.data.bookmarks_count;
        currentComment.isBookmarked = response.data.is_bookmarked;

        const newComments = findAndReplaceObjectByKey(
          [...this.comments],
          'uuid',
          payload.commentId,
          currentComment,
        );
        this.setComments(newComments);
      }
    }
  }

  public async postCommentLikes(payload: IPostCommentVoteData): Promise<void> {
    const { postId, commentId } = payload;

    const response = await this.commentsService.postCommentLikes(postId, commentId);

    const currentComment = findObjectInArrayByKey(this.comments, 'uuid', commentId);

    if (currentComment && response.success) {
      currentComment.likes = response.data.likes_total;
      currentComment.isLiked = response.data.is_liked;

      const newComments = findAndReplaceObjectByKey(
        [...this.comments],
        'uuid',
        commentId,
        currentComment,
      );

      this.setComments(newComments);
    }
  }
}
