import { memo } from 'react';
import { isSameDay } from 'date-fns';

import { IGameDetailed } from 'stores/scoreboard/interfaces/game-detailed.interface';

import { GameDetailsTabs } from '../game/game.config';

import { ScoreboardDay } from './scoreboard-day/scoreboard-day.component';

import styles from './scoreboard.module.less';

interface IScoreboardProps {
  formattedDates: string[];
  games: IGameDetailed[];
  timezone: string;
  onPlayerClick: (slug: string, gameId: string) => void;
  onTeamClick: (teamId: number) => void;
  onGameClick: (gameId: number, tabQuery: Maybe<GameDetailsTabs>) => void;
}

export const Scoreboard = memo((props: IScoreboardProps) => {
  const { formattedDates, games, timezone } = props;

  return (
    <div className={styles.Scoreboard}>
      {formattedDates.map((date) => {
        const filteredGames = games.filter((game) =>
          isSameDay(new Date(game.dateStart), new Date(date)),
        );

        if (!filteredGames.length) {
          return null;
        }

        return (
          <ScoreboardDay
            formattedSelectedDate={date}
            games={filteredGames}
            key={date}
            timezone={timezone}
            onPlayerClick={props.onPlayerClick}
            onTeamClick={props.onTeamClick}
            onGameClick={props.onGameClick}
          />
        );
      })}
    </div>
  );
});
