import { ColDef } from 'ag-grid-community';

import { dayColumnComparator } from 'stores/player/utils/comparators/day-column-comparator';
import { monthColumnComparator } from 'stores/player/utils/comparators/month-column-comparator';
import { opponentsColumnComparator } from 'stores/player/utils/comparators/opponents-column-comparator';
import { resultColumnComparator } from 'stores/player/utils/comparators/result-column-comparator';
import { seasonColumnComparator } from 'stores/player/utils/comparators/season-column-comparator';

import { objectValueFormatter } from 'components/base-table/helpers/object-value-formatter.util';
import { SplitsGameTableHeader } from 'components/player-profile/player-profile-games/splits-game-table-header/splits-game-table-header';
import { PLAYERS_STATS_COL_DEF } from 'components/team-stats/team-stats.config';

import { OpponentCell } from './opponent-cell/opponent-cell.component';
import { TeamCell } from './team-cell/team-cell.component';

const PLAYER_SPLITS_FIRST_COLUMN_WIDTH = 112;

export const DEFAULT_PLAYER_SPLITS_COLUMNS = PLAYERS_STATS_COL_DEF.slice(1).map((colDef) => {
  return {
    ...colDef,
    minWidth: 43,
    sortable: true,
    sort: undefined,
  };
});

export const TOTAL_COL_DEF: ColDef = {
  field: 'total',
  pinned: 'left',
  headerName: 'Total',
  headerClass: 'ag-players-stats-header-name-first',
  cellClass: 'ag-players-stats-left-first',
  width: PLAYER_SPLITS_FIRST_COLUMN_WIDTH,
};

export const SPLIT_COL_DEF: ColDef = {
  field: 'split',
  pinned: 'left',
  headerName: 'Split',
  headerClass: 'ag-players-stats-header-name-first',
  cellClass: 'ag-players-stats-left-first',
  width: PLAYER_SPLITS_FIRST_COLUMN_WIDTH,
};

export const MONTH_COL_DEF: ColDef = {
  field: 'month',
  pinned: 'left',
  headerName: 'Month',
  headerClass: 'ag-players-stats-header-name-first',
  cellClass: 'ag-players-stats-left-first',
  width: PLAYER_SPLITS_FIRST_COLUMN_WIDTH,
  sort: 'asc',
  comparator: monthColumnComparator,
};

export const RESULT_COL_DEF: ColDef = {
  field: 'result',
  pinned: 'left',
  headerName: 'Result',
  headerClass: 'ag-players-stats-header-name-first',
  cellClass: 'ag-players-stats-left-first',
  width: PLAYER_SPLITS_FIRST_COLUMN_WIDTH,
  sort: 'asc',
  comparator: resultColumnComparator,
};

export const DAY_COL_DEF: ColDef = {
  field: 'day',
  pinned: 'left',
  headerName: 'Day',
  headerClass: 'ag-players-stats-header-name-first',
  cellClass: 'ag-players-stats-left-first',
  width: PLAYER_SPLITS_FIRST_COLUMN_WIDTH,
  sort: 'asc',
  comparator: dayColumnComparator,
};

export const OPPONENT_COL_DEF: ColDef = {
  field: 'opponent',
  pinned: 'left',
  headerName: 'Opponent',
  headerClass: 'ag-players-stats-header-name-first',
  cellClass: 'ag-players-stats-left-first',
  width: PLAYER_SPLITS_FIRST_COLUMN_WIDTH,
  cellRenderer: OpponentCell,
  sort: 'asc',
  comparator: opponentsColumnComparator,
  valueFormatter: objectValueFormatter,
};

export const SEASONAL_PLAYER_STATS_FIRST_COLUMNS: ColDef[] = [
  {
    field: 'season',
    pinned: 'left',
    headerName: 'Season',
    cellClass: 'ag-players-stats-left-first',
    width: 82,
    valueFormatter: (params) => params.value.label,
    sortable: true,
    comparator: seasonColumnComparator,
  },
  {
    field: 'team',
    pinned: 'left',
    headerName: 'TM',
    cellClass: 'ag-players-stats-left-first',
    maxWidth: 44,
    cellRenderer: TeamCell,
    valueFormatter: objectValueFormatter,
  },
];

const COMMON_SPLITS_COL_DEF: ColDef[] = [
  {
    headerTooltip: 'Minutes',
    field: 'minutes',
    headerName: 'MIN',
  },
  {
    headerTooltip: 'Points',
    field: 'points',
    headerName: 'PTS',
  },
  {
    headerTooltip: 'Rebounds',
    field: 'totalRebounds',
    headerName: 'REB',
  },
  {
    headerTooltip: 'Assists',
    field: 'assists',
    headerName: 'AST',
  },
  {
    headerTooltip: 'Blocks',
    field: 'blocks',
    headerName: 'BLK',
  },
  {
    headerTooltip: 'Steals',
    field: 'steals',
    headerName: 'STL',
  },
  {
    headerTooltip: '+/-',
    field: 'plusMinus',
    headerName: '+/-',
  },
  {
    headerTooltip: 'True Shooting Percentage',
    field: 'trueShootingPercentage',
    headerName: 'TS%',
  },
  {
    headerTooltip: 'Field Goals',
    field: 'fieldGoalsMade',
    headerName: 'FG',
  },
  {
    headerTooltip: 'Field Goals Percentage',
    field: 'fieldGoalsPercentage',
    headerName: 'FG%',
  },
  {
    headerTooltip: '3-Point Field Goals',
    field: 'threePointsFieldGoalsMade',
    headerName: '3PT',
  },
  {
    headerTooltip: '3-Point Field Goals Percentage',
    field: 'threePointsFieldGoalsPercentage',
    headerName: '3P%',
  },
  {
    headerTooltip: 'Free Throws',
    field: 'freeThrowsMade',
    headerName: 'FT',
  },
  {
    headerTooltip: 'Free Throws Percentage',
    field: 'freeThrowsPercentage',
    headerName: 'FT%',
  },
  {
    headerTooltip: 'Offensive Rebounds',
    field: 'offensiveReb',
    headerName: 'OREB',
  },
  {
    headerTooltip: 'Defensive Rebounds',
    field: 'defensiveReb',
    headerName: 'DREB',
  },
  {
    headerTooltip: 'Turnovers',
    field: 'turnovers',
    headerName: 'TO',
  },
  {
    headerTooltip: 'Personal Fouls',
    field: 'personalFouls',
    headerName: 'PF',
  },
  {
    headerTooltip: 'Fantasy Points',
    field: 'fantasyPoints',
    headerName: 'FPS',
  },
];

const commonSplitsColDefWithCustomHeader = COMMON_SPLITS_COL_DEF.map((col) => ({
  ...col,
  headerComponent: SplitsGameTableHeader,
}));

export const SPLITS_GAME_PLAYER_STATS_WITH_CUSTOM_HEADER: ColDef[] = [
  { ...SPLIT_COL_DEF, headerComponent: SplitsGameTableHeader },
  ...commonSplitsColDefWithCustomHeader,
];
