import { useCallback } from 'react';

import { ISliderItem } from 'containers/posts/config/feed.config';

import { BarAction } from 'components/bars/bar-action.enum';
import { GroupPostResponseTypeDataType } from 'components/forms/create-group-post/create-group-post-form.component';
import { IHookFormInput } from 'components/ui/form-fields/hook-form-input.interface';
import {
  CheckboxSize,
  SingleCheckbox,
} from 'components/ui/form-fields/single-checkbox/single-checkbox.component';
import { SelectSlider } from 'components/ui/select-slider/select-slider.component';

import styles from './group-post-settings.module.less';

interface IGroupPostSettingsProps extends IHookFormInput<GroupPostResponseTypeDataType> {
  value: GroupPostResponseTypeDataType;
}

const sliderItems: ISliderItem[] = [
  {
    label: 'Image',
    action: {
      type: BarAction.Click,
      payload: 'image',
    },
    value: 'image',
    isEnableNestedToggle: false,
    isLeftSide: false,
  },
  {
    label: 'Video',
    action: {
      type: BarAction.Click,
      payload: 'video',
    },
    value: 'video',
    isEnableNestedToggle: false,
    isLeftSide: false,
  },
];

export const GroupPostSettings = (props: IGroupPostSettingsProps) => {
  const { value, onChange } = props;

  const handleMediaTypeChange = useCallback(() => {
    if (onChange) {
      const media = value.media ? null : 'image';

      const tempValue: GroupPostResponseTypeDataType = {
        ...value,
        media,
      };
      onChange(tempValue);
    }
  }, [onChange, value]);

  const handleTextAllowedChange = useCallback(() => {
    if (onChange) {
      const tempValue: GroupPostResponseTypeDataType = {
        ...value,
        textAllowed: !value.textAllowed,
      };
      onChange(tempValue);
    }
  }, [onChange, value]);

  const handleMediaToggleClick = useCallback(() => {
    if (onChange && value.media) {
      const newMedia = value.media === 'image' ? 'video' : 'image';

      const tempValue: GroupPostResponseTypeDataType = {
        ...value,
        media: newMedia,
      };
      onChange(tempValue);
    }
  }, [onChange, value]);

  return (
    <div className={styles.GroupPostSettings}>
      <div className={styles.GroupPostSettings__Item}>
        <SingleCheckbox
          id="media"
          size={CheckboxSize.Big}
          label="Media"
          disableHover
          onChange={handleMediaTypeChange}
          selected={Boolean(value.media)}
          disabled={false}
        />
        {value.media && (
          <SelectSlider
            items={sliderItems}
            fullWidth={false}
            selected={value.media}
            onItemClick={handleMediaToggleClick}
          />
        )}
      </div>
      <div className={styles.GroupPostSettings__Item}>
        <SingleCheckbox
          id="text"
          size={CheckboxSize.Big}
          label="Text"
          disableHover
          onChange={handleTextAllowedChange}
          selected={Boolean(value.textAllowed)}
          disabled={false}
        />
      </div>
    </div>
  );
};
