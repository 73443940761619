import { inject, injectable } from 'inversify';

import { USER_PUBLIC_DETAILS } from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { TYPES } from 'configs/di-types.config';

import { USER_SLUG_API_PARAM } from '../http/consts/api-endpoint-params.constants';

import { IUserPublicResponse } from './interfaces/user-public-response.interface';

@injectable()
export class UserPublicService {
  private readonly httpService: HttpService;

  constructor(@inject<HttpService>(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchUserDetails(userSlug: string): Promise<IResponse<IUserPublicResponse>> {
    return this.httpService.request({
      method: 'GET',
      url: USER_PUBLIC_DETAILS,
      routeParams: { [USER_SLUG_API_PARAM]: userSlug },
    });
  }
}
