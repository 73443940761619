import { useCallback, useMemo, useRef, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import cn from 'classnames';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';
import { PreviewConfig } from 'emoji-picker-react/dist/config/config';
import { $createTextNode, $getSelection } from 'lexical';

import { ConvertImageItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';

import { FILES_IMAGE_TYPES, FILES_VIDEO_TYPES } from 'validation/validation.constants';

import { AuthTooltip } from 'components/auth/auth-tooltip/auth-tooltip.component';
import {
  EditorAttachmentsType,
  EditorType,
} from 'components/editor/components/base-editor/base-editor.component';
import { GifPicker } from 'components/modals/gif-picker/gif-picker.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { PostImageInput } from 'components/ui/form-fields/post-image-input/post-image-input.component';
import { UploadImageType } from 'components/ui/form-fields/post-image-input/post-image-input.types';
import { PostVideoInput } from 'components/ui/form-fields/post-video-input/post-video-input.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Tooltip, TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import './emoji-picker-override.css';
import styles from './toolbar.module.less';

interface IBottomToolbarPluginProps {
  disableAttachments: boolean;
  isSendDisabled: boolean;
  attachments: Maybe<EditorAttachmentsType>;
  editorType?: EditorType;
  isVideoLoading: boolean;
  isLoadingImages: boolean;
  isGifPickerOpen: boolean;
  isSendHidden: boolean;
  onPollClick: (toggleFlag: boolean) => void;
  onImageLoad: UploadImageType;
  onUploadVideo?: (video: File) => void;
  onGifSelect: (gif: string) => void;
  setLoadingImages?: (value: string[]) => void;
  setIsGifPickerOpen: (value: boolean) => void;
  onVideoError?: (error: string) => void;
  convertImageItemToAttachment?: ConvertImageItemToAttachmentType;
}

export const BottomToolbarPlugin = (props: IBottomToolbarPluginProps) => {
  const {
    disableAttachments,
    isSendHidden,
    editorType,
    isGifPickerOpen,
    isSendDisabled,
    attachments,
    isLoadingImages,
    isVideoLoading,
    setIsGifPickerOpen,
    onPollClick,
  } = props;

  const [editor] = useLexicalComposerContext();

  const toolbarRef = useRef<HTMLDivElement>(null);

  const [isPollHidden, setIsPollHidden] = useState(true);
  const [isVideoInputOpen, setIsVideoInputOpen] = useState(false);
  const [shouldCloseEmojiPicker, setShouldCloseEmojiPicker] = useState(false);
  const [isVideoPermissionsRequested, setIsVideoPermissionsRequested] = useState(false);

  const handleEmojiClick = useCallback(
    (emojiData: EmojiClickData) => {
      setShouldCloseEmojiPicker(true);

      editor.focus(() => {
        editor.update(() => {
          const textNode = $createTextNode(emojiData.emoji);
          const selection = $getSelection();

          if (selection) {
            selection.insertNodes([textNode]);
          }
        });
      });
    },
    [editor],
  );

  const handleToggleShowGifPicker = useCallback(() => {
    setIsGifPickerOpen(!isGifPickerOpen);
  }, [isGifPickerOpen, setIsGifPickerOpen]);

  const handleRemovePoll = useCallback(() => {
    setIsPollHidden(true);
    onPollClick(true);
  }, [onPollClick]);

  const emojiPreviewConfig: Partial<PreviewConfig> = {
    showPreview: false,
  };

  const toolbarClasses = useMemo(() => cn(styles.Toolbar, styles['Toolbar--bottom']), []);

  const handleAddVideo = useCallback(() => {
    setIsVideoInputOpen(true);
  }, [setIsVideoInputOpen]);

  const isVideoButtonDisabled = useMemo(() => {
    return Boolean(
      attachments?.videos?.length ||
        Number(attachments?.gifs?.length) + Number(attachments?.images?.length) > 8 ||
        attachments?.poll ||
        isVideoLoading,
    );
  }, [attachments, isVideoLoading]);

  const idGifButtonDisabled = useMemo(() => {
    return Boolean(
      Number(attachments?.gifs?.length) + Number(attachments?.images?.length) > 8 ||
        attachments?.poll,
    );
  }, [attachments]);

  return (
    <div ref={toolbarRef} className={toolbarClasses}>
      {editorType !== EditorType.GameChat && !disableAttachments && (
        <IconButton
          theme={IconButtonTheme.Secondary}
          iconName={IconFontName.VideoCamera}
          iconSize={IconFontSize.Big}
          onClick={handleAddVideo}
          disabled={isVideoButtonDisabled}
        />
      )}
      {editorType !== EditorType.GameChat && !disableAttachments && (
        <PostImageInput
          convertImageItemToAttachment={props.convertImageItemToAttachment}
          accept={FILES_IMAGE_TYPES.join(', ')}
          onImageLoad={props.onImageLoad}
          id="attachImage"
          name="attachImage"
          isDisabled={!isPollHidden || isLoadingImages}
          setLoadingImages={props.setLoadingImages}
        />
      )}
      {!disableAttachments && (
        <IconButton
          theme={IconButtonTheme.Secondary}
          iconName={IconFontName.Gif}
          iconSize={IconFontSize.Big}
          onClick={handleToggleShowGifPicker}
          disabled={idGifButtonDisabled}
        />
      )}
      <Tooltip
        toggleOnClick
        tooltipPortalRoot={toolbarRef.current}
        eventType={TooltipEventType.click}
        isNeedCloseTooltip={shouldCloseEmojiPicker}
        setIsNeedCloseTooltip={setShouldCloseEmojiPicker}
        tooltipOffset={12}
        tooltipContent={
          <EmojiPicker
            theme={Theme.DARK}
            previewConfig={emojiPreviewConfig}
            onEmojiClick={handleEmojiClick}
          />
        }
      >
        <IconButton theme={IconButtonTheme.Secondary} iconName={IconFontName.Reaction} />
      </Tooltip>
      {!isPollHidden && (
        <div className={styles.Toolbar__RemovePoll}>
          <Button theme={ButtonTheme.TextAlert} size={ButtonSize.Small} onClick={handleRemovePoll}>
            Remove Poll
          </Button>
        </div>
      )}
      {!isSendHidden && (
        <div className={styles.Toolbar__SubmitButton}>
          <AuthTooltip>
            <Button
              disabled={isSendDisabled}
              size={ButtonSize.Small}
              theme={ButtonTheme.Primary}
              type="submit"
              iconName={isSendDisabled ? IconFontName.Send : IconFontName.SendFilled}
            />
          </AuthTooltip>
        </div>
      )}
      {isGifPickerOpen && (
        <GifPicker
          onGifSelect={props.onGifSelect}
          onClose={handleToggleShowGifPicker}
          visible={isGifPickerOpen}
        />
      )}
      <div className={styles.Video}>
        <PostVideoInput
          isVideoInputOpen={isVideoInputOpen}
          setIsVideoInputOpen={setIsVideoInputOpen}
          accept={FILES_VIDEO_TYPES.join(', ')}
          onVideoLoad={props.onUploadVideo}
          id="attachVideo"
          name="attachVideo"
          onError={props.onVideoError}
          isVideoPermissionsRequested={isVideoPermissionsRequested}
          setIsVideoPermissionsRequested={setIsVideoPermissionsRequested}
        />
      </div>
    </div>
  );
};
