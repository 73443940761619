import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import {
  ICreatePostParams,
  ICreatePostPayload,
} from 'services/posts/interfaces/create-post-payload.interface';
import { IPublicationPayload } from 'services/posts/interfaces/publication-payload.interface';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { PlayerStore } from 'stores/player/player.store';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { FeedSectionEnum } from 'stores/posts/posts.config';
import { CreatePostTypeEnum, PostsStore } from 'stores/posts/posts.store';

import { TYPES } from 'configs/di-types.config';
import { IS_ENABLE_ATHLETE_POST_CREATION } from 'configs/feature.config';
import * as paths from 'routes/paths.constants';

import { postsLogger } from 'loggers/posts.logger';

import { ShortVideoBottomSheet } from 'components/bottom-sheet/short-video-bottom-sheet/short-video-bottom-sheet.component';
import { CreatePostButton } from 'components/create-post-button/create-post-button.component';
import { CreateGroupPostModal } from 'components/posts/create-group-post-modal/create-group-post-modal.component';
import { CreatePollPostModal } from 'components/posts/create-poll-post-modal/create-poll-post-modal.component';
import { CreateUserGeneratedPostModal } from 'components/posts/create-user-generated-post-modal/create-user-generated-post-modal.component';
import { CreateVideoModal } from 'components/posts/create-video-post-modal/create-video-post-modal.component';
import { RecordVideoModal } from 'components/posts/record-video-post-modal/record-video-post-modal.component';

export const PostCreationContainer = observer(() => {
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
  const [isCreatePostDisabled, setIsCreatePostDisabled] = useState(false);
  const [isShortVideoBottomSheetOpen, setIsShortVideoBottomSheetOpen] = useState(false);
  const [isRecordVideoModalOpen, setIsRecordVideoModalOpen] = useState(false);
  const params = useParams<{
    [paths.TEAM_ID_PARAM]: string;
  }>();

  const { teamId } = params;

  useEffect(() => {
    if (playerStore.playerDetails) {
      const { firstname, lastname } = playerStore.playerDetails;

      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.PlayerProfileFanZone,
          player: `${firstname} ${lastname}`,
        },
      });
    }
  }, [playerStore.playerDetails]);

  const handleCreateUserGeneratedPostModalOpen = useCallback(() => {
    if (!authStore.triggerAuthorisationCheck()) {
      return;
    }

    postsStore.setIsPostCreationModalVisible(true);
    postsStore.setSelectedCreatePostType(CreatePostTypeEnum.UserGeneratedPost);
  }, [postsStore, authStore]);

  const handleCreatePollPostModalOpen = useCallback(() => {
    if (!authStore.triggerAuthorisationCheck()) {
      return;
    }

    postsStore.setIsPostCreationModalVisible(true);
    postsStore.setSelectedCreatePostType(CreatePostTypeEnum.PollPost);
  }, [postsStore, authStore]);

  const handleCreateGroupPostModalOpen = useCallback(() => {
    if (!authStore.triggerAuthorisationCheck()) {
      return;
    }

    postsStore.setIsPostCreationModalVisible(true);
    postsStore.setSelectedCreatePostType(CreatePostTypeEnum.GroupPost);
  }, [postsStore, authStore]);

  const isCreatePostEnabled = useMemo(() => {
    return (
      layoutStore.activeFeed === FeedTypes.PlayerFanZone ||
      (layoutStore.activeFeed === FeedTypes.TeamFanZone && !authStore.isAthlete)
    );
  }, [layoutStore.activeFeed, authStore.isAthlete]);

  const handleVideoError = useCallback(
    (message: string) => {
      postsStore.setErrors([
        {
          message,
        },
      ]);
    },
    [postsStore],
  );

  const handleCreatePost = useCallback(
    async (post: ICreatePostParams) => {
      if (!authStore.triggerAuthorisationCheck()) {
        return;
      }

      const createPostPayload: ICreatePostPayload = {
        attachments: post.attachments,
        section: FeedSectionEnum.FAN_ZONE,
        title: post.title,
        content: post.content,
      };

      if (playerStore.playerDetails?.id) {
        createPostPayload.playerId = playerStore.playerDetails.id;
      }

      if (teamId) {
        createPostPayload.teamId = teamId;
      }

      if (post.responseType) {
        createPostPayload.collaboration = {
          text_allowed: post.responseType.textAllowed,
          media: post.responseType?.media ? post.responseType?.media : undefined,
        };
      }

      setIsCreatePostDisabled(true);

      const isPostCreated = await postsStore.createPost(createPostPayload);

      if (isPostCreated) {
        postsStore.setIsPostCreationModalVisible(false);
      }

      setIsCreatePostDisabled(false);
    },
    [authStore, playerStore.playerDetails, postsStore, teamId],
  );

  const handleCreateVideoPost = useCallback(
    async (post: IPublicationPayload) => {
      if (!authStore.triggerAuthorisationCheck() || !playerStore.playerDetails?.id) {
        return;
      }

      const createPostPayload: ICreatePostPayload = {
        attachments: post.attachments,
        playerId: playerStore.playerDetails.id,
        section: FeedSectionEnum.FAN_ZONE,
        content: post.content,
      };

      setIsCreatePostDisabled(true);

      const isPostCreated = await postsStore.createPost(createPostPayload);

      if (isPostCreated) {
        postsStore.setIsPostCreationModalVisible(false);
      }

      setIsCreatePostDisabled(false);
    },
    [authStore, playerStore.playerDetails, postsStore],
  );

  const handleCreatePostModalClose = useCallback(() => {
    postsStore.setIsPostCreationModalVisible(false);
    postsStore.setPostVideo(null);
  }, [postsStore]);

  const handleRemoveVideo = useCallback(() => {
    postsStore.setPostVideo(null);
  }, [postsStore]);

  const handleUploadVideo = useCallback(
    async (file: File) => {
      postsLogger.debug({ msg: 'passed chosen video file', file });
      await postsStore.uploadVideoForPost(file);
      setIsShortVideoBottomSheetOpen(false);
    },
    [postsStore],
  );

  const handleTakeVideo = useCallback(() => {
    setIsShortVideoBottomSheetOpen(false);
    setIsRecordVideoModalOpen(true);
  }, []);

  const handleSelectFromLibrary = useCallback(() => {
    postsStore.setSelectedCreatePostType(CreatePostTypeEnum.VideoPost);
  }, [postsStore]);

  const handleCloseVideoModal = useCallback(() => {
    postsStore.setPostVideo(null);
    postsStore.setSelectedCreatePostType(null);
    setIsShortVideoBottomSheetOpen(false);
  }, [postsStore]);

  const handleCloseVideoRecordModal = useCallback(() => {
    setIsRecordVideoModalOpen(false);
    setIsShortVideoBottomSheetOpen(false);
  }, []);

  const handleNewRecordedVideo = useCallback(
    async (video: File) => {
      await postsStore.uploadVideoForPost(video);
      postsStore.setSelectedCreatePostType(CreatePostTypeEnum.VideoPost);
      setIsRecordVideoModalOpen(false);
    },
    [postsStore],
  );

  return (
    <>
      {isCreatePostEnabled && (
        <CreatePostButton
          isAthlete={authStore.isAthlete}
          hasSmartBanner={layoutStore.isDisplayedSmartBanner}
          onCreatePoll={handleCreatePollPostModalOpen}
          onCreateGroupPost={handleCreateGroupPostModalOpen}
          onCreateVideo={() => setIsShortVideoBottomSheetOpen(true)}
          onCreateUserGeneratedPost={handleCreateUserGeneratedPostModalOpen}
        />
      )}
      {postsStore.isPostCreationModalVisible &&
        postsStore.selectedCreatePostType === CreatePostTypeEnum.UserGeneratedPost && (
          <CreateUserGeneratedPostModal
            convertImageItemToAttachment={applicationStore.convertImageItemToAttachment}
            isFetching={postsStore.processingCreatePost}
            isPostVideoLoading={postsStore.isPostVideoLoading}
            postVideo={postsStore.postVideo}
            onUploadVideo={handleUploadVideo}
            onRemoveVideo={handleRemoveVideo}
            onClose={handleCreatePostModalClose}
            isVisible={postsStore.isPostCreationModalVisible}
            onPostCreate={handleCreatePost}
            isCreateDisabled={isCreatePostDisabled}
            onVideoError={handleVideoError}
          />
        )}
      {postsStore.isPostCreationModalVisible &&
        postsStore.selectedCreatePostType === CreatePostTypeEnum.GroupPost && (
          <CreateGroupPostModal
            convertImageItemToAttachment={applicationStore.convertImageItemToAttachment}
            isFetching={postsStore.processingCreatePost}
            isPostVideoLoading={postsStore.isPostVideoLoading}
            postVideo={postsStore.postVideo}
            onUploadVideo={handleUploadVideo}
            onRemoveVideo={handleRemoveVideo}
            onClose={handleCreatePostModalClose}
            isVisible={postsStore.isPostCreationModalVisible}
            onPostCreate={handleCreatePost}
            isCreateDisabled={isCreatePostDisabled}
            onVideoError={handleVideoError}
          />
        )}
      {IS_ENABLE_ATHLETE_POST_CREATION &&
        postsStore.isPostCreationModalVisible &&
        postsStore.selectedCreatePostType === CreatePostTypeEnum.PollPost && (
          <CreatePollPostModal
            convertImageItemToAttachment={applicationStore.convertImageItemToAttachment}
            isVisible={postsStore.isPostCreationModalVisible}
            isFetching={postsStore.processingCreatePost}
            onPostCreate={handleCreatePost}
            onClose={handleCreatePostModalClose}
          />
        )}
      {isShortVideoBottomSheetOpen && (
        <ShortVideoBottomSheet
          visible
          onSelectFromLibrary={handleSelectFromLibrary}
          onTakeVideo={handleTakeVideo}
          onClose={() => setIsShortVideoBottomSheetOpen(false)}
          onUploadVideo={handleUploadVideo}
          isLoading={postsStore.isPostVideoLoading}
        />
      )}
      {IS_ENABLE_ATHLETE_POST_CREATION &&
        postsStore.postVideo &&
        postsStore.selectedCreatePostType === CreatePostTypeEnum.VideoPost && (
          <CreateVideoModal
            isVisible
            isFetching={postsStore.processingCreatePost}
            isPostVideoLoading={postsStore.isPostVideoLoading}
            postVideo={postsStore.postVideo}
            onUploadVideo={handleUploadVideo}
            onRemoveVideo={handleRemoveVideo}
            onClose={handleCloseVideoModal}
            onPostCreate={handleCreateVideoPost}
            isCreateDisabled={isCreatePostDisabled}
          />
        )}
      {IS_ENABLE_ATHLETE_POST_CREATION && isRecordVideoModalOpen && (
        <RecordVideoModal
          isVisible={isRecordVideoModalOpen}
          onUploadVideo={handleNewRecordedVideo}
          isLoading={postsStore.isPostVideoLoading}
          onClose={handleCloseVideoRecordModal}
        />
      )}
    </>
  );
});
