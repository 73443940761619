import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import { IBucketsResponse } from 'services/buckets/interfaces/buckets.interface';
import { REPUTATION_BUCKETS } from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { DEFAULT_SEASONS_PAGINATION } from 'stores/seasons/seasons.config';

import { TYPES } from 'configs/di-types.config';

@injectable()
export class BucketsService {
  private readonly httpService: HttpService;

  constructor(@inject<HttpService>(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchBuckets(
    pagination: IPagination = DEFAULT_SEASONS_PAGINATION,
  ): Promise<IResponse<IBucketsResponse>> {
    const { limit, page } = pagination;

    const params = new URLSearchParams({
      limit: String(limit),
      page: String(page),
    });

    return this.httpService.request<IBucketsResponse>({
      method: 'GET',
      url: REPUTATION_BUCKETS,
      params,
    });
  }
}
