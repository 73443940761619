import { FC, memo } from 'react';

import { IGameWithStats } from 'stores/game/interfaces/game.interface';

import { GamesScores } from 'components/game/game-score/game-scores/game-scores.component';
import { Team, ThemeTeam } from 'components/game/game-score/team/team.component';

import styles from './game-score.module.less';

interface IGameScoreProps {
  game: IGameWithStats;
  isDraw: boolean;
  homeIsWinning: boolean;
  onTeamClick: (teamId: number) => void;
}

export const GameScore: FC<IGameScoreProps> = memo((props: IGameScoreProps) => {
  const { game, isDraw, homeIsWinning } = props;

  return (
    <div className={styles.GameInfo}>
      <Team
        status={game.status}
        team={game.visitors}
        theme={ThemeTeam.Visitor}
        seasonType={game.season.type}
        isWinning={!homeIsWinning && !isDraw}
        onTeamClick={props.onTeamClick}
      />
      <GamesScores
        status={game.status}
        formattedPeriod={game.formattedPeriod}
        clockFormatted={game.clockFormatted}
        homePoints={game.home.points}
        visitorsPoints={game.visitors.points}
        homeIsWinning={homeIsWinning && !isDraw}
      />
      <Team
        status={game.status}
        team={game.home}
        theme={ThemeTeam.Home}
        seasonType={game.season.type}
        isWinning={homeIsWinning && !isDraw}
        onTeamClick={props.onTeamClick}
      />
    </div>
  );
});
