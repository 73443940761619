import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import {
  ICommentResponse,
  ICommentsResponse,
  ICommentsSearchParams,
  IDeleteCommentPayload,
  IGetCommentPayload,
  IPostCommentBookmarkPayload,
} from 'services/comments/interfaces/comments.interface';
import {
  COMMENT_ID_API_PARAM,
  POST_ID_API_PARAM,
} from 'services/http/consts/api-endpoint-params.constants';
import { HttpService } from 'services/http/http.service';
import { ITogglePostBookmarkPayload } from 'services/posts/interfaces/create-post-payload.interface';
import { IPollAttachmentResponse } from 'services/posts/interfaces/post-attachments-response.interface';
import { ILikesResponse, IVideoResponse } from 'services/posts/interfaces/posts-response.interface';
import { IPublicationPayload } from 'services/posts/interfaces/publication-payload.interface';

import { VideoUploadPayloadType } from 'stores/posts/interfaces/post.interface';

import { TYPES } from 'configs/di-types.config';
import {
  POLL_ID_PARAM,
  POLL_OPTION_ID_PARAM,
  POST_COMMENT_ID_PARAM,
  POST_ID_PARAM,
} from 'routes/paths.constants';

import { IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

import {
  ADD_POST_COMMENT_TO_BOOKMARK,
  POST_COMMENT_LIKE,
  POST_COMMENT_REPLIES,
  POST_COMMENTS,
  POST_POLL_VOTE,
  SINGLE_COMMENT,
  VIDEOS_UPLOAD,
} from '../http/consts/api-endpoints.constants';

@injectable()
export class CommentsService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public async fetchComments(
    pagination: IPagination,
    postId: string,
    commentId: Maybe<string>,
    sort: string,
  ): Promise<IResponse<ICommentsResponse>> {
    const { limit, page } = pagination;

    const paramsObject: ICommentsSearchParams = {
      limit: limit.toString(),
      page: page.toString(),
      sort,
    };

    const routeParams = {
      [POST_ID_PARAM]: postId,
      [POST_COMMENT_ID_PARAM]: commentId || '',
    };

    let url;

    if (commentId) {
      url = POST_COMMENT_REPLIES;
    } else {
      url = POST_COMMENTS;
    }

    return this.httpService.request<ICommentsResponse>({
      method: 'GET',
      url,
      headers: {
        'X-Version': '6',
      },
      params: new URLSearchParams({ ...paramsObject }),
      routeParams,
    });
  }

  public async createComment(
    postId: string,
    payload: IPublicationPayload,
    commentId: Maybe<string>,
  ): Promise<IResponse<ICommentResponse>> {
    let url;

    if (commentId) {
      url = POST_COMMENT_REPLIES;
    } else {
      url = POST_COMMENTS;
    }

    return this.httpService.request<ICommentResponse, IPublicationPayload>({
      method: 'POST',
      headers: {
        'X-Version': '6',
      },
      url,
      routeParams: {
        [POST_ID_PARAM]: postId,
        [POST_COMMENT_ID_PARAM]: commentId || '',
      },
      body: payload,
    });
  }

  public async commentPollVote(
    payload: IPollVoteData,
  ): Promise<IResponse<IPollAttachmentResponse>> {
    return this.httpService.request<IPollAttachmentResponse, IPollVoteData>({
      method: 'POST',
      url: POST_POLL_VOTE,
      routeParams: { [POLL_ID_PARAM]: payload.pollId, [POLL_OPTION_ID_PARAM]: payload.answerId },
    });
  }

  public async deleteComment(payload: IDeleteCommentPayload): Promise<IResponse<ICommentResponse>> {
    return this.httpService.request<ICommentResponse, IGetCommentPayload>({
      method: 'DELETE',
      headers: {
        'X-Version': '6',
      },
      url: SINGLE_COMMENT,
      routeParams: {
        [POST_ID_PARAM]: payload.postId,
        [POST_COMMENT_ID_PARAM]: payload.commentId,
      },
    });
  }

  public async fetchSingleComment(
    payload: IGetCommentPayload,
  ): Promise<IResponse<ICommentResponse>> {
    return this.httpService.request<ICommentResponse, IGetCommentPayload>({
      method: 'GET',
      headers: {
        'X-Version': '6',
      },
      url: SINGLE_COMMENT,
      routeParams: {
        [POST_ID_PARAM]: payload.postId,
        [POST_COMMENT_ID_PARAM]: payload.commentId,
      },
    });
  }

  public async togglePostCommentBookmark(
    payload: IPostCommentBookmarkPayload,
  ): Promise<IResponse<ICommentResponse>> {
    return this.httpService.request<ICommentResponse, ITogglePostBookmarkPayload>({
      method: 'POST',
      headers: {
        'X-Version': '6',
      },
      url: ADD_POST_COMMENT_TO_BOOKMARK,
      routeParams: {
        [POST_ID_API_PARAM]: payload.postId,
        [COMMENT_ID_API_PARAM]: payload.commentId,
      },
    });
  }

  public async postCommentLikes(
    postId: string,
    commentId: string,
  ): Promise<IResponse<ILikesResponse>> {
    return this.httpService.request<ILikesResponse>({
      method: 'POST',
      headers: {
        'X-Version': '6',
      },
      url: POST_COMMENT_LIKE,
      routeParams: {
        [POST_ID_PARAM]: postId,
        [COMMENT_ID_API_PARAM]: commentId,
      },
    });
  }

  public async uploadVideo(payload: VideoUploadPayloadType): Promise<IResponse<IVideoResponse>> {
    return this.httpService.request<IVideoResponse, VideoUploadPayloadType>({
      method: 'POST',
      url: VIDEOS_UPLOAD,
      body: payload,
      isMultipart: true,
    });
  }
}
