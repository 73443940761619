import { useMemo } from 'react';
import cn from 'classnames';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { CommentCreateDataType } from 'components/forms/comment-create/comment-create-form.component';
import { TextCommentCreateForm } from 'components/forms/text-comment-create/text-comment-create-form.component';
import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './create-text-comment-modal.module.less';

interface ICreateTextCommentModalProps {
  isVisible: boolean;
  processing: boolean;
  title?: string;
  placeholder: string;
  isCreateCommentSuccess: boolean;
  isNeedActivateEditor: boolean;
  initialValues?: CommentCreateDataType;
  onClose: () => void;
  setIsCreateCommentSuccess?: (isSuccess: boolean) => void;
  setIsNeedActivateEditor?: (activeEditor: boolean) => void;
  onSubmit: (data: CommentCreateDataType) => void;
}

export const CreateTextCommentModal = (props: ICreateTextCommentModalProps) => {
  const {
    title,
    placeholder,
    processing,
    isVisible,
    isCreateCommentSuccess,
    isNeedActivateEditor,
    initialValues,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const postCreateModalClasses = useMemo<string>(() => {
    return cn(styles.PostCreateModal, {
      [styles['PostCreateModal--blur']]: processing,
    });
  }, [processing]);

  return (
    <BaseModalComponent
      size={ModalWindowSize.M}
      title={placeholder}
      onClose={props.onClose}
      visible={isVisible}
      isFullScreen={!isDesktopPlus}
    >
      <div className={postCreateModalClasses}>
        <TextCommentCreateForm
          title={title}
          placeholder={placeholder}
          initialValues={initialValues}
          isFixedPosition
          isCreateCommentSuccess={isCreateCommentSuccess}
          isNeedActivateEditor={isNeedActivateEditor}
          isEditorExpand={false}
          processing={processing}
          setIsCreateCommentSuccess={props.setIsCreateCommentSuccess}
          setIsNeedActivateEditor={props.setIsNeedActivateEditor}
          onSubmit={props.onSubmit}
        />
      </div>
      <Loader isShow={processing} />
    </BaseModalComponent>
  );
};
