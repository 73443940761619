import { memo, SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import { GroupBase, OnChangeValue } from 'react-select';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';

import { IHookFormInput } from 'components/ui/form-fields/hook-form-input.interface';
import { GameOption } from 'components/ui/form-fields/select-team/game-option/game-option.component';

import { ResponsiveSelect } from '../responsive-select/responsive-select.component';

import { TeamControl } from './team-control/team-control.component';
import { TeamDropdownIndicator } from './team-dropdown-indicator/team-dropdown-indicator.component';
import { TeamMenuList } from './team-menu-list/team-menu-list.component';
import { TeamOption } from './team-option/team-option.component';
import { TeamSingleValue } from './team-single-value/team-single-value.component';

import './select-team.less';

const selectTeamComponents = {
  IndicatorSeparator: () => null,
  DropdownIndicator: TeamDropdownIndicator,
  Option: TeamOption,
  Control: TeamControl,
  SingleValue: TeamSingleValue,
  MenuList: TeamMenuList,
};

interface ISelectTeamProps extends IHookFormInput<Maybe<number>> {
  options: ITeamOption[];
  rightAligned?: boolean;
  forGames?: boolean;
  onFocusCallback?: () => void;
  setDisabledScroll: (isDisabledScroll: boolean) => void;
}

export const SelectTeam = memo((props: ISelectTeamProps) => {
  const {
    rightAligned = false,
    forGames = false,
    options,
    disabled,
    onChange,
    value,
    placeholder,
    onBlur,
    onFocusCallback,
    setDisabledScroll,
  } = props;

  const inputElementRef = useRef<Maybe<HTMLInputElement>>(null);

  const handleChange = useCallback(
    (teamOption: OnChangeValue<ITeamOption | null, false>) => {
      if (teamOption) {
        onChange?.(teamOption.value);
      }
    },
    [onChange],
  );

  const currentOption = useMemo<Maybe<ITeamOption>>(
    () =>
      options.find(({ value: currentValue }) => {
        if (value) {
          return currentValue === value;
        }
        return null;
      }) || null,
    [options, value],
  );

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (event: SyntheticEvent) => {
    if (!inputElementRef.current) {
      inputElementRef.current = event.target as HTMLInputElement;
    }

    setIsFocused(true);
    setDisabledScroll(true);

    onFocusCallback?.();
  };

  const handleBlur = (event: SyntheticEvent) => {
    setIsFocused(false);
    setDisabledScroll(false);

    onBlur?.(event);
  };

  const handleMenuClose = () => {
    if (inputElementRef.current) {
      inputElementRef.current.blur();
    }
  };

  const setDefaultStyles = useMemo(() => {
    const defaultStyles = {
      container: () => ({}),
      control: () => ({}),
      menu: () => ({}),
      option: () => ({}),
      indicator: () => ({}),
      input: () => ({}),
      placeholder: () => ({}),
      valueContainer: () => ({}),
      singleValue: () => ({}),
    };

    if (rightAligned) {
      defaultStyles.menu = () => ({
        position: 'absolute',
        right: 0,
      });
    }

    return defaultStyles;
  }, [rightAligned]);

  const localComponents = useMemo(() => {
    if (!forGames) {
      return selectTeamComponents as Partial<
        SelectComponents<Maybe<ITeamOption>, false, GroupBase<Maybe<ITeamOption>>>
      >;
    }

    return {
      ...selectTeamComponents,
      Option: GameOption,
    } as Partial<SelectComponents<Maybe<ITeamOption>, false, GroupBase<Maybe<ITeamOption>>>>;
  }, [forGames]);

  return (
    <ResponsiveSelect<Maybe<ITeamOption>>
      styles={setDefaultStyles}
      components={localComponents}
      className="TeamSelect__container"
      menuPortalTarget={document.body}
      menuPosition="absolute"
      menuPlacement="bottom"
      classNamePrefix="TeamSelect"
      placeholder={isFocused ? '' : placeholder}
      isMulti={false}
      isDisabled={disabled}
      value={currentOption}
      options={options}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMenuClose={handleMenuClose}
      isWebSelectOnMobile
      closeMenuOnSelect
    />
  );
});
