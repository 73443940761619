import { inject, injectable } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { IUserAchievement } from 'stores/reputations-points/interfaces/user-achievement.interface';
import { SeasonsStore } from 'stores/seasons/seasons.store';

import { TYPES } from 'configs/di-types.config';

@injectable()
export class ReputationsPointsStore extends ApiConnectedStore {
  public achievementSeasonId: Maybe<string>;

  public userAchievements: IUserAchievement[];

  private readonly seasonsStore: SeasonsStore;

  constructor(
    @inject<SeasonsStore>(TYPES.SeasonsStore)
    seasonsStore: SeasonsStore,
  ) {
    super();

    this.seasonsStore = seasonsStore;

    this.achievementSeasonId = null;

    this.userAchievements = [];

    makeObservable(this, {
      achievementSeasonId: observable,
      userAchievements: observable,

      setAchievementSeasonId: action.bound,
      setUserAchievements: action.bound,
      retrieveAchievements: action.bound,
    });

    reaction(() => [this.achievementSeasonId], this.handleFilterChange);
  }

  private handleFilterChange = async () => {
    // TODO: Implement
  };

  public setAchievementSeasonId(seasonId: Maybe<string>) {
    this.achievementSeasonId = seasonId;
  }

  public setUserAchievements(userAchievements: IUserAchievement[]) {
    this.userAchievements = userAchievements;
  }

  public retrieveAchievements() {
    // TODO: Implement
    return [];
  }
}
