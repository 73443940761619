import { useCallback, useEffect, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { AuthMode } from 'stores/auth/enums/auth-mode.enum';

import { MINIMUM_TIME_TO_EXPIRE_S, REFRESH_TOKEN_INTERVAL_MS } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { authLogger } from 'loggers/auth.logger';

import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';

import { ForgotPasswordContainer } from './forgot-password/forgot-password.container';
import { LoginContainer } from './login/login.container';
import { SignUpContainer } from './sign-up/sign-up.container';

export const AuthContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const modalTitle = useMemo<Maybe<string>>(() => {
    switch (authStore.authMode) {
      case AuthMode.Login:
        return 'Login';

      case AuthMode.SignUp:
        return 'Create Account';

      case AuthMode.ForgotPassword: {
        if (!authStore.forgotPasswordPreserved?.confirmed) {
          return 'Forgot Password';
        }
        return 'Create New Password';
      }
      default:
        return null;
    }
  }, [authStore.authMode, authStore.forgotPasswordPreserved]);

  const isNeedBackClick = useMemo(
    () =>
      authStore.authMode === AuthMode.ForgotPassword &&
      !authStore.forgotPasswordPreserved?.confirmed,
    [authStore.authMode, authStore.forgotPasswordPreserved],
  );

  const handleClose = useCallback(() => {
    let buttonTapType: Maybe<ButtonTapsEnum> = null;

    if (authStore.authMode === AuthMode.SignUp) {
      buttonTapType = ButtonTapsEnum.SignUpCancel;
    }
    if (authStore.authMode === AuthMode.ForgotPassword) {
      buttonTapType = ButtonTapsEnum.LoginForgotPasswordCancel;
    }
    if (authStore.authMode === AuthMode.Login) {
      buttonTapType = ButtonTapsEnum.LoginCancel;
    }

    if (buttonTapType) {
      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: buttonTapType,
        },
      });
    }

    authStore.setAuthMode(null);
  }, [authStore]);

  const handleBackClick = useCallback(() => {
    authStore.setAuthMode(AuthMode.Login);
  }, [authStore]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    const { activeSession } = authStore;

    if (activeSession) {
      interval = setInterval(() => {
        const currentTimestamp = Date.now() / 1000;
        const difference = activeSession.expiresIn - currentTimestamp;

        authLogger.info({ msg: `Token expires in ${difference} seconds` });

        if (difference <= MINIMUM_TIME_TO_EXPIRE_S) {
          authLogger.info({
            msg: `Token expires in less than ${MINIMUM_TIME_TO_EXPIRE_S} seconds and will be refreshed`,
          });

          authStore.fetchRefreshedSession(activeSession);

          clearInterval(interval);
        }
      }, REFRESH_TOKEN_INTERVAL_MS);
    }

    return () => {
      clearInterval(interval);
    };
  }, [authStore.activeSession, authStore]);

  return (
    <BaseModalComponent
      size={ModalWindowSize.M}
      isFullScreen={!isDesktopPlus}
      visible={authStore.isModalVisible}
      title={modalTitle}
      onClose={handleClose}
      onBackClick={handleBackClick}
      isNeedBackClick={isNeedBackClick}
    >
      {authStore.authMode === AuthMode.Login && <LoginContainer />}
      {authStore.authMode === AuthMode.SignUp && <SignUpContainer />}
      {authStore.authMode === AuthMode.ForgotPassword && <ForgotPasswordContainer />}
    </BaseModalComponent>
  );
});
