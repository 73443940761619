import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IPublicLink } from 'stores/auth/interfaces/custom-link.interface';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './editable-public-links.module.less';

interface IEditablePublicLinksProps {
  links: IPublicLink[];
  onEditClick?: (id: number) => void;
  onAddNewLink?: () => void;
  isAthlete: boolean;
}

export const EditablePublicLinks: FC<IEditablePublicLinksProps> = (props) => {
  const { links, onEditClick, isAthlete, onAddNewLink } = props;

  const linksClassNames = useMemo(
    () =>
      cn(styles.EditablePublicLinks, {
        [styles['EditablePublicLinks--empty']]: !links.length,
      }),
    [links],
  );

  const handleEditClick = useCallback(
    (event: SyntheticEvent, id: number) => {
      event.preventDefault();

      onEditClick?.(id);
    },
    [onEditClick],
  );

  return (
    <div className={linksClassNames}>
      {links.map((item) => {
        return (
          <a
            href={item.url}
            key={item.id}
            className={cn(styles.LinkWrapper, isAthlete ? styles['LinkWrapper--athlete'] : '')}
          >
            <div className={styles.LinkWrapper__Icon}>
              {item.iconSrc && <img src={item.iconSrc} alt={item.title} />}
            </div>
            <div className={styles.LinkWrapper__Content}>
              <div className={styles.Title}>{item.title}</div>
              <div className={styles.Description}>{item.description}</div>
            </div>
            {onEditClick ? (
              <IconButton
                iconSize={IconFontSize.ExtraBig}
                iconName={IconFontName.Edit}
                theme={IconButtonTheme.Transparent}
                onClick={(event: SyntheticEvent) => handleEditClick(event, item.id)}
              />
            ) : (
              <div className={styles.LinkWrapper__Button} />
            )}
          </a>
        );
      })}
      {onAddNewLink && (
        <Button
          theme={ButtonTheme.Tertiary2}
          fluid
          iconName={IconFontName.Add}
          onClick={props.onAddNewLink}
          size={ButtonSize.Big}
        >
          Add a Link
        </Button>
      )}
    </div>
  );
};
