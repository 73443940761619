import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import cn from 'classnames';

import { FieldWrapper } from 'components/ui/form-fields/field-wrapper/field-wrapper.component';
import { IFormField } from 'components/ui/form-fields/form-field.interface';

import styles from './text-area.module.less';

export interface ITextAreaProps extends IFormField {
  rows: number;
  maxLength?: number;
  onFocus?: (event: SyntheticEvent) => void;
  isShowCounter?: boolean;
  disabledResize?: boolean;
}

const DEFAULT_TITLE_INPUT_MAX_LENGTH = 300;

export const TextArea = forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  (props: ITextAreaProps, ref) => {
    const {
      maxLength = DEFAULT_TITLE_INPUT_MAX_LENGTH,
      disabled,
      readOnly,
      id,
      error,
      helperTextContent,
      helperTextTheme,
      label,
      name,
      placeholder,
      value = '',
      aside,
      onChange,
      onBlur,
      rows,
      rightIconProps,
      isShowCounter = true,
      disabledResize = false,
    } = props;

    const areaRef = useRef<HTMLTextAreaElement>(null);

    const handleResizeChange = useCallback(() => {
      if (areaRef?.current && !disabledResize) {
        areaRef.current.style.height = 'initial';
        const scrollHeight = areaRef?.current?.scrollHeight;
        areaRef.current.style.height = `${scrollHeight}px`;
      }
    }, [disabledResize]);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (onChange) {
          onChange(event.target.value);
        }
      },
      [onChange],
    );

    useEffect(() => {
      const inputRef = ref;

      if (inputRef && typeof inputRef === 'object') {
        inputRef.current = areaRef.current;
      }
    }, [ref]);

    useEffect(() => {
      handleResizeChange();
    });

    const handleKeyDown = useCallback((event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.code === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
      }
    }, []);

    const handleBlur = useCallback(
      (event: SyntheticEvent) => {
        if (onBlur) {
          onBlur(event);
        }
      },
      [onBlur],
    );

    const symbolsLeft = useMemo(() => {
      if (!value || !value.length) {
        return maxLength;
      }

      if (value?.length >= maxLength) {
        return 0;
      }

      return maxLength - value.length;
    }, [value, maxLength]);

    return (
      <FieldWrapper
        {...{
          error,
          helperTextContent,
          helperTextTheme,
          id,
          label,
          aside,
          rightIconProps,
        }}
      >
        {({ isInvalid }) => (
          <>
            <textarea
              {...{ readOnly, id, name, disabled, placeholder, ref, value, rows }}
              className={cn(styles.TextArea, {
                [styles['TextArea--invalid']]: isInvalid,
                [styles['TextArea--without-counter']]: !isShowCounter,
              })}
              maxLength={maxLength}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              ref={areaRef}
              onFocus={props.onFocus}
            />
            {isShowCounter && <div className={styles.Counter}>{symbolsLeft}</div>}
          </>
        )}
      </FieldWrapper>
    );
  },
);
