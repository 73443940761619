import { FC, memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { ISliderItem } from 'containers/posts/config/feed.config';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';

import styles from './select-slider.module.less';

interface ISelectSliderProps {
  items: ISliderItem[];
  fullWidth: boolean;
  selected: string;
  onItemClick: (action: BarActionType, value: string) => void;
}

export const SelectSlider: FC<ISelectSliderProps> = memo((props: ISelectSliderProps) => {
  const { items, fullWidth, onItemClick, selected } = props;

  const selectSliderWrapperClasses = useMemo(
    () =>
      cn(styles.SelectSlider, {
        [styles['SelectSlider--full-width']]: fullWidth,
      }),
    [fullWidth],
  );

  const getItemClasses = useCallback(
    (action: BarActionType) => {
      return cn(styles.SelectSlider__Item, {
        [styles['SelectSlider__Item--selected']]:
          action.type === BarAction.Click && action.payload === selected,
      });
    },
    [selected],
  );

  const handleItemClick = useCallback(
    (action: BarActionType, value: string) => () => {
      onItemClick(action, value);
    },
    [onItemClick],
  );

  return (
    <div className={selectSliderWrapperClasses}>
      {items.map((item, index) => (
        <div
          tabIndex={index}
          role="button"
          key={item.label}
          className={getItemClasses(item.action)}
          onClick={handleItemClick(item.action, item.value)}
          onKeyDown={handleItemClick(item.action, item.value)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
});
