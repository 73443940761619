import { FC, memo, PropsWithChildren, ReactNode, useMemo } from 'react';
import cn from 'classnames';

import styles from './label.module.less';

export enum LabelTheme {
  Disabled,
}

interface ILabelProps {
  htmlFor: string;
  children?: ReactNode;
  theme?: LabelTheme;
}

export const Label: FC<ILabelProps> = memo((props: PropsWithChildren<ILabelProps>) => {
  const { htmlFor, children, theme } = props;

  const labelClassNames = useMemo(() => {
    return cn(styles.Label, {
      [styles['Label--theme-disabled']]: theme === LabelTheme.Disabled,
    });
  }, [theme]);

  return (
    <label className={labelClassNames} htmlFor={htmlFor}>
      {children}
    </label>
  );
});
