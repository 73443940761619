import { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import cn from 'classnames';
import { $getRoot, EditorState, TextNode } from 'lexical';

import {
  ConvertImageItemToAttachmentType,
  ConvertVideoItemToAttachmentType,
  IUploadImageData,
} from 'services/application/interfaces/upload-image.interface';
import {
  CollaborationMediaEnum,
  CollaborationMediaType,
} from 'services/collaboration/interfaces/collaboration.interface';
import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';

import { arrayFilterUniqueByKey } from 'helpers/array-filter-unique-by-key.util';

import { useMainProvider } from 'hooks/use-main-provider';
import { useOnClickOutside } from 'hooks/use-on-click-outside';

import { ContentEditableWithScroll } from 'components/editor/components/content-editable-with-scroll/content-editable-with-scroll.component';
import { Placeholder } from 'components/editor/components/placeholder/placeholder.component';
import AutoLinkPlugin from 'components/editor/components/plugins/AutoLinkPlugin';
import { BottomToolbarPlugin } from 'components/editor/plugins/toolbar/bottom-toolbar-plugin';
import { SingleMediaToolbarPlugin } from 'components/editor/plugins/toolbar/single-media-toolbar-plugin';
import { TopToolbarPlugin } from 'components/editor/plugins/toolbar/top-toolbar-plugin';
import { VideoPostToolbarPlugin } from 'components/editor/plugins/toolbar/video-post-toolbar-plugin';
import { TreeViewPlugin } from 'components/editor/plugins/treeview/tree-view-plugin';
import { GifSize, SingleGif } from 'components/single-gif/single-gif.component';
import { AttachmentsImageItem } from 'components/ui/attachments-image-item/attachment-image-item.component';
import {
  IPollAnswer,
  IPollCreateData,
} from 'components/ui/form-fields/poll-form/interfaces/poll.interface';
import { PollForm } from 'components/ui/form-fields/poll-form/poll-form.component';
import { InputImageDataType } from 'components/ui/form-fields/post-image-input/post-image-input.types';
import { isIUploadImageDataArray } from 'components/ui/form-fields/post-image-input/utils';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Loader, LoaderSizeEnum, LoaderTheme } from 'components/ui/loader/loader.component';
import { TextTooltip } from 'components/ui/text-tooltip/text-tooltip.component';
import { TooltipEventType } from 'components/ui/tooltip/tooltip.component';
import { VideoPreview, VideoPreviewSize } from 'components/video-preview/video-preview.component';

import styles from './base-editor.module.less';

const AUTOFOCUS_DELAY = 100; // ms

export enum EditorTheme {
  Grey,
  Light,
  BottomSheet,
}

export enum EditorType {
  Default,
  GroupPost,
  GameChat,
  Collaboration,
  VideoPost,
}

type EditorContentType = {
  value: string;
  length?: number;
};

export type EditorAttachmentsType = {
  images?: IUploadImageData[];
  gifs?: string[];
  videos?: string[];
  poll?: IPollCreateData;
};

export type EditorDataType = {
  content?: EditorContentType;
  attachments?: Maybe<EditorAttachmentsType>;
};

export type EditorLoadDataType = {
  clear: () => void;
};

interface IBaseEditorProps {
  theme: EditorTheme;
  disableAttachments: boolean;
  postId?: string;
  placeholder?: string;
  isFullHeight: boolean;
  topToolbarRight: ReactNode;
  isExpand?: boolean;
  isHighlighted?: boolean;
  isActive?: boolean;
  isTreeViewEnabled?: boolean;
  isNeedForceExpand: boolean;
  isInvalid?: boolean;
  disabled?: boolean;
  isVideoLoading: boolean;
  isSendHidden: boolean;
  loadingImages?: string[];
  editorData?: EditorDataType;
  postVideo: Maybe<IVideoResponse>;
  onLoad?: (editor: EditorLoadDataType) => void;
  setLoadingImages?: (value: string[]) => void;
  onEditorFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: EditorDataType) => void;
  onUploadVideo?: (video: File) => void;
  onRemoveVideo?: () => void;
  onVideoError?: (error: string) => void;
  convertImageItemToAttachment?: ConvertImageItemToAttachmentType;
  editorType?: EditorType;
  mediaType?: Maybe<CollaborationMediaEnum>;
  collaborationMediaItem?: Maybe<CollaborationMediaType>;
  convertVideoItemToAttachment?: ConvertVideoItemToAttachmentType;
  setCollaborationMediaItem?: (value: Maybe<CollaborationMediaType>) => void;
  isCollaborationVideoLoading?: boolean;
  isCollaborationImageLoading?: boolean;
}

export const BaseEditor = memo((props: IBaseEditorProps) => {
  const {
    theme,
    editorType = EditorType.Default,
    disableAttachments,
    isNeedForceExpand,
    placeholder = 'Type message',
    isFullHeight,
    isHighlighted = false,
    isExpand = false,
    isActive = false,
    isSendHidden,
    isTreeViewEnabled = false,
    isInvalid,
    disabled = false,
    editorData,
    postVideo,
    loadingImages,
    isVideoLoading,
    onBlur,
    onLoad,
    onChange,
    onRemoveVideo,
    mediaType,
    collaborationMediaItem,
  } = props;

  const { uuid: postVideoId } = postVideo || {};

  const [isGifPickerOpen, setIsGifPickerOpen] = useState(false);

  const [isPollHidden, setIsPollHidden] = useState(true);

  const editorRef = useRef<HTMLDivElement>(null);

  const [editor] = useLexicalComposerContext();

  const { isNativeAndroidApp } = useMainProvider();

  const handleEditorBlur = useCallback(() => {
    onBlur?.();
  }, [onBlur]);

  useOnClickOutside(editorRef, handleEditorBlur);

  editor.update(() => {
    if (isNativeAndroidApp && isActive) {
      const root = $getRoot();
      const nodes = root.getAllTextNodes();

      nodes.forEach((node) => {
        if (node instanceof TextNode) {
          const textContent = node.getTextContent() as unknown;
          const decoder = new TextDecoder('utf-8');

          node.setTextContent(decoder.decode(textContent as Uint8Array));
          root.selectEnd();
        }
      });
    }
  });

  const getFilteredAttachments = (
    attachmentsCollection: EditorAttachmentsType,
    keyToRemove: keyof EditorAttachmentsType,
  ): Maybe<EditorAttachmentsType> => {
    const attachmentFilteredKeys = Object.keys(attachmentsCollection).filter(
      (key) => key !== keyToRemove,
    );

    if (!attachmentFilteredKeys.length) {
      return null;
    }

    const reducerCallback = (
      acc: Partial<EditorAttachmentsType>,
      key: string,
    ): NonNullable<EditorAttachmentsType> => {
      if (Array.isArray(attachmentsCollection[key as keyof EditorAttachmentsType])) {
        return {
          ...acc,
          [key]: attachmentsCollection[key as keyof EditorAttachmentsType],
        };
      }

      return {
        ...acc,
        [key]: { ...attachmentsCollection[key as keyof EditorAttachmentsType] },
      };
    };

    return attachmentFilteredKeys.reduce(reducerCallback, {});
  };

  const handleContentChange = useCallback(
    (editorState: EditorState) => {
      editorState.read(() => {
        const root = $getRoot();
        const contentLength = root.getTextContent().trim().length;

        onChange?.({
          ...editorData,
          content: {
            value: JSON.stringify(editorState),
            length: contentLength,
          },
        });
      });
    },
    [editorData, onChange],
  );

  const handleAddImageToAttachments = useCallback(
    (fileData: InputImageDataType) => {
      const images = editorData?.attachments?.images || [];

      if (onChange && isIUploadImageDataArray(fileData)) {
        const uniqueImages = arrayFilterUniqueByKey([...images, ...fileData], 'hash');

        onChange({
          ...editorData,
          attachments: {
            ...editorData?.attachments,
            images: uniqueImages,
          },
        });
      }
    },
    [editorData, onChange],
  );

  const handleGifSelect = useCallback(
    (id: string) => {
      const existedGifs = editorData?.attachments?.gifs || [];
      const isGifUniq = !existedGifs.includes(id);

      setIsGifPickerOpen(false);

      if (isGifUniq && onChange) {
        onChange({
          ...editorData,
          attachments: {
            ...editorData?.attachments,
            gifs: [...existedGifs, id],
          },
        });
      }
    },
    [editorData, onChange, setIsGifPickerOpen],
  );

  const handlePollCreate = useCallback(
    (pollOptions: IPollAnswer[]) => {
      if (!onChange) {
        return;
      }

      const changedObject = {
        ...editorData,
        attachments: {
          ...editorData?.attachments,
          poll: {
            options: pollOptions,
          },
        },
      };

      onChange(changedObject);
    },
    [editorData, onChange],
  );

  const handleImageDelete = useCallback(
    (imgUuid: string) => () => {
      if (editorData?.attachments?.images && onChange && !loadingImages?.length) {
        const deleteResult = editorData.attachments.images.filter((img) => img.uuid !== imgUuid);

        if (!deleteResult.length) {
          const filteredAttachments: Maybe<EditorAttachmentsType> = getFilteredAttachments(
            editorData.attachments,
            'images',
          );

          onChange({ ...editorData, attachments: filteredAttachments });
        } else {
          onChange({
            ...editorData,
            attachments: {
              ...editorData.attachments,
              images: deleteResult,
            },
          });
        }
      }
    },
    [editorData, onChange, loadingImages],
  );

  const handleVideoDelete = useCallback(() => {
    if (!onChange || !editorData?.attachments?.videos || !onRemoveVideo) {
      return;
    }

    const filteredAttachments: Maybe<EditorAttachmentsType> = {
      ...editorData.attachments,
    };

    delete filteredAttachments.videos;

    onRemoveVideo();
    onChange({ ...editorData, attachments: filteredAttachments });
  }, [editorData, onChange, onRemoveVideo]);

  const handleGifDelete = useCallback(
    (gif: string) => () => {
      if (editorData?.attachments?.gifs && onChange) {
        const deleteResult = editorData.attachments.gifs.filter((item) => item !== gif);

        if (!deleteResult.length) {
          const filteredAttachments: Maybe<EditorAttachmentsType> = getFilteredAttachments(
            editorData.attachments,
            'gifs',
          );

          onChange({ ...editorData, attachments: filteredAttachments });
        } else {
          onChange({
            ...editorData,
            attachments: {
              ...editorData.attachments,
              gifs: deleteResult,
            },
          });
        }
      }
    },
    [editorData, onChange],
  );

  const handlePollVisibility = useCallback(
    (hidePollToggle: boolean) => {
      setIsPollHidden(hidePollToggle);

      if (!hidePollToggle || !onChange || !editorData?.attachments) {
        return;
      }

      const filteredAttachments: Maybe<EditorAttachmentsType> = getFilteredAttachments(
        editorData.attachments,
        'poll',
      );

      onChange({ ...editorData, attachments: filteredAttachments });
    },
    [editorData, onChange],
  );

  const clearEditor = useCallback(() => {
    editor.update(() => {
      const root = $getRoot();

      root.clear();
    });

    // Use setTimeout because commentEditor.update set focus on form that
    // can be removed in next render
    setTimeout(() => {
      editor.blur();
      editorRef.current?.blur();
      setIsPollHidden(true);
    });
  }, [editor]);

  const editorClasses = useMemo(
    () =>
      cn(styles.BaseEditor, {
        [styles['BaseEditor--active']]: isHighlighted,
        [styles['BaseEditor--expand']]: isExpand,
        [styles['BaseEditor--error']]: isInvalid,
        [styles['BaseEditor--full-height']]: isFullHeight,
        [styles['BaseEditor--grey-theme']]: theme === EditorTheme.Grey,
        [styles['BaseEditor--light-theme']]: theme === EditorTheme.Light,
        [styles['BaseEditor--bottom-sheet-theme']]: theme === EditorTheme.BottomSheet,
      }),
    [theme, isHighlighted, isExpand, isInvalid, isFullHeight],
  );

  useEffect(() => {
    onLoad?.({
      clear: clearEditor,
    });
  }, [clearEditor, onLoad]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isActive) {
      timeout = setTimeout(() => {
        editor?.focus(() => {}, { defaultSelection: 'rootEnd' });
      }, AUTOFOCUS_DELAY);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [editor, isActive, isNativeAndroidApp]);

  useEffect(() => {
    if (postVideoId && (!editorData || !editorData?.attachments?.videos)) {
      const changedObject = {
        ...editorData,
        attachments: {
          ...editorData?.attachments,
          videos: [postVideoId],
        },
      };

      onChange?.(changedObject);
    }
  }, [postVideoId, editorData, onChange]);

  return (
    <>
      <div ref={editorRef} className={editorClasses} onFocus={props.onEditorFocus}>
        {isExpand && <TopToolbarPlugin topToolbarRight={props.topToolbarRight} />}
        <RichTextPlugin
          contentEditable={
            <ContentEditableWithScroll
              theme={theme}
              isFullHeight={isFullHeight}
              isWithPoll={!isPollHidden}
              isWithInitialHeight={isPollHidden && isNeedForceExpand}
              editorType={editorType}
            />
          }
          placeholder={<Placeholder theme={theme} isToolBarVisible={isExpand} text={placeholder} />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        {!isPollHidden && (
          <PollForm
            theme={theme}
            onPullUpdate={handlePollCreate}
            convertImageItemToAttachment={props.convertImageItemToAttachment}
          />
        )}
        {editorType !== EditorType.VideoPost && (
          <div className={styles.BaseEditor__AttachImagesWrapper}>
            {editorType !== EditorType.GameChat &&
              editorData?.attachments?.images &&
              editorData.attachments.images.map((file, index) => (
                <div key={file.uuid} className={styles.BaseEditor__AttachImageItem}>
                  {!loadingImages?.length && (
                    <div className={styles.BaseEditor__AttachDelete}>
                      <TextTooltip
                        tooltipOffset={10}
                        text="Remove file"
                        eventType={TooltipEventType.hover}
                        placement="top"
                      >
                        <IconButton
                          iconName={IconFontName.Close}
                          iconSize={IconFontSize.Small}
                          theme={IconButtonTheme.AttachmentAction}
                          onClick={handleImageDelete(file.uuid)}
                        />
                      </TextTooltip>
                    </div>
                  )}
                  <AttachmentsImageItem
                    alt={`Attach ${index}`}
                    imageUrl={file.url}
                    mimeType={file.mimeType}
                  />
                </div>
              ))}
            {editorData?.attachments?.gifs?.length &&
              editorData?.attachments?.gifs.map((gifId) => (
                <div className={styles.BaseEditor__AttachImageItem}>
                  <div className={styles.BaseEditor__AttachDelete}>
                    <IconButton
                      iconName={IconFontName.Close}
                      iconSize={IconFontSize.Small}
                      theme={IconButtonTheme.AttachmentAction}
                      onClick={handleGifDelete(gifId)}
                    />
                  </div>
                  <SingleGif isNeedGifIcon size={GifSize.S} id={gifId} />
                </div>
              ))}
            {editorType !== EditorType.GameChat && editorData?.attachments?.videos?.length && (
              <div className={styles.BaseEditor__AttachImageItem}>
                <div className={styles.BaseEditor__AttachDelete}>
                  <IconButton
                    iconName={IconFontName.Close}
                    iconSize={IconFontSize.Small}
                    theme={IconButtonTheme.AttachmentAction}
                    onClick={handleVideoDelete}
                  />
                </div>
                <VideoPreview size={VideoPreviewSize.S} url={postVideo?.url || ''} />
              </div>
            )}
            {loadingImages?.map((imageName) => (
              <div key={imageName} className={styles.ImagesLoadingWrapper}>
                <Loader size={LoaderSizeEnum.S} theme={LoaderTheme.Blue} isShow />
              </div>
            ))}
            {editorType !== EditorType.GameChat && isVideoLoading && (
              <div className={styles.ImagesLoadingWrapper}>
                <Loader size={LoaderSizeEnum.S} theme={LoaderTheme.Blue} isShow />
              </div>
            )}
          </div>
        )}
        <HistoryPlugin />
        <ListPlugin />
        <LinkPlugin />
        <AutoLinkPlugin />
        <OnChangePlugin onChange={handleContentChange} ignoreSelectionChange />
        {isExpand &&
          (editorType === EditorType.Default ||
            editorType === EditorType.GroupPost ||
            editorType === EditorType.GameChat) && (
            <BottomToolbarPlugin
              disableAttachments={disableAttachments}
              editorType={editorType}
              isGifPickerOpen={isGifPickerOpen}
              isVideoLoading={isVideoLoading}
              isSendHidden={isSendHidden}
              isSendDisabled={disabled}
              attachments={editorData?.attachments || null}
              isLoadingImages={!!loadingImages && loadingImages.length > 0}
              setIsGifPickerOpen={setIsGifPickerOpen}
              onImageLoad={handleAddImageToAttachments}
              onGifSelect={handleGifSelect}
              onPollClick={handlePollVisibility}
              setLoadingImages={props.setLoadingImages}
              onUploadVideo={props.onUploadVideo}
              onVideoError={props.onVideoError}
              convertImageItemToAttachment={props.convertImageItemToAttachment}
            />
          )}
        {editorType === EditorType.Collaboration && (
          <SingleMediaToolbarPlugin
            postId={props.postId}
            isSendHidden={isSendHidden}
            isSendDisabled={disabled}
            mediaType={mediaType}
            isLoading={false}
            isCollaborationVideoLoading={props.isCollaborationVideoLoading}
            isCollaborationImageLoading={props.isCollaborationImageLoading}
            collaborationMediaItem={collaborationMediaItem}
            setCollaborationMediaItem={props.setCollaborationMediaItem}
            convertImageItemToAttachment={props.convertImageItemToAttachment}
            convertVideoItemToAttachment={props.convertVideoItemToAttachment}
          />
        )}
        {editorType === EditorType.VideoPost && (
          <VideoPostToolbarPlugin
            postVideo={postVideo}
            isSendHidden={isSendHidden}
            isSendDisabled={disabled}
            isVideoLoading={isVideoLoading}
            onUploadVideo={props.onUploadVideo}
          />
        )}
      </div>
      {isTreeViewEnabled && <TreeViewPlugin />}
    </>
  );
});
