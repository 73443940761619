import { format, isAfter, isSameDay } from 'date-fns';
import { format as formatTz } from 'date-fns-tz';
import { inject } from 'inversify';
import { action, autorun, makeObservable, observable, reaction } from 'mobx';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { IGame, IGameMiniResponse } from 'services/statistics/interface/game.interface';
import { StatisticsService } from 'services/statistics/statistics.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { IoMessage } from 'stores/io/enums/io-message.enum';
import { IoStore } from 'stores/io/io.store';
import { singleGameMiniAdapter } from 'stores/statistics/adapters/single-game-mini-adapter.util';
import { TeamsStore } from 'stores/teams/teams.store';

import { DATE_FORMAT, TIME_ZONE_FORMAT } from 'configs/date.config';
import { TYPES } from 'configs/di-types.config';

import { gamesAdapter } from './adapters/games-adapter.util';

export class StatisticsStore extends ApiConnectedStore {
  private readonly ioStore: IoStore;

  private readonly teamsStore: TeamsStore;

  private readonly statisticsService: StatisticsService;

  public entries: IGame[];

  public firstGameIds: number[];

  public firstGameTodayId: Maybe<number>;

  constructor(
    @inject(TYPES.IoStore) ioStore: IoStore,
    @inject(TYPES.TeamsStore) teamsStore: TeamsStore,
    @inject(TYPES.StatisticsService) statisticsService: StatisticsService,
  ) {
    super();

    this.ioStore = ioStore;

    this.teamsStore = teamsStore;

    this.statisticsService = statisticsService;

    this.entries = [];

    this.firstGameIds = [];

    this.firstGameTodayId = null;

    makeObservable(this, {
      entries: observable,
      firstGameIds: observable,
      firstGameTodayId: observable,

      setFirstGameIds: action.bound,
      setFirstGameTodayId: action.bound,
      setEntries: action.bound,
    });

    reaction(() => this.ioStore.socket, this.updateSubscriptions);

    reaction(() => this.ioStore.socket, this.handleRetrieve);

    reaction(() => this.entries, this.handleEntriesChange);

    autorun(() => this.updateSubscriptions());
  }

  private handleRetrieve = () => {
    if (this.teamsStore.teamId) {
      this.retrieveForTeam();
    } else {
      this.retrieve();
    }
  };

  private updateSubscriptions = () => {
    this.ioStore.socket.on(
      IoMessage.GameStatisticsMiniUpdated,
      this.handleGameStatisticsMiniUpdated,
    );
  };

  public handleGameStatisticsMiniUpdated = (rawGame: IGameMiniResponse) => {
    const newGame = singleGameMiniAdapter(rawGame);

    this.setEntries(
      this.entries.map((existingGame) => {
        if (existingGame.id === newGame.id) {
          return newGame;
        }

        return existingGame;
      }),
    );
  };

  private handleEntriesChange = () => {
    const ids: number[] = [];

    const dates: string[] = [];

    let todayId: Maybe<number> = null;

    this.entries.forEach((game) => {
      if (!todayId && (game.status === GameStatus.Live || game.status === GameStatus.Halftime)) {
        todayId = game.id;
      }

      if (
        !todayId &&
        (isSameDay(new Date(game.dateStart), new Date()) ||
          isAfter(new Date(game.dateStart), new Date()))
      ) {
        todayId = game.id;
      }

      const formattedDate = format(new Date(game.dateStart), DATE_FORMAT);

      if (dates.includes(formattedDate)) {
        return;
      }

      dates.push(formattedDate);

      ids.push(game.id);
    });

    this.setFirstGameIds(ids);

    this.setFirstGameTodayId(todayId);
  };

  public setEntries(entities: IGame[]) {
    this.entries = entities;
  }

  public setFirstGameIds(ids: number[]) {
    this.firstGameIds = ids;
  }

  public setFirstGameTodayId(id: Maybe<number>) {
    this.firstGameTodayId = id;
  }

  public async retrieve(): Promise<void> {
    this.resetErrors();
    this.setFetched(false);
    this.setFetching(true);

    const timezone = formatTz(new Date(), TIME_ZONE_FORMAT);

    const response: IResponse<IGameMiniResponse[]> = await this.statisticsService.retrieve(
      timezone,
    );

    if (response.success) {
      this.setEntries(gamesAdapter(response.data));
    }

    this.setFetched(true);
    this.setFetching(false);
  }

  public async retrieveForTeam(): Promise<void> {
    this.resetErrors();
    this.setFetched(false);
    this.setFetching(true);

    const timezone = formatTz(new Date(), TIME_ZONE_FORMAT);

    if (this.teamsStore.teamId) {
      const response: IResponse<IGameMiniResponse[]> = await this.statisticsService.retrieveForTeam(
        this.teamsStore.teamId,
        timezone,
      );

      if (response.success) {
        this.setEntries(gamesAdapter(response.data));
      }

      this.setFetched(true);
      this.setFetching(false);
    }
  }
}
