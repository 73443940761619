import { memo, useMemo } from 'react';
import cn from 'classnames';

import { useMainProvider } from 'hooks/use-main-provider';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './floating-button.module.less';

interface IFloatingButtonProps {
  hasSmartBanner: boolean;
  onClick: () => void;
}

export const FloatingButton = memo((props: IFloatingButtonProps) => {
  const { hasSmartBanner } = props;

  const { isNativeApp } = useMainProvider();

  const floatingButtonClasses = useMemo(
    () =>
      cn(styles.FloatingButton, {
        [styles['FloatingButton--has-smart-banner']]: !isNativeApp && hasSmartBanner,
      }),
    [hasSmartBanner, isNativeApp],
  );

  return (
    <div className={floatingButtonClasses}>
      <IconButton
        onClick={props.onClick}
        onTouchEnd={props.onClick}
        iconName={IconFontName.Chat}
        theme={IconButtonTheme.Primary}
      />
    </div>
  );
});
