import { memo, useCallback, useEffect, useRef, useState } from 'react';
import QuickPinchZoom, { make2dTransformValue } from 'react-quick-pinch-zoom';

import {
  DOUBLE_TAP_ZOOM_OUT_ON_MAX_SCALE,
  INERTIA_FRICTION,
  MAX_ZOOM,
  MIN_ZOOM,
  SET_OFFSETS_ONCE,
} from 'configs/quick-pinch-zoom.config';

import styles from './image-preview.module.less';

interface IImagePreviewProps {
  url: string;
  filename: string;
  isDesktopPlus: boolean;
  desktopStyles: any;
}

interface IQuickPinchZoomUpdateEvent {
  x: number;
  y: number;
  scale: number;
}

export const ImagePreview = memo((props: IImagePreviewProps) => {
  const { url, filename, desktopStyles, isDesktopPlus } = props;

  const quickPinchZoomRef = useRef<QuickPinchZoom>(null!);
  const [transformImage, setTransformImage] = useState<string>('');

  const handleUpdate = useCallback(
    (e: IQuickPinchZoomUpdateEvent) => {
      const { x, y, scale } = e;

      const transform = make2dTransformValue({ x, y, scale });
      setTransformImage(transform);
    },
    [setTransformImage],
  );

  useEffect(() => {
    if (!isDesktopPlus) {
      quickPinchZoomRef.current?.scaleTo({
        x: 0,
        y: 0,
        scale: 1,
        duration: 150,
      });
    }
  }, [url, isDesktopPlus]);

  if (isDesktopPlus) {
    return <img className={styles.MainImage} style={desktopStyles} src={url} alt={filename} />;
  }

  return (
    <QuickPinchZoom
      ref={quickPinchZoomRef}
      containerProps={{ className: styles.QuickPinchZoom }}
      maxZoom={MAX_ZOOM}
      minZoom={MIN_ZOOM}
      setOffsetsOnce={SET_OFFSETS_ONCE}
      inertiaFriction={INERTIA_FRICTION}
      doubleTapZoomOutOnMaxScale={DOUBLE_TAP_ZOOM_OUT_ON_MAX_SCALE}
      onUpdate={handleUpdate}
    >
      <img
        className={styles.MainImage}
        style={{ transform: transformImage }}
        src={url}
        alt={filename}
      />
    </QuickPinchZoom>
  );
});
