export enum LayoutEntity {
  HeaderCenter,
  HeaderProfile,
  HeaderRight,
  HeaderLeft,
  PageHeader,
  Tabs,
  TabsRight,
  PageTitle,
}
