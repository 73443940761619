import {
  CellClassParams,
  ColDef,
  GridOptions,
  HeaderClassParams,
  SortDirection,
} from 'ag-grid-community';

export const PAGE_PARAM = 'page';
export const WRAPPER_CLASS_NAME = 'ag-theme-base-table';
export const PAGINATION_CLASS_NAME = 'ag-theme-base-table-pagination';
export const HIGHLIGHTED_ROW_CLASS_NAME = 'ag-row-highlighted';
export const MINIMUM_ROWS_STICKY_HEADER = 3;

export const RANK_COL_DEF: ColDef = {
  field: 'rank',
  headerName: 'RK',
  headerTooltip: 'Rank',
  pinned: 'left',
  maxWidth: 44,
};

const SORTED_CELL_CLASS_NAME = 'ag-cell-sorted';
const DEFAULT_SORTING_ORDER: SortDirection[] = ['desc', 'asc'];

export const DEFAULT_GRID_OPTIONS: GridOptions = {
  animateRows: true,
  suppressCellFocus: true,
  domLayout: 'autoHeight',
  suppressHorizontalScroll: true,
  suppressPaginationPanel: true,
  defaultColDef: {
    suppressMovable: true,
    minWidth: 60,
    flex: 1,
    sortable: false,
    cellClassRules: {
      [SORTED_CELL_CLASS_NAME]: (params: CellClassParams) =>
        !!params.column.getSort() && !!params.colDef.sortable,
    },
    headerClass: (params: HeaderClassParams) =>
      params.column?.getSort() ? SORTED_CELL_CLASS_NAME : undefined,
    sortingOrder: DEFAULT_SORTING_ORDER,
  },
  tooltipShowDelay: 0,
};
