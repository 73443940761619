import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import cn from 'classnames';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { IGame } from 'services/statistics/interface/game.interface';

import { IPublicLink, ISocialLink, LinkType } from 'stores/auth/interfaces/custom-link.interface';
import { IPlayerDetails } from 'stores/player/interfaces/player-profile.interfaces';

import { IAboutData } from 'containers/team-profile/team-profile-overview/team-profile-overview.container';

import { IS_ENABLE_ATHLETES_LINKS } from 'configs/feature.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';
import { PLAYER_SLUG_PARAM } from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { EditablePublicLinks } from 'components/editable-public-links/editable-public-links';
import { PlayerProfileInfoStats } from 'components/player-profile/player-profile-info-stats/player-profile-info-stats.component';
import { ProfileInfoSocials } from 'components/profile-info-socials/profile-info-socials.component';
import { Statistics } from 'components/statistics/statistics.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { StatsBox, StatsBoxTheme } from 'components/ui/stats-box/stats-box.component';

import playerProfileStyles from '../player-profile.module.less';
import styles from './player-profile-overview.module.less';

interface IPlayerProfileOverviewProps {
  socialLinks: ISocialLink[];
  currentTeamId: Maybe<number>;
  player: Maybe<IPlayerDetails>;
  aboutThePlayer: IAboutData[];
  recentGames: IGame[];
  biography?: string;
  customLinks: IPublicLink[];
}

export const getLinkValueByKey = (links: Maybe<ISocialLink[]>, key: LinkType) => {
  if (!links || !links.length || key === LinkType.Custom) {
    return null;
  }

  const currentLink = links.find((link) => link.type === key);

  return currentLink?.url ?? null;
};

export const PlayerProfileOverview: FC<IPlayerProfileOverviewProps> = (props) => {
  const {
    customLinks,
    player,
    currentTeamId,
    aboutThePlayer,
    recentGames,
    biography,
    socialLinks,
  } = props;

  const [isExtantPlayerInfo, setIsExtantPlayerInfo] = useState(false);

  const navigate = useNavigate();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const params = useParams<{ [PLAYER_SLUG_PARAM]: string }>();

  const playerSlug = params[PLAYER_SLUG_PARAM];

  const recentGamesSectionClasses = useMemo(
    () => cn(styles.RecentGamesSection, TOUCH_IGNORE_CLASS),
    [],
  );

  const aboutThePlayerSectionClasses = useMemo(
    () =>
      cn(styles.AboutThePlayerSection, {
        [styles['AboutThePlayerSection--extant']]: isExtantPlayerInfo,
      }),
    [isExtantPlayerInfo],
  );

  const sendShowAllActionToGa = useCallback(() => {
    if (player) {
      const { firstname, lastname } = player;

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PlayerProfileProfileSeeAll,
          player: `${firstname} ${lastname}`,
        },
      });
    }
  }, [player]);

  const handleNavigateScores = useCallback(() => {
    if (currentTeamId) {
      sendShowAllActionToGa();

      navigate(
        getPath(paths.TEAM_SCORES, {
          [paths.TEAM_ID_PARAM]: currentTeamId.toString(),
        }),
      );
    }
  }, [currentTeamId, sendShowAllActionToGa, navigate]);

  const handleGameClick = useCallback(
    (gameId: number) => {
      if (playerSlug) {
        GoogleAnalyticService.event({
          eventName: 'screen_custom_view',
          eventParams: {
            screen_type: ScreenNamesEnum.PlayerPerformance,
            player: playerSlug,
            entry_point: ScreenNamesEnum.PlayerProfileProfile,
          },
        });

        navigate(
          getPath(paths.PLAYER_PERFORMANCE, {
            [paths.PLAYER_SLUG_PARAM]: playerSlug,
            [paths.GAME_ID_PARAM]: gameId.toString(),
          }),
        );
      }
    },
    [navigate, playerSlug],
  );

  const handlePersonalInfoShowMoreClick = useCallback(() => {
    setIsExtantPlayerInfo(true);

    if (player) {
      const { firstname, lastname } = player;

      GoogleAnalyticService.event({
        eventName: 'button_custom_tap',
        eventParams: {
          button_tap_type: ButtonTapsEnum.PlayerProfileProfileAboutPlayerShowMore,
          player: `${firstname} ${lastname}`,
        },
      });
    }
  }, [player]);

  const isShowSocialSection = useMemo(() => {
    return player?.instagramUrl || player?.twitterUrl || player?.youtubeUrl || player?.tiktokUrl;
  }, [player]);

  if (!player) {
    return null;
  }

  return (
    <div className={playerProfileStyles.PlayerProfile}>
      <div className={playerProfileStyles.PlayerProfile__Section}>
        {!isDesktopPlus && isShowSocialSection && (
          <div className={playerProfileStyles.Socials}>
            <ProfileInfoSocials
              instagramUrl={
                getLinkValueByKey(socialLinks, LinkType.Instagram) ?? player.instagramUrl
              }
              twitterUrl={getLinkValueByKey(socialLinks, LinkType.Twitter) ?? player.twitterUrl}
              youtubeUrl={getLinkValueByKey(socialLinks, LinkType.Youtube) ?? player.youtubeUrl}
              tiktokUrl={getLinkValueByKey(socialLinks, LinkType.TikTok) ?? player.tiktokUrl}
              snapchat={getLinkValueByKey(socialLinks, LinkType.Snapchat)}
              discord={getLinkValueByKey(socialLinks, LinkType.Discord)}
            />
          </div>
        )}
      </div>
      <div className={playerProfileStyles.PlayerProfile__Section}>
        <PlayerProfileInfoStats playerDetails={player} />
      </div>
      {IS_ENABLE_ATHLETES_LINKS && customLinks && !!customLinks.length && (
        <div className={playerProfileStyles['PlayerProfile__Section--links']}>
          <EditablePublicLinks isAthlete links={customLinks} />
        </div>
      )}
      <div className={playerProfileStyles.PlayerProfile__Section}>
        <div className={playerProfileStyles.PlayerProfile__Header}>About Player</div>
        <div className={aboutThePlayerSectionClasses}>
          {aboutThePlayer.map(({ content, title, ...rest }) => (
            <StatsBox
              key={title}
              theme={StatsBoxTheme.Team}
              title={title}
              content={content}
              {...rest}
            />
          ))}
          {!isExtantPlayerInfo && !isDesktopPlus && (
            <Button
              onClick={handlePersonalInfoShowMoreClick}
              theme={ButtonTheme.Text}
              size={ButtonSize.Small}
            >
              Show {aboutThePlayer.length - 2} more
            </Button>
          )}
        </div>
      </div>
      {!!recentGames.length && (
        <div className={playerProfileStyles.PlayerProfile__Section}>
          <div className={playerProfileStyles.PlayerProfile__HeaderWrapper}>
            <div className={playerProfileStyles.PlayerProfile__Header}>Recent Games</div>
            <Button onClick={handleNavigateScores} theme={ButtonTheme.Text} size={ButtonSize.Small}>
              Show All
            </Button>
          </div>
          <div className={recentGamesSectionClasses}>
            <Statistics games={recentGames} onGameClick={handleGameClick} />
          </div>
        </div>
      )}
      {!!biography && (
        <div className={playerProfileStyles.PlayerProfile__Section}>
          <div className={playerProfileStyles.PlayerProfile__HeaderWrapper}>
            <div className={playerProfileStyles.PlayerProfile__Header}>Biography</div>
          </div>
          <div className={styles.BiographySection}>{biography} </div>
        </div>
      )}
    </div>
  );
};
