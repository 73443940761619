import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  linkDescriptionSchema,
  linkTitleSchema,
  linkUrlSchema,
} from 'validation/schemas/link.schema';
import {
  MAX_LINK_DESCRIPTION_LENGTH,
  MAX_LINK_TITLE_LENGTH,
} from 'validation/validation.constants';

import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE, DEFAULT_VALIDATION_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { SubmitSection } from 'components/forms/submit-section/submit-section.component';
import { TextArea } from 'components/ui/form-fields/text-area/text-area.component';
import { TextInput } from 'components/ui/form-fields/text-input/text-input.component';

import formsStyles from '../forms.module.less';

const editLinkFormSchema = z.object({
  url: linkUrlSchema,
  title: linkTitleSchema,
  description: linkDescriptionSchema,
});

type EditLinkFormDataType = z.infer<typeof editLinkFormSchema>;

type EditLinkFormFieldsType = keyof EditLinkFormDataType;

interface IEditLinkFormProps extends IForm<EditLinkFormDataType> {
  focusField?: Maybe<EditLinkFormFieldsType>;
}

export const EditLinkForm: FC<IEditLinkFormProps> = (props) => {
  const { submitText, initialValues, focusField, onSubmit } = props;

  const { control, formState, handleSubmit, setFocus } = useForm({
    defaultValues: initialValues,
    mode: DEFAULT_VALIDATION_MODE,
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(editLinkFormSchema),
  });

  const { isValid, errors } = formState;

  useEffect(() => {
    if (focusField) {
      setFocus(focusField);
    }
  }, [focusField, setFocus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={formsStyles.FormContent}>
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <TextArea
              {...field}
              maxLength={MAX_LINK_TITLE_LENGTH}
              error={errors.title?.message}
              id="title"
              label="Title"
              placeholder="Create Title"
              rows={1}
              disabledResize
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextArea
              {...field}
              maxLength={MAX_LINK_DESCRIPTION_LENGTH}
              error={errors.description?.message}
              id="description"
              label="Description"
              placeholder="Add Description (optional)"
              rows={1}
              disabledResize
            />
          )}
        />
        <Controller
          name="url"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.url?.message}
              id="url"
              label="Link"
              placeholder="Insert Link"
            />
          )}
        />
      </div>
      <SubmitSection
        disabled={!formState.isDirty || formState.isSubmitting || formState.isSubmitSuccessful}
        isFormValid={isValid}
        buttonText={submitText}
      />
    </Form>
  );
};
