export const findAndReplaceObjectByKey = <T>(
  array: T[],
  key: keyof T,
  targetValue: any,
  replacementValue: T,
): T[] => {
  return array.map((obj) => {
    if (obj[key] === targetValue) {
      return { ...replacementValue };
    }

    return obj;
  });
};
