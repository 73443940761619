import { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { AuthStore } from 'stores/auth/auth.store';
import { AuthMode } from 'stores/auth/enums/auth-mode.enum';
import { LayoutHeaderLeftActionEnum } from 'stores/layout/interfaces/layout-header-left.interface';
import { LayoutStore } from 'stores/layout/layout.store';
import { SelectedHeaderTabType } from 'stores/layout/types/layout-entities.type';
import { HeaderLeftValue } from 'stores/layout/types/layout-header-left-value.type';
import { PlayerStore } from 'stores/player/player.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { BLACK_COLOR, DEFAULT_GREY_ICON_COLOR, SCROLL_TOP_ELEMENT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';
import { HOME_FEED } from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { HeaderDesktop } from 'components/header/header-desktop/header-desktop.component';
import { HeaderMobile } from 'components/header/header-mobile/header-mobile.component';

import { AUTHORIZED_HEADER_TABS, ENABLED_SCROLL_PATHNAMES } from './header.config';

export const HeaderContainer: FC = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);

  const params = useParams<{ [paths.POST_ID_PARAM]: string; [paths.TEAM_ID_PARAM]: string }>();

  const { postId, teamId } = params;

  const navigate = useNavigate();
  const location = useLocation();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const headerTabs = useMemo(() => {
    const tabs = !authStore.isAuthorised ? [] : [...AUTHORIZED_HEADER_TABS];
    return tabs.map((item) => {
      return {
        ...item,
        isActive: item.slug === layoutStore.sidePanelActiveTab,
      };
    });
  }, [authStore.isAuthorised, layoutStore.sidePanelActiveTab]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleForward = useCallback(() => {
    navigate(+1);
  }, [navigate]);

  const handleLeftItemClick = useCallback(
    (headerLeftValue: Maybe<HeaderLeftValue>) => () => {
      if (headerLeftValue?.type === LayoutHeaderLeftActionEnum.Menu) {
        GoogleAnalyticService.event({
          eventName: 'button_custom_tap',
          eventParams: {
            button_tap_type: ButtonTapsEnum.Explore,
          },
        });

        layoutStore.setMobileSidebarOpen(!layoutStore.isMobileSidebarOpen);
        layoutStore.setSidePanelActiveTab(null);
      }

      if (headerLeftValue?.type === LayoutHeaderLeftActionEnum.Back) {
        if (location.key === 'default') {
          navigate(HOME_FEED);
        } else {
          navigate(-1);
        }
      }

      if (
        headerLeftValue?.type === LayoutHeaderLeftActionEnum.Custom &&
        headerLeftValue?.callback
      ) {
        headerLeftValue.callback();
      }
    },
    [layoutStore, navigate, location],
  );

  const handleSignIn = useCallback(() => {
    authStore.setAuthMode(AuthMode.Login);
  }, [authStore]);

  const handleSignUp = useCallback(() => {
    authStore.setAuthMode(AuthMode.SignUp);
  }, [authStore]);

  const handleTabClick = useCallback(
    (slug: SelectedHeaderTabType) => {
      if (layoutStore.sidePanelActiveTab === slug) {
        layoutStore.setSidePanelActiveTab(null);
      } else {
        layoutStore.setSidePanelActiveTab(slug);
      }
    },
    [layoutStore],
  );

  const handleScrollTopClick = useCallback(() => {
    const topElement = document.getElementById(SCROLL_TOP_ELEMENT);

    topElement?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const handleAvatarClick = useCallback(() => {
    if (authStore.userMe?.username) {
      navigate(
        getPath(paths.USER_PUBLIC_FEED, {
          [paths.USERNAME_PARAM]: authStore.userMe.username,
        }),
      );
    }
  }, [authStore.userMe, navigate]);

  const isEnabledScrollToTop = useMemo(
    () => !!postId || ENABLED_SCROLL_PATHNAMES.includes(location.pathname),
    [postId, location.pathname],
  );

  const currentTeam = useMemo(() => {
    if (teamId) {
      return teamsStatsStore.teams.find((team) => Number(teamId) === team.id) || null;
    }

    return null;
  }, [teamId, teamsStatsStore.teams]);

  const headerBackgroundColor = useMemo(() => {
    if (currentTeam) {
      return currentTeam.color;
    }

    if (playerStore.playerDetails?.team) {
      return playerStore.playerDetails.team.color;
    }

    return null;
  }, [currentTeam, playerStore.playerDetails]);

  const headerTextColor = useMemo(() => {
    if (currentTeam) {
      return currentTeam.secondaryTextColor;
    }

    if (playerStore.playerDetails?.team) {
      return DEFAULT_GREY_ICON_COLOR;
    }

    return null;
  }, [currentTeam, playerStore.playerDetails]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--dot-border-color',
      headerBackgroundColor || BLACK_COLOR,
    );
  }, [headerBackgroundColor]);

  if (isDesktopPlus) {
    return (
      <HeaderDesktop
        isAthlete={Boolean(authStore.userMe?.athlete)}
        isAuthorised={authStore.isAuthorised}
        title={layoutStore.pageTitle}
        center={layoutStore.headerCenter?.()}
        userMe={authStore.userMe}
        fetching={authStore.fetching}
        onBack={handleBack}
        onForward={handleForward}
        onSignIn={handleSignIn}
        onSignUp={handleSignUp}
        onTabClick={handleTabClick}
        onAvatarClick={handleAvatarClick}
        tabItems={headerTabs}
        backgroundColor={headerBackgroundColor}
        textColor={headerTextColor}
      />
    );
  }

  return (
    <HeaderMobile
      title={layoutStore.pageTitle}
      onLeftItemClick={handleLeftItemClick(layoutStore.headerLeft)}
      leftCornerIcon={layoutStore.headerLeft?.icon || null}
      center={layoutStore.headerCenter?.()}
      onScrollTopClick={handleScrollTopClick}
      isEnabledScrollToTop={isEnabledScrollToTop}
      rightCorner={layoutStore.headerRight?.()}
      backgroundColor={headerBackgroundColor}
      textColor={headerTextColor}
      profile={layoutStore.headerProfile?.()}
    />
  );
});
