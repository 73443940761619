import { Preferences } from '@capacitor/preferences';
import { injectable } from 'inversify';

import { StorageField } from './enum/storage-field.enum';

@injectable()
export class StorageService {
  public async configureLocalStorage() {
    await Preferences.configure({
      group: 'Digits',
    });
  }

  private async setLocalStorage(key: StorageField, value: string) {
    await Preferences.set({ key, value });
  }

  private async removeLocalStorage(key: StorageField) {
    await Preferences.remove({ key });
  }

  private async getLocalStorage(key: StorageField) {
    const { value } = await Preferences.get({ key });

    return value;
  }

  private async clearLocalStorage() {
    await Preferences.clear();
  }

  public clear(sessionOnly = false) {
    sessionStorage.clear();

    if (!sessionOnly) {
      this.clearLocalStorage();
    }
  }

  private parseStoredValue(value: Maybe<string>, parse: boolean = true) {
    return parse ? value && JSON.parse(value) : value;
  }

  public async get<T>(field: StorageField, parse = true, sessionOnly = false): Promise<Maybe<T>> {
    try {
      if (sessionOnly) {
        const storedValue = sessionStorage.getItem(field);

        return this.parseStoredValue(storedValue, parse);
      }

      if (!sessionOnly) {
        const storedValue = await this.getLocalStorage(field);

        return this.parseStoredValue(storedValue, parse);
      }

      return null;
    } catch {
      return null;
    }
  }

  public remove(field: StorageField): void {
    sessionStorage.removeItem(field);
    this.removeLocalStorage(field);
  }

  public set<T>(field: StorageField, value: T, session = false): void {
    if (session) {
      sessionStorage.setItem(field, JSON.stringify(value));
    } else {
      this.setLocalStorage(field, JSON.stringify(value));
    }
  }
}
