import {
  ChangeEvent,
  memo,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IFileData } from 'helpers/to-base64.util';

import { ImageCropModal } from 'components/modals/image-crop-modal/image-crop-modal.component';

import { IHookFormInput } from '../hook-form-input.interface';

import styles from './file-input-with-cropping.module.less';

interface IFileUploadInputProps extends IHookFormInput<IFileData[]> {
  accept?: string;
  isCropCircle: boolean;
  isCropModalVisible: boolean;
  fileInstance?: File;
  onAddFilesSet?: Maybe<(handler: () => void) => void>;
  onSetCropModal: (isOpen: boolean) => void;
  onSaveImage: (fileData: IFileData) => void;
  onClick?: (event: SyntheticEvent) => void;
}

export const FileInputWithCropping = memo((props: IFileUploadInputProps) => {
  const {
    id,
    onBlur,
    onClick,
    name,
    accept,
    fileInstance,
    onAddFilesSet,
    onSetCropModal,
    isCropModalVisible,
    isCropCircle = false,
  } = props;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [localFile, setLocalFile] = useState<Maybe<File>>(null);

  const handleBlur = useCallback(
    (event: SyntheticEvent) => {
      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur],
  );

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { files },
      } = event;

      if (files?.length) {
        setLocalFile(files[0]);
      }

      handleBlur(event);
    },
    [handleBlur],
  );

  const handleClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  const handlePhotoCameraClick = useCallback(
    (event: SyntheticEvent) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick],
  );

  const handleCloseCropModal = useCallback(() => {
    if (fileInputRef?.current?.value) {
      fileInputRef.current.value = '';
    }
    setLocalFile(null);

    onSetCropModal(false);
  }, [onSetCropModal]);

  useEffect(() => {
    if (onAddFilesSet) {
      onAddFilesSet(handleClick);
    }
  }, [handleClick, onAddFilesSet]);

  useEffect(() => {
    if (localFile) {
      onSetCropModal(true);
    }
  }, [localFile, onSetCropModal]);

  useEffect(() => {
    if (fileInstance && setLocalFile) {
      setLocalFile(fileInstance);
    }
  }, [fileInstance, setLocalFile]);

  const fileUrl = useMemo(() => {
    if (localFile) {
      return URL.createObjectURL(localFile);
    }

    return '';
  }, [localFile]);

  return (
    <>
      <input
        className={styles.FileInput}
        multiple={false}
        accept={accept}
        id={id}
        name={name}
        type="file"
        onChange={handleChange}
        ref={fileInputRef}
        onBlur={handleBlur}
        onClick={handlePhotoCameraClick}
      />
      {isCropModalVisible && (
        <ImageCropModal
          isCropCircle={isCropCircle}
          onClose={handleCloseCropModal}
          fileUrl={fileUrl}
          visible={isCropModalVisible}
          onSaveAvatar={props.onSaveImage}
        />
      )}
    </>
  );
});
