import { inject, injectable } from 'inversify';
import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import {
  ICreatePlayerPostPayload,
  ICreatePostPayload,
  ICreateTeamPostPayload,
  IDeletePostPayload,
  IFetchPostPayload,
  ITogglePostBookmarkPayload,
} from 'services/posts/interfaces/create-post-payload.interface';
import { IPollAttachmentResponse } from 'services/posts/interfaces/post-attachments-response.interface';
import { ILikesResult, IVideoResponse } from 'services/posts/interfaces/posts-response.interface';
import { IPublicationUpdatePayload } from 'services/posts/interfaces/publication-payload.interface';
import { PostsService } from 'services/posts/posts.service';
import { ReportsService } from 'services/reports/reports.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { ApplicationStore } from 'stores/application/application.store';
import { IBasePublicationAuthor } from 'stores/entries/interfaces/entries-autor.interface';
import { FavoritesFeedStore } from 'stores/feeds/favorites-feed.store';
import { GameVideosFeedStore } from 'stores/feeds/game-videos-feed.store';
import { GlobalFanZoneFavoritesFeedStore } from 'stores/feeds/global-fan-zone-favorites-feed.store';
import { GlobalFanZoneFeedStore } from 'stores/feeds/global-fan-zone-feed.store';
import { PlayerFanZoneFavoritesFeedStore } from 'stores/feeds/player-fan-zone-favorites-feed.store';
import { PlayerFanZoneFeedStore } from 'stores/feeds/player-fan-zone-feed.store';
import { PlayerFeedStore } from 'stores/feeds/player-feed.store';
import { PublicUserFeedStore } from 'stores/feeds/public-user-feed.store';
import { TeamFanZoneFeedStore } from 'stores/feeds/team-fan-zone.store';
import { TeamFeedStore } from 'stores/feeds/team-feed.store';
import { YourFeedStore } from 'stores/feeds/your-feed.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';

import { TYPES } from 'configs/di-types.config';
import { customFormData } from 'helpers/custom-form-data.util';

import { postsLogger } from 'loggers/posts.logger';

import { IPollUpdateData, IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

import { pollAdapter } from './adapters/poll-adapter.util';
import { publicationAdapter } from './adapters/publication-adapter.util';
import { IPost, IPostVoteData } from './interfaces/post.interface';

type FeedsList =
  | YourFeedStore
  | TeamFeedStore
  | TeamFanZoneFeedStore
  | PlayerFeedStore
  | PlayerFanZoneFeedStore
  | FavoritesFeedStore
  | GlobalFanZoneFeedStore
  | GlobalFanZoneFavoritesFeedStore
  | GameVideosFeedStore
  | PublicUserFeedStore
  | PlayerFanZoneFavoritesFeedStore;

export enum CreatePostTypeEnum {
  // PredictionPoll = 'PredictionPoll',
  PollPost = 'PollPost',
  GroupPost = 'GroupPost',
  VideoPost = 'VideoPost',
  UserGeneratedPost = 'UserGeneratedPost',
}

@injectable()
export class PostsStore extends ApiConnectedStore {
  private readonly applicationStore: ApplicationStore;

  private readonly postsService: PostsService;

  private readonly layoutStore: LayoutStore;

  private readonly reportsService: ReportsService;

  private readonly yourFeedStore: YourFeedStore;

  private readonly gameVideosFeedStore: GameVideosFeedStore;

  private readonly favoritesFeedStore: FavoritesFeedStore;

  private readonly playerFeedStore: PlayerFeedStore;

  private readonly playerFanZoneFeedStore: PlayerFanZoneFeedStore;

  private readonly playerFanZoneFavoritesFeedStore: PlayerFanZoneFavoritesFeedStore;

  private readonly teamFeedStore: TeamFeedStore;

  private readonly teamFanZoneFeedStore: TeamFanZoneFeedStore;

  private readonly globalFanZoneFeedStore: GlobalFanZoneFeedStore;

  private readonly globalFanZoneFavoritesFeedStore: GlobalFanZoneFavoritesFeedStore;

  private readonly publicUserFeedStore: PublicUserFeedStore;

  public isLoadMore: boolean;

  public post: Maybe<IPost>;

  public posts: IPost[];

  public processingCreatePost: boolean;

  public currentPostId: Maybe<string>;

  public postVideo: Maybe<IVideoResponse>;

  public isPostVideoLoading: boolean;

  public isIdInvalid: boolean;

  public isPostCreationModalVisible: boolean;

  public selectedCreatePostType: Maybe<CreatePostTypeEnum>;

  public isSubmittedContentReport: boolean;

  public userDetails: Maybe<IBasePublicationAuthor>;

  constructor(
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
    @inject(TYPES.ApplicationStore) applicationStore: ApplicationStore,
    @inject(TYPES.YourFeedStore) yourFeedStore: YourFeedStore,
    @inject(TYPES.FavoritesFeedStore) favoritesFeedStore: FavoritesFeedStore,
    @inject(TYPES.PlayerFeedStore) playerFeedStore: PlayerFeedStore,
    @inject(TYPES.PlayerFanZoneFeedStore) playerFanZoneFeedStore: PlayerFanZoneFeedStore,
    @inject(TYPES.TeamFeedStore) teamFeedStore: TeamFeedStore,
    @inject(TYPES.TeamFanZoneFeedStore) teamFanZoneFeedStore: TeamFanZoneFeedStore,
    @inject(TYPES.GlobalFanZoneFeedStore) globalFanZoneFeedStore: GlobalFanZoneFeedStore,
    @inject(TYPES.GlobalFanZoneFavoritesFeedStore)
    globalFanZoneFavoritesFeedStore: GlobalFanZoneFavoritesFeedStore,
    @inject(TYPES.ReportsService) reportsService: ReportsService,
    @inject(TYPES.PostsService) postsService: PostsService,
    @inject(TYPES.GameVideosFeedStore) gameVideosFeedStore: GameVideosFeedStore,
    @inject(TYPES.PlayerFanZoneFavoritesFeedStore)
    playerFanZoneFavoritesFeedStore: PlayerFanZoneFavoritesFeedStore,
    @inject(TYPES.PublicUserFeedStore)
    publicUserFeedStore: PublicUserFeedStore,
  ) {
    super();

    this.applicationStore = applicationStore;

    this.yourFeedStore = yourFeedStore;

    this.gameVideosFeedStore = gameVideosFeedStore;

    this.favoritesFeedStore = favoritesFeedStore;

    this.playerFeedStore = playerFeedStore;

    this.playerFanZoneFeedStore = playerFanZoneFeedStore;

    this.teamFeedStore = teamFeedStore;

    this.postsService = postsService;

    this.layoutStore = layoutStore;

    this.globalFanZoneFeedStore = globalFanZoneFeedStore;

    this.globalFanZoneFavoritesFeedStore = globalFanZoneFavoritesFeedStore;

    this.reportsService = reportsService;

    this.playerFanZoneFavoritesFeedStore = playerFanZoneFavoritesFeedStore;

    this.teamFanZoneFeedStore = teamFanZoneFeedStore;

    this.publicUserFeedStore = publicUserFeedStore;

    this.isLoadMore = false;

    this.post = null;

    this.posts = [];

    this.postVideo = null;

    this.isPostVideoLoading = false;

    this.currentPostId = null;

    this.selectedCreatePostType = null;

    this.processingCreatePost = false;

    this.isIdInvalid = false;

    this.isPostCreationModalVisible = false;

    this.isSubmittedContentReport = false;

    this.userDetails = null;

    makeObservable(this, {
      isIdInvalid: observable,
      isPostVideoLoading: observable,
      isPostCreationModalVisible: observable,
      isSubmittedContentReport: observable,
      processingCreatePost: observable,
      currentPostId: observable,
      postVideo: observable,
      post: observable,
      posts: observable,
      isLoadMore: observable,
      userDetails: observable,
      selectedCreatePostType: observable,

      fetchingState: computed,
      fetchedState: computed,
      isLastPageState: computed,

      setIsIdInvalid: action.bound,
      setUserDetails: action.bound,
      setProcessingCreatePost: action.bound,
      setCurrentPostId: action.bound,
      setIsPostVideoLoading: action.bound,
      setIsPostCreationModalVisible: action.bound,
      setPostVideo: action.bound,
      setSubmittedContentReport: action.bound,
      setPost: action.bound,
      setPosts: action.bound,
      setLoadMorePosts: action.bound,
      setLoadMore: action.bound,
      setSelectedCreatePostType: action.bound,
      handleCurrentPostIdChange: action.bound,
    });

    reaction(() => this.currentPostId, this.handleCurrentPostIdChange);

    autorun(() => this.syncFeedFetchedEntries());
  }

  public handleCurrentPostIdChange = async () => {
    if (this.currentPostId) {
      this.fetchRegularPostById({ postId: this.currentPostId });
    }
  };

  private syncFeedFetchedEntries() {
    switch (this.layoutStore.activeFeed) {
      case FeedTypes.YourFeed:
        this.setPosts(this.yourFeedStore.entries);

        break;
      case FeedTypes.GameVideos:
        this.setPosts(this.gameVideosFeedStore.entries);

        break;
      case FeedTypes.Team:
        this.setPosts(this.teamFeedStore.entries);

        break;

      case FeedTypes.TeamFanZone:
        this.setPosts(this.teamFanZoneFeedStore.entries);

        break;
      case FeedTypes.GlobalFavorites:
        this.setPosts(this.favoritesFeedStore.entries);

        break;
      case FeedTypes.Player:
        this.setPosts(this.playerFeedStore.entries);
        break;

      case FeedTypes.PlayerFanZoneFavorites:
        this.setPosts(this.playerFanZoneFavoritesFeedStore.entries);
        break;

      case FeedTypes.PlayerFanZone:
        this.setPosts(this.playerFanZoneFeedStore.entries);
        break;

      case FeedTypes.GlobalFanZone:
        this.setPosts(this.globalFanZoneFeedStore.entries);
        break;

      case FeedTypes.GlobalFanZoneFavorites:
        this.setPosts(this.globalFanZoneFavoritesFeedStore.entries);
        break;

      case FeedTypes.PublicUser:
        this.setPosts(this.publicUserFeedStore.entries);
        break;

      default:
        this.setPosts([]);

        break;
    }
  }

  private getCurrentFeedInstance() {
    const feedStores: Record<FeedTypes, Maybe<FeedsList>> = {
      [FeedTypes.YourFeed]: this.yourFeedStore,
      [FeedTypes.GlobalFavorites]: this.favoritesFeedStore,

      [FeedTypes.Team]: this.teamFeedStore,
      [FeedTypes.TeamFanZone]: this.teamFanZoneFeedStore,

      [FeedTypes.Player]: this.playerFeedStore,
      [FeedTypes.PlayerFanZone]: this.playerFanZoneFeedStore,
      [FeedTypes.PlayerFanZoneFavorites]: this.playerFanZoneFavoritesFeedStore,

      [FeedTypes.GlobalFanZone]: this.globalFanZoneFeedStore,
      [FeedTypes.GlobalFanZoneFavorites]: this.globalFanZoneFavoritesFeedStore,

      [FeedTypes.PublicUser]: this.publicUserFeedStore,

      [FeedTypes.Headlines]: null,

      [FeedTypes.GameVideos]: this.gameVideosFeedStore,
    };

    if (this.layoutStore.activeFeed) {
      return feedStores[this.layoutStore.activeFeed];
    }

    return null;
  }

  private setFeedEntries(entries: IPost[]) {
    this.setPosts(entries);

    const currentFeed = this.getCurrentFeedInstance();

    currentFeed?.setEntries(entries);
  }

  public get fetchingState() {
    const currentFeed = this.getCurrentFeedInstance();

    return currentFeed?.fetching;
  }

  public get fetchedState() {
    const currentFeed = this.getCurrentFeedInstance();

    return currentFeed?.fetched;
  }

  public get isLastPageState() {
    const currentFeed = this.getCurrentFeedInstance();

    return currentFeed?.isLastPage;
  }

  public setLoadMore(isLoadMore: boolean) {
    this.isLoadMore = isLoadMore;
  }

  public setLoadMorePosts(isLoadMore: boolean) {
    if (this.layoutStore.activeFeed === FeedTypes.YourFeed) {
      this.setLoadMore(isLoadMore);
      return;
    }

    const currentFeed = this.getCurrentFeedInstance();

    if (currentFeed) {
      currentFeed.setLoadMore(isLoadMore);
    }
  }

  public setPost(post: Maybe<IPost>) {
    this.post = post;
  }

  public setSelectedCreatePostType(type: Maybe<CreatePostTypeEnum>) {
    this.selectedCreatePostType = type;
  }

  public setPosts(posts: IPost[]) {
    this.posts = [...posts];
  }

  public setIsIdInvalid(isIdInvalid: boolean) {
    this.isIdInvalid = isIdInvalid;
  }

  public setCurrentPostId(postId: Maybe<string>) {
    this.currentPostId = postId;
  }

  public setProcessingCreatePost(value: boolean) {
    this.processingCreatePost = value;
  }

  public setIsPostCreationModalVisible(isPostCreationModalVisible: boolean) {
    this.isPostCreationModalVisible = isPostCreationModalVisible;
  }

  public setSubmittedContentReport(isSubmittedContentReport: boolean) {
    this.isSubmittedContentReport = isSubmittedContentReport;
  }

  public setIsPostVideoLoading(value: boolean) {
    this.isPostVideoLoading = value;
  }

  public setUserDetails(userDetails: Maybe<IBasePublicationAuthor>) {
    this.userDetails = userDetails;
  }

  public setPostVideo(value: Maybe<IVideoResponse>) {
    this.postVideo = value;
  }

  public async fetchToLoadMorePosts() {
    const currentFeed = this.getCurrentFeedInstance();

    this.setLoadMorePosts(true);

    await currentFeed?.fetchNext();

    this.setLoadMorePosts(false);
  }

  public async createPost(post: ICreatePostPayload): Promise<boolean> {
    if (!post.playerId && !post.teamId) {
      return false;
    }

    this.setProcessingCreatePost(true);
    let response;

    if (post.playerId) {
      response = await this.postsService.createPlayerPost(post as ICreatePlayerPostPayload);
    } else {
      response = await this.postsService.createTeamPost(post as ICreateTeamPostPayload);
    }

    if (response.success) {
      const currentFeed = this.getCurrentFeedInstance();
      if (currentFeed) {
        currentFeed.forceFetchToRefresh();
      }

      this.setProcessingCreatePost(false);
      this.setPostVideo(null);

      return true;
    }

    if (response.code === 500 || response.code === 504) {
      const error: INotificationMessage = {
        message: 'We were unable to create your post at this time. Please try again later',
      };

      this.setErrors([error]);

      return false;
    }

    this.setErrors(response.errors);
    this.setProcessingCreatePost(false);

    return false;
  }

  public async fetchRegularPostById(payload: IFetchPostPayload) {
    this.setIsIdInvalid(false);

    const { postId } = payload;

    const response = await this.postsService.fetchPostById(postId);

    if (response.success) {
      const postData = <IPost>publicationAdapter(response.data);

      this.setPost(postData);

      this.layoutStore.setPulledRefresher(false);
      return postData;
    }

    if (response.code === 404 || response.code === 400) {
      this.setIsIdInvalid(true);
    }

    this.layoutStore.setPulledRefresher(false);
    return null;
  }

  public async sendPostReport(postId: string, reasonId: number): Promise<boolean> {
    this.setErrors([]);
    this.setSubmittedContentReport(false);

    const response = await this.reportsService.sendPostReport(postId, reasonId);

    if (!response.success) {
      this.setErrors(response.errors);
    }

    this.setSubmittedContentReport(true);

    return response.success;
  }

  public async forceFetchToRefreshFeed() {
    const currentFeed = this.getCurrentFeedInstance();

    await currentFeed?.forceFetchToRefresh();
  }

  public async togglePostBookmark(payload: ITogglePostBookmarkPayload): Promise<void> {
    const { id } = payload;

    const response = await this.postsService.togglePostBookmark(payload);

    if (response.success) {
      const updatedPosts = this.posts.map((post) => {
        if (post.uuid === id) {
          return {
            ...post,
            isBookmarked: response.data.is_bookmarked,
            bookmarksCount: response.data.bookmarks_count,
          };
        }

        return post;
      });

      this.setFeedEntries(updatedPosts);
    }
  }

  public async postPollVote(payload: IPollVoteData): Promise<void> {
    const response = await this.postsService.postPollVote(payload);

    if (response.success) {
      if (this.posts.length) {
        this.updatePostsFeedWithPollData(payload.pollId, response.data);
      }
    } else {
      this.setErrors(response.errors);
    }
  }

  public async updatePoll(payload: IPollUpdateData): Promise<void> {
    const response = await this.postsService.postPollUpdate(payload);

    if (response.success) {
      if (this.posts.length) {
        this.updatePostsFeedWithPollData(payload.pollId, response.data);
      }
    } else {
      this.setErrors(response.errors);
    }
  }

  protected updatePostsFeedWithPollData(
    pollId: string,
    pollAttachmentData: IPollAttachmentResponse,
  ): void {
    const postId = this.posts
      .filter((item) => !!item.attachments)
      .find((post) => post.attachments.poll && post.attachments?.poll.uuid === pollId)?.uuid;

    const updatedPosts = this.posts.map((post) => {
      if (post.uuid === postId) {
        return <IPost>{
          ...post,
          attachments: {
            ...post.attachments,
            poll: pollAdapter(pollAttachmentData),
          },
        };
      }

      return post;
    });

    this.setFeedEntries(updatedPosts);
  }

  public async postLikes(payload: IPostVoteData): Promise<Maybe<ILikesResult>> {
    const { postId } = payload;
    const response = await this.postsService.postLikes(postId);

    if (response.success) {
      const newLikes = {
        likes: response.data.likes_total,
        isLiked: response.data.is_liked,
      };

      const updatedPosts = this.posts.map((post) => {
        if (post.uuid === postId) {
          return {
            ...post,
            ...newLikes,
          };
        }

        return post;
      });

      this.setFeedEntries(updatedPosts);

      return newLikes;
    }

    this.setErrors(response.errors);

    return null;
  }

  public async asyncUpdatePostById(payload: IPublicationUpdatePayload): Promise<void> {
    const { postId, commentsSort } = payload;

    const response = await this.fetchRegularPostById({
      postId,
      commentsSort,
    });

    if (response && this.posts.length) {
      const updatedPosts = this.posts.map((post) => {
        if (post.uuid === response.uuid) {
          return response;
        }

        return post;
      });

      this.setFeedEntries(updatedPosts);
    }
  }

  public updatePostShareCount(id: string, sharesCount: number) {
    const updatedPosts = this.posts.map((post) => {
      if (post.uuid === id) {
        return {
          ...post,
          sharesCount,
        };
      }

      return post;
    });

    this.setFeedEntries(updatedPosts);
  }

  public getPostById(id: string): Maybe<IPost> {
    const currentPost = this.posts.find((post) => post.uuid === id);

    if (currentPost) {
      return currentPost;
    }

    return null;
  }

  public async uploadVideoForPost(payload: File): Promise<void> {
    this.setIsPostVideoLoading(true);

    const formData = await customFormData(
      payload,
      'file',
      'base64File',
      this.applicationStore.isNativeApp,
    );

    postsLogger.debug({ msg: 'video form data', formData });

    const response = await this.postsService.uploadVideo(formData);

    if (response.success) {
      this.setPostVideo({
        uuid: response.data.uuid,
        url: response.data.url,
      });
    } else {
      this.setErrors(response.errors);
    }

    this.setIsPostVideoLoading(false);
  }

  public removePostFromEntries(postId: string) {
    const updatedPosts = this.posts.filter((post) => post.uuid !== postId);

    this.setFeedEntries(updatedPosts);
  }

  public async deletePost(payload: IDeletePostPayload): Promise<boolean> {
    const response = await this.postsService.deletePost(payload);

    if (response.success) {
      this.removePostFromEntries(response.data.uuid);

      return true;
    }

    return false;
  }
}
