import { FC } from 'react';

import {
  IconButtonWithText,
  IconButtonWithTextSize,
  IconButtonWithTextTheme,
} from 'components/ui/icon-button-with-text/icon-button-with-text.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './global-search-header-desktop.module.less';

interface IGlobalSearchHeaderDesktopProps {
  searchValue: string;
  onBackButtonClick: () => void;
}

export const GlobalSearchHeaderDesktop: FC<IGlobalSearchHeaderDesktopProps> = (props) => {
  const { searchValue } = props;

  return (
    <div className={styles.SearchHeader}>
      <IconButtonWithText
        theme={IconButtonWithTextTheme.Tertiary}
        onClick={props.onBackButtonClick}
        iconName={IconFontName.ChevronLeft}
        text="Back"
        size={IconButtonWithTextSize.Small}
      />
      <div className={styles.SearchHeader__Label}>
        <span>{`Search results for "${searchValue}"`}</span>
      </div>
    </div>
  );
};
