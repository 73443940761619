import { IImageAttachment } from 'stores/posts/interfaces/post.interface';

export interface IPollAnswer {
  uuid: string;
  title: string;
  votesTotal: number;
  order: number;
  percentage: number;
  attachmentImage?: IImageAttachment | null;
}

export interface IPollVoteData {
  pollId: string;
  answerId: string;
  entityId: string;
}

export enum IStopPollType {
  Now = 'now',
  InOneHour = 'in_one_hour',
}

export interface IPollUpdateData {
  pollId: string;
  quoteContent?: string;
  stopPollType?: IStopPollType;
}
