import { FC } from 'react';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { TeamProfileHeaderInfoContainer } from 'containers/team/team-profile-header/team-profile-header.container';
import { TeamProfileTabsContainer } from 'containers/team/team-profile-tabs/team-profile-tabs';
import { TeamProfileInfoContainer } from 'containers/team-profile/team-profile-info/team-profile-info.container';
import { TeamProfileThumbnailContainer } from 'containers/team-profile/team-profile-thumbnail/team-profile-thumbnail.container';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const TeamProfileRoute: FC = () => {
  return (
    <PageWrapperContainer>
      <TeamProfileThumbnailContainer />
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <TeamProfileHeaderInfoContainer />
          <TeamProfileTabsContainer />
          <TeamProfileInfoContainer />
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
