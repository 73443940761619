import { SyntheticEvent, useMemo } from 'react';

import { getDomainFromLink } from 'helpers/get-domain-from-link.util';

import { IconBadge } from '../icon-badge/icon-badge.component';
import { IconFontName, IconFontSize } from '../icon-font/icon-font.component';

import styles from './image-with-badge.module.less';

interface IImageWithBadgeProps {
  imagePreviewUrl: string;
  sourceUrl: string;
  onImageClick: (event: SyntheticEvent) => void;
}

export const ImageWithBadge = (props: IImageWithBadgeProps) => {
  const { imagePreviewUrl, sourceUrl } = props;

  const sourceContent = useMemo(() => getDomainFromLink(sourceUrl), [sourceUrl]);

  return (
    <button className={styles.ImageWithBadge} onClick={props.onImageClick}>
      <div className={styles.ImageWithBadge__ImageWrapper}>
        <img className={styles.ImageWithBadge__Image} src={imagePreviewUrl} alt="attach" />
      </div>
      <div className={styles.ImageWithBadge__Badge}>
        <IconBadge
          iconName={IconFontName.Link2}
          iconSize={IconFontSize.Big}
          content={sourceContent}
        />
      </div>
    </button>
  );
};
