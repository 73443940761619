import { FC, useCallback, useMemo } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { ITeamStatsExtended } from 'stores/teams-stats/interfaces/teams-stats-all.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { ProfileInfoSocials } from 'components/profile-info-socials/profile-info-socials.component';
import { IStatsBoxItem } from 'components/ui/stats-box/interfaces/stats-box.interface';
import { StatsBox, StatsBoxTheme } from 'components/ui/stats-box/stats-box.component';

import { prepareTeamStatsBoxItems } from './utils/prepare-team-stats-box-items.util';

import styles from './team-profile-stats-info.module.less';

interface ITeamProfileStatsInfoProps {
  teamsStats: ITeamStatsExtended;
}

export const TeamProfileStatsInfo: FC<ITeamProfileStatsInfoProps> = (
  props: ITeamProfileStatsInfoProps,
) => {
  const { teamsStats } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const navigate = useNavigate();

  const teamStatsBoxItems = useMemo<IStatsBoxItem[]>(() => {
    if (teamsStats) {
      return prepareTeamStatsBoxItems(teamsStats);
    }

    return [];
  }, [teamsStats]);

  const handleRankClick = useCallback(
    (sort?: string) => () => {
      if (sort) {
        navigate({
          pathname: paths.HOME_STATS_TEAMS_ALL,
          search: `${createSearchParams({ sort, direction: 'desc' })}`,
        });
      }
    },
    [navigate],
  );

  return (
    <div className={styles.TeamProfileInfoWrapper}>
      {!isDesktopPlus && (
        <div className={styles.TeamProfileInfo__Socials}>
          <ProfileInfoSocials
            discord={null}
            snapchat={null}
            instagramUrl={teamsStats.instagramUrl}
            twitterUrl={teamsStats.twitterUrl}
            youtubeUrl={teamsStats.youtubeUrl}
            tiktokUrl={teamsStats.tiktokUrl}
          />
        </div>
      )}
      <div className={styles.TeamProfileInfo__Stats}>
        {!!teamStatsBoxItems.length &&
          teamStatsBoxItems.map((item) => (
            <StatsBox
              key={item.slug}
              title={isDesktopPlus ? item.title : item.slug}
              content={item.content}
              theme={StatsBoxTheme.Transparent}
              rank={item.rank}
              onClick={handleRankClick(item.sortByColumn)}
            />
          ))}
      </div>
    </div>
  );
};
