import * as paths from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { IItemLineGroup } from 'components/ui/item-line-list/interfaces/item-line-group.interface';

export const NATIVE_APP_SETTINGS: IItemLineGroup[] = [
  {
    id: 1,
    item: {
      icon: IconFontName.Profile,
      text: 'Account',
      actionType: { type: BarAction.Click, payload: 'account' },
    },
  },
  {
    id: 2,
    isVisibleAnonymously: true,
    item: {
      icon: IconFontName.Notifications,
      text: 'Notifications',
      actionType: { type: BarAction.Click, payload: 'notifications' },
    },
  },
  {
    id: 3,
    isVisibleAnonymously: true,
    item: {
      icon: IconFontName.Info,
      text: 'Privacy & Safety',
      actionType: { type: BarAction.Click, payload: 'privacy' },
    },
  },
  {
    id: 4,
    isVisibleAnonymously: true,
    item: {
      icon: IconFontName.Feedback,
      text: 'Contact Us',
      actionType: { type: BarAction.Link, path: paths.CONTACT_US },
    },
  },
  {
    id: 5,
    isVisibleAnonymously: true,
    item: {
      icon: IconFontName.Feedback3,
      text: 'Send Feedback',
      actionType: { type: BarAction.Click, payload: 'feedback' },
      noArrow: true,
    },
  },
  {
    id: 6,
    item: {
      icon: IconFontName.Logout,
      text: 'Log Out',
      actionType: { type: BarAction.Click, payload: 'logout' },
      noArrow: true,
    },
  },
];

export const WEB_APP_SETTINGS: IItemLineGroup[] = [
  {
    id: 1,
    item: {
      icon: IconFontName.Profile,
      text: 'Account',
      actionType: { type: BarAction.Click, payload: 'account' },
    },
  },
  {
    id: 2,
    isVisibleAnonymously: true,
    item: {
      icon: IconFontName.Info,
      text: 'Privacy & Safety',
      actionType: { type: BarAction.Click, payload: 'privacy' },
    },
  },
  {
    id: 3,
    isVisibleAnonymously: true,
    item: {
      icon: IconFontName.Feedback,
      text: 'Contact Us',
      actionType: { type: BarAction.Link, path: paths.CONTACT_US },
    },
  },
  {
    id: 4,
    isVisibleAnonymously: true,
    item: {
      icon: IconFontName.Feedback3,
      text: 'Send Feedback',
      actionType: { type: BarAction.Click, payload: 'feedback' },
      noArrow: true,
    },
  },
  {
    id: 5,
    item: {
      icon: IconFontName.Logout,
      text: 'Log Out',
      actionType: { type: BarAction.Click, payload: 'logout' },
      noArrow: true,
    },
  },
];

export const SETTINGS_PRIVACY_SAFETY: IItemLineGroup[] = [
  {
    id: 1,
    item: {
      icon: IconFontName.Document,
      text: 'Privacy policy',
      actionType: { type: BarAction.Click, payload: 'privacy' },
    },
  },
  {
    id: 2,
    item: {
      icon: IconFontName.Post,
      text: 'Terms of use',
      actionType: { type: BarAction.Click, payload: 'terms' },
    },
  },
  {
    id: 3,
    item: {
      icon: IconFontName.Document,
      text: 'User agreement',
      actionType: { type: BarAction.Click, payload: 'agreement' },
    },
  },
];

export const SETTINGS_ACCOUNT: IItemLineGroup[] = [
  {
    id: 1,
    item: {
      text: 'Delete Account Permanently',
      actionType: { type: BarAction.Click, payload: 'account' },
      noArrow: true,
    },
  },
];

export const CONTACT_US: IItemLineGroup[] = [
  {
    id: 1,
    item: {
      icon: IconFontName.Feedback,
      text: 'Contact Us',
      actionType: { type: BarAction.Click, payload: 'contact' },
    },
  },
];
