import { memo } from 'react';

import chipImg from 'assets/images/svg/chip-image.svg';

import styles from './predictions.module.less';

export const Predictions = memo(() => {
  return (
    <div className={styles.Predictions}>
      <img className={styles.Predictions__Image} src={chipImg} alt="Icon" />
      <div className={styles.Predictions__Title}>In progress</div>
      <div className={styles.Predictions__Message}>
        We are currently working on this feature. Check it soon
      </div>
    </div>
  );
});
