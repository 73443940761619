import { FC } from 'react';

import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutHeaderLeftActionEnum } from 'stores/layout/interfaces/layout-header-left.interface';

import { CollaborationCommentsContainer } from 'containers/collaboration-comments/collaboration-comments.container';
import { CollaborationItemContainer } from 'containers/collaboration-item/collaboration-item.container';
import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';

import { useAllowSwipeBack } from 'hooks/use-allow-swipe-back';
import { useEnableRefresher } from 'hooks/use-enable-refresher';
import { useForceCollapseSmartBanner } from 'hooks/use-force-collapse-smart-banner';
import { useLayoutEntity } from 'hooks/use-layout-entity';
import { useMobileLayoutStructure } from 'hooks/use-mobile-layout-structure';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const CollaborationItemRoute: FC = () => {
  useLayoutEntity({ type: LayoutEntity.PageTitle, value: 'Content details' });
  useLayoutEntity({
    type: LayoutEntity.HeaderLeft,
    value: { icon: IconFontName.ChevronLeft, type: LayoutHeaderLeftActionEnum.Back },
  });
  useMobileLayoutStructure({
    isHiddenHeader: false,
  });

  useForceCollapseSmartBanner();
  useAllowSwipeBack();
  useEnableRefresher();

  return (
    <PageWrapperContainer>
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1}>
          <CollaborationItemContainer />
          <CollaborationCommentsContainer />
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
