import { publicLinkAdapterUtil } from 'stores/auth/adapters/public-link-adapter.util';
import { IPlayerAthlete, IPlayerAthleteResponse } from 'stores/auth/interfaces/athlete.interface';
import { LinkType } from 'stores/auth/interfaces/custom-link.interface';

import { convertColorToRGB, convertColorToRGBA } from 'helpers/rgb-to-rgba.utils';

export function playerAthleteAdapter(
  athleteResponse: IPlayerAthleteResponse,
): Maybe<IPlayerAthlete> {
  if (!athleteResponse) {
    return null;
  }

  const {
    player_slug: playerSlug,
    links,
    bio,
    medium_logo_url: mediumLogoUrl,
    lastname: lastName,
    firstname: firstName,
    small_logo_url: smallLogoUrl,
    primary_color: primaryColor,
    secondary_color: secondaryColor,
  } = athleteResponse;

  let localAthlete: Maybe<IPlayerAthlete> = null;

  if (playerSlug) {
    localAthlete = {
      biography: bio,
      primaryColor: convertColorToRGB(primaryColor),
      primaryColorWithOpacity: convertColorToRGBA(primaryColor, 0.7),
      primaryColorWithZeroOpacity: convertColorToRGBA(primaryColor, 0),
      secondaryColor: convertColorToRGB(secondaryColor),
      secondaryColorWithOpacity: convertColorToRGBA(secondaryColor, 0.7),
      playerSlug,
      firstName,
      lastName,
      smallLogoUrl,
      mediumLogoUrl,
    };

    if (links?.length) {
      const allLInks = links.map(publicLinkAdapterUtil).sort((a, b) => a.id - b.id);

      localAthlete.customLinks = allLInks.filter((link) => link.type === LinkType.Custom);
      localAthlete.socialLinks = allLInks.filter((link) => link.type !== LinkType.Custom);
    }
  }

  return localAthlete;
}
