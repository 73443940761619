import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import { ImageUploadPayloadType } from 'services/application/interfaces/upload-image.interface';
import {
  ICollaborationCommentResponse,
  ICollaborationCommentsResponse,
  ICollaborationImageResponse,
  ICollaborationItemPayload,
  ICollaborationItemResponse,
  ICollaborationItemsResponse,
  ICollaborationItemsSearchParams,
  ICollaborationVideoResponse,
} from 'services/collaboration/interfaces/collaboration.interface';
import {
  COLLABORATION_ID_API_PARAM,
  COLLABORATION_ITEM_COMMENT_ID_API_PARAM,
  COLLABORATION_ITEM_ID_API_PARAM,
} from 'services/http/consts/api-endpoint-params.constants';
import { HttpService } from 'services/http/http.service';
import { ICommentCreatePayload } from 'services/posts/interfaces/create-comment-payload.interface';

import { VideoUploadPayloadType } from 'stores/posts/interfaces/post.interface';
import { IShareCollaborationItemResponse } from 'stores/share/interfaces/share-response.interface';

import { TYPES } from 'configs/di-types.config';

import {
  COLLABORATION_BOOKMARK,
  COLLABORATION_COMMENTS,
  COLLABORATION_IMAGE_UPLOAD,
  COLLABORATION_ITEM,
  COLLABORATION_ITEM_COMMENT,
  COLLABORATION_ITEM_COMMENT_LIKE,
  COLLABORATION_LIKE,
  COLLABORATION_SHARE,
  COLLABORATION_VIDEO_UPLOAD,
  COLLABORATIONS,
} from '../http/consts/api-endpoints.constants';

@injectable()
export class CollaborationService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public async fetchCollaborationItems(
    pagination: IPagination,
    collaborationId: number,
  ): Promise<IResponse<ICollaborationItemsResponse>> {
    const { limit, page } = pagination;

    const paramsObject: ICollaborationItemsSearchParams = {
      limit: String(limit),
      page: String(page),
    };

    return this.httpService.request<ICollaborationItemsResponse>({
      method: 'GET',
      url: COLLABORATIONS,
      params: new URLSearchParams({ ...paramsObject }),
      routeParams: {
        [COLLABORATION_ID_API_PARAM]: String(collaborationId),
      },
    });
  }

  public async createCollaborationItem(
    collaborationId: number,
    payload: ICollaborationItemPayload,
  ): Promise<IResponse<ICollaborationItemResponse>> {
    return this.httpService.request<ICollaborationItemResponse, ICollaborationItemPayload>({
      method: 'POST',
      url: COLLABORATIONS,
      routeParams: {
        [COLLABORATION_ID_API_PARAM]: String(collaborationId),
      },
      body: payload,
    });
  }

  public async likeCollaborationItem(
    collaborationId: number,
    collaborationItemId: number,
  ): Promise<IResponse<ICollaborationItemResponse>> {
    return this.httpService.request<ICollaborationItemResponse, ICollaborationItemPayload>({
      method: 'POST',
      url: COLLABORATION_LIKE,
      routeParams: {
        [COLLABORATION_ID_API_PARAM]: String(collaborationId),
        [COLLABORATION_ITEM_ID_API_PARAM]: String(collaborationItemId),
      },
    });
  }

  public async bookmarkCollaborationItem(
    collaborationId: number,
    collaborationItemId: number,
  ): Promise<IResponse<ICollaborationItemResponse>> {
    return this.httpService.request<ICollaborationItemResponse, ICollaborationItemPayload>({
      method: 'POST',
      url: COLLABORATION_BOOKMARK,
      routeParams: {
        [COLLABORATION_ID_API_PARAM]: String(collaborationId),
        [COLLABORATION_ITEM_ID_API_PARAM]: String(collaborationItemId),
      },
    });
  }

  public async getCollaborationItem(
    collaborationId: number,
    collaborationItemId: number,
  ): Promise<IResponse<ICollaborationItemResponse>> {
    return this.httpService.request<ICollaborationItemResponse, ICollaborationItemPayload>({
      method: 'GET',
      url: COLLABORATION_ITEM,
      routeParams: {
        [COLLABORATION_ID_API_PARAM]: String(collaborationId),
        [COLLABORATION_ITEM_ID_API_PARAM]: String(collaborationItemId),
      },
    });
  }

  public uploadCollaborationImage(
    payload: ImageUploadPayloadType,
  ): Promise<IResponse<ICollaborationImageResponse>> {
    return this.httpService.request<ICollaborationImageResponse>({
      url: COLLABORATION_IMAGE_UPLOAD,
      method: 'POST',
      body: payload,
      isMultipart: true,
    });
  }

  public async uploadCollaborationVideo(
    payload: VideoUploadPayloadType,
  ): Promise<IResponse<ICollaborationVideoResponse>> {
    return this.httpService.request<ICollaborationVideoResponse, VideoUploadPayloadType>({
      method: 'POST',
      url: COLLABORATION_VIDEO_UPLOAD,
      body: payload,
      isMultipart: true,
    });
  }

  public async createCollaborationItemComment(
    collaborationId: number,
    collaborationItemId: number,
    payload: ICommentCreatePayload,
  ): Promise<IResponse<ICollaborationCommentResponse>> {
    return this.httpService.request<ICollaborationCommentResponse, ICommentCreatePayload>({
      method: 'POST',
      url: COLLABORATION_COMMENTS,
      routeParams: {
        [COLLABORATION_ID_API_PARAM]: String(collaborationId),
        [COLLABORATION_ITEM_ID_API_PARAM]: String(collaborationItemId),
      },
      body: payload,
    });
  }

  public async shareCollaborationItem(
    collaborationId: number,
    collaborationItemId: number,
  ): Promise<IResponse<IShareCollaborationItemResponse>> {
    return this.httpService.request<IShareCollaborationItemResponse>({
      method: 'POST',
      url: COLLABORATION_SHARE,
      routeParams: {
        [COLLABORATION_ID_API_PARAM]: String(collaborationId),
        [COLLABORATION_ITEM_ID_API_PARAM]: String(collaborationItemId),
      },
    });
  }

  public async fetchCollaborationItemComments(
    pagination: IPagination,
    collaborationId: number,
    collaborationItemId: number,
  ): Promise<IResponse<ICollaborationCommentsResponse>> {
    const { limit, page } = pagination;

    const paramsObject: ICollaborationItemsSearchParams = {
      limit: limit.toString(),
      page: page.toString(),
    };

    return this.httpService.request<ICollaborationCommentsResponse>({
      method: 'GET',
      url: COLLABORATION_COMMENTS,

      routeParams: {
        [COLLABORATION_ID_API_PARAM]: String(collaborationId),
        [COLLABORATION_ITEM_ID_API_PARAM]: String(collaborationItemId),
      },
      params: new URLSearchParams({ ...paramsObject }),
    });
  }

  public async deleteCollaborationItem(
    collaborationId: number,
    collaborationItemId: number,
  ): Promise<IResponse<ICollaborationItemResponse>> {
    return this.httpService.request<ICollaborationItemResponse>({
      method: 'DELETE',
      url: COLLABORATION_ITEM,
      routeParams: {
        [COLLABORATION_ID_API_PARAM]: String(collaborationId),
        [COLLABORATION_ITEM_ID_API_PARAM]: String(collaborationItemId),
      },
    });
  }

  public async deleteCollaborationItemComment(
    collaborationId: number,
    collaborationItemId: number,
    collaborationCommentId: number,
  ): Promise<IResponse<ICollaborationCommentResponse>> {
    return this.httpService.request<ICollaborationCommentResponse>({
      method: 'DELETE',
      url: COLLABORATION_ITEM_COMMENT,
      routeParams: {
        [COLLABORATION_ID_API_PARAM]: String(collaborationId),
        [COLLABORATION_ITEM_ID_API_PARAM]: String(collaborationItemId),
        [COLLABORATION_ITEM_COMMENT_ID_API_PARAM]: String(collaborationCommentId),
      },
    });
  }

  public async likeCollaborationItemComment(
    collaborationId: number,
    collaborationItemId: number,
    collaborationCommentId: number,
  ): Promise<IResponse<ICollaborationCommentResponse>> {
    return this.httpService.request<ICollaborationCommentResponse>({
      method: 'POST',
      url: COLLABORATION_ITEM_COMMENT_LIKE,
      routeParams: {
        [COLLABORATION_ID_API_PARAM]: String(collaborationId),
        [COLLABORATION_ITEM_ID_API_PARAM]: String(collaborationItemId),
        [COLLABORATION_ITEM_COMMENT_ID_API_PARAM]: String(collaborationCommentId),
      },
    });
  }
}
