import { IImageAttachmentResponse } from 'services/posts/interfaces/post-attachments-response.interface';

import { IImageAttachment } from '../interfaces/post.interface';

export function imageAdapter(imageResponse: IImageAttachmentResponse): IImageAttachment {
  return {
    uuid: imageResponse.uuid,
    url: imageResponse.url,
    mediumPreviewUrl: imageResponse.medium_preview_url,
    smallPreviewUrl: imageResponse.small_preview_url,
    mimeType: imageResponse.mime_type,
    filename: imageResponse.filename,
    dateCreated: imageResponse.date_created,
    type: 'image',
  };
}
