import { FC, useCallback, useState } from 'react';

import {
  ConvertImageItemToAttachmentType,
  ConvertVideoItemToAttachmentType,
} from 'services/application/interfaces/upload-image.interface';
import {
  CollaborationMediaEnum,
  CollaborationMediaType,
  ICollaborationItemPayload,
} from 'services/collaboration/interfaces/collaboration.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { trimEditorContent } from 'helpers/trim-editor-content.util';

import { useResponsive } from 'hooks/use-responsive';

import { FILES_IMAGE_TYPES, FILES_VIDEO_TYPES } from 'validation/validation.constants';

import { AuthTooltip } from 'components/auth/auth-tooltip/auth-tooltip.component';
import { CollaborationEditorBottomSheet } from 'components/bottom-sheet/editor-bottom-sheet/collaboration-editor-bottom-sheet.component';
import { CollaborationItemCreateDataType } from 'components/forms/collaboration-editor/collaboration-editor-form.component';
import { CollaborationEditorModal } from 'components/modals/collaboration-editor-modal/collaboration-editor-modal.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { PostImageInput } from 'components/ui/form-fields/post-image-input/post-image-input.component';
import { InputImageDataType } from 'components/ui/form-fields/post-image-input/post-image-input.types';
import {
  isCollaborationImage,
  isCollaborationVideo,
  isICollaborationMediaArray,
} from 'components/ui/form-fields/post-image-input/utils';
import { PostVideoInput } from 'components/ui/form-fields/post-video-input/post-video-input.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './collaboration-action.module.less';

interface IFeedCollaborationProps {
  collaborationId: number;
  postId: string;
  selectedItemId: Maybe<number>;
  media: Maybe<CollaborationMediaEnum>;
  isLoading: boolean;
  isAuthorised: boolean;
  textAllowed: boolean;
  onVideoError?: (error: string) => void;
  onOpenCollaborationTextResponseModal: () => void;
  onConvertImageItemToAttachment?: ConvertImageItemToAttachmentType;
  convertVideoItemToAttachment?: ConvertVideoItemToAttachmentType;
  collaborationMediaItem?: Maybe<CollaborationMediaType>;
  onCreateCollaborationItem?: (payload: ICollaborationItemPayload) => void;
  setCollaborationMediaItem?: (value: Maybe<CollaborationMediaType>) => void;
  isCollaborationVideoLoading?: boolean;
  isCollaborationImageLoading?: boolean;
}

export const CollaborationAction: FC<IFeedCollaborationProps> = (props) => {
  const {
    postId,
    collaborationId,
    media,
    selectedItemId,
    isLoading,
    isAuthorised,
    textAllowed,
    collaborationMediaItem,
    setCollaborationMediaItem,
    convertVideoItemToAttachment,
    onCreateCollaborationItem,
    onConvertImageItemToAttachment: convertImageItemToAttachment,
  } = props;
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [isVideoPermissionsRequested, setIsVideoPermissionsRequested] = useState(false);
  const [isVideoInputOpen, setIsVideoInputOpen] = useState(false);
  const [isOpenCreateCollaborationModal, setIsOpenCreateCollaborationModal] = useState(false);

  const handleCloseEditor = useCallback(() => {
    setCollaborationMediaItem?.(null);
    setIsOpenCreateCollaborationModal(false);
  }, [setCollaborationMediaItem]);

  const handleCreateCollaborationImageItem = useCallback(
    (fileData: InputImageDataType) => {
      if (!isICollaborationMediaArray(fileData)) {
        return;
      }
      const imageId = fileData.length && fileData[0].id;

      if (imageId && onCreateCollaborationItem) {
        onCreateCollaborationItem({
          collaboration_item_image_id: imageId,
          id: collaborationId,
        });
      }
    },
    [onCreateCollaborationItem, collaborationId],
  );

  const handleCreateCollaborationImageItemWithText = useCallback(
    () =>
      async ({ editor }: CollaborationItemCreateDataType) => {
        if (
          collaborationMediaItem &&
          onCreateCollaborationItem &&
          editor.content?.length &&
          editor.content.length > 0
        ) {
          const editorState = JSON.parse(editor?.content?.value);
          let content = editorState?.root?.children;

          const newEditorState = {
            ...editorState,
            root: {
              ...editorState.root,
              children: trimEditorContent(content),
            },
          };

          content = JSON.stringify(newEditorState);

          if (isCollaborationImage(collaborationMediaItem)) {
            const payload: ICollaborationItemPayload = {
              text_content: content,
              collaboration_item_image_id: collaborationMediaItem.id,
              id: collaborationId,
            };

            onCreateCollaborationItem(payload);
          } else if (isCollaborationVideo(collaborationMediaItem)) {
            const payload: ICollaborationItemPayload = {
              text_content: content,
              collaboration_item_video_id: collaborationMediaItem.id,
              id: collaborationId,
            };

            onCreateCollaborationItem(payload);
          }
        }
      },
    [collaborationMediaItem, collaborationId, onCreateCollaborationItem],
  );

  const handleCreateCollaborationVideoItem = useCallback(
    async (file: File) => {
      const newVideoAttachment = await convertVideoItemToAttachment?.(file, postId);
      if (newVideoAttachment?.id && onCreateCollaborationItem && collaborationId) {
        onCreateCollaborationItem({
          collaboration_item_video_id: newVideoAttachment.id,
          id: collaborationId,
        });
      }
    },
    [collaborationId, convertVideoItemToAttachment, onCreateCollaborationItem, postId],
  );

  const handleOpenImageWithTextModal = useCallback(
    (fileData: InputImageDataType) => {
      if (!isICollaborationMediaArray(fileData) || !fileData.length) {
        return;
      }

      setCollaborationMediaItem?.(fileData[0]);
      setIsOpenCreateCollaborationModal(true);
    },
    [setCollaborationMediaItem],
  );

  const handleOpenVideoWithTextModal = useCallback(
    async (file: File) => {
      await convertVideoItemToAttachment?.(file, postId);
      setIsOpenCreateCollaborationModal?.(true);
    },
    [convertVideoItemToAttachment, postId],
  );

  const handleOpenVideoInput = useCallback(() => {
    if (!isAuthorised) {
      return;
    }

    setIsVideoInputOpen(true);
  }, [isAuthorised, setIsVideoInputOpen]);

  return (
    <>
      {!selectedItemId &&
        !textAllowed &&
        media === CollaborationMediaEnum.Image &&
        convertImageItemToAttachment && (
          <div className={styles.FeedCollaboration__Actions}>
            <PostImageInput
              allowMultiple={false}
              accept={FILES_IMAGE_TYPES.join(', ')}
              convertImageItemToAttachment={convertImageItemToAttachment}
              onImageLoad={handleCreateCollaborationImageItem}
              id="attachImage"
              name="attachImage"
              isDisabled={!isAuthorised}
            >
              {isLoading ? (
                <Loader isShow />
              ) : (
                <AuthTooltip>
                  <Button
                    iconName={IconFontName.Image}
                    iconSize={IconFontSize.Big}
                    size={ButtonSize.Small}
                    theme={ButtonTheme.Primary}
                    fluid
                  >
                    Suggest Media
                  </Button>
                </AuthTooltip>
              )}
            </PostImageInput>
          </div>
        )}
      {!selectedItemId &&
        textAllowed &&
        media === CollaborationMediaEnum.Image &&
        convertImageItemToAttachment && (
          <div className={styles.FeedCollaboration__Actions}>
            <PostImageInput
              allowMultiple={false}
              accept={FILES_IMAGE_TYPES.join(', ')}
              convertImageItemToAttachment={convertImageItemToAttachment}
              onImageLoad={handleOpenImageWithTextModal}
              id="attachImage"
              name="attachImage"
              isDisabled={!isAuthorised}
            >
              {isLoading ? (
                <Loader isShow />
              ) : (
                <AuthTooltip>
                  <Button
                    iconName={IconFontName.Image}
                    iconSize={IconFontSize.Big}
                    size={ButtonSize.Small}
                    theme={ButtonTheme.Primary}
                    fluid
                  >
                    Suggest Media
                  </Button>
                </AuthTooltip>
              )}
            </PostImageInput>
          </div>
        )}
      {!selectedItemId &&
        textAllowed &&
        media === CollaborationMediaEnum.Video &&
        convertImageItemToAttachment && (
          <div className={styles.FeedCollaboration__Actions}>
            <PostVideoInput
              isVideoInputOpen={isVideoInputOpen}
              setIsVideoInputOpen={setIsVideoInputOpen}
              accept={FILES_VIDEO_TYPES.join(', ')}
              onVideoLoad={handleOpenVideoWithTextModal}
              id="attachVideo"
              name="attachVideo"
              onError={props.onVideoError}
              isVideoPermissionsRequested={isVideoPermissionsRequested}
              setIsVideoPermissionsRequested={setIsVideoPermissionsRequested}
            >
              {isLoading ? (
                <Loader isShow />
              ) : (
                <AuthTooltip>
                  <Button
                    iconName={IconFontName.Image}
                    iconSize={IconFontSize.Big}
                    size={ButtonSize.Small}
                    theme={ButtonTheme.Primary}
                    fluid
                    onClick={handleOpenVideoInput}
                  >
                    Suggest Media
                  </Button>
                </AuthTooltip>
              )}
            </PostVideoInput>
          </div>
        )}
      {!selectedItemId && !textAllowed && media === CollaborationMediaEnum.Video && (
        <div className={styles.FeedCollaboration__Actions}>
          <PostVideoInput
            isVideoInputOpen={isVideoInputOpen}
            setIsVideoInputOpen={setIsVideoInputOpen}
            accept={FILES_VIDEO_TYPES.join(', ')}
            onVideoLoad={handleCreateCollaborationVideoItem}
            id="attachVideo"
            name="attachVideo"
            onError={props.onVideoError}
            isVideoPermissionsRequested={isVideoPermissionsRequested}
            setIsVideoPermissionsRequested={setIsVideoPermissionsRequested}
          >
            {isLoading ? (
              <Loader isShow />
            ) : (
              <AuthTooltip>
                <Button
                  iconName={IconFontName.Image}
                  iconSize={IconFontSize.Big}
                  size={ButtonSize.Small}
                  theme={ButtonTheme.Primary}
                  fluid
                  onClick={handleOpenVideoInput}
                >
                  Suggest Media
                </Button>
              </AuthTooltip>
            )}
          </PostVideoInput>
        </div>
      )}

      {!selectedItemId && !media && textAllowed && (
        <div className={styles.FeedCollaboration__Actions}>
          <AuthTooltip>
            <Button
              iconName={IconFontName.Chat}
              iconSize={IconFontSize.Big}
              size={ButtonSize.Small}
              theme={ButtonTheme.Primary}
              onClick={props.onOpenCollaborationTextResponseModal}
              fluid
            >
              Submit Response
            </Button>
          </AuthTooltip>
        </div>
      )}
      {!isDesktopPlus && collaborationMediaItem && isOpenCreateCollaborationModal && (
        <CollaborationEditorBottomSheet
          postId={postId}
          visible={isOpenCreateCollaborationModal}
          onCloseBottomSheet={handleCloseEditor}
          onCreateCollaborationMediaItemWithText={handleCreateCollaborationImageItemWithText()}
          mediaType={media}
          convertImageItemToAttachment={convertImageItemToAttachment}
          collaborationMediaItem={collaborationMediaItem}
          setCollaborationMediaItem={props.setCollaborationMediaItem}
          convertVideoItemToAttachment={props.convertVideoItemToAttachment}
          isCollaborationVideoLoading={props.isCollaborationVideoLoading}
          isCollaborationImageLoading={props.isCollaborationImageLoading}
        />
      )}
      {isDesktopPlus && collaborationMediaItem && isOpenCreateCollaborationModal && (
        <CollaborationEditorModal
          postId={postId}
          title="Add message"
          visible={isOpenCreateCollaborationModal}
          onClose={handleCloseEditor}
          onCreateCollaborationMediaItemWithText={handleCreateCollaborationImageItemWithText()}
          mediaType={media}
          convertVideoItemToAttachment={props.convertVideoItemToAttachment}
          convertImageItemToAttachment={convertImageItemToAttachment}
          collaborationMediaItem={collaborationMediaItem}
          setCollaborationMediaItem={setCollaborationMediaItem}
          isCollaborationVideoLoading={props.isCollaborationVideoLoading}
          isCollaborationImageLoading={props.isCollaborationImageLoading}
        />
      )}
      {selectedItemId && (
        <div className={styles.FeedCollaboration__Approved}>
          <Button
            iconName={IconFontName.Confirm}
            iconSize={IconFontSize.Big}
            size={ButtonSize.Small}
            theme={ButtonTheme.Secondary}
            fluid
          >
            Response Submitted
          </Button>
        </div>
      )}
    </>
  );
};
