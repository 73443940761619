import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { AuthStore } from 'stores/auth/auth.store';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';
import { HOME_FEED } from 'routes/paths.constants';

interface IPrivateRouteProps {
  children: JSX.Element;
}

export const PersonalRoute = observer((props: PropsWithChildren<IPrivateRouteProps>) => {
  const { children } = props;
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      userPublicStore.userPublic &&
      authStore.userMe &&
      userPublicStore.userPublic.username !== authStore.userMe.username
    ) {
      navigate(HOME_FEED);
    }
  }, [authStore.userMe, navigate, userPublicStore.userPublic]);

  return children;
});
