import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import { IPlayerPerformance } from 'stores/player-performance/interfaces/player-performance.interface';

import { ENCODED_BULLET } from 'configs/encoded-symbols.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { formatPastDate } from 'helpers/format/format-past-date.util';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { ISinglePointProps, SinglePoint } from 'components/ui/single-point/single-point.component';
import {
  VectorIcon,
  VectorIconName,
  VectorIconSize,
} from 'components/ui/vector-icon/vector-icon.component';

import logotypeIcon from 'assets/images/svg/logo-light.svg';

import styles from './player-performance-profile-info.module.less';

interface IPlayerPerformanceProfileInfoProps {
  playerPerformance: IPlayerPerformance;
  onOpenArena: () => void;
  onSharePlayerPerformance: () => void;
}

export const PlayerPerformanceProfileInfo: FC<IPlayerPerformanceProfileInfoProps> = (
  props: IPlayerPerformanceProfileInfoProps,
) => {
  const { playerPerformance } = props;

  const { playerInfo, game, totalStats } = playerPerformance;

  const navigate = useNavigate();
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const playerStatsItems = useMemo<ISinglePointProps[]>(() => {
    if (playerPerformance?.totalStats) {
      return [
        {
          label: 'PTS',
          value: playerPerformance.totalStats.points,
        },
        {
          label: 'REB',
          value: playerPerformance.totalStats.totalRebounds,
        },
        {
          label: 'AST',
          value: playerPerformance.totalStats.assists,
        },
        {
          label: 'BLK',
          value: playerPerformance.totalStats.blocks,
        },
        {
          label: 'STL',
          value: playerPerformance.totalStats.steals,
        },
        {
          label: '+/-',
          value: playerPerformance.totalStats.plusMinus,
        },
      ];
    }

    return [];
  }, [playerPerformance?.totalStats]);

  const dateString = useMemo<string>(() => {
    if (game.dateStart) {
      if (game.status === GameStatus.Halftime) {
        return 'Halftime';
      }

      if (game.status === GameStatus.Live) {
        return `${game.formattedPeriod} ${game.clockFormatted}`;
      }

      return formatPastDate(game.dateStart, 'shortDay');
    }

    return '';
  }, [game.clockFormatted, game.dateStart, game.formattedPeriod, game.status]);

  const handlePlayerClick = useCallback(() => {
    navigate(
      getPath(paths.PLAYER_PROFILE, {
        [paths.PLAYER_SLUG_PARAM]: playerPerformance.playerInfo.slug,
      }),
    );
  }, [navigate, playerPerformance.playerInfo.slug]);

  const homeTeamClassNames = useMemo<string>(
    () =>
      cn({
        [styles.LeftSide__Winner]:
          game.status === GameStatus.Finished &&
          game.teams.visitors.score.points < game.teams.home.score.points,
      }),
    [game.teams, game.status],
  );

  const visitorsTeamClassNames = useMemo<string>(
    () =>
      cn({
        [styles.LeftSide__Winner]:
          game.status === GameStatus.Finished &&
          game.teams.visitors.score.points > game.teams.home.score.points,
      }),
    [game.teams, game.status],
  );

  return (
    <div className={styles.PlayerProfileInfo}>
      <div className={styles.PlayerProfileInfo__Wrapper}>
        <div className={styles.LeftSide}>
          <div className={styles.LeftSide__Scores}>
            <Avatar
              size={AvatarSize.XXS}
              username={game.teams.visitors.name || ''}
              src={game.teams.visitors.smallLogoUrl}
            />
            <button className={styles.LeftSide__Results} onClick={props.onOpenArena}>
              <span className={visitorsTeamClassNames}>{game.teams.visitors.score.points}</span>
              <span>-</span>
              <span className={homeTeamClassNames}> {game.teams.home.score.points}</span>
            </button>
            <Avatar
              size={AvatarSize.XXS}
              username={game.teams.home.name || ''}
              src={game.teams.home.smallLogoUrl}
            />
          </div>
          <div className={styles.Separator}>{ENCODED_BULLET}</div>
          <div className={styles.LeftSide__Date}>{dateString}</div>
        </div>

        <div className={styles.AvatarWrapper}>
          <button className={styles.AvatarWrapper__Avatar}>
            <Avatar
              src={playerInfo.mediumLogoUrl}
              username={playerInfo.firstname}
              size={AvatarSize.MEGA}
            />
          </button>
          {playerInfo.emojiUrl && (
            <div className={styles.AvatarWrapper__Emoji}>
              <img
                src={playerInfo.emojiUrl}
                alt={`${playerInfo.firstname}-${playerInfo.lastname}-emoji`}
              />
            </div>
          )}
        </div>

        <div className={styles.RightSide}>
          <div className={styles.RightSide__Fantasy}>
            <img src={logotypeIcon} alt="Logotype" className={styles.RightSide__Logo} />
            <div className={styles.RightSide__Points}>
              <span>{totalStats.fantasyPoints || 0}</span> FPS
            </div>
          </div>
          {playerInfo.position && playerInfo.jersey && (
            <div className={styles.Separator}>{ENCODED_BULLET}</div>
          )}
          <div className={styles.RightSide__Info}>
            {playerInfo.position && (
              <div className={styles.RightSide__Position}>{playerInfo.position}</div>
            )}
            {playerInfo.position && playerInfo.jersey && (
              <div className={styles.Separator}>{ENCODED_BULLET}</div>
            )}
            {playerInfo.jersey && (
              <div className={styles.RightSide__Jersey}>#{playerInfo.jersey}</div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.NameSection}>
        <button onClick={handlePlayerClick} className={styles.NameSection__Name}>
          <IconFont name={IconFontName.Link2} size={IconFontSize.Big} />
          {playerInfo.firstname} {playerInfo.lastname}
          {playerPerformance.isLeader && (
            <VectorIcon size={VectorIconSize.M} name={VectorIconName.Fire} />
          )}
        </button>
        {isDesktopPlus && (
          <div className={styles.NameSection__Buttons}>
            <IconButton
              iconName={IconFontName.Arena}
              onClick={props.onOpenArena}
              theme={IconButtonTheme.Transparent}
            />
            <IconButton
              iconName={IconFontName.Share}
              onClick={props.onSharePlayerPerformance}
              theme={IconButtonTheme.Transparent}
            />
          </div>
        )}
      </div>

      {!!playerStatsItems.length && (
        <div className={styles.PlayerProfileInfo__Statistics}>
          {playerStatsItems.map((item) => (
            <SinglePoint key={item.label} label={item.label} value={item.value} />
          ))}
        </div>
      )}
    </div>
  );
};
