import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { TeamContainer } from 'containers/team/team.container';
import { TeamProfileScrollControllerContainer } from 'containers/team/team-profile-scroll-controller/team-profile-scroll-controller.container';
import { WelcomePopupTeamContainer } from 'containers/welcome-popup/welcome-popup-team.container';

export const TeamRoute: FC = () => {
  return (
    <>
      <WelcomePopupTeamContainer />
      <TeamContainer />
      <TeamProfileScrollControllerContainer />
      <Outlet />
    </>
  );
};
