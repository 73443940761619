import cn from 'classnames';

import { IOption } from '../bottom-sheet-select.interface';

import styles from './item.module.less';

interface ITextItemProps<T extends IOption | null> {
  value: Maybe<T> | undefined;
  isSelected?: boolean;
  onPress: () => void;
}

export const TextItem = <T extends IOption | null>(props: ITextItemProps<T>) => {
  const { value, onPress, isSelected } = props;

  return (
    <li
      key={value?.value}
      className={cn(styles.TextItem, isSelected ? styles['TextItem--active'] : '')}
      role="tab"
      onClick={() => onPress()}
      onKeyDown={() => onPress()}
    >
      {value?.label}
    </li>
  );
};
