import { useCallback, useEffect, useState } from 'react';
import { SafeArea, SafeAreaInsets } from 'capacitor-plugin-safe-area';

interface ISafeAreaInsetsProps {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export const useRetrieveSafeAreas = () => {
  const [safeAreaInsets, setSafeAreaInsets] = useState<ISafeAreaInsetsProps>();

  const setSafeAreaInsetVariables = useCallback(
    ({ top, right, bottom, left }: ISafeAreaInsetsProps) => {
      document.documentElement.style.setProperty('--cap-safe-area-top', `${top / 10}rem`);
      document.documentElement.style.setProperty('--cap-safe-area-right', `${right / 10}rem`);
      document.documentElement.style.setProperty('--cap-safe-area-bottom', `${bottom / 10}rem`);
      document.documentElement.style.setProperty('--cap-safe-area-left', `${left / 10}rem`);
    },
    [],
  );

  useEffect(() => {
    const handleSafeAreaChanged = (data: SafeAreaInsets) => {
      const { insets } = data;
      setSafeAreaInsetVariables(insets);
      setSafeAreaInsets(insets);
    };

    const initializeSafeArea = async () => {
      const { insets } = await SafeArea.getSafeAreaInsets();
      setSafeAreaInsetVariables(insets);
      setSafeAreaInsets(insets);
    };

    const subscribeSafeAreaChanges = async () => {
      const eventListener = await SafeArea.addListener('safeAreaChanged', handleSafeAreaChanged);

      return () => {
        eventListener.remove();
      };
    };

    initializeSafeArea();
    subscribeSafeAreaChanges();
  }, [setSafeAreaInsetVariables]);

  return safeAreaInsets;
};
