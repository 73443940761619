import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { BookmarksStore } from 'stores/bookmarks/bookmarks.store';
import { CollaborationStore } from 'stores/collaboration/collaboration.store';
import { IBasePublicationAuthor } from 'stores/entries/interfaces/entries-autor.interface';
import { LayoutStore } from 'stores/layout/layout.store';
import { MetaTagsStore } from 'stores/meta-tags/meta-tags.store';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { IPost } from 'stores/posts/interfaces/post.interface';
import { PostsStore } from 'stores/posts/posts.store';
import { SharedType } from 'stores/share/enums/share-type.enum';
import { ShareStore } from 'stores/share/share.store';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { ISharePayload } from 'containers/posts/interfaces/share-payload.interface';

import { TYPES } from 'configs/di-types.config';
import { DEFAULT_SHARE_TITLE } from 'configs/share.config';
import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';

import { useMainProvider } from 'hooks/use-main-provider';
import { useNativeShare } from 'hooks/use-native-share';
import { useTeamClick } from 'hooks/use-team-click';
import { useToggleIgnoredTouchClass } from 'hooks/use-toggle-ignored-touch-class';

import { ShareModal } from 'components/modals/share-modal/share-modal.component';
import { PostCardFeedsTheme } from 'components/posts/post-card/post-card-feeds/post-card-feeds.component';
import { PostsFeed } from 'components/posts/posts-feed/posts-feed.component';
import { Loader } from 'components/ui/loader/loader.component';

export const GameVideosContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);
  const bookmarksStore = useInjection<BookmarksStore>(TYPES.BookmarksStore);
  const shareStore = useInjection<ShareStore>(TYPES.ShareStore);
  const metaTagsStore = useInjection<MetaTagsStore>(TYPES.MetaTagsStore);
  const collaborationStore = useInjection<CollaborationStore>(TYPES.CollaborationStore);

  const navigate = useNavigate();
  const { isNativeApp } = useMainProvider();

  useEffect(() => {
    layoutStore.setActiveFeed(FeedTypes.GameVideos);
  }, [layoutStore]);

  const [sharePublicationData, setSharePublicationData] = useState<Maybe<IPost>>(null);

  const { shareByNativeModal } = useNativeShare();

  useToggleIgnoredTouchClass(postsStore.fetching);

  const isBottomLoading = useMemo(() => {
    return !!postsStore.fetchingState && postsStore.isLoadMore;
  }, [postsStore.fetchingState, postsStore.isLoadMore]);

  const handleLoadMore = useCallback(() => {
    postsStore.fetchToLoadMorePosts();
  }, [postsStore]);

  const handleOpenUserDetailsPopUp = useCallback(
    (userData: IBasePublicationAuthor) => {
      postsStore.setUserDetails(userData);
      layoutStore.setIsUserDetailsPopUpOpen(true);
    },
    [postsStore, layoutStore],
  );

  const handleDeletePost = useCallback(
    async (id: string) => {
      const isAuth = authStore.triggerAuthorisationCheck();

      if (isAuth) {
        await postsStore.deletePost({ postId: id });
        await bookmarksStore.refresh();
      }
    },
    [authStore, bookmarksStore, postsStore],
  );

  const handleTogglePostToBookmark = useCallback(
    async (postId: string) => {
      if (!authStore.isAuthorised) return;

      await postsStore.togglePostBookmark({ id: postId });

      await bookmarksStore.refresh();
    },
    [authStore, bookmarksStore, postsStore],
  );

  const handleCopySharedLink = useCallback(() => {
    shareStore.copyLink();
  }, [shareStore]);

  const handleShowShareModal = useCallback(
    async (payload: ISharePayload) => {
      await shareStore.fetchSharePublicationData(payload.postId);

      if (shareStore.shareData) {
        postsStore.updatePostShareCount(payload.postId, shareStore.shareData.count);
      }

      if (isNativeApp && shareStore.shareData?.type === SharedType.Post) {
        shareByNativeModal(
          shareStore.shareData.link,
          shareStore.shareData.title || metaTagsStore.metaTags?.title || DEFAULT_SHARE_TITLE,
        );
        return;
      }

      if (!isNativeApp) {
        const currentPost = postsStore.getPostById(payload.postId);

        setSharePublicationData(currentPost);
        shareStore.setIsShareModalVisible(true);
      }
    },
    [isNativeApp, postsStore, shareStore, metaTagsStore.metaTags, shareByNativeModal],
  );

  const handleResetSharing = useCallback(() => {
    shareStore.reset();
    setSharePublicationData(null);
  }, [shareStore]);

  const handlePostLike = useCallback(
    async (postId: string) => {
      if (!authStore.isAuthorised) return;

      await postsStore.postLikes({
        postId,
      });
    },
    [authStore, postsStore],
  );

  const handleGetFavoriteTeam = useCallback((): Maybe<ITeamsStats> => {
    return null;
  }, []);

  const handleGetFavoritePlayer = useCallback((): Maybe<IPlayerStats> => {
    return null;
  }, []);

  const handleTeamClick = useTeamClick();

  const handlePlayerClick = useCallback(
    (slug: string) => {
      navigate(getPath(path.PLAYER_PROFILE, { [path.PLAYER_SLUG_PARAM]: slug }));
    },
    [navigate],
  );

  const handleRemovePostFromEntries = useCallback(
    (postId: string) => {
      if (!postsStore.isSubmittedContentReport) {
        return;
      }

      postsStore.setSubmittedContentReport(false);
      postsStore.removePostFromEntries(postId);
    },
    [postsStore],
  );

  const handleReportSubmit = useCallback(
    async (postId: string, reasonId: number) => {
      return postsStore.sendPostReport(postId, reasonId);
    },
    [postsStore],
  );

  useEffect(() => {
    if (layoutStore.isPulledRefresher) {
      postsStore.forceFetchToRefreshFeed();
    }
  }, [layoutStore.isPulledRefresher, postsStore]);

  return (
    <>
      <PostsFeed
        requestReasons={applicationStore.retrieveReportReasons}
        onLikeClick={handlePostLike}
        onOpenUserDetailsPopUp={handleOpenUserDetailsPopUp}
        feedsTheme={PostCardFeedsTheme.Collapse}
        headlines={[]}
        playerContentCards={[]}
        gameContentCards={[]}
        reportReasons={applicationStore.reportReasons}
        isPulledRefresher={layoutStore.isPulledRefresher}
        userId={authStore?.userMe?.id || null}
        isAthlete={authStore.isAthlete}
        hasMore={!postsStore.fetchingState && !postsStore.isLastPageState}
        entries={postsStore.posts}
        team={null}
        fetched={!!postsStore.fetchedState}
        fetching={!!postsStore.fetchingState}
        visitedPostId={null}
        activeFeed={layoutStore.activeFeed}
        onLoadMore={handleLoadMore}
        onTogglePostToBookmarks={handleTogglePostToBookmark}
        onShareClick={handleShowShareModal}
        onDeletePost={handleDeletePost}
        onGetFavoriteTeam={handleGetFavoriteTeam}
        onGetFavoritePlayer={handleGetFavoritePlayer}
        onTeamClick={handleTeamClick}
        onPlayerClick={handlePlayerClick}
        onSendReport={handleReportSubmit}
        onCloseReportsPopup={handleRemovePostFromEntries}
        postProcessingId={null}
        convertImageItemToAttachment={collaborationStore.uploadCollaborationImage}
        onCreateCollaborationItem={collaborationStore.handleCreateCollaborationItem}
        collaborationMediaItem={null}
        setCollaborationMediaItem={collaborationStore.setNewCollaborationItemMedia}
      />
      <Loader isShow={isBottomLoading} isLocal />
      {!isNativeApp && sharePublicationData && shareStore.shareData?.type === SharedType.Post && (
        <ShareModal
          id={sharePublicationData.uuid}
          author={sharePublicationData.user}
          onClose={handleResetSharing}
          onCopyLink={handleCopySharedLink}
          shareContent={{
            pollOptions: shareStore.shareData.pollOptions,
            title: shareStore.shareData.title,
            attachments: shareStore.shareData.attachmentCounts,
            dateCreated: sharePublicationData.formattedDates.relativeLong,
            content: shareStore.shareData.content,
          }}
          visible={shareStore.isShareModalVisible}
        />
      )}
    </>
  );
});
