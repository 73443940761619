import { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { Gif } from '@giphy/react-components';
import cn from 'classnames';

import { GIPHY_KEY } from 'configs/environment.config';

import styles from './single-gif.module.less';

interface ISingleGifProps {
  onGifClick?: (gif: IGif) => void;
  id: string;
  size: GifSize;
  isNeedGifIcon?: boolean;
}

const giphyFetch = new GiphyFetch(GIPHY_KEY);

export enum GifSize {
  XS = 38,
  S = 64,
  M = 112,
  L = 302,
}

export const SingleGif: FC<ISingleGifProps> = (props) => {
  const { id, size, onGifClick, isNeedGifIcon = false } = props;

  const [gif, setGif] = useState<IGif | null>(null);

  useEffect(() => {
    (async () => {
      const { data } = await giphyFetch.gif(id);

      setGif(data);
    })();
  }, [id]);

  const handleGifClick = useCallback(
    (gifId: IGif, event: SyntheticEvent) => {
      if (onGifClick) {
        onGifClick(gifId);
      }

      event.preventDefault();
    },
    [onGifClick],
  );

  const SingleGifClasses = useMemo<string>(() => {
    return cn(styles.SingleGif, {
      [styles['SingleGif--extra-small']]: size === GifSize.XS,
      [styles['SingleGif--small']]: size === GifSize.S,
      [styles['SingleGif--medium']]: size === GifSize.M,
      [styles['SingleGif--large']]: size === GifSize.L,
    });
  }, [size]);

  const GifIconClassNames = useMemo(
    () =>
      cn(styles.GifIcon, {
        [styles['GifIcon--visible']]: isNeedGifIcon,
      }),
    [isNeedGifIcon],
  );

  return (
    gif && (
      <div className={SingleGifClasses}>
        <div className={GifIconClassNames} />
        <Gif hideAttribution onGifClick={handleGifClick} gif={gif} width={size} />{' '}
      </div>
    )
  );
};
