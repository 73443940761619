import { ChangeEvent, FC, useCallback } from 'react';

import { IFormField } from 'components/ui/form-fields/form-field.interface';

import styles from './radio-button.module.less';

interface IRadioItem {
  label: string;
  value: string;
  id: string;
}

interface IOnChangeCallback {
  (event: ChangeEvent<HTMLInputElement>): void;
}

interface IRadioButtonProps extends IFormField {
  items: Array<IRadioItem>;
  title: string;
  value: string;
  onChange: (id: string) => void;
}

export const RadioButton: FC<IRadioButtonProps> = (props: IRadioButtonProps) => {
  const { onChange, items, title, value, disabled } = props;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange && event?.target) {
        onChange(event.target.value);
      }
    },
    [onChange],
  );

  const renderSingleInput = (
    item: IRadioItem,
    name: string,
    handleOnChange: IOnChangeCallback,
    selectedId: string,
  ) => {
    const { id, value: itemValue, label } = item;

    return (
      <div key={id} className={styles.RadioButtonWrapper__Item}>
        <input
          id={id}
          name={name}
          onChange={handleOnChange}
          value={itemValue}
          disabled={disabled}
          type="radio"
          checked={selectedId === itemValue}
          className={styles.RadioButtonWrapper__Input}
        />
        <label className={styles.RadioButtonWrapper__Label} htmlFor={id}>
          {label}
        </label>
      </div>
    );
  };

  return (
    <div className={styles.RadioButtonWrapper}>
      <div className={styles.RadioButtonWrapper__Title}>{title}:</div>
      {items.length &&
        items.map((item: IRadioItem) => {
          return renderSingleInput(item, 'radio', handleChange, value);
        })}
    </div>
  );
};
