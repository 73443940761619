import { FC } from 'react';
import { Outlet } from 'react-router';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { PlayerProfileContainer } from 'containers/player-profile/player-profile.container';
import { PlayerProfileScrollControllerContainer } from 'containers/player-profile/player-profile-scroll-controller/player-profile-scroll-controller.container';
import { WelcomePopupPlayerProfileContainer } from 'containers/welcome-popup/welcome-popup-player-profile.container';

export const PlayerProfileRoute: FC = () => (
  <PageWrapperContainer>
    <WelcomePopupPlayerProfileContainer />
    <PlayerProfileContainer />
    <PlayerProfileScrollControllerContainer />
    <Outlet />
  </PageWrapperContainer>
);
