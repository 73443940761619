import { v4 } from 'uuid';

import { POST_ID_API_PARAM } from 'services/http/consts/api-endpoint-params.constants';
import { POSTS_NEWS_IMAGE_PREVIEW } from 'services/http/consts/api-endpoints.constants';
import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';
import { IPostSource, ISourceAuthor } from 'services/posts/interfaces/post-source.interface';
import {
  IPostSourceResponse,
  ISourceAuthorResponse,
} from 'services/posts/interfaces/post-source-response.interface';
import {
  FeedsResponseType,
  IHomeFeedResponse,
  IPlayerFeedResponse,
  IPostResponse,
  ITeamFeedResponse,
  IUserResponse,
  PostInteractionType,
  PostInteractionTypeValues,
} from 'services/posts/interfaces/posts-response.interface';

import { userProfileAthleteAdapter } from 'stores/auth/adapters/user-profile-athlete-adapter.util';
import { collaborationAdapter } from 'stores/collaboration/adapters/collaboration-adapter.util';
import { IBasePublicationAuthor } from 'stores/entries/interfaces/entries-autor.interface';
import { gifAdapter } from 'stores/posts/adapters/gif-adapter.util';

import { formatPastDate } from 'helpers/format/format-past-date.util';
import { formatUsername } from 'helpers/format-username.util';
import { getPath } from 'helpers/get-path.util';

import {
  IFeeds,
  IGifAttachment,
  IHomeFeed,
  IIframeAttachment,
  IImageAttachment,
  ILinkAttachment,
  IPlayerFeed,
  IPollAttachment,
  IPost,
  ITeamFeed,
  IVideoAttachment,
} from '../interfaces/post.interface';

import { iframeAdapter } from './iframe-adapter.util';
import { imageAdapter } from './image-adapter.util';
import { linkAdapter } from './link-adapter.util';
import { pollAdapter } from './poll-adapter.util';
import { videoAdapter } from './video-adapter.util';

export function publicationAdapter(
  postResponse: IPostResponse,
  actions?: PostInteractionType[],
): IPost {
  const {
    comments_allowed: isCommentsAllowed,
    likes_total: likes,
    is_liked: isLiked,
    is_bookmarked: isBookmarked,
    title,
    uuid,
    content,
    date_created: timestamp,
    comments_amount: commentsAmount,
    bookmarks_count: bookmarksCount,
    shares_count: sharesCount,
    user,
    attachments,
    source,
    feeds,
    collaboration,
  } = postResponse;

  const convertedImages: Maybe<IImageAttachment[]> = attachments.images.length
    ? attachments.images.map(imageAdapter)
    : null;

  const convertedVideos: Maybe<IVideoAttachment[]> = attachments.videos.length
    ? attachments.videos.map(videoAdapter)
    : null;

  const convertedGifs: Maybe<IGifAttachment[]> = attachments.gifs.length
    ? attachments.gifs.map(gifAdapter)
    : null;

  const convertedIframes: Maybe<IIframeAttachment[]> = attachments.iframes?.length
    ? attachments.iframes.map(iframeAdapter)
    : null;

  const convertedPoll: Maybe<IPollAttachment> = attachments.poll
    ? { ...pollAdapter(attachments.poll) }
    : null;

  const convertedLinks: ILinkAttachment[] = attachments.links.length
    ? attachments.links.map(linkAdapter)
    : [];

  const isExistMediaAttachments: boolean = !!(
    convertedImages?.length ||
    convertedVideos?.length ||
    convertedGifs?.length ||
    convertedIframes?.length
  );

  const filteredLinks: ILinkAttachment[] = convertedLinks.map((item) => {
    const updatedItem = { ...item };

    if (isExistMediaAttachments) {
      updatedItem.isEmbedded = false;
    }

    return updatedItem;
  });

  const embeddedLinks = isExistMediaAttachments
    ? []
    : filteredLinks.filter((item) => item.isEmbedded);

  if (source?.channel === 'tiktok') {
    embeddedLinks.unshift({
      embeddedType: 'tiktok',
      isEmbedded: true,
      url: source.url,
      sourceFaviconUrl: null,
      sourceName: null,
      uuid: v4(),
      title: null,
      previewImageUrl: null,
    });
  }

  const notEmbeddedLinks = isExistMediaAttachments
    ? convertedLinks
    : convertedLinks.filter((item) => !item.isEmbedded);

  const parsedFeeds = feedsAdapter(feeds);
  const defaultFeedTitle = getFeedName(parsedFeeds.feed);
  const actionsList = getActionsList(actions);

  return {
    likes,
    isLiked,
    isCommentsAllowed,
    isBookmarked,
    bookmarksCount,
    sharesCount,
    formattedDates: {
      timeOnly: formatPastDate(timestamp, 'timeOnly'),
      relativeLong: formatPastDate(timestamp, 'relativeLong'),
      relativeShort: formatPastDate(timestamp, 'relativeShort'),
      full: formatPastDate(timestamp),
    },
    commentsAmount,
    uuid,
    title,
    content,
    user: publicationUserAdapter(user),
    attachments: {
      images: convertedImages,
      poll: convertedPoll,
      videos: convertedVideos,
      gifs: convertedGifs,
      links: notEmbeddedLinks,
      iframes: convertedIframes,
    },
    embeddedLink: embeddedLinks.length ? embeddedLinks[0] : null,
    source: source ? postSourceAdapter(source, uuid) : null,
    feeds: parsedFeeds,
    collaboration: collaboration ? collaborationAdapter(collaboration) : null,
    defaultFeedTitle: defaultFeedTitle || undefined,
    actionsList: actionsList || undefined,
  };
}

function feedsAdapter(feeds: Maybe<FeedsResponseType>): IFeeds {
  const preparedFeeds: IFeeds = {
    teams: [],
    players: [],
    feed: null,
  };

  if (!feeds) return preparedFeeds;

  feeds.forEach((feed) => {
    if (!feed.data) return;

    if (feed.data.type === PostsFeedType.Player) {
      preparedFeeds.players.push(playerFeedAdapter(feed.data));
    }

    if (feed.data.type === PostsFeedType.Team) {
      preparedFeeds.teams.push(teamFeedAdapter(feed.data));
    }

    if (feed.is_default && feed.data.type === PostsFeedType.Player) {
      preparedFeeds.feed = playerFeedAdapter(feed.data);
    }

    if (feed.is_default && feed.data.type === PostsFeedType.Team) {
      preparedFeeds.feed = teamFeedAdapter(feed.data);
    }

    if (feed.is_default && feed.data.type === PostsFeedType.Home) {
      preparedFeeds.feed = homeFeedAdapter(feed.data);
    }
  });

  return preparedFeeds;
}

function playerFeedAdapter(feed: IPlayerFeedResponse): IPlayerFeed {
  return {
    type: PostsFeedType.Player,
    playerId: feed.player_id,
    slug: feed.slug,
    firstname: feed.firstname,
    lastname: feed.lastname,
    smallLogoUrl: feed.small_logo_url,
    mediumLogoUrl: feed.medium_logo_url,
  };
}

function teamFeedAdapter(feed: ITeamFeedResponse): ITeamFeed {
  return {
    type: PostsFeedType.Team,
    teamId: feed.team_id,
    name: feed.name,
    code: feed.code,
    cityName: feed.city_name,
    smallLogoUrl: feed.small_logo_url,
    mediumLogoUrl: feed.medium_logo_url,
  };
}

function homeFeedAdapter(feed: IHomeFeedResponse): IHomeFeed {
  return {
    type: feed.type,
  };
}

function postSourceAuthorAdapter(authorResponse: ISourceAuthorResponse): ISourceAuthor {
  const { name, username, profile_image_url: profileImage } = authorResponse;

  return {
    name,
    username: username ? formatUsername(username) : null,
    profileImage,
  };
}

export function publicationUserAdapter(userResponse: IUserResponse): IBasePublicationAuthor {
  const {
    small_avatar_url: smallAvatarUrl,
    small_thumbnail_url: smallThumbnailUrl,
    thumbnail_nickname_url: thumbnailNicknameUrl,
    thumbnail_logo_url: thumbnailLogoUrl,
    uuid,
    avatar_url: avatarUrl,
    real_name: realName,
    username,
    favorite_team_id: favoriteTeamId,
    favorite_player_id: favoritePlayerId,
    athlete,
  } = userResponse;

  return {
    smallAvatarUrl,
    smallThumbnailUrl,
    thumbnailNicknameUrl,
    thumbnailLogoUrl,
    uuid,
    avatarUrl,
    name: realName ?? username,
    username: `@${username}`,
    favoriteTeamId,
    favoritePlayerId,
    athlete: athlete ? userProfileAthleteAdapter(athlete) : null,
  };
}

function postSourceAdapter(postSourceResponse: IPostSourceResponse, postId: string): IPostSource {
  const { channel, favicon, type, url, has_show_more: hasShowMore, author } = postSourceResponse;

  return {
    channel,
    favicon,
    type,
    url,
    imagePreview: getPath(POSTS_NEWS_IMAGE_PREVIEW, {
      [POST_ID_API_PARAM]: postId,
    }),
    hasShowMore,
    author: author ? postSourceAuthorAdapter(author) : null,
  };
}

const getFeedName = (feed: Maybe<IHomeFeed | IPlayerFeed | ITeamFeed>) => {
  if (!feed) {
    return 'Social Feed';
  }

  if (feed.type === 'team') {
    return `${feed.name} Fan Zone`;
  }

  if (feed.type === 'player') {
    return `${feed.firstname} ${feed.lastname} Fan Zone`;
  }

  return `Home Feed`;
};

function convertEnumToOpposite(enumValue: string): string {
  switch (enumValue) {
    case PostInteractionType.created:
      return 'Created Post';
    case PostInteractionType.liked:
      return 'Liked';
    case PostInteractionType.voted:
      return 'Voted';
    case PostInteractionType.discussed:
      return 'Discussed';
    case PostInteractionType.participated:
      return 'Submitted';
    default:
      return '';
  }
}

const getActionsList = (actions?: PostInteractionTypeValues[] | null) => {
  if (!actions || !Array.isArray(actions)) {
    return '';
  }

  let convertedOpposites = actions.map(convertEnumToOpposite).join(', ');

  if (convertedOpposites.endsWith(', ')) {
    convertedOpposites = convertedOpposites.slice(0, convertedOpposites.length - 2);
  }

  return convertedOpposites;
};
