import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { format } from 'date-fns';

import { IGame } from 'services/statistics/interface/game.interface';

import { STATISTICS_ELEMENT_ID } from 'configs/controls.config';
import { DAY_FORMAT } from 'configs/date.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { ArenaGameItem } from '../arena-game-item/arena-game-item.component';
import { GameHeader } from '../game-header/game-header.component';
import { StatisticsDayItem } from '../statistics-day-item/statistics-day-item.component';
import { StatisticsTeam } from '../statistics-team/statistics-team.component';
import { StatisticsType } from '../statistics-type.enum';

import styles from './statistics-item.module.less';

const DESKTOP_OFFSET_DISTANCE = 4;

interface IStatisticsItemProps {
  isFirstGame: boolean;
  isFirstGameToday: boolean;
  isSelectedArenaGame: boolean;
  expanded: boolean;
  type: StatisticsType;
  game: IGame;
  onGameClick: (gameId: number, opponents?: string) => void;
}

export const StatisticsItem: FC<IStatisticsItemProps> = memo((props: IStatisticsItemProps) => {
  const { isFirstGame, isFirstGameToday, isSelectedArenaGame, expanded, type, game, onGameClick } =
    props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [position, setPosition] = useState(0);

  const itemElementRef = useRef<HTMLDivElement>(null);

  const dateAndTime = useMemo(
    () => ({
      date: format(new Date(game.dateStart), DAY_FORMAT),
      time: format(new Date(game.dateStart), 'p'),
      day: format(new Date(game.dateStart), 'E'),
    }),
    [game.dateStart],
  );

  const homeIsWinning = useMemo(
    () => game.teams.home.score.points > game.teams.visitors.score.points,
    [game.teams.home.score.points, game.teams.visitors.score.points],
  );

  const isEqualPoints = useMemo(
    () => game.teams.home.score.points === game.teams.visitors.score.points,
    [game.teams.home.score.points, game.teams.visitors.score.points],
  );

  const offsetDistance = useMemo(() => {
    if (isDesktopPlus) {
      return DESKTOP_OFFSET_DISTANCE;
    }

    return 0;
  }, [isDesktopPlus]);

  const handleGameClick = useCallback(() => {
    onGameClick(game.id, `${game.teams.visitors.code} vs ${game.teams.home.code}`);
  }, [game, onGameClick]);

  useEffect(() => {
    if (StatisticsType.ShortcutBar === type || StatisticsType.Arena === type) {
      const itemElement = itemElementRef.current;
      const parentElement = itemElement?.closest(`#${STATISTICS_ELEMENT_ID}`);

      if (parentElement && itemElement) {
        const itemPosition = itemElement.getBoundingClientRect().x;
        const parentPosition = parentElement.getBoundingClientRect().x;

        const offset = itemPosition - parentPosition;

        setPosition((prevState) => (prevState === 0 ? offset : prevState));

        parentElement.scrollTo(position - offsetDistance, 0);
      }
    }
  }, [type, position, offsetDistance, expanded]);

  if (type === StatisticsType.Arena && !isDesktopPlus) {
    return (
      <ArenaGameItem
        ref={isSelectedArenaGame ? itemElementRef : null}
        game={game}
        selected={isSelectedArenaGame}
        homeIsWinning={homeIsWinning}
        isEqualPoints={isEqualPoints}
        onGameClick={handleGameClick}
      />
    );
  }

  return (
    <>
      {isFirstGame && (
        <StatisticsDayItem
          ref={isFirstGameToday ? itemElementRef : null}
          date={dateAndTime.date}
          day={dateAndTime.day}
        />
      )}
      <div ref={isSelectedArenaGame ? itemElementRef : null}>
        <button className={styles.GameInfo} onClick={handleGameClick}>
          <GameHeader
            time={dateAndTime.time}
            quarter={game.formattedPeriod}
            gameStatus={game.status}
          />
          <div className={styles.GameInfo__Content}>
            <StatisticsTeam
              gameStatus={game.status}
              team={game.teams.visitors}
              isWinning={!homeIsWinning && !isEqualPoints}
            />
            <StatisticsTeam
              isHomeGame
              gameStatus={game.status}
              team={game.teams.home}
              isWinning={homeIsWinning}
            />
          </div>
        </button>
      </div>
    </>
  );
});
