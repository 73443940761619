export enum FeedTypes {
  YourFeed = 'YourFeed',
  Headlines = 'Headlines',
  Player = 'Player',
  Team = 'Team',
  TeamFanZone = 'TeamFanZone',
  GlobalFavorites = 'GlobalFavorites',
  GlobalFanZone = 'GlobalFanZone',
  GlobalFanZoneFavorites = 'GlobalFanZoneFavorites',
  PlayerFanZone = 'PlayerFanZone',
  PlayerFanZoneFavorites = 'PlayerFanZoneFavorites',
  GameVideos = 'GameVideos',
  PublicUser = 'PublicUser',
}
