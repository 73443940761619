import { memo, useCallback } from 'react';
import { ICellRendererParams } from 'ag-grid-community';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './player-name-cell.module.less';

interface IPlayerNameProps extends ICellRendererParams {
  onPlayerClick: (slug: string) => void;
}

export const PlayerNameCell = memo((props: IPlayerNameProps) => {
  const { column, value, onPlayerClick } = props;

  const handlePlayerClick = useCallback(() => {
    if (value) {
      onPlayerClick(value.slug);
    }
  }, [onPlayerClick, value]);

  if (value) {
    const { avatarUrl, fullName } = value;

    if (column?.getColId() === 'player') {
      return (
        <button className={styles.Player} onClick={handlePlayerClick}>
          <Avatar size={AvatarSize.XS} username={fullName} src={avatarUrl} />
          <span className={styles.Player__Name}>{fullName}</span>
        </button>
      );
    }
  }

  return value;
});
