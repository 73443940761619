import { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { PlayerRankingsCategoryEnum } from 'services/player-rankings/interfaces/player-rankings-response.interface';

import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutStore } from 'stores/layout/layout.store';
import { getRankByCategory } from 'stores/player-rankings/helpers/getRankByCategory.util';
import { ICategoryPlayerRankingsItem } from 'stores/player-rankings/interfaces/player-rankings.interface';
import { PlayerRankingsStore } from 'stores/player-rankings/player-rankings.store';
import { ShareStore } from 'stores/share/share.store';

import {
  NO_RANKINGS_MESSAGE,
  NO_RANKINGS_TITLE,
  RANKING_CATEGORIES,
} from 'containers/player-rankings/player-rankings.config';

import { SCROLL_TOP_ELEMENT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';
import { formatISODate } from 'helpers/format/format-iso-date.util';
import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';

import { useInfiniteScroll } from 'hooks/use-infinite-scroll';
import { useLayoutEntity } from 'hooks/use-layout-entity';
import { useMainProvider } from 'hooks/use-main-provider';
import { useNativeShare } from 'hooks/use-native-share';
import { useResponsive } from 'hooks/use-responsive';

import { EmptyState } from 'components/empty-state/empty-state.component';
import { ShareRankingsModal } from 'components/modals/share-rankings-modal/share-rankings-modal.component';
import { PlayerRankingItem } from 'components/player-rankings/player-rankings-item/player-rankings-item.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import {
  IconButtonWithText,
  IconButtonWithTextSize,
  IconButtonWithTextTheme,
} from 'components/ui/icon-button-with-text/icon-button-with-text.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';
import {
  PlayerRankingsAvatar,
  PlayerRankingsAvatarSize,
} from 'components/ui/player-rankings-avatar/player-rankings-avatar.component';

import styles from './player-rankings-details.module.less';

const PLAYERS_AMOUNT_BEFORE_PRE_LOAD = 20;

export const PlayerRankingsDetailsContainer: FC = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const shareStore = useInjection<ShareStore>(TYPES.ShareStore);
  const playerRankingsStore = useInjection<PlayerRankingsStore>(TYPES.PlayerRankingsStore);

  const navigate = useNavigate();

  const { shareByNativeModal } = useNativeShare();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const { isNativeApp } = useMainProvider();

  const params = useParams<{
    [path.CATEGORY_PARAM]: string;
  }>();

  const { category } = params;

  const categoryTitle = useMemo(
    () => RANKING_CATEGORIES.find((item) => item.key === category)?.label || '',
    [category],
  );

  const location = useLocation();

  const handleBackClick = useCallback(() => {
    if (location.key === 'default') {
      navigate(path.HOME);
    } else {
      navigate(-1);
    }
  }, [navigate, location]);

  const dateText = useMemo(() => {
    return playerRankingsStore.meta?.meta?.date
      ? formatISODate(playerRankingsStore.meta.meta.date)
      : '';
  }, [playerRankingsStore?.meta?.meta?.date]);

  useEffect(() => {
    if (layoutStore.isPulledRefresher) {
      playerRankingsStore.fetchSingleCategoryByPullToRefresh();
    }
  }, [playerRankingsStore, layoutStore.isPulledRefresher]);

  const renderHeader = useCallback(() => {
    return (
      <div className={styles.CustomSection}>
        <div className={styles.CustomSection__Title}>{categoryTitle}</div>
        <div className={styles.CustomSection__Description}>{dateText}</div>
      </div>
    );
  }, [categoryTitle, dateText]);

  useLayoutEntity({
    type: LayoutEntity.HeaderCenter,
    value: renderHeader,
  });

  useEffect(() => {
    if (category) {
      playerRankingsStore.setFilter(category);
    }
  }, [playerRankingsStore, category]);

  const handlePlayerClick = useCallback(
    (slug: string, gameId: string) => {
      navigate(
        getPath(path.PLAYER_PERFORMANCE, {
          [path.GAME_ID_PARAM]: gameId,
          [path.PLAYER_SLUG_PARAM]: slug,
        }),
      );
    },
    [navigate],
  );

  const handleGameClick = useCallback(
    (gameId: string) => {
      navigate(
        getPath(path.ARENA_GAME, {
          [path.GAME_ID_PARAM]: gameId,
        }),
      );
    },
    [navigate],
  );

  const handleCopySharedLink = useCallback(() => {
    shareStore.copyLink();
  }, [shareStore]);

  const handleResetSharing = useCallback(() => {
    shareStore.reset();
  }, [shareStore]);

  const handleLoadMore = useCallback(async () => {
    await playerRankingsStore.fetchNext();
  }, [playerRankingsStore]);

  const hasMore = useMemo(
    () => !playerRankingsStore.fetching && !playerRankingsStore.isLastPage,
    [playerRankingsStore.fetching, playerRankingsStore.isLastPage],
  );

  const setIntersectionObserver = useInfiniteScroll(hasMore, handleLoadMore);

  useEffect(() => {
    const topElement = document.getElementById(SCROLL_TOP_ELEMENT)!;

    const timeout = setTimeout(() => {
      topElement.scrollIntoView({ behavior: 'smooth' });
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleSharePlayersRankingsDetails = useCallback(async () => {
    if (category) {
      await shareStore.fetchSharePlayersRankingsDetails(category);

      if (isNativeApp && shareStore.shareData) {
        shareByNativeModal(shareStore.shareData.link, categoryTitle);
      } else {
        shareStore.setIsShareModalVisible(true);
      }
    }
  }, [category, categoryTitle, isNativeApp, shareStore, shareByNativeModal]);

  const infoSectionClasses = useMemo(() => cn(styles.Info, TOUCH_IGNORE_CLASS), []);

  return (
    <div className={styles.PlayerRankingsDetails}>
      {isDesktopPlus && (
        <div className={styles.PlayerRankingsDetails__Header}>
          <div className={styles.LeftSide}>
            <IconButtonWithText
              theme={IconButtonWithTextTheme.Tertiary}
              onClick={handleBackClick}
              iconName={IconFontName.ChevronLeft}
              text="Back"
              size={IconButtonWithTextSize.Small}
            />
            <div className={styles.LeftSide__Title}>{categoryTitle}</div>
            <div className={styles.LeftSide__Date}>{dateText}</div>
          </div>
          <IconButton
            iconName={IconFontName.Share}
            onClick={handleSharePlayersRankingsDetails}
            theme={IconButtonTheme.Transparent}
          />
        </div>
      )}
      {playerRankingsStore.fetched && !playerRankingsStore.entries.length && (
        <EmptyState title={NO_RANKINGS_TITLE} isBlackBackground message={NO_RANKINGS_MESSAGE} />
      )}
      {!!playerRankingsStore.entries.length && (
        <div className={styles.PlayerRankingsDetails__List}>
          <div className={styles.Avatars}>
            {category &&
              playerRankingsStore.entries.map((item, index: number) => (
                <div
                  ref={
                    playerRankingsStore.entries.length < index + PLAYERS_AMOUNT_BEFORE_PRE_LOAD
                      ? setIntersectionObserver
                      : undefined
                  }
                  key={item.playerInfo.slug}
                  className={styles.PlayerRankingsDetails__Item}
                >
                  <PlayerRankingsAvatar
                    rank={getRankByCategory<ICategoryPlayerRankingsItem>(
                      item,
                      category as PlayerRankingsCategoryEnum,
                    )}
                    size={PlayerRankingsAvatarSize.SIZE_48}
                    iconUrl={item.playerInfo.smallLogoUrl}
                    forDetails
                    teamLogoUrl={item.team.smallLogoUrl}
                    playerName={item.playerInfo.fullName}
                    player={item}
                    onGameClick={handleGameClick}
                    onPlayerClick={handlePlayerClick}
                  />
                </div>
              ))}
          </div>
          <div className={infoSectionClasses}>
            {category &&
              playerRankingsStore.entries.map((item) => (
                <PlayerRankingItem
                  key={item.playerInfo.slug}
                  categorySlug={category as PlayerRankingsCategoryEnum}
                  onPlayerClick={handlePlayerClick}
                  player={item}
                />
              ))}
          </div>
        </div>
      )}
      {playerRankingsStore.fetching && (
        <Loader isLocal={!!playerRankingsStore.entries.length} isShow />
      )}
      {!isNativeApp && shareStore.isShareModalVisible && (
        <ShareRankingsModal
          title={categoryTitle}
          visible={shareStore.isShareModalVisible}
          onCopyLink={handleCopySharedLink}
          onClose={handleResetSharing}
        />
      )}
    </div>
  );
});
