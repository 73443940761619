import { IBucket } from 'stores/buckets/interfaces/bucket.interface';

import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';

export const bucketOptionAdapter = (bucket: IBucket): ISelectOption & { logo: string } => {
  const { id, data } = bucket;

  return {
    label: data.name,
    value: String(id),
    logo: data.logoSrc,
  };
};
