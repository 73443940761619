import { useCallback, useMemo } from 'react';
import cn from 'classnames';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './performer-card.module.less';

interface IPerformerCardProps {
  name: string;
  url: string;
  points: number;
  position: Maybe<string>;
  label: string;
  isLast?: boolean;
  slug: string;
  onPlayerClick: (slug: string) => void;
}

export const PerformerCard = (props: IPerformerCardProps) => {
  const { name, url, points, label, isLast, position, slug, onPlayerClick } = props;

  const itemClassNames = useMemo(
    () =>
      cn(styles.CardWrapper, {
        [styles['CardWrapper--last']]: isLast,
      }),
    [isLast],
  );

  const handlePlayerClick = useCallback(() => {
    onPlayerClick(slug);
  }, [onPlayerClick, slug]);

  return (
    <div className={itemClassNames}>
      <div className={styles.CardWrapper__Label}>{label}</div>
      <button className={styles.CardWrapper__Profile} onClick={handlePlayerClick}>
        <Avatar size={AvatarSize.L} username={name} src={url} />
        <div className={styles.PlayerInfo}>
          <div className={styles.PlayerInfo__FirstRow}>
            <div className={styles.Name}>{name}</div>
            <div className={styles.Position}>{position}</div>
          </div>
          <div className={styles.PlayerInfo__Points}>{points}</div>
        </div>
      </button>
    </div>
  );
};
