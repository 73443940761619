export enum BarActionsListType {
  Home = 'Home',
  Scores = 'Scores',
  Favorites = 'Favorites',
  // submenu global
  Rankings = 'Rankings',
  Stats = 'Stats',
  Standings = 'Standings',
  // submenu team & players
  Feed = 'Feed',
  Profile = 'Profile',
  PlayerStats = 'PlayerStats',
  TeamScores = 'TeamScores',
}
