import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { FollowStore } from 'stores/follow/follow.store';
import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutHeaderLeftActionEnum } from 'stores/layout/interfaces/layout-header-left.interface';
import { LayoutStore } from 'stores/layout/layout.store';
import { PlayerStore } from 'stores/player/player.store';

import { TYPES } from 'configs/di-types.config';
import * as paths from 'routes/paths.constants';

import { useLayoutEntity } from 'hooks/use-layout-entity';

import { PlayerProfileHeader } from 'components/player-profile/player-profile-header/player-profile-header.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const PlayerProfileContainer = observer(() => {
  const playerStore = useInjection<PlayerStore>(TYPES.PlayerStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const followStore = useInjection<FollowStore>(TYPES.FollowStore);

  const params = useParams<{
    [paths.PLAYER_SLUG_PARAM]: string;
    [paths.POST_ID_PARAM]: string;
  }>();

  const { playerSlug, postId } = params;

  useEffect(() => {
    if (playerSlug) {
      playerStore.setPlayerSlug(playerSlug);
    } else {
      playerStore.setPlayerSlug(null);
    }

    layoutStore.setSidePanelActiveTab(null);

    return () => {
      playerStore.setPlayerSlug(null);
    };
  }, [layoutStore, playerSlug, playerStore]);

  const handleFollowPlayer = useCallback(() => {
    playerStore.followPlayer();
  }, [playerStore]);

  const handleUnfollowPlayer = useCallback(() => {
    playerStore.unfollowPlayer();
  }, [playerStore]);

  const renderHeader = useCallback(() => {
    const player = playerStore.playerDetails;

    if (player && !postId) {
      return (
        <PlayerProfileHeader
          isVisibleForMobile={layoutStore.isMobilePlayerOrTeamHeaderVisible}
          isFollowFetched={!!followStore.follow.length}
          isFollowedPlayer={playerStore.isFollowedPlayer}
          isFollowFetching={followStore.fetching}
          avatarUrl={player.mediumLogoUrl}
          firstname={player.firstname}
          lastname={player.lastname}
          onFollowPlayer={handleFollowPlayer}
          onUnfollowPlayer={handleUnfollowPlayer}
        />
      );
    }

    return null;
  }, [
    postId,
    playerStore.playerDetails,
    playerStore.isFollowedPlayer,
    followStore.fetching,
    followStore.follow,
    handleFollowPlayer,
    handleUnfollowPlayer,
    layoutStore.isMobilePlayerOrTeamHeaderVisible,
  ]);

  useLayoutEntity({
    type: LayoutEntity.HeaderCenter,
    value: renderHeader,
  });

  useLayoutEntity({
    type: LayoutEntity.HeaderLeft,
    value: { icon: IconFontName.ChevronLeft, type: LayoutHeaderLeftActionEnum.Back },
  });

  useEffect(() => {
    if (playerStore.playerDetails) {
      const { firstname, lastname } = playerStore.playerDetails;

      GoogleAnalyticService.event({
        eventName: 'screen_custom_view',
        eventParams: {
          screen_type: ScreenNamesEnum.PlayerProfile,
          player: `${firstname} ${lastname}`,
        },
      });
    }
  }, [playerStore.playerDetails]);

  useEffect(() => {
    if (playerStore.playerDetails?.team) {
      document.documentElement.style.setProperty(
        '--player-primary-color',
        playerStore.playerDetails.team.color,
      );
      document.documentElement.style.setProperty(
        '--player-text-color',
        playerStore.playerDetails.team.textColor,
      );
      document.documentElement.style.setProperty(
        '--player-secondary-text-color',
        playerStore.playerDetails.team.secondaryTextColor,
      );
    }

    return () => {
      document.documentElement.style.removeProperty('--player-primary-color');
      document.documentElement.style.removeProperty('--player-text-color');
      document.documentElement.style.removeProperty('--player-secondary-text-color');
    };
  }, [playerStore.playerDetails]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--team-primary-color',
      playerStore.playerDetails?.team?.color || null,
    );
    document.documentElement.style.setProperty(
      '--team-secondary-color',
      playerStore.playerDetails?.team?.textColor || null,
    );

    return () => {
      document.documentElement.style.removeProperty('--team-primary-color');
      document.documentElement.style.removeProperty('--team-secondary-color');
    };
  }, [playerStore.playerDetails?.team]);

  return null;
});
