import { inject, injectable } from 'inversify';
import { reaction } from 'mobx';

import { GameService } from 'services/game/game.service';
import { IPostResponse } from 'services/posts/interfaces/posts-response.interface';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { GameStore } from 'stores/game/game.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { publicationAdapter } from 'stores/posts/adapters/publication-adapter.util';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { IPost, IPostAdapter } from 'stores/posts/interfaces/post.interface';
import { IPostsResponse } from 'stores/posts/interfaces/post-response.inerface';

import { TYPES } from 'configs/di-types.config';

import { PAGINATION_LIMIT } from './feeds.config';

@injectable()
export class GameVideosFeedStore extends AdvancedEntriesStore<
  IPost,
  IPostResponse,
  IPostsResponse
> {
  private readonly gameService: GameService;

  private readonly layoutStore: LayoutStore;

  private readonly gameStore: GameStore;

  constructor(
    @inject(TYPES.GameService) gameService: GameService,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
    @inject(TYPES.GameStore) gameStore: GameStore,
  ) {
    super(PAGINATION_LIMIT);

    this.gameService = gameService;

    this.gameStore = gameStore;

    this.layoutStore = layoutStore;

    reaction(
      () => [this.layoutStore.activeFeed, this.gameStore.id],
      () => this.forceFetchToRefresh(),
    );
  }

  public async fetchNext(): Promise<void> {
    if (this.layoutStore.activeFeed === FeedTypes.GameVideos && this.gameStore.id) {
      await this.retrieveNext(
        this.fetchGameVideosPosts(this.gameStore.id),
        <IPostAdapter>publicationAdapter,
      );
    } else {
      this.setEntries([]);
    }
  }

  public async fetchGameVideosPosts(gameId: number): Promise<IResponse<IPostsResponse>> {
    return this.gameService.fetchGameVideosPosts(this.pagination, gameId);
  }

  public async forceFetchToRefresh() {
    if (this.layoutStore.activeFeed === FeedTypes.GameVideos && this.gameStore.id) {
      await this.refresh();

      await this.forceRefresh(
        this.fetchGameVideosPosts(this.gameStore.id),
        <IPostAdapter>publicationAdapter,
        this.layoutStore.setPulledRefresher,
      );
    }
  }

  public async refresh() {
    this.setFetched(false);
    this.setFetching(false);

    this.setIsLastPage(false);
    this.setCurrentPage(1);

    await this.initialise();
  }
}
