import { IPollOption } from 'stores/posts/interfaces/post.interface';

import { TextTooltip } from 'components/ui/text-tooltip/text-tooltip.component';
import { TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import styles from './share-poll.module.less';

export interface ISharePollProps {
  options: IPollOption[];
}

export const SharePoll: React.FC<ISharePollProps> = (props: ISharePollProps) => {
  const { options } = props;

  return (
    <div className={styles.Poll}>
      <div className={styles.Poll__Title}>Poll</div>
      <ul className={styles.Poll__Options}>
        {options.map((option, i) => (
          <li key={option.uuid} className={styles.Poll__Option}>
            <TextTooltip
              text={option.title}
              eventType={TooltipEventType.hover}
              tooltipOffset={{ mainAxis: 5 }}
            >
              <span>{`${i + 1}. ${option.title}`}</span>
            </TextTooltip>
          </li>
        ))}
      </ul>
    </div>
  );
};
