import { FC } from 'react';

import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutHeaderLeftActionEnum } from 'stores/layout/interfaces/layout-header-left.interface';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { AuthorCardContainer } from 'containers/posts/author-card/author-card.container';
import { PostDetailedContainer } from 'containers/posts/post-detailed/post-detailed.container';
import { ScrollTopContainer } from 'containers/scroll-top/scroll-top.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useAllowSwipeBack } from 'hooks/use-allow-swipe-back';
import { useEnableRefresher } from 'hooks/use-enable-refresher';
import { useForceCollapseSmartBanner } from 'hooks/use-force-collapse-smart-banner';
import { useLayoutEntity } from 'hooks/use-layout-entity';
import { useMobileLayoutStructure } from 'hooks/use-mobile-layout-structure';
import { useResponsive } from 'hooks/use-responsive';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const PostDetailedRoute: FC = () => {
  useLayoutEntity({ type: LayoutEntity.PageTitle, value: 'Post Details' });
  useLayoutEntity({
    type: LayoutEntity.HeaderLeft,
    value: { icon: IconFontName.ChevronLeft, type: LayoutHeaderLeftActionEnum.Back },
  });

  useForceCollapseSmartBanner();
  useAllowSwipeBack();
  useEnableRefresher();
  useMobileLayoutStructure({
    isHiddenHeader: false,
  });

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  return (
    <PageWrapperContainer>
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1}>
          <PostDetailedContainer />
        </Column>
        {isDesktopPlus && (
          <Column mobile={2}>
            <AuthorCardContainer />
            <ScrollTopContainer />
          </Column>
        )}
      </Row>
    </PageWrapperContainer>
  );
};
