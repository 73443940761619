import { FC, useCallback, useMemo } from 'react';
import { Clipboard } from '@capacitor/clipboard';
import cn from 'classnames';

import { getPreviewImage } from 'stores/gallery/utils/get-preview-image.util';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';
import { IUserPublic } from 'stores/user-public/interfaces/user-public.interface';

import { SelectTeamAndPlayerContainer } from 'containers/select-team-and-player/select-team-and-player.container';
import { SocialLinksContainer } from 'containers/social-links/social-links.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { TextTooltip } from 'components/ui/text-tooltip/text-tooltip.component';
import { TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import bronzeFrame from 'assets/images/svg/bronze-frame.svg';
import goldFrame from 'assets/images/svg/gold-frame.svg';
import silverFrame from 'assets/images/svg/silver-frame.svg';
import defaultThumbnailUrl from 'assets/images/svg/user-default-background.svg';

import styles from './profile-info.module.less';

export enum AvatarFrame {
  Bronze = 'bronze',
  Silver = 'silver',
  Gold = 'gold',
}

interface IProfileInfoProps {
  userData: IUserPublic | null;
  isAuthUserProfile: boolean;
  isMyPage: boolean;
  frame?: AvatarFrame;
  onEditProfilePress: () => void;
  onOpenPreview: (image: IInteractiveMediaAttachment) => void;
}

const DEFAULT_THUMBNAIL_URL = defaultThumbnailUrl;

const AVATAR_FRAME_MAP: Record<AvatarFrame, string> = {
  [AvatarFrame.Bronze]: bronzeFrame,
  [AvatarFrame.Silver]: silverFrame,
  [AvatarFrame.Gold]: goldFrame,
};

export const ProfileInfo: FC<IProfileInfoProps> = (props: IProfileInfoProps) => {
  const { userData, frame, isAuthUserProfile, onOpenPreview, isMyPage } = props;
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handleShare = useCallback(async () => {
    await Clipboard.write({
      string: window.location.href,
    });
  }, []);

  const handleOpenPreview = useCallback(() => {
    if (!userData?.avatarUrl) {
      return;
    }

    const image = getPreviewImage(
      userData.avatarUrl,
      `${userData.username.toLowerCase()}-logo`,
      userData.smallAvatarUrl,
    );

    onOpenPreview(image);
  }, [onOpenPreview, userData]);

  const avatarClasses = useMemo(() => {
    return cn(styles.ProfileInfo__Avatar, {
      [styles.ProfileInfo__Avatar__withFrame]: frame,
    });
  }, [frame]);

  if (!userData) {
    return null;
  }

  return (
    <div className={styles.ProfileInfo}>
      <div className={styles.ProfileInfo__HeaderThumbnail}>
        <img
          src={userData?.thumbnailUrl || DEFAULT_THUMBNAIL_URL}
          alt="thumbnail background"
          className={styles.ProfileInfo__Background}
        />
      </div>
      <div className={styles.ProfileInfo__Content}>
        {!userData.athlete ? (
          <div className={styles.ProfileInfo__Content_Left}>
            <div className={styles.ProfileInfo__Points}>
              <IconButton
                iconSize={IconFontSize.ExtraSmall}
                iconName={IconFontName.StarFilled}
                theme={IconButtonTheme.Secondary}
              />
              <span className={styles.ProfileInfo__Rating}>{userData.rating}</span>
            </div>
            {isDesktopPlus && <SelectTeamAndPlayerContainer />}
          </div>
        ) : (
          <div />
        )}
        <div
          role="button"
          tabIndex={0}
          className={styles.ProfileInfo__AvatarWrapper}
          onKeyDown={handleOpenPreview}
          onClick={handleOpenPreview}
        >
          <div className={avatarClasses}>
            {frame && (
              <img
                className={styles.ProfileInfo__Avatar__Frame}
                src={AVATAR_FRAME_MAP[frame]}
                alt="avatar frame"
              />
            )}
            <Avatar
              username={userData.username}
              size={AvatarSize.SIZE_144}
              src={userData.avatarUrl}
            />
          </div>
        </div>
        <div className={styles.ProfileInfo__Content_Right}>
          {isDesktopPlus && <SocialLinksContainer />}
          <TextTooltip
            tooltipOffset={10}
            tooltipDisappearTime={1000}
            eventType={TooltipEventType.click}
            text="Link Copied"
            placement="top"
          >
            <Button
              onClick={handleShare}
              theme={ButtonTheme.Text}
              size={ButtonSize.Small}
              iconName={IconFontName.Share}
            >
              Share
            </Button>
          </TextTooltip>
        </div>
        {isAuthUserProfile && (
          <div className={styles.ProfileInfo__HeaderButtons}>
            {isMyPage && (
              <Button
                onClick={props.onEditProfilePress}
                theme={ButtonTheme.Tertiary}
                size={ButtonSize.SmallSecondary}
              >
                {isDesktopPlus ? 'Edit Profile' : 'Edit'}
              </Button>
            )}
          </div>
        )}
      </div>
      <div className={styles.ProfileInfo__Details}>
        <div className={styles.ProfileInfo__Names}>
          <h4>{userData.realName}</h4>
          <span>@{userData.username}</span>
        </div>
        {!isDesktopPlus && <SelectTeamAndPlayerContainer />}
      </div>
    </div>
  );
};
