import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import { PLAYER_SLUG_API_PARAM } from 'services/http/consts/api-endpoint-params.constants';
import {
  ATHLETE,
  PLAYER_CAREER,
  PLAYER_DETAILS,
  PLAYER_RANKED_STATS,
  PLAYER_SPLITS,
  PLAYER_SPLITS_IN_GAME,
  PLAYER_SPLITS_OPPONENT,
  PLAYER_TEAMMATES,
  PLAYER_TOP_LIST,
  PLAYERS_LEADERS,
  PLAYERS_STATS,
  PLAYERS_STATS_ALL,
} from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';
import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';
import { SeasonType } from 'services/seasons/enums/season-type.enum';

import { IPlayerAthleteResponse } from 'stores/auth/interfaces/athlete.interface';
import { CareerPerMode } from 'stores/player/enums/career-per-mode.enum';
import {
  DEFAULT_PLAYERS_PAGINATION,
  DEFAULT_TOP_PLAYERS_PAGINATION,
} from 'stores/player/player.config';

import { TYPES } from 'configs/di-types.config';

import { IPlayerDetailsResponse, IPlayerResponse } from './interfaces/player.interface';
import { IPlayerCareerResponse } from './interfaces/player-career.interface';
import { IPlayersLeadersResponse } from './interfaces/player-leaders.interface';
import {
  IPlayerSplitsOpponentResponse,
  IPlayerSplitsStatsResponse,
  ISplitsInGameResponse,
  ISplitsInGameSearchParams,
  ISplitsMonthsResponse,
  ISplitsMonthsSearchParams,
} from './interfaces/player-splits.interface';
import {
  IPlayerRankedStatsResponse,
  IPlayersStatsSearchParam,
  IPlayerStatsExtendedResponse,
  IPlayerStatsMiniResponse,
} from './interfaces/player-stats.interface';
import { ITopPlayerItemsResponse } from './interfaces/top-players.interface';

@injectable()
export class PlayerService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchTopPlayers(
    feedType: PostsFeedType,
    pagination: IPagination = DEFAULT_TOP_PLAYERS_PAGINATION,
  ): Promise<IResponse<ITopPlayerItemsResponse>> {
    const { limit, page } = pagination;

    const searchParams = {
      limit: String(limit),
      page: String(page),
      type: feedType,
    };

    return this.httpService.request({
      method: 'GET',
      url: PLAYER_TOP_LIST,
      params: new URLSearchParams(searchParams),
    });
  }

  public fetchPlayers(
    pagination: IPagination = DEFAULT_PLAYERS_PAGINATION,
  ): Promise<IResponse<IPlayerResponse[]>> {
    const { limit, page } = pagination;

    return this.httpService.request({
      method: 'GET',
      url: PLAYERS_STATS,
      params: new URLSearchParams({ limit: String(limit), page: String(page) }),
    });
  }

  public fetchPlayerDetails(playerSlug: string): Promise<IResponse<IPlayerDetailsResponse>> {
    return this.httpService.request({
      method: 'GET',
      url: PLAYER_DETAILS,
      routeParams: { [PLAYER_SLUG_API_PARAM]: playerSlug },
    });
  }

  public fetchPlayerRankedStats(
    playerSlug: string,
    seasonId: Maybe<string>,
  ): Promise<IResponse<Maybe<IPlayerRankedStatsResponse>>> {
    let params;

    if (seasonId) {
      params = new URLSearchParams({ seasonId });
    }

    return this.httpService.request({
      method: 'GET',
      url: PLAYER_RANKED_STATS,
      routeParams: { [PLAYER_SLUG_API_PARAM]: playerSlug },
      params,
    });
  }

  public fetchPlayerCareer(
    playerSlug: string,
    seasonType: SeasonType,
    mode: CareerPerMode,
  ): Promise<IResponse<IPlayerCareerResponse>> {
    return this.httpService.request({
      method: 'GET',
      url: PLAYER_CAREER,
      routeParams: { [PLAYER_SLUG_API_PARAM]: playerSlug },
      params: new URLSearchParams({ season_type: seasonType, mode }),
    });
  }

  public fetchPlayerTeammates(playerSlug: string): Promise<IResponse<IPlayerStatsMiniResponse[]>> {
    return this.httpService.request({
      method: 'GET',
      url: PLAYER_TEAMMATES,
      routeParams: { [PLAYER_SLUG_API_PARAM]: playerSlug },
    });
  }

  public fetchPlayerSplits(
    playerSlug: string,
    seasonId: Maybe<string>,
  ): Promise<IResponse<IPlayerSplitsStatsResponse[]>> {
    let params;

    if (seasonId) {
      params = new URLSearchParams({ seasonId });
    }

    return this.httpService.request({
      method: 'GET',
      url: PLAYER_SPLITS,
      routeParams: { [PLAYER_SLUG_API_PARAM]: playerSlug },
      params,
    });
  }

  public fetchPlayerSplitsOpponent(
    playerSlug: string,
    seasonId: Maybe<string>,
  ): Promise<IResponse<IPlayerSplitsOpponentResponse[]>> {
    let params;

    if (seasonId) {
      params = new URLSearchParams({ seasonId });
    }

    return this.httpService.request({
      method: 'GET',
      url: PLAYER_SPLITS_OPPONENT,
      routeParams: { [PLAYER_SLUG_API_PARAM]: playerSlug },
      params,
    });
  }

  public fetchPlayersStats(
    teamId: Maybe<number>,
    seasonId: Maybe<string>,
    position: Maybe<string>,
  ): Promise<IResponse<IPlayerStatsExtendedResponse[]>> {
    const paramsObj: IPlayersStatsSearchParam = {};

    if (teamId) {
      paramsObj.teamId = teamId.toString();
    }

    if (seasonId) {
      paramsObj.seasonId = seasonId;
    }

    if (position) {
      paramsObj.position = position;
    }

    return this.httpService.request({
      method: 'GET',
      url: PLAYERS_STATS_ALL,
      params: new URLSearchParams({ ...paramsObj }),
    });
  }

  public fetchPlayersLeaders(seasonId: Maybe<string>): Promise<IResponse<IPlayersLeadersResponse>> {
    let params;

    if (seasonId) {
      params = new URLSearchParams({ seasonId });
    }

    return this.httpService.request({
      method: 'GET',
      url: PLAYERS_LEADERS,
      params,
    });
  }

  public fetchSplitsInGame(
    playerSlug: string,
    seasonId: Maybe<string>,
    pagination: IPagination,
  ): Promise<IResponse<ISplitsInGameResponse[]>> {
    const { limit, page } = pagination;

    const paramsObj: ISplitsInGameSearchParams = {
      limit: String(limit),
      page: String(page),
    };

    if (seasonId) {
      paramsObj.seasonId = seasonId;
    }

    return this.httpService.request({
      method: 'GET',
      url: PLAYER_SPLITS_IN_GAME,
      routeParams: { [PLAYER_SLUG_API_PARAM]: playerSlug },
      params: new URLSearchParams({ ...paramsObj }),
    });
  }

  public fetchSplitsMonths(
    playerSlug: string,
    seasonId: Maybe<string>,
    teamId: Maybe<number>,
    splitSize: Maybe<number>,
  ): Promise<IResponse<ISplitsMonthsResponse[]>> {
    const paramsObj: ISplitsMonthsSearchParams = {};

    if (seasonId) {
      paramsObj.seasonId = seasonId;
    }

    if (teamId) {
      paramsObj.team_id = teamId.toString();
    }

    if (splitSize) {
      paramsObj.split_size = splitSize.toString();
    }

    return this.httpService.request({
      method: 'GET',
      headers: {
        'X-Version': '3',
      },
      url: PLAYER_SPLITS_OPPONENT,
      routeParams: { [PLAYER_SLUG_API_PARAM]: playerSlug },
      params: new URLSearchParams({ ...paramsObj }),
    });
  }

  public fetchAthleteBySlug(slug: string): Promise<IResponse<IPlayerAthleteResponse>> {
    return this.httpService.request<IPlayerAthleteResponse>({
      url: ATHLETE,
      method: 'GET',
      routeParams: { [PLAYER_SLUG_API_PARAM]: slug },
    });
  }
}
