import { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { RangeInput } from 'components/ui/range-input/range-input.component';

import styles from './select-game-mobile.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

interface ISelectGameMobileOption {
  value: Maybe<number>;
  label: string;
}

interface ISelectGameMobileProps {
  visible: boolean;
  options: ISelectGameMobileOption[];
  value: Maybe<number>;
  onChange: (option: Maybe<number>) => void;
  onClose: () => void;
}

export const SelectGameMobile = (props: ISelectGameMobileProps) => {
  const { options, value, visible } = props;

  const [isShowCustomAmountButton, setIsShowCustomAmountButton] = useState(false);
  const [customAmount, setCustomAmount] = useState(value);

  const handleCustomAmountChange = useCallback((customValue: number) => {
    setCustomAmount(customValue);
    setIsShowCustomAmountButton(true);
  }, []);

  const handleCustomAmountSubmit = useCallback(() => {
    props.onChange(customAmount);
  }, [customAmount, props]);

  const isCustomAmount = useMemo(() => {
    const valueIndex = options.findIndex((option) => option.value === value);

    return valueIndex === -1;
  }, [options, value]);

  const selectGameRangeLabelClasses = useMemo(
    () =>
      cn(styles.SelectGame__RangeLabel, {
        [styles['SelectGame__RangeLabel--active']]: isCustomAmount,
      }),
    [isCustomAmount],
  );

  useEffect(() => {
    if (!visible) {
      setIsShowCustomAmountButton(false);
    }
  }, [visible]);

  return (
    <BaseIonBottomSheet
      visible={visible}
      safeAreaBottom={0}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      initialBreakpoint={INITIAL_BREAKPOINT}
      isAutoHeight
      onClose={props.onClose}
    >
      <div className={styles.SelectGame}>
        <ul className={styles.SelectGame__List}>
          {options.map((option) => (
            <li
              key={option.value}
              className={cn(
                styles.SelectGame__Item,
                option.value === value ? styles['SelectGame__Item--active'] : '',
              )}
              role="tab"
              tabIndex={0}
              onClick={() => props.onChange(option.value)}
              onKeyDown={() => props.onChange(option.value)}
            >
              {option.label}
            </li>
          ))}
          <li className={styles.SelectGame__Item}>
            <div className={selectGameRangeLabelClasses}>Custom Amount</div>
            <RangeInput
              customMinLabel="All"
              max={30}
              value={value}
              step={1}
              onUpdate={handleCustomAmountChange}
            />
            {isShowCustomAmountButton && (
              <Button
                fluid
                size={ButtonSize.Small}
                theme={ButtonTheme.Primary}
                onClick={handleCustomAmountSubmit}
              >
                Apply Amount
              </Button>
            )}
          </li>
        </ul>
      </div>
    </BaseIonBottomSheet>
  );
};
