import { FC } from 'react';
import { MenuListProps } from 'react-select';

import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';

import styles from './team-menu-list.module.less';

interface ITeamMenuList extends MenuListProps<Maybe<ITeamOption>, false> {}

export const TeamMenuList: FC<ITeamMenuList> = ({ children }) => {
  return <div className={styles.MenuList}>{children}</div>;
};
