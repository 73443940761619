import { useRef, useState } from 'react';

import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';

import { FILES_VIDEO_TYPES } from 'validation/validation.constants';

import { AuthTooltip } from 'components/auth/auth-tooltip/auth-tooltip.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { PostVideoInput } from 'components/ui/form-fields/post-video-input/post-video-input.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';
import { VideoPreview, VideoPreviewSize } from 'components/video-preview/video-preview.component';

import styles from './toolbar.module.less';

interface IVideoPostToolbarPluginProps {
  isSendDisabled: boolean;
  isSendHidden: boolean;
  isVideoLoading: boolean;
  postVideo: Maybe<IVideoResponse>;
  onUploadVideo?: (video: File) => void;
}

export const VideoPostToolbarPlugin = (props: IVideoPostToolbarPluginProps) => {
  const { postVideo, isSendHidden, isVideoLoading, isSendDisabled } = props;
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [isVideoInputOpen, setIsVideoInputOpen] = useState(false);
  const [isVideoPermissionsRequested, setIsVideoPermissionsRequested] = useState(false);
  return (
    <div ref={toolbarRef} className={styles.VideoToolbar}>
      {postVideo && (
        <div className={styles.SingleVideo}>
          {isVideoLoading ? (
            <Loader isShow />
          ) : (
            <VideoPreview size={VideoPreviewSize.S} url={postVideo.url} />
          )}
        </div>
      )}
      <PostVideoInput
        isVideoInputOpen={isVideoInputOpen}
        setIsVideoInputOpen={setIsVideoInputOpen}
        accept={FILES_VIDEO_TYPES.join(', ')}
        onVideoLoad={props.onUploadVideo}
        id="attachVideo"
        name="attachVideo"
        isVideoPermissionsRequested={isVideoPermissionsRequested}
        setIsVideoPermissionsRequested={setIsVideoPermissionsRequested}
      >
        <Button
          size={ButtonSize.Small}
          theme={ButtonTheme.Secondary}
          fluid
          disabled={isVideoLoading}
          onClick={() => setIsVideoInputOpen(true)}
        >
          Change Video
        </Button>
      </PostVideoInput>

      {!isSendHidden && (
        <div className={styles.Toolbar__SubmitButton}>
          <AuthTooltip>
            <Button
              disabled={isSendDisabled || isVideoLoading}
              size={ButtonSize.Small}
              theme={ButtonTheme.Primary}
              type="submit"
              iconName={isSendDisabled ? IconFontName.Send : IconFontName.SendFilled}
            />
          </AuthTooltip>
        </div>
      )}
    </div>
  );
};
