import { memo, useCallback, useMemo } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { IPlayerDetails } from 'stores/player/interfaces/player-profile.interfaces';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { PLAYERS_PAGE_SIZE } from 'components/stats/global-stats/global-stats-table.config';
import { IStatsBoxItem } from 'components/ui/stats-box/interfaces/stats-box.interface';
import { StatsBox, StatsBoxTheme } from 'components/ui/stats-box/stats-box.component';

import { preparePlayerStatsBoxItems } from './utils/prepare-player-stats-box-items.util';

import styles from './player-profile-info-stats.module.less';

interface IPlayerProfileInfoStatsProps {
  playerDetails: Maybe<IPlayerDetails>;
}

export const PlayerProfileInfoStats = memo((props: IPlayerProfileInfoStatsProps) => {
  const { playerDetails } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const navigate = useNavigate();

  const playerStatsBoxItems = useMemo<IStatsBoxItem[]>(() => {
    if (playerDetails?.stats) {
      return preparePlayerStatsBoxItems(playerDetails.stats);
    }

    return [];
  }, [playerDetails]);

  const handleRankClick = useCallback(
    (item: IStatsBoxItem) => () => {
      if (playerDetails?.slug && item.sortByColumn && item.rank?.value) {
        const rank = item.rank.value;
        const page = Math.ceil(rank / PLAYERS_PAGE_SIZE);

        navigate({
          pathname: paths.HOME_STATS_PLAYERS_ALL,
          search: `${createSearchParams({
            sort: item.sortByColumn,
            playerSlug: playerDetails.slug,
            direction: 'desc',
            page: page.toString(),
          })}`,
        });
      }
    },
    [navigate, playerDetails],
  );

  if (!playerStatsBoxItems.length) {
    return null;
  }

  return (
    <div className={styles.PlayerProfileInfoStats}>
      {playerStatsBoxItems.map((item) => (
        <StatsBox
          key={item.slug}
          title={isDesktopPlus ? item.title : item.slug}
          content={item.content}
          theme={StatsBoxTheme.Transparent}
          rank={item.rank}
          onClick={handleRankClick(item)}
        />
      ))}
    </div>
  );
});
