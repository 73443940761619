import { FC } from 'react';

import {
  BaseModalComponent,
  IModal,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import styles from './share-rankings-modal.module.less';

interface IShareRankingsModalProps extends IModal {
  title: string;
  onCopyLink: () => void;
}

export const ShareRankingsModal: FC<IShareRankingsModalProps> = (
  props: IShareRankingsModalProps,
) => {
  const { title, visible } = props;

  return (
    <BaseModalComponent
      headerButton={{
        icon: IconFontName.Link,
        text: 'Copy Link',
        onClick: props.onCopyLink,
        tooltip: {
          eventType: TooltipEventType.click,
          placement: 'top',
          text: 'Link Copied',
          disappearTime: 500,
        },
      }}
      size={ModalWindowSize.M}
      visible={visible}
      closeOnOverlayClick
      title="Share"
      onClose={props.onClose}
    >
      <div className={styles.ModalContentWrapper}>
        <div className={styles.ModalContent}>
          <div className={styles.ModalContent__Title}>{title}</div>
        </div>
      </div>
    </BaseModalComponent>
  );
};
