import { FC, memo, ReactNode, useCallback } from 'react';

import { SwitcherSliderItem } from 'components/ui/tabs-switcher-slider/switcher-slider-item/switcher-slider-item.component';

import styles from './tabs-switcher-slider.module.less';

interface ISwitcherSliderItem {
  element: ReactNode;
  id: string;
}

interface ITabsSwitcherSliderProps {
  items: ISwitcherSliderItem[];
  activeId: string;
  disabled?: boolean;
  onItemClick: (id: string) => void;
}

export const TabsSwitcherSlider: FC<ITabsSwitcherSliderProps> = memo(
  (props: ITabsSwitcherSliderProps) => {
    const { items, disabled, onItemClick, activeId } = props;

    const handleItemClick = useCallback(
      (id: string) => {
        onItemClick(id);
      },
      [onItemClick],
    );

    return (
      <ul className={styles.TabsSwitcherSlider}>
        {items.map(({ id, element }) => (
          <SwitcherSliderItem
            id={id}
            disabled={disabled}
            active={id === activeId}
            onClick={handleItemClick}
            key={id}
            element={element}
          />
        ))}
      </ul>
    );
  },
);
