import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { PlayerRankingsCategoryEnum } from 'services/player-rankings/interfaces/player-rankings-response.interface';

import {
  CategoryRankingsStatsItemOnly,
  ICategoryPlayerRankingsItem,
} from 'stores/player-rankings/interfaces/player-rankings.interface';

import { RANKING_CATEGORIES } from 'containers/player-rankings/player-rankings.config';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getStatsPropertyAbbreviationByKey } from 'helpers/get-stats-property-abbreviation-by-key.util';

import { useResponsive } from 'hooks/use-responsive';

import { SinglePoint } from 'components/single-point/single-point.component';

import styles from './player-rankings-item.module.less';

interface IPlayerRankingItemProps {
  player: ICategoryPlayerRankingsItem;
  categorySlug: PlayerRankingsCategoryEnum;
  onPlayerClick: (slug: string, gameId: string) => void;
}

export const PlayerRankingItem = memo((props: IPlayerRankingItemProps) => {
  const { player, onPlayerClick, categorySlug } = props;
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const categoryStatsList = useMemo(() => {
    const stats = RANKING_CATEGORIES.find((item) => item.key === categorySlug);

    return stats || null;
  }, [categorySlug]);

  const handlePlayerClick = useCallback(() => {
    onPlayerClick(player.playerInfo.slug, player.game.id.toString());
  }, [onPlayerClick, player]);

  const playerRankingItemClasses = useMemo(
    () =>
      cn(styles.PlayerRankingItem, {
        [styles['PlayerRankingItem--stretched']]:
          categorySlug === PlayerRankingsCategoryEnum.performers && isDesktopPlus,
      }),
    [categorySlug, isDesktopPlus],
  );

  return (
    <button className={playerRankingItemClasses} onClick={handlePlayerClick}>
      {categoryStatsList?.statsList.map((item, index) => (
        <SinglePoint
          // there is no any other uniq value
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          isSpecial={index === 0}
          categoryName={item}
          label={getStatsPropertyAbbreviationByKey(item)}
          value={player[item as keyof CategoryRankingsStatsItemOnly]}
        />
      ))}
    </button>
  );
});
