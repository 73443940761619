import { FC } from 'react';
import { components, MultiValueGenericProps } from 'react-select';

import styles from './custom-multi-value-container.module.less';

export const CustomMultiValueContainer: FC<MultiValueGenericProps> = ({ children, ...props }) => {
  return (
    <components.MultiValueContainer {...props}>
      <div className={styles.wrapper}>{children}</div>
    </components.MultiValueContainer>
  );
};
