import { FC, useCallback } from 'react';
import cn from 'classnames';

import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';

import {
  IframePreview,
  IframePreviewSize,
} from 'components/iframe-preview/iframe-preview.component';
import { GifSize, SingleGif } from 'components/single-gif/single-gif.component';
import {
  AttachmentsImageItem,
  AttachmentsImageTheme,
} from 'components/ui/attachments-image-item/attachment-image-item.component';
import { VideoPreview, VideoPreviewSize } from 'components/video-preview/video-preview.component';

import styles from './small-preview.module.less';

interface ISmallPreviewProps {
  attachments: IInteractiveMediaAttachment[];
  onClick: (id: number) => void;
  selectedItem: number;
}

export const SmallPreview: FC<ISmallPreviewProps> = (props: ISmallPreviewProps) => {
  const { attachments, selectedItem, onClick } = props;
  const getCurrentFileName = useCallback((url: string) => {
    return url!.substring(url.lastIndexOf('/') + 1);
  }, []);

  const getItemClassNames = useCallback(
    (index: number) => {
      return cn(styles.Preview__Item, {
        [styles['Preview__Item--selected']]: index === selectedItem,
      });
    },
    [selectedItem],
  );

  const handleClick = useCallback(
    (index: number) => {
      return () => {
        onClick(index);
      };
    },
    [onClick],
  );

  return (
    <div className={styles.Preview}>
      {attachments.map((attachment, index) => (
        <button
          onClick={handleClick(index)}
          // in this case index is good solution because attachments cant be changed
          /* eslint-disable-next-line react/no-array-index-key */
          key={index}
          className={getItemClassNames(index)}
        >
          {attachment.type === 'image' && (
            <AttachmentsImageItem
              imageUrl={attachment.url}
              mimeType={attachment.mimeType}
              alt={getCurrentFileName(attachment.url)}
              theme={AttachmentsImageTheme.SmallPreview}
            />
          )}
          {attachments[index].type === 'video' && (
            <VideoPreview
              size={VideoPreviewSize.XS}
              type={attachment.type}
              url={attachment.url}
              forGallery
            />
          )}
          {attachments[index].type === 'gif' && (
            <SingleGif isNeedGifIcon size={GifSize.XS} id={attachment.url} />
          )}
          {attachments[index].type === 'iframe' && (
            <IframePreview url={attachment.url} size={IframePreviewSize.XS} />
          )}
        </button>
      ))}
    </div>
  );
};
