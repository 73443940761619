import { FC } from 'react';

import {
  ConvertImageItemToAttachmentType,
  ConvertVideoItemToAttachmentType,
} from 'services/application/interfaces/upload-image.interface';
import {
  CollaborationMediaEnum,
  CollaborationMediaType,
} from 'services/collaboration/interfaces/collaboration.interface';

import {
  CollaborationEditorForm,
  CollaborationItemCreateDataType,
} from 'components/forms/collaboration-editor/collaboration-editor-form.component';
import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';

interface ICollaborationEditorModalProps {
  visible: boolean;
  onClose: () => void;
  postId: string;
  title: string;
  placeholder?: string;
  mediaType: Maybe<CollaborationMediaEnum>;
  collaborationMediaItem: Maybe<CollaborationMediaType>;
  setCollaborationMediaItem?: (value: Maybe<CollaborationMediaType>) => void;
  convertImageItemToAttachment?: ConvertImageItemToAttachmentType;
  onCreateCollaborationMediaItemWithText: (value: CollaborationItemCreateDataType) => void;
  convertVideoItemToAttachment?: ConvertVideoItemToAttachmentType;
  isCollaborationVideoLoading?: boolean;
  isCollaborationImageLoading?: boolean;
}

export const CollaborationEditorModal: FC<ICollaborationEditorModalProps> = (props) => {
  const { collaborationMediaItem, setCollaborationMediaItem } = props;

  return (
    <BaseModalComponent
      visible={props.visible}
      size={ModalWindowSize.M}
      title={props.title}
      closeOnOverlayClick
      onClose={props.onClose}
    >
      <CollaborationEditorForm
        isCollaborationImageLoading={props.isCollaborationImageLoading}
        isCollaborationVideoLoading={props.isCollaborationVideoLoading}
        postId={props.postId}
        visible={props.visible}
        mediaType={props.mediaType}
        placeholder={props.placeholder}
        onSubmit={props.onCreateCollaborationMediaItemWithText}
        collaborationMediaItem={collaborationMediaItem}
        setCollaborationMediaItem={setCollaborationMediaItem}
        convertImageItemToAttachment={props.convertImageItemToAttachment}
        convertVideoItemToAttachment={props.convertVideoItemToAttachment}
      />
    </BaseModalComponent>
  );
};
