import { FC, memo } from 'react';
import cn from 'classnames';

import { IS_ENABLE_ATHLETE_POST_CREATION } from 'configs/feature.config';

import styles from './feed-filter-tabs.module.less';

export enum FanZoneFilterTabs {
  All = 'all',
  Videos = 'videos',
  Polls = 'polls',
  GroupPosts = 'collaborations',
}

const tabs = [
  {
    key: FanZoneFilterTabs.All,
    title: 'All',
    visible: true,
  },
  {
    key: FanZoneFilterTabs.Videos,
    title: 'Videos',
    visible: true,
  },
  {
    key: FanZoneFilterTabs.Polls,
    title: 'Polls',
    visible: IS_ENABLE_ATHLETE_POST_CREATION,
  },
  {
    key: FanZoneFilterTabs.GroupPosts,
    title: 'Group Posts',
    visible: true,
  },
];

interface IFeedFiltersTabsProps {
  activeFilter: FanZoneFilterTabs;
  onTabChange: (tab: FanZoneFilterTabs) => void;
}

export const FeedFiltersTabs: FC<IFeedFiltersTabsProps> = memo((props: IFeedFiltersTabsProps) => {
  const { activeFilter, onTabChange } = props;

  const visibleTabs = tabs.filter(({ visible }) => visible);

  return (
    <ul className={styles.FeedFiltersTabs}>
      {visibleTabs.map(({ key, title }) => (
        <li
          tabIndex={0}
          role="tab"
          onKeyDown={() => onTabChange(key)}
          onClick={() => onTabChange(key)}
          key={key}
          title={title}
          className={cn(styles.Tab, {
            [styles['Tab--active']]: key === activeFilter,
          })}
        >
          {title}
        </li>
      ))}
    </ul>
  );
});
