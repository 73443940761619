import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionLink } from 'components/bars/bar-action.type';
import { ITab } from 'components/ui/tabs/tabs.component';

export const getTeamRouteTabs = (teamId: string | number): ITab<BarActionLink>[] => [
  {
    id: 'team_feed',
    title: 'Feed',
    action: {
      type: BarAction.Link,
      path: getPath(path.TEAM_FEED, { [path.TEAM_ID_PARAM]: String(teamId) }),
    },
  },
  {
    id: 'team_scores',
    title: 'Scores',
    action: {
      type: BarAction.Link,
      path: getPath(path.TEAM_SCORES, { [path.TEAM_ID_PARAM]: String(teamId) }),
    },
  },
  {
    id: 'team_stats',
    title: 'Stats',
    action: {
      type: BarAction.Link,
      path: getPath(path.TEAM_STATS, { [path.TEAM_ID_PARAM]: String(teamId) }),
    },
  },
  {
    id: 'team_roster',
    title: 'Roster',
    action: {
      type: BarAction.Link,
      path: getPath(path.TEAM_ROSTER, { [path.TEAM_ID_PARAM]: String(teamId) }),
    },
  },
  {
    id: 'team_profile',
    title: 'Profile',
    action: {
      type: BarAction.Link,
      path: getPath(path.TEAM_PROFILE, { [path.TEAM_ID_PARAM]: String(teamId) }),
    },
  },
];
