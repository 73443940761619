import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IGetRowsParams, SortChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { SortType } from 'types/sort-type.type';

import { LeaderboardStore } from 'stores/leaderboard/leaderboard.store';

import { TYPES } from 'configs/di-types.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { Leaderboard } from 'components/leaderboard/leaderboard.component';
import { LeaderboardColumns } from 'components/leaderboard/leaderboard.config';

export const LeaderboardContainer = observer(() => {
  const leaderboardStore = useInjection<LeaderboardStore>(TYPES.LeaderboardStore);

  const navigate = useNavigate();

  const agGridRef = useRef<AgGridReact>();

  const dataSource = useMemo(() => {
    return {
      getRows: async ({ successCallback, endRow }: IGetRowsParams) => {
        const { rows, count } = await leaderboardStore.retrieveUserBalances();
        let lastRow = -1;

        if (count <= endRow) {
          lastRow = count;
        }

        successCallback(rows, lastRow);
      },
    };
  }, [leaderboardStore]);

  useEffect(() => {
    if (agGridRef.current) {
      const { api, columnApi } = agGridRef.current;

      if (api) {
        api.refreshHeader();
        api.refreshCells();
        api.ensureIndexVisible(0, 'top');

        api.setDatasource(dataSource);
      }

      if (columnApi) {
        columnApi.applyColumnState({
          state: [
            {
              colId: LeaderboardColumns.Total,
              sort: 'desc',
            },
          ],
          defaultState: { sort: null },
        });
      }
    }

    return () => {
      leaderboardStore.refresh();
    };
  }, [leaderboardStore, leaderboardStore.seasonId, leaderboardStore.bucketId, dataSource]);

  useEffect(() => {
    if (!agGridRef.current || !agGridRef.current.api) {
      return;
    }

    agGridRef.current.api.setPinnedBottomRowData(
      leaderboardStore.currentUserBalance ? [leaderboardStore.currentUserBalance] : undefined,
    );
  }, [leaderboardStore, leaderboardStore.currentUserBalance]);

  const handleUserNavigation = useCallback(
    (username: string) => {
      navigate(
        getPath(paths.USER_PUBLIC_PROFILE, {
          [paths.USERNAME_PARAM]: username,
        }),
      );
    },
    [navigate],
  );

  const handleSortChanged = useCallback(
    (event: SortChangedEvent) => {
      event.api.refreshHeader();
      event.api.refreshCells();
      event.api.ensureIndexVisible(0, 'top');

      leaderboardStore.setCurrentPage(1);
      const focusedCell = event.columnApi.getColumns()?.find((column) => column.getSort());

      if (focusedCell?.getSort()) {
        leaderboardStore.setOrder(focusedCell.getSort()!.toUpperCase() as SortType);
      }

      event.api.setPinnedBottomRowData(
        leaderboardStore.currentUserBalance ? [leaderboardStore.currentUserBalance] : undefined,
      );
    },
    [leaderboardStore],
  );

  return (
    <Leaderboard
      sortParam={leaderboardStore.sortParam}
      onUserClick={handleUserNavigation}
      onSortChanged={handleSortChanged}
      onSetSortParam={leaderboardStore.setSortParam}
      tableRef={agGridRef}
      dataSource={dataSource}
    />
  );
});
