import { FC, memo, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import cn from 'classnames';

import { ISliderItem } from 'containers/posts/config/feed.config';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { isShowToggle } from 'components/ui/feeds-select-slider-with-toggle/utils/is-show-toggle.util';
import { Toggle } from 'components/ui/form-fields/toggle/toggle.component';

import styles from './feeds-select-slider-with-toggle.module.less';

export interface IFeedsSelectSliderWithToggleProps {
  items: ISliderItem[];
  fullWidth: boolean;
  isRightSideSelected: boolean;
  onItemClick: (action: BarActionType, value: string) => void;
  isLeftSideToggleOn: boolean;
  isRightSideToggleOn: boolean;
  onLeftSideToggleClick?: () => void;
  onRightSideToggleClick?: () => void;
}

export const FeedsSelectSliderWithToggle: FC<IFeedsSelectSliderWithToggleProps> = memo(
  (props: IFeedsSelectSliderWithToggleProps) => {
    const {
      items,
      fullWidth,
      onItemClick,
      isRightSideSelected,
      isLeftSideToggleOn,
      isRightSideToggleOn,
      onLeftSideToggleClick,
      onRightSideToggleClick,
    } = props;
    const { pathname } = useLocation();

    const feedsSelectSliderWrapperClasses = useMemo(
      () =>
        cn(styles.FeedsSelectSlider, {
          [styles['FeedsSelectSlider--full-width']]: fullWidth,
        }),
      [fullWidth],
    );

    const getItemClasses = useCallback(
      (action: BarActionType) =>
        cn(styles.FeedsSelectSlider__Item, {
          [styles['FeedsSelectSlider__Item--selected']]:
            action.type === BarAction.Link && pathname === action.path,
        }),
      [pathname],
    );

    const handleItemClick = useCallback(
      (action: BarActionType, value: string) => () => {
        onItemClick(action, value);

        window.history.replaceState({}, '');
      },
      [onItemClick],
    );

    const handleChange = useCallback(
      (item: ISliderItem) => {
        if (item.isLeftSide) {
          return onLeftSideToggleClick;
        }
        return onRightSideToggleClick;
      },
      [onRightSideToggleClick, onLeftSideToggleClick],
    );

    return (
      <div className={feedsSelectSliderWrapperClasses}>
        {items.map((item) => (
          <button
            key={item.label}
            className={getItemClasses(item.action)}
            onClick={handleItemClick(item.action, item.value)}
          >
            {item.label}
            {isShowToggle(item.isLeftSide, isRightSideSelected) && item.isEnableNestedToggle && (
              <Toggle
                forFeed
                id={item.label}
                name={item.label}
                value={item.isLeftSide ? isLeftSideToggleOn : isRightSideToggleOn}
                onChange={handleChange(item)}
              />
            )}
          </button>
        ))}
      </div>
    );
  },
);
