import { memo, useCallback, useMemo } from 'react';
import { HeaderClassParams, SortDirection } from 'ag-grid-community';
import cn from 'classnames';

import { SortParams } from 'stores/leaderboard/leaderboard.store';

import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './leaderboard-header-cell.module.less';

interface ILeaderboardHeaderCellProps extends HeaderClassParams {
  iconName: IconFontName;
  sortParam: SortParams;
  setSort: (sort: SortDirection | undefined) => void;
  setSortParam: (param: SortParams) => void;
}

export const LeaderboardHeaderCell = memo((props: ILeaderboardHeaderCellProps) => {
  const { iconName, column, setSort, setSortParam, sortParam } = props;

  const sortDirection = useMemo(() => column?.getSort(), [column]);

  const handleClick = useCallback(() => {
    const newSortDirection = sortDirection === 'desc' ? 'asc' : 'desc';

    if (!sortDirection) {
      setSortParam(sortParam);
    }

    setSort(newSortDirection);
  }, [setSort, sortDirection, setSortParam, sortParam]);

  const classNames = useMemo(() => {
    return cn(styles.LeaderboardHeaderCell, {
      [styles.LeaderboardHeaderCell__highlighted]: !!sortDirection,
    });
  }, [sortDirection]);

  const sortClassNames = useMemo(() => {
    return cn({ [styles.LeaderboardHeaderCell__asc]: sortDirection === 'asc' });
  }, [sortDirection]);

  return (
    <button className={classNames} onClick={handleClick}>
      <IconFont size={IconFontSize.Small} name={iconName} />
      {sortDirection && (
        <div className={sortClassNames}>
          <IconFont size={IconFontSize.Small} name={IconFontName.MarkerExpanded} />
        </div>
      )}
    </button>
  );
});
