import { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { AuthTooltip } from 'components/auth/auth-tooltip/auth-tooltip.component';

import { Button, ButtonSize, ButtonTheme } from '../ui/button/button.component';

import styles from './empty-state.module.less';

interface IEmptyStateProps {
  title?: string;
  message: string;
  imgSrc?: string;
  btnText?: string;
  isBlackBackground?: boolean;
  isNoImage?: boolean;
  isForSidePanel?: boolean;
  onBtnClick?: () => void;
}

export const EmptyState = memo((props: IEmptyStateProps) => {
  const {
    isForSidePanel,
    message,
    imgSrc,
    btnText,
    onBtnClick,
    isBlackBackground = false,
    isNoImage = false,
    title = '',
  } = props;

  const handleClick = useCallback(() => {
    if (onBtnClick) {
      onBtnClick();
    }
  }, [onBtnClick]);

  const classNames = useMemo(
    () =>
      cn(styles.EmptyState, {
        [styles['EmptyState--is-side-panel']]: isForSidePanel,
        [styles['EmptyState--black']]: isBlackBackground,
        [styles['EmptyState--no-image']]: isNoImage,
      }),
    [isForSidePanel, isBlackBackground, isNoImage],
  );

  return (
    <div className={classNames}>
      {imgSrc && <img className={styles.EmptyState__Image} src={imgSrc} alt="Icon" />}
      {title && <div className={styles.EmptyState__Title}>{title}</div>}
      <div className={styles.EmptyState__Message}>{message}</div>
      {btnText && (
        <AuthTooltip>
          <Button theme={ButtonTheme.Primary} size={ButtonSize.Big} onClick={handleClick}>
            {btnText}
          </Button>
        </AuthTooltip>
      )}
    </div>
  );
});
