import { BucketType } from 'services/buckets/enums/bucket-type.enum';

import { IBucket } from 'stores/buckets/interfaces/bucket.interface';

import logoBg from 'assets/images/svg/logo-bg.svg';

export const GLOBAL_LEADERBOARD: IBucket = {
  id: 0,
  data: {
    type: BucketType.global,
    name: 'Global Leaderboard',
    logoSrc: logoBg,
  },
};
