import { FC, useCallback, useMemo } from 'react';
import { parse } from 'date-fns';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import { AuthStore } from 'stores/auth/auth.store';
import { GameStore } from 'stores/game/game.store';
import { GameEventsStore } from 'stores/game-events/game-events.store';

import { TYPES } from 'configs/di-types.config';

import { useInfiniteScroll } from 'hooks/use-infinite-scroll';

import { EmptyState } from 'components/empty-state/empty-state.component';
import { Countdown } from 'components/game/game-countdown/game-countdown.component';
import { GameEvent } from 'components/game/game-event/game-event.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './game-plays.module.less';

const GAME_EVENTS_AMOUNT_BEFORE_PRE_LOAD = 10;

export const GamePlaysContainer: FC = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const gameStore = useInjection<GameStore>(TYPES.GameStore);
  const gameChatEventsStore = useInjection<GameEventsStore>(TYPES.GameEventsStore);

  const handleLikes = useCallback(
    async (messageId: string) => {
      if (authStore.isAuthorised && gameStore.id) {
        const payload = {
          gameId: gameStore.id,
          messageId,
        };

        await gameChatEventsStore.gameChatEventLikes(payload);
      }
    },
    [authStore, gameChatEventsStore, gameStore.id],
  );

  const handleLoadMore = useCallback(() => {
    if (gameStore.id) {
      gameChatEventsStore.fetchNextGameChatEvents(gameStore.id);
    }
  }, [gameChatEventsStore, gameStore]);

  const gameStartTimestamp = useMemo(() => {
    const gameDate = gameStore.entry?.gameDateTimeStart || '';
    const dateObject = parse(gameDate, 'h:mm a, MMMM dd, yyyy', new Date());
    return dateObject.getTime();
  }, [gameStore.entry]);

  const hasMore = useMemo(
    () => !gameChatEventsStore.fetching && !gameChatEventsStore.isLastPage,
    [gameChatEventsStore.fetching, gameChatEventsStore.isLastPage],
  );

  const setIntersectionObserver = useInfiniteScroll(hasMore, handleLoadMore);

  return (
    <>
      {gameStore.entry?.status === GameStatus.Scheduled && (
        <Countdown
          gameStatus={gameStore.entry?.status}
          label="Game starts in"
          targetDate={gameStartTimestamp}
        />
      )}
      <div className={styles.EventsWrapper}>
        {gameChatEventsStore.fetched && !gameChatEventsStore.entries.length && (
          <EmptyState
            isBlackBackground
            title="No plays yet"
            message="Play-by-Play events will appear here since the game starts"
          />
        )}
        {!!gameChatEventsStore.entries.length && (
          <div className={styles.EventsList}>
            {gameChatEventsStore.entries.map((item, index) => {
              if (gameStore.entry) {
                return (
                  <div
                    ref={
                      gameChatEventsStore.entries.length <
                      index + GAME_EVENTS_AMOUNT_BEFORE_PRE_LOAD
                        ? setIntersectionObserver
                        : undefined
                    }
                    key={item.uuid}
                  >
                    <GameEvent
                      isLiked={item.isLiked}
                      onLikes={handleLikes}
                      eventType={item.eventType}
                      scoreType={item.scoreType}
                      team={
                        item.isHomeTeamEvent
                          ? gameStore.entry.home.teamInfo
                          : gameStore.entry.visitors.teamInfo
                      }
                      isTeamEvent={item.isTeamEvent}
                      isHomeTeamEvent={item.isHomeTeamEvent}
                      period={item.period}
                      clock={item.clock}
                      homeTeamPoints={item.homeTeamPoints}
                      visitorsTeamPoints={item.visitorsTeamPoints}
                      content={item.content}
                      date={item.date}
                      likes={item.likes}
                      eventId={item.uuid}
                      key={item.uuid}
                    />
                  </div>
                );
              }

              return null;
            })}
          </div>
        )}
        {gameChatEventsStore.fetching && (
          <div className={styles.EventsLoaderWrapper}>
            <Loader isShow />
          </div>
        )}
      </div>
    </>
  );
});
