import { IGameChatMessageResponse } from 'services/game-chat/interfaces/game-chat-messages.interface';

import { IGameChatMessage } from 'stores/game-chat/interfaces/game-chat-messages.interface';
import { gifAdapter } from 'stores/posts/adapters/gif-adapter.util';
import { imageAdapter } from 'stores/posts/adapters/image-adapter.util';
import { linkAdapter } from 'stores/posts/adapters/link-adapter.util';
import { pollAdapter } from 'stores/posts/adapters/poll-adapter.util';
import { publicationUserAdapter } from 'stores/posts/adapters/publication-adapter.util';
import { videoAdapter } from 'stores/posts/adapters/video-adapter.util';
import {
  IGifAttachment,
  IImageAttachment,
  ILinkAttachment,
  IPollAttachment,
  IVideoAttachment,
} from 'stores/posts/interfaces/post.interface';

import { formatPastDate } from 'helpers/format/format-past-date.util';

export function gameChatMessageAdapter(response: IGameChatMessageResponse): IGameChatMessage {
  const {
    is_liked: isLiked,
    likes_total: likes,
    item_type: itemType,
    uuid,
    content,
    date_created: timestamp,
    comments_amount: commentsAmount,
    user,
    attachments,
  } = response;
  const convertedImages: Maybe<IImageAttachment[]> = attachments?.images?.length
    ? attachments.images.map(imageAdapter)
    : null;

  const convertedVideos: Maybe<IVideoAttachment[]> = attachments?.videos?.length
    ? attachments.videos.map(videoAdapter)
    : null;

  const convertedGifs: Maybe<IGifAttachment[]> = attachments?.gifs?.length
    ? attachments.gifs.map(gifAdapter)
    : null;

  const convertedPoll: Maybe<IPollAttachment> = attachments?.poll
    ? { ...pollAdapter(attachments.poll) }
    : null;

  const convertedLinks: ILinkAttachment[] = attachments?.links?.length
    ? attachments.links.map(linkAdapter)
    : [];

  const isExistMediaAttachments: boolean = !!(
    convertedImages?.length ||
    convertedVideos?.length ||
    convertedGifs?.length
  );

  const filteredLinks: ILinkAttachment[] = convertedLinks.map((item) => {
    const updatedItem = { ...item };

    if (isExistMediaAttachments) {
      updatedItem.isEmbedded = false;
    }

    return updatedItem;
  });

  const embeddedLinks = isExistMediaAttachments
    ? []
    : filteredLinks.filter((item) => item.isEmbedded);

  const notEmbeddedLinks = isExistMediaAttachments
    ? convertedLinks
    : convertedLinks.filter((item) => !item.isEmbedded);

  return {
    isLiked,
    likes,
    itemType,
    commentsAmount,
    formattedDates: {
      timeOnly: formatPastDate(timestamp, 'timeOnly'),
      relativeLong: formatPastDate(timestamp, 'relativeLong'),
      relativeShort: formatPastDate(timestamp, 'relativeShort'),
      full: formatPastDate(timestamp),
    },
    uuid,
    content,
    user: publicationUserAdapter(user),
    attachments: {
      images: convertedImages,
      poll: convertedPoll,
      videos: convertedVideos,
      gifs: convertedGifs,
      links: notEmbeddedLinks,
    },
    embeddedLink: embeddedLinks.length ? embeddedLinks[0] : null,
  };
}
