import { memo, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';

import { ICategoryPlayerRankingsItem } from 'stores/player-rankings/interfaces/player-rankings.interface';

import { ENCODED_BULLET } from 'configs/encoded-symbols.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { useResponsive } from 'hooks/use-responsive';

import { GameResult } from 'components/game-result/game-result.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import {
  VectorIcon,
  VectorIconName,
  VectorIconSize,
} from 'components/ui/vector-icon/vector-icon.component';

import styles from './player-rankings-avatar.module.less';

export enum PlayerRankingsAvatarSize {
  SIZE_80 = 'SIZE_80',
  SIZE_56 = 'SIZE_56',
  SIZE_48 = 'SIZE_48',
}

interface IPlayerRankingsAvatar {
  iconUrl: string;
  rank: number;
  playerName: string;
  size: PlayerRankingsAvatarSize;
  teamLogoUrl: Maybe<string>;
  forDetails?: boolean;
  player?: ICategoryPlayerRankingsItem;
  onGameClick?: (gameId: string) => void;
  onPlayerClick?: (slug: string, gameId: string) => void;
}

export const PlayerRankingsAvatar = memo((props: IPlayerRankingsAvatar) => {
  const {
    iconUrl,
    rank,
    size,
    playerName,
    teamLogoUrl,
    player,
    onGameClick,
    onPlayerClick,
    forDetails = false,
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const sizeClasses = useMemo(
    () =>
      cn({
        [styles['Avatar--size-80']]: size === PlayerRankingsAvatarSize.SIZE_80,
        [styles['Avatar--size-56']]: size === PlayerRankingsAvatarSize.SIZE_56,
        [styles['Avatar--size-48']]: size === PlayerRankingsAvatarSize.SIZE_48,
        [styles['Avatar--size-48-for-detials']]:
          size === PlayerRankingsAvatarSize.SIZE_48 && forDetails,
      }),
    [size, forDetails],
  );

  const rankClasses = useMemo(
    () =>
      cn(styles.Rank, {
        [styles['Rank--first']]: rank === 1 && !forDetails,
        [styles['Rank--second']]: rank === 2 && !forDetails,
        [styles['Rank--third']]: rank === 3 && !forDetails,
      }),
    [rank, forDetails],
  );

  const teamClasses = useMemo(
    () =>
      cn(styles.Team, {
        [styles['Team--shifted']]: !forDetails && isDesktopPlus,
      }),
    [forDetails, isDesktopPlus],
  );

  const infoClasses = useMemo(() => cn(styles.Info, TOUCH_IGNORE_CLASS), []);

  const iconCup = useMemo(() => {
    if (rank === 2) {
      return VectorIconName.RankingSilver;
    }

    if (rank === 3) {
      return VectorIconName.RankingBronze;
    }

    return VectorIconName.RankingGold;
  }, [rank]);

  const isGameLive = useMemo(
    () => player?.game.status === GameStatus.Live || player?.game.status === GameStatus.Halftime,
    [player?.game.status],
  );

  const isFinishedGame = useMemo(
    () => player?.game.status === GameStatus.Finished,
    [player?.game.status],
  );

  const isHomeTeamPlayer = useMemo(() => player?.game.teams.home.id === player?.team.id, [player]);

  const isPlayerWin = useMemo(() => {
    if (!player) return false;

    if (isHomeTeamPlayer) {
      return player.game.teams.visitors.score.points < player.game.teams.home.score.points;
    }

    return player.game.teams.visitors.score.points > player.game.teams.home.score.points;
  }, [player, isHomeTeamPlayer]);

  const handleGameClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      if (player) {
        onGameClick?.(player.game.id.toString());
      }
    },
    [onGameClick, player],
  );

  const handlePlayerClick = useCallback(() => {
    if (player) {
      onPlayerClick?.(player.playerInfo.slug, player.game.id.toString());
    }
  }, [onPlayerClick, player]);

  return (
    <div role="presentation" className={styles.Avatar} onClick={handlePlayerClick}>
      <span className={rankClasses}>{rank}</span>
      {forDetails && rank <= 3 && (
        <div className={styles.Cup}>
          <VectorIcon size={VectorIconSize.S} name={iconCup} />
        </div>
      )}
      <img className={sizeClasses} src={iconUrl} alt={playerName} />
      <div className={teamClasses}>
        <Avatar size={AvatarSize.SIZE_20} username={playerName} src={teamLogoUrl} />
      </div>
      {player && (
        <div className={infoClasses}>
          <div className={styles.Info__Name}>{player.playerInfo.fullName}</div>
          <div className={styles.Info__Jercey}>#{player.playerInfo.jersey}</div>
          <div className={styles.Info__Position}>{player.playerInfo.position}</div>
          <div className={styles.Splitter}>{ENCODED_BULLET}</div>
          {isFinishedGame && isPlayerWin && <span className={styles.Info__Win}> W</span>}
          {isFinishedGame && !isPlayerWin && <span className={styles.Info__Lose}>L</span>}
          <GameResult
            isHomeTeamPlayer={isHomeTeamPlayer}
            game={player.game}
            onClick={handleGameClick}
          />
          {isGameLive && (
            <div className={styles.Info__LiveGamePart}>
              <div className={styles.Splitter}>{ENCODED_BULLET}</div>
              <div>{player.game.formattedPeriod}</div>
              <div>{player.game.clockFormatted}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
});
