export enum ButtonTapsEnum {
  OnboardingStartPickFavorites = 'onboarding_start_pick_favorites',
  OnboardingStartNotNow = 'onboarding_start_not_now',

  FollowTeamsPlayersSkip = 'follow_teams_players_skip',
  SkipFollowTeamPlayers = 'skip_follow_team_players',

  Headline = 'headline',
  ShortcutHeadline = 'shortcut_headline',

  YourFeedLivePlayer = 'your_feed_live_cc_player',
  YourFeedLiveTeam = 'your_feed_live_cc_team',

  SignUpGetCode = 'sign_up_get_code',
  SignUpCreateAccount = 'sign_up_create_account',
  SignUpLogin = 'sign_up_login',
  SignUpCancel = 'sign_up_cancel',

  LoginForgotPassword = 'login_forgot_password',
  LoginForgotPasswordGetCode = 'login_forgot_password_get_code',
  LoginForgotPasswordCancel = 'login_forgot_password_cancel',
  LoginSubmit = 'login_submit',
  LoginSignUp = 'login_sign_up',
  LoginCancel = 'login_cancel',

  HomeFeedComment = 'home_feed_comment',
  HomeFeedBookmark = 'home_feed_bookmark',
  HomeFeedShare = 'home_feed_share',
  HomeFeedCopyLink = 'home_feed_copy_link',

  ArenaShare = 'arena_share',
  CommentShare = 'comment_share',

  SaveFavoriteTeamsPlayers = 'save_favorite_teams_players',

  TeamProfileFeedComment = 'team_profile_feed_comment',
  TeamProfileFeedBookmark = 'team_profile_feed_bookmark',
  TeamProfileShare = 'team_profile_share',
  TeamProfileCopyLink = 'team_profile_copy_link',

  PlayerPostFeedComment = 'player_post_feed_comment',
  PlayerPostFeedBookmark = 'player_post_feed_bookmark',
  PlayerPostFeedShare = 'player_post_feed_share',
  PlayerPostFeedCopyLink = 'player_post_feed_copy_link',

  HomeFeed = 'home_feed',
  Arena = 'arena',
  Rankings = 'rankings',
  UserProfile = 'user_profile',

  Explore = 'explore',
  ExploreTeam = 'explore_team',
  ExploreEditFavorites = 'explore_edit_favorites',

  TeamSearch = 'team_search',
  PlayerSearch = 'player_search',
  HomeSearch = 'home_search',
  StatsSearch = 'stats_search',

  AccountProfileSettings = 'account_profile_settings',
  AccountProfileSettingsLogout = 'account_profile_settings_logout',

  PlayerPerformanceShare = 'player_performance_share',
  RankingsShare = 'rankings_share',

  PlayerProfileGamesFilterTeam = 'player_profile_games_filter_team',
  PlayerProfileGamesFilterNumberGames = 'player_profile_games_filter_num_games',
  PlayerProfileGamesFilterSeason = 'player_profile_games_filter_season',

  PlayerProfileFeedTopPlayer = 'player_profile_feed_top_player',
  PlayerProfileProfileSeeAll = 'player_profile_profile_see_all',
  PlayerProfileProfileAboutPlayerShowMore = 'player_profile_profile_about_player_show_more',
  PlayerProfileGamesCloseMonth = 'player_profile_games_close_month',
  PlayerProfileGamesOpenMonth = 'player_profile_games_open_month',
  PlayerProfileGamesLogTapped = 'player_profile_games_log_tapped',
  PlayerProfileFollow = 'player_profile_follow',

  PermissionsNotificationsAllow = 'permissions_notifications_allow',
  PermissionsNotificationsNotNow = 'permissions_notifications_not_now',
  PermissionsCameraAccessAllow = 'permissions_camera_access_allow',
  PermissionsCameraAccessNotNow = 'permissions_camera_access_not_now',
  PermissionsPhotoLibraryAllowNotNow = 'permissions_photo_library_allow_not_now',
  PermissionsPhotoLibraryAllow = 'permissions_photo_library_allow',

  IntroPromptTeamEnter = 'intro_prompt_team_team_enter',
  IntroPromptHomeEnter = 'intro_prompt_home_enter',
  IntroPromptRankingsEnter = 'intro_prompt_rankings_enter',
  IntroPromptArenaEnter = 'intro_prompt_arena_enter',
  IntroPromptPlayerProfileEnter = 'intro_prompt_player_profile_enter',

  ShortcutFavorite = 'shortcut_favorite',
  ShortcutGame = 'shortcut_game',
  ShortcutSelectFavorites = 'shortcut_select_favorites',
  ShortcutExpandFavorites = 'shortcut_expand_favorites',
  ShortcutExpandGames = 'shortcut_expand_games',
}
