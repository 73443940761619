import { IUserMeResponse } from 'services/auth/interfaces/user-me-response.interface';

import { publicLinkAdapterUtil } from 'stores/auth/adapters/public-link-adapter.util';
import { userProfileAthleteAdapter } from 'stores/auth/adapters/user-profile-athlete-adapter.util';
import { LinkType } from 'stores/auth/interfaces/custom-link.interface';

import { IUserMe } from '../interfaces/user-me.interface';

export function userMeAdapter(userMeResponse: IUserMeResponse): IUserMe {
  const { email, username, phone, profile, roles, uuid, athlete, links } = userMeResponse;

  const allLInks = links?.map(publicLinkAdapterUtil).sort((a, b) => a.id - b.id) ?? [];

  return {
    id: uuid,
    email,
    username,
    phone,
    roles,
    customLinks: allLInks.filter((link) => link.type === LinkType.Custom),
    socialLinks: allLInks.filter((link) => link.type !== LinkType.Custom),
    athlete: athlete ? userProfileAthleteAdapter(athlete) : null,
    profile: {
      biography: profile.bio,
      logo: profile.logo,
      realName: profile.real_name,
      status: profile.status,
      favoritePlayerId: profile?.favorite_player_id,
      favoriteTeamId: profile?.favorite_team_id,
      avatarUrl: profile.avatar_url,
      smallAvatarUrl: profile.small_avatar_url,
      thumbnailUrl: profile.thumbnail_url,
      smallThumbnailUrl: profile.small_thumbnail_url,
      thumbnailNicknameUrl: profile.thumbnail_nickname_url,
      thumbnailLogoUrl: profile.thumbnail_logo_url,
      playerProfileFirstVisited: profile.player_profile_first_visited,
      rankingsFirstVisited: profile.rankings_first_visited,
      mainFeedFirstVisited: profile.main_feed_first_visited,
      teamFeedFirstVisited: profile.team_feed_first_visited,
      arenaFirstVisited: profile.arena_first_visited,
    },
  };
}
