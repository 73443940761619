import {
  CSSProperties,
  FC,
  memo,
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useMemo,
} from 'react';
import cn from 'classnames';

import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './dropdown-button.module.less';

export enum DropdownButtonTheme {
  TeamBadge = 'teamBadge',
}

interface IDropdownButtonProps {
  label: string;
  disabled?: boolean;
  customStyles?: CSSProperties;
  theme?: DropdownButtonTheme;
  onClick: () => void;
}

export const DropdownButton: FC<IDropdownButtonProps> = memo(
  (props: PropsWithChildren<IDropdownButtonProps>) => {
    const { label, theme, onClick, disabled = false, customStyles = {} } = props;

    const dropdownButtonClasses = useMemo(
      () =>
        cn(styles.DropdownButton, {
          [styles['DropdownButton--teamBadge']]: theme === DropdownButtonTheme.TeamBadge,
        }),
      [theme],
    );

    const handleClick = useCallback(
      (event: SyntheticEvent) => {
        event.stopPropagation();
        onClick();
      },
      [onClick],
    );

    return (
      <button
        type="button"
        style={customStyles}
        onClick={handleClick}
        disabled={disabled}
        className={dropdownButtonClasses}
        name={label}
      >
        <span>{label}</span>
        <IconFont name={IconFontName.ChevronDown} size={IconFontSize.Big} />
      </button>
    );
  },
);
