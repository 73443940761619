import { z } from 'zod';

import { postTitleSchema } from 'validation/schemas/post-title.schema';

export const pollOptionsSchema = z
  .array(
    z.object({
      id: z.string(),
      title: postTitleSchema,
      order: z.number(),
      imageData: z
        .object({
          uuid: z.string(),
          url: z.string(),
          hash: z.string(),
          mimeType: z.string(),
          filename: z.string(),
        })
        .optional(),
    }),
  )
  .min(2, 'Min 2 options required')
  .max(4, 'Max 4 options are allowed');
