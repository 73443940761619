import { IUserMePayload } from 'services/auth/interfaces/user-me-payload.interface';

import { IUpdateUserParams } from 'stores/auth/interfaces/update-user.params.interface';

export const userMeUpdatePayloadBuilder = (
  params: IUpdateUserParams,
  shouldApplyFavoriteTeamBackground: boolean,
): IUserMePayload => {
  const payload: IUserMePayload = {};

  if (params.avatar) {
    payload.avatar = params.avatar;
  }

  if (params.favoritePlayerId || params.favoritePlayerId === null) {
    payload.favorite_player_id = params.favoritePlayerId;
  }

  if (params.favoriteTeamId || params.favoriteTeamId === null) {
    payload.favorite_team_id = params.favoriteTeamId;
    payload.favorite_team_thumbnail = shouldApplyFavoriteTeamBackground;
  }

  if (params.thumbnail) {
    payload.thumbnail = params.thumbnail;
  }

  if (params.realName) {
    payload.real_name = params.realName;
  }

  if (params.realName === '') {
    payload.real_name = null;
  }

  if (params.biography) {
    payload.bio = params.biography;
  }

  if (!params.biography) {
    payload.bio = '';
  }

  if (params.userName) {
    payload.username = params.userName;
  }

  if (params.mainFeedFirstVisited) {
    payload.main_feed_first_visited = params.mainFeedFirstVisited;
  }

  if (params.arenaFirstVisited) {
    payload.arena_first_visited = params.arenaFirstVisited;
  }

  if (params.playerProfileFirstVisited) {
    payload.player_profile_first_visited = params.playerProfileFirstVisited;
  }

  if (params.rankingsFirstVisited) {
    payload.rankings_first_visited = params.rankingsFirstVisited;
  }

  if (params.teamFeedFirstVisited) {
    payload.team_feed_first_visited = params.teamFeedFirstVisited;
  }

  return payload;
};
