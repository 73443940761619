import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import {
  COMMENT_ID_API_PARAM,
  PLAYER_ID_API_PARAM,
  TEAM_ID_API_PARAM,
  USER_SLUG_API_PARAM,
} from 'services/http/consts/api-endpoint-params.constants';
import { HttpService } from 'services/http/http.service';
import { HttpOptionsType } from 'services/http/interfaces/http-options.interface';
import { IUpdatePollPayload } from 'services/posts/interfaces/update-poll-payload.interface';

import { VideoUploadPayloadType } from 'stores/posts/interfaces/post.interface';
import {
  IInteractedPostsResponse,
  IPostsResponse,
} from 'stores/posts/interfaces/post-response.inerface';
import { FeedSectionEnum, SortEnum } from 'stores/posts/posts.config';
import { ShareResponse } from 'stores/share/types/share-response.type';

import { TYPES } from 'configs/di-types.config';
import { POLL_ID_PARAM, POLL_OPTION_ID_PARAM, POST_ID_PARAM } from 'routes/paths.constants';

import { FanZoneFilterTabs } from 'components/feed-filter-tabs/feed-filter-tabs.component';
import { feedTabsAdapter } from 'components/feed-filter-tabs/utils/feed-tabs-adapter.util';
import { IPollUpdateData, IPollVoteData } from 'components/ui/poll/interfaces/poll.interface';

import {
  ADD_POST_TO_BOOKMARK,
  DELETE_POST,
  PLAYER_FEED,
  POST_FAVORITES,
  POST_LIKE,
  POST_POLL_UPDATE,
  POST_POLL_VOTE,
  POSTS_TOP,
  PUBLIC_USER_POSTS,
  SHARE_POST,
  SHARE_POST_COMMENT,
  SINGLE_POST,
  TEAM_POSTS,
  VIDEOS_UPLOAD,
} from '../http/consts/api-endpoints.constants';

import {
  ICreatePlayerPostPayload,
  ICreateTeamPostPayload,
  IDeletePostPayload,
  IPostsSearchParams,
  ISharePostCommentPayload,
  ISharePostPayload,
  ITogglePostBookmarkPayload,
} from './interfaces/create-post-payload.interface';
import { IPollAttachmentResponse } from './interfaces/post-attachments-response.interface';
import {
  IDeletePostResponse,
  ILikesResponse,
  IPostResponse,
  ITogglePostBookmarkResponse,
  IVideoResponse,
} from './interfaces/posts-response.interface';

@injectable()
export class PostsService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchPlayerPosts(
    pagination: IPagination,
    playerId: string,
    isRegular: boolean,
    favorites: boolean,
    filter: Maybe<FanZoneFilterTabs>,
  ): Promise<IResponse<IPostsResponse>> {
    const { limit, page } = pagination;

    const localFilter = feedTabsAdapter(filter);

    const paramsObject: IPostsSearchParams = {
      sort: SortEnum.NEW,
      limit: limit.toString(),
      page: page.toString(),
      section: isRegular ? FeedSectionEnum.REGULAR : FeedSectionEnum.FAN_ZONE,
      ...(localFilter && { type: localFilter }),
      ...(favorites && { favorites: 'true' }),
    };

    return this.httpService.request({
      method: 'GET',
      url: PLAYER_FEED,
      headers: {
        'X-Version': '6',
      },
      routeParams: { [PLAYER_ID_API_PARAM]: playerId },
      params: new URLSearchParams({ ...paramsObject }),
    });
  }

  public async fetchPosts(
    pagination: IPagination,
    isRegular: boolean,
    isFavorites: boolean,
    filter: Maybe<FanZoneFilterTabs>,
  ): Promise<IResponse<IPostsResponse>> {
    const { limit, page } = pagination;

    const localFilter = feedTabsAdapter(filter);

    const paramsObject: IPostsSearchParams = {
      limit: limit.toString(),
      page: page.toString(),
      sort: SortEnum.NEW,
      section: isRegular ? FeedSectionEnum.REGULAR : FeedSectionEnum.FAN_ZONE,
      ...(localFilter && { type: localFilter }),
      ...(isFavorites && { favorites: 'true' }),
    };

    return this.httpService.request<IPostsResponse>({
      method: 'GET',
      headers: {
        'X-Version': '6',
      },
      url: POSTS_TOP,
      params: new URLSearchParams({ ...paramsObject }),
    });
  }

  public async fetchFavoritesPosts(pagination: IPagination): Promise<IResponse<IPostsResponse>> {
    const { limit, page } = pagination;

    const paramsObject: IPostsSearchParams = {
      limit: limit.toString(),
      page: page.toString(),
      sort: SortEnum.NEW,
      section: FeedSectionEnum.REGULAR,
    };

    return this.httpService.request<IPostsResponse>({
      method: 'GET',
      headers: {
        'X-Version': '4',
      },
      url: POST_FAVORITES,
      params: new URLSearchParams({ ...paramsObject }),
    });
  }

  public async fetchTeamPosts(
    teamId: number,
    pagination: IPagination,
    isRegular: boolean,
  ): Promise<IResponse<IPostsResponse>> {
    const { limit, page } = pagination;

    const paramsObject: IPostsSearchParams = {
      limit: limit.toString(),
      page: page.toString(),
      sort: SortEnum.NEW,
      section: isRegular ? FeedSectionEnum.REGULAR : FeedSectionEnum.FAN_ZONE,
    };

    return this.httpService.request<IPostsResponse>({
      method: 'GET',
      headers: {
        'X-Version': '6',
      },
      url: TEAM_POSTS,
      params: new URLSearchParams({ ...paramsObject }),
      routeParams: { [TEAM_ID_API_PARAM]: teamId.toString() },
    });
  }

  public async fetchPostById(
    postId: string,
    commentsSort?: SortEnum,
  ): Promise<IResponse<IPostResponse>> {
    const requestOptions: HttpOptionsType = {
      method: 'GET',
      url: SINGLE_POST,
      headers: {
        'X-Version': '6',
      },
      routeParams: { [POST_ID_PARAM]: postId },
    };

    if (commentsSort) {
      requestOptions.params = new URLSearchParams({ sort: commentsSort });
    }

    return this.httpService.request<IPostResponse>(requestOptions);
  }

  public async createPlayerPost(
    payload: ICreatePlayerPostPayload,
  ): Promise<IResponse<IPostResponse>> {
    const paramsObject = {
      section: payload.section,
    };
    const body = { ...payload, section: undefined, playerId: undefined };

    return this.httpService.request<
      IPostResponse,
      Omit<ICreatePlayerPostPayload, 'section' | 'playerId'>
    >({
      method: 'POST',
      headers: {
        'X-Version': '6',
      },
      url: PLAYER_FEED,
      body,
      routeParams: { [PLAYER_ID_API_PARAM]: payload.playerId.toString() },
      params: new URLSearchParams({ ...paramsObject }),
    });
  }

  public async createTeamPost(payload: ICreateTeamPostPayload): Promise<IResponse<IPostResponse>> {
    const paramsObject = {
      section: payload.section,
    };
    const body = { ...payload };

    return this.httpService.request<IPostResponse, ICreateTeamPostPayload>({
      method: 'POST',
      headers: {
        'X-Version': '6',
      },
      url: TEAM_POSTS,
      body,
      routeParams: { [TEAM_ID_API_PARAM]: payload.teamId.toString() },
      params: new URLSearchParams({ ...paramsObject }),
    });
  }

  public async togglePostBookmark(
    payload: ITogglePostBookmarkPayload,
  ): Promise<IResponse<ITogglePostBookmarkResponse>> {
    return this.httpService.request<ITogglePostBookmarkResponse, ITogglePostBookmarkPayload>({
      method: 'POST',
      headers: {
        'X-Version': '6',
      },
      url: ADD_POST_TO_BOOKMARK,
      routeParams: { [POST_ID_PARAM]: payload.id },
    });
  }

  public async deletePost(payload: IDeletePostPayload): Promise<IResponse<IDeletePostResponse>> {
    return this.httpService.request<IDeletePostResponse, IDeletePostPayload>({
      method: 'DELETE',
      headers: {
        'X-Version': '6',
      },
      url: DELETE_POST,
      routeParams: { [POST_ID_PARAM]: payload.postId },
    });
  }

  public async sharePost(payload: ISharePostPayload): Promise<IResponse<ShareResponse>> {
    return this.httpService.request<ShareResponse, ISharePostPayload>({
      method: 'POST',
      url: SHARE_POST,
      routeParams: { [POST_ID_PARAM]: payload.id },
    });
  }

  public async sharePostComment(
    payload: ISharePostCommentPayload,
  ): Promise<IResponse<ShareResponse>> {
    return this.httpService.request<ShareResponse, ISharePostCommentPayload>({
      method: 'POST',
      url: SHARE_POST_COMMENT,
      routeParams: { [POST_ID_PARAM]: payload.postId, [COMMENT_ID_API_PARAM]: payload.commentId },
    });
  }

  public async postPollVote(payload: IPollVoteData): Promise<IResponse<IPollAttachmentResponse>> {
    return this.httpService.request<IPollAttachmentResponse, IPollVoteData>({
      method: 'POST',
      headers: {
        'X-Version': '6',
      },
      url: POST_POLL_VOTE,
      routeParams: { [POLL_ID_PARAM]: payload.pollId, [POLL_OPTION_ID_PARAM]: payload.answerId },
    });
  }

  public async postPollUpdate(
    payload: IPollUpdateData,
  ): Promise<IResponse<IPollAttachmentResponse>> {
    return this.httpService.request<IPollAttachmentResponse, IUpdatePollPayload>({
      method: 'POST',
      url: POST_POLL_UPDATE,
      body: {
        quote_content: payload.quoteContent,
        stop_type: payload.stopPollType,
      },
      routeParams: { [POLL_ID_PARAM]: payload.pollId },
    });
  }

  public async postLikes(postId: string): Promise<IResponse<ILikesResponse>> {
    return this.httpService.request<ILikesResponse>({
      method: 'POST',
      headers: {
        'X-Version': '6',
      },
      url: POST_LIKE,
      routeParams: { [POST_ID_PARAM]: postId },
    });
  }

  public async uploadVideo(payload: VideoUploadPayloadType): Promise<IResponse<IVideoResponse>> {
    return this.httpService.request<IVideoResponse, VideoUploadPayloadType>({
      method: 'POST',
      url: VIDEOS_UPLOAD,
      body: payload,
      isMultipart: true,
    });
  }

  public fetchPublicUserPosts(
    pagination: IPagination,
    userSlug: string,
  ): Promise<IResponse<IInteractedPostsResponse>> {
    const { limit, page } = pagination;

    const paramsObject: IPostsSearchParams = {
      sort: SortEnum.NEW,
      limit: limit.toString(),
      page: page.toString(),
    };

    return this.httpService.request({
      method: 'GET',
      url: PUBLIC_USER_POSTS,
      routeParams: { [USER_SLUG_API_PARAM]: userSlug },
      params: new URLSearchParams({ ...paramsObject }),
    });
  }
}
