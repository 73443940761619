import { inject, injectable } from 'inversify';
import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { FollowService } from 'services/follow/follow.service';
import { SearchService } from 'services/search/search.service';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { AuthStore } from 'stores/auth/auth.store';
import { prepareSearchItem } from 'stores/search/adapters/prepare-search-items-adapter.util';
import { searchAdapter } from 'stores/search/adapters/search-results-adapter.util';
import {
  IPreparedSearchItem,
  IRecentSearchItem,
  ISearchAdapter,
  ISearchItem,
  ISearchItemResponse,
  ISearchResultsResponse,
  SearchResultType,
} from 'stores/search/interfaces/search.interface';
import { TeamsStore } from 'stores/teams/teams.store';

import { TYPES } from 'configs/di-types.config';
import { debounce } from 'helpers/debounce.util';

import { ISearchFormData } from 'components/forms/search-form/search-form.component';
import { SearchFilters } from 'components/global-search/global-search.config';

export enum SearchScreen {
  RecentHistory,
  Suggestion,
  SearchResults,
}

const SEARCH_PAGINATION_LIMIT = 20;

@injectable()
export class SearchStore extends AdvancedEntriesStore<
  ISearchItem,
  ISearchItemResponse,
  ISearchResultsResponse
> {
  private readonly authStore: AuthStore;

  private readonly teamsStore: TeamsStore;

  private readonly followService: FollowService;

  private readonly searchService: SearchService;

  public recentSearch: IRecentSearchItem[];

  public query: ISearchFormData;

  public isNeedForceInputFocus: boolean;

  public filter: SearchFilters;

  public screen: SearchScreen;

  public debouncedRefresh: () => void;

  constructor(
    @inject(TYPES.AuthStore) authStore: AuthStore,
    @inject(TYPES.TeamsStore) teamsStore: TeamsStore,
    @inject(TYPES.FollowService) followService: FollowService,
    @inject(TYPES.SearchService) searchService: SearchService,
  ) {
    super(SEARCH_PAGINATION_LIMIT, false);

    this.authStore = authStore;

    this.teamsStore = teamsStore;

    this.followService = followService;

    this.searchService = searchService;

    this.isNeedForceInputFocus = false;

    this.recentSearch = [];

    this.query = { searchPhrase: '' };

    this.filter = SearchFilters.ALL;

    this.screen = SearchScreen.RecentHistory;

    this.debouncedRefresh = debounce(this.refresh.bind(this), 500);

    makeObservable(this, {
      query: observable,
      recentSearch: observable,
      filter: observable,
      screen: observable,
      isNeedForceInputFocus: observable,

      preparedSearchItems: computed,

      refresh: action.bound,
      fetchNext: action.bound,
      setRecentSearch: action.bound,
      setIsNeedForceInputFocus: action.bound,
      setFilter: action.bound,
      setScreen: action.bound,
      setQuery: action.bound,
      loadRecentSearch: action.bound,
      deleteRecentItem: action.bound,
      createNewRecentItem: action.bound,
      followTeamOrPlayer: action.bound,
      debouncedRefresh: action.bound,
    });

    reaction(() => this.authStore.isAuthorised, this.handleIsAuthorisedChange);

    reaction(() => [this.query, this.filter], this.debouncedRefresh);

    autorun(() => this.loadRecentSearch());
  }

  public setRecentSearch(value: IRecentSearchItem[]) {
    this.recentSearch = value;
  }

  public setIsNeedForceInputFocus(value: boolean) {
    this.isNeedForceInputFocus = value;
  }

  public setQuery(value: ISearchFormData) {
    this.query = value;
  }

  public setFilter(value: SearchFilters) {
    this.filter = value;
  }

  public setScreen(value: SearchScreen) {
    this.screen = value;
  }

  public get preparedSearchItems(): IPreparedSearchItem[] {
    return this.entries.map((item) => prepareSearchItem(item, this.teamsStore.teams));
  }

  public async loadRecentSearch() {
    const response = await this.searchService.loadRecentSearch();

    if (response.success) {
      this.setRecentSearch(response.data);
    }
  }

  public async followTeamOrPlayer(resultId: number, type: SearchResultType, entityId: number) {
    let response;

    if (type === SearchResultType.PLAYER) {
      response = await this.followService.followPlayer(entityId);
    }

    if (type === SearchResultType.TEAM) {
      response = await this.followService.followTeam(entityId);
    }

    if (response?.success) {
      const newResults = this.entries.map((item) => {
        if (item.id === resultId) {
          return {
            ...item,
            options: {
              ...item.options,
              isFollowed: true,
            },
          };
        }

        return item;
      });

      this.setEntries(newResults);
    }
  }

  public async deleteRecentItem(id: string) {
    const response = await this.searchService.deleteRecentItem({ id });

    if (response.success) {
      const newResults = this.recentSearch.filter((item) => item.phrase !== response.data.phrase);

      this.setRecentSearch(newResults);
    }
  }

  public async createNewRecentItem() {
    const response = await this.searchService.createNewRecentSearch({
      phrase: this.query.searchPhrase,
    });

    if (response.success) {
      await this.loadRecentSearch();
    }
  }

  private handleIsAuthorisedChange = async () => {
    if (!this.authStore.isAuthorised) {
      this.setRecentSearch([]);
    } else {
      await this.loadRecentSearch();
    }
  };

  public async fetchNext(): Promise<void> {
    if (this.query.searchPhrase.length > 1) {
      const filterValue = this.filter === SearchFilters.ALL ? undefined : this.filter.toUpperCase();

      await this.retrieveNext(
        this.searchService.fetchSearch(this.pagination, this.query.searchPhrase, filterValue),
        <ISearchAdapter>searchAdapter,
      );
    }
  }

  public async refresh() {
    await super.refresh();
    await this.fetchNext();
  }
}
