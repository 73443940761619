import { memo, useMemo } from 'react';
import cn from 'classnames';

import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { IPollAnswer } from 'components/ui/poll/interfaces/poll.interface';
import { indexToLetter } from 'components/ui/poll/utils/index-to-letter.util';

import styles from './poll-attachments.module.less';

interface IPollAttachmentsProps {
  answers: IPollAnswer[];
  userAnswerId: Maybe<string>;
  handlePollAttachmentClick: (uuid: string) => void;
}

export const PollAttachments = memo((props: IPollAttachmentsProps) => {
  const { answers, userAnswerId, handlePollAttachmentClick } = props;

  const attachments = useMemo(() => {
    return answers
      .map((answer, index) => ({
        id: answer.uuid,
        image: answer.attachmentImage,
        marker: indexToLetter(index),
      }))
      .filter((item) => item.image);
  }, [answers]);

  return (
    <div className={styles.Poll__Attachments}>
      {attachments.map((attachment) => {
        const image = attachment.image!;
        const isVoted = attachment.id === userAnswerId;

        return (
          <div
            role="presentation"
            key={image.uuid}
            className={styles.Poll__Attachments__ImageWrapper}
            onClick={() => handlePollAttachmentClick(image.uuid)}
          >
            <div
              className={cn(styles.Poll__Attachments__Marker, {
                [styles.Poll__Attachments__Marker__Voted]: isVoted,
              })}
            >
              {isVoted && <IconFont name={IconFontName.Confirm} size={IconFontSize.Small} />}
              <span>{attachment.marker}</span>
            </div>
            <img src={image.url} alt={image.filename} />
          </div>
        );
      })}
    </div>
  );
});
