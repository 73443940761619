import { FC, memo, useCallback, useMemo } from 'react';
// import { useNavigate } from 'react-router';
import cn from 'classnames';

import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { IS_ENABLE_REPUTATION_POINTS } from 'configs/feature.config';

import { AvatarFrame } from 'components/profile-info/profile-info.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';
import { IReputationBoxItem } from 'components/user-details/reputation-box-item/interfaces/reputation-box-item.interface';
import { IReputationPoints } from 'components/user-details/reputation-box-item/interfaces/reputation-points.interface';
import { ReputationBoxItem } from 'components/user-details/reputation-box-item/reputation-box-item.component';
import { prepareReputationPointsItems } from 'components/user-details/utils/prepare-reputation-points-items';

import bronzeFrame from 'assets/images/svg/bronze-frame.svg';
import goldFrame from 'assets/images/svg/gold-frame.svg';
import silverFrame from 'assets/images/svg/silver-frame.svg';
import defaultThumbnailUrl from 'assets/images/svg/user-default-background.svg';

import styles from './user-details.module.less';

interface IUserDetailsProps {
  username: string;
  realName?: string;
  team: Maybe<ITeamsStats>;
  player: Maybe<IPlayerStats>;
  avatarSrc: Maybe<string>;
  thumbnailURL: Maybe<string>;
  reputationPoints: Maybe<IReputationPoints>;
  frame?: AvatarFrame;
  // onClose?: () => void;
}

const DEFAULT_THUMBNAIL_URL = defaultThumbnailUrl;

const AVATAR_FRAME_MAP: Record<AvatarFrame, string> = {
  [AvatarFrame.Bronze]: bronzeFrame,
  [AvatarFrame.Silver]: silverFrame,
  [AvatarFrame.Gold]: goldFrame,
};

export const UserDetails: FC<IUserDetailsProps> = memo((props: IUserDetailsProps) => {
  const {
    username,
    realName,
    team,
    player,
    thumbnailURL,
    avatarSrc,
    reputationPoints,
    frame,
    // onClose,
  } = props;

  // const navigate = useNavigate();

  const handleMockCallback = useCallback(() => {}, []);

  // const handleLeaderboardClick = useCallback(() => {
  //   onClose();
  //   navigate(paths.LEADERBOARD);
  // }, [onClose, navigate]);

  // const handleSeeAllAchievementsClick = useCallback(() => {
  //   onClose();
  //   navigate(paths.USER_ACHIEVEMENTS);
  // }, [onClose, navigate]);

  const reputationPointsBoxItems = useMemo<IReputationBoxItem[]>(() => {
    if (!reputationPoints) {
      const defaultPoints = {
        total: 0,
        totalRank: 0,
        prediction: 0,
        athlete: 0,
        participation: 0,
      };

      return prepareReputationPointsItems(defaultPoints);
    }
    return prepareReputationPointsItems(reputationPoints);
  }, [reputationPoints]);

  const avatarClasses = useMemo(() => {
    return cn(styles.AuthorCard__Avatar, {
      [styles.AuthorCard__Avatar__withFrame]: frame,
    });
  }, [frame]);

  return (
    <div className={styles.AuthorCard}>
      <div className={styles.AuthorCard__Header}>
        <div className={styles.AuthorCard__HeaderThumbnail}>
          <img
            src={thumbnailURL || DEFAULT_THUMBNAIL_URL}
            alt="thumbnail background"
            className={styles.AuthorCard__Background}
          />
        </div>
        <div className={avatarClasses}>
          {frame && (
            <>
              <img
                className={styles.AuthorCard__Avatar__Frame}
                src={AVATAR_FRAME_MAP[frame]}
                alt="avatar frame"
              />
              {/* Uncomment when backend is ready */}
              {/* <div className={styles.AuthorCard__Avatar__Badge}> */}
              {/*  <img */}
              {/*    src={playerAvatar} */}
              {/*    alt="achievement badge" */}
              {/*  /> */}
              {/* </div> */}
            </>
          )}
          <Avatar username={username} size={AvatarSize.MEGA} src={avatarSrc} />
        </div>
      </div>
      <div className={styles.AuthorCard__Details}>
        <div className={styles.AuthorCard__Names}>
          <h4>{username}</h4>
          <span>{realName}</span>
        </div>
        <TeamPlayerLabel
          team={team}
          player={player}
          onTeamClick={handleMockCallback}
          onPlayerClick={handleMockCallback}
        />
      </div>
      {IS_ENABLE_REPUTATION_POINTS && (
        <div className={styles.ReputationPoints}>
          <div className={styles.ReputationPoints__ReputationHeader}>
            <div className={styles.ReputationPoints__Title}>Reputation points</div>
            {/* <button className={styles.ReputationPoints__Action} onClick={handleLeaderboardClick}> */}
            {/*  Leaderboard */}
            {/* </button> */}
          </div>
          <div className={styles.ReputationPoints__Table}>
            {reputationPointsBoxItems.map((item) => (
              <ReputationBoxItem
                isTotal={item.title === 'Total'}
                key={item.title}
                title={item.title}
                content={item.content}
                rank={item.rank}
              />
            ))}
          </div>
          {/* TODO: Uncomment when backend is ready */}
          {/* <div className={styles.ReputationPoints__ReputationHeader}> */}
          {/*  <div className={styles.ReputationPoints__Title}>Achievements</div> */}
          {/*  <button */}
          {/*    className={styles.ReputationPoints__Action} */}
          {/*    onClick={handleSeeAllAchievementsClick} */}
          {/*  > */}
          {/*    See All */}
          {/*  </button> */}
          {/* </div> */}
        </div>
      )}
    </div>
  );
});
