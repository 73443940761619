import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { ConvertImageItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';
import { ICreatePostParams } from 'services/posts/interfaces/create-post-payload.interface';
import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { trimEditorContent } from 'helpers/trim-editor-content.util';

import { useResponsive } from 'hooks/use-responsive';

import {
  CreateUserGeneratedPostForm,
  CreateUserGeneratedPostFormDataType,
} from 'components/forms/create-user-generated-post/create-user-generated-post-form.component';
import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { ConfirmationModal } from 'components/modals/confirmation-modal/confirmation-modal.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './create-user-generated-post-modal.module.less';

interface ICreateUserGeneratedPostModalProps {
  isVisible: boolean;
  isFetching: boolean;
  isCreateDisabled: boolean;
  isPostVideoLoading: boolean;
  postVideo: Maybe<IVideoResponse>;
  onClose: () => void;
  onPostCreate: (post: ICreatePostParams) => void;
  onUploadVideo: (video: File) => void;
  onRemoveVideo: () => void;
  onVideoError?: (error: string) => void;
  convertImageItemToAttachment: ConvertImageItemToAttachmentType;
}

export const CreateUserGeneratedPostModal = (props: ICreateUserGeneratedPostModalProps) => {
  const {
    isVisible,
    isCreateDisabled,
    isFetching,
    isPostVideoLoading,
    postVideo,
    onClose,
    onPostCreate,
  } = props;

  const [modalSize, setModalSize] = useState<ModalWindowSize>(ModalWindowSize.M);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [isFormDirty, setIsFormDirty] = useState(false);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handlePostCreate = useCallback(
    (formData: CreateUserGeneratedPostFormDataType) => {
      const postObject: ICreatePostParams = {
        title: formData.title,
        attachments: {
          videos: [],
        },
      };

      if (formData.editor?.content && formData.editor?.content.length > 0) {
        const editorState = JSON.parse(formData.editor?.content?.value);
        const content = editorState?.root?.children;

        const newEditorState = {
          ...editorState,
          root: {
            ...editorState.root,
            children: trimEditorContent(content),
          },
        };

        postObject.content = JSON.stringify(newEditorState);
      }

      if (formData.editor?.attachments?.videos) {
        postObject.attachments.videos = formData.editor.attachments.videos;
      }

      if (formData.editor?.attachments?.gifs) {
        postObject.attachments.gifs = formData.editor.attachments.gifs;
      }

      if (formData.editor?.attachments?.images) {
        const imagesAttachments = formData.editor.attachments.images.map((attach) => attach.uuid);

        postObject.attachments = {
          ...postObject.attachments,
          images: imagesAttachments,
        };
      }

      onPostCreate(postObject);
    },
    [onPostCreate],
  );

  const handleModalClose = useCallback(() => {
    setConfirmationModalOpen(isFormDirty);

    if (!isFormDirty) {
      onClose();
    }
  }, [isFormDirty, onClose]);

  const handleConfirmationModalClose = useCallback(() => {
    setConfirmationModalOpen(false);
  }, []);

  const handleConfirmClosePost = useCallback(() => {
    setConfirmationModalOpen(false);
    onClose();
  }, [onClose]);

  const handleFormStateChange = useCallback((flag: boolean) => {
    setIsFormDirty(flag);
  }, []);

  const handleChangeSize = useCallback(() => {
    setModalSize((prev) => (prev === ModalWindowSize.M ? ModalWindowSize.L : ModalWindowSize.M));
  }, []);

  const expandIconButton = useMemo(() => {
    if (!isDesktopPlus) return null;

    return (
      <IconButton
        theme={IconButtonTheme.Secondary}
        iconName={
          modalSize === ModalWindowSize.M ? IconFontName.Fullscreen : IconFontName.Framescreen
        }
        onClick={handleChangeSize}
      />
    );
  }, [handleChangeSize, modalSize, isDesktopPlus]);

  const postCreateModalClasses = useMemo<string>(() => {
    return cn(styles.PostCreateModal, {
      [styles['PostCreateModal--blur']]: isFetching,
    });
  }, [isFetching]);

  return (
    <>
      <BaseModalComponent
        size={modalSize}
        title="Create Post"
        onClose={handleModalClose}
        visible={isVisible}
        isFullScreen={!isDesktopPlus}
      >
        <div className={postCreateModalClasses}>
          <CreateUserGeneratedPostForm
            convertImageItemToAttachment={props.convertImageItemToAttachment}
            isFullHeight={modalSize === ModalWindowSize.L}
            processing={isCreateDisabled}
            submitText="Create"
            onSubmit={handlePostCreate}
            topToolbarRight={expandIconButton}
            isPostVideoLoading={isPostVideoLoading}
            postVideo={postVideo}
            onUploadVideo={props.onUploadVideo}
            onRemoveVideo={props.onRemoveVideo}
            onFormStateChange={handleFormStateChange}
            onVideoError={props.onVideoError}
          />
        </div>
        <Loader isShow={isFetching} />
      </BaseModalComponent>
      <ConfirmationModal
        content="Are you sure you want to leave? The post will be deleted."
        onSuccessCallback={handleConfirmClosePost}
        onClose={handleConfirmationModalClose}
        title="Close Post"
        visible={confirmationModalOpen}
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
      />
    </>
  );
};
