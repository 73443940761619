import { forwardRef, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IonModal } from '@ionic/react';
import cn from 'classnames';

import { calculateIonBottomSheetBreakpoint } from './calculate-safe-area-bottom-breakpoint.util';

import styles from './base-ion-bottom-sheet.module.less';

const HANDLE_BUTTON_HEIGHT_AREA = 34; // px

interface IBaseIonBottomSheetProps {
  children: ReactNode;
  visible: boolean;
  canDismiss?: boolean;
  initialBreakpoint: number;
  breakpoints: number[];
  safeAreaBottom: number;
  isAutoHeight?: boolean;
  selectedBreakpoint?: Maybe<number>;
  isCustomSwipeLine?: boolean;
  isOverflowVisible?: boolean;
  onClose: () => void;
}

export const BaseIonBottomSheet = forwardRef<Maybe<HTMLIonModalElement>, IBaseIonBottomSheetProps>(
  (props, ref) => {
    const {
      children,
      visible,
      initialBreakpoint,
      breakpoints,
      safeAreaBottom,
      selectedBreakpoint,
      isAutoHeight = false,
      isCustomSwipeLine = false,
      isOverflowVisible = false,
      canDismiss = true,
      onClose,
    } = props;

    const modalRef = useRef<HTMLIonModalElement>(null);

    const [currentBreakpoint, setCurrentBreakpoint] = useState(0);
    const [bottomSheetHeight, setBottomSheetHeight] = useState('0px');

    const getFormattedBreakpoint = useCallback(
      (breakpoint: number) => {
        if (isAutoHeight) {
          return 1;
        }

        const safeAreaBottomBreakpoint = calculateIonBottomSheetBreakpoint(safeAreaBottom);
        const calculated = breakpoint + safeAreaBottomBreakpoint;

        if (calculated > 0.95) {
          return 0.95;
        }

        return calculated;
      },
      [safeAreaBottom, isAutoHeight],
    );

    const breakpointsWithSafeAreaBottom = useMemo(() => {
      const [, ...restBreakpoints] = breakpoints;

      const changedElements = restBreakpoints.map(getFormattedBreakpoint);
      const changedBreakpoints = [0, ...changedElements];

      return changedBreakpoints;
    }, [breakpoints, getFormattedBreakpoint]);

    useEffect(() => {
      const modalElement = modalRef.current;

      if (selectedBreakpoint && modalElement) {
        modalElement.setCurrentBreakpoint(getFormattedBreakpoint(selectedBreakpoint));
      }
    }, [selectedBreakpoint, getFormattedBreakpoint]);

    useEffect(() => {
      const bottomSheetRef = ref;

      if (bottomSheetRef && typeof bottomSheetRef === 'object') {
        bottomSheetRef.current = modalRef.current;
      }
    }, [ref]);

    useEffect(() => {
      setCurrentBreakpoint(getFormattedBreakpoint(initialBreakpoint));
    }, [initialBreakpoint, getFormattedBreakpoint]);

    useEffect(() => {
      const modalElement = modalRef.current;

      modalElement?.addEventListener('willDismiss', onClose);

      return () => {
        modalElement?.removeEventListener('willDismiss', onClose);
      };
    }, [onClose]);

    useEffect(() => {
      if (isAutoHeight) {
        setBottomSheetHeight('unset');
        return;
      }

      const calculatedHeight = window.innerHeight * currentBreakpoint;
      const currentVisibleHeight = calculatedHeight - HANDLE_BUTTON_HEIGHT_AREA;

      setBottomSheetHeight(`${currentVisibleHeight}px`);
    }, [currentBreakpoint, isAutoHeight]);

    const getDialogShadowNode = useCallback(() => {
      if (!visible) return null;

      const modalElement = modalRef.current;
      const shadowNode = modalElement?.shadowRoot;

      const childNodes = Array.from(shadowNode?.childNodes || []);
      const dialogNode = childNodes.find((element) =>
        (element as HTMLDivElement).classList.contains('modal-wrapper'),
      );

      return dialogNode || null;
    }, [visible]);

    useEffect(() => {
      const dialogNode = getDialogShadowNode();

      const handleTouchEvent = (event: Event) => {
        const target = event.target as HTMLElement;
        const isHandleButton = target?.classList.contains('modal-handle');

        if (!isHandleButton) {
          event.stopImmediatePropagation();
        }
      };

      dialogNode?.addEventListener('touchmove', handleTouchEvent, { passive: true });

      return () => {
        dialogNode?.removeEventListener('touchmove', handleTouchEvent);
      };
    }, [getDialogShadowNode]);

    useEffect(() => {
      const modalElement = modalRef.current;

      // The ionic doesn't provide to import its interfaces therefore `any` type
      const handleBreakpointChange = (event: any) => {
        if (event.detail.breakpoint === 0) {
          setCurrentBreakpoint(getFormattedBreakpoint(initialBreakpoint));
        } else {
          setCurrentBreakpoint(event.detail.breakpoint);
        }
      };

      modalElement?.addEventListener('ionBreakpointDidChange', handleBreakpointChange);

      return () => {
        modalElement?.removeEventListener('ionBreakpointDidChange', handleBreakpointChange);
      };
    }, [initialBreakpoint, getFormattedBreakpoint]);

    const ionBottomSheetClasses = useMemo(
      () =>
        cn(styles.IonBottomSheet, {
          [styles['IonBottomSheet--auto-height']]: isAutoHeight,
          [styles['IonBottomSheet--overflow-visible']]: isOverflowVisible,
          [styles['IonBottomSheet--custom-swipe-line']]: isCustomSwipeLine,
          [styles['IonBottomSheet--without-handle-element']]: !canDismiss,
        }),
      [isAutoHeight, isOverflowVisible, isCustomSwipeLine, canDismiss],
    );

    return (
      <IonModal
        canDismiss={canDismiss}
        ref={modalRef}
        isOpen={visible}
        initialBreakpoint={getFormattedBreakpoint(initialBreakpoint)}
        breakpoints={breakpointsWithSafeAreaBottom}
        backdropBreakpoint={0}
        className={ionBottomSheetClasses}
      >
        <div className={styles.IonBottomSheet__Wrapper} style={{ height: bottomSheetHeight }}>
          {children}
        </div>
      </IonModal>
    );
  },
);
