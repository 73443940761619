import { memo, useMemo } from 'react';

import { IAthlete } from 'stores/auth/interfaces/athlete.interface';

import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import {
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';

import styles from './poll-quote.module.less';

interface IPollQuoteProps {
  quoteContent: string;
  athlete?: Maybe<IAthlete>;
  isEditAllowed: boolean;
  onEditClick: () => void;
}

export const PollQuote = memo((props: IPollQuoteProps) => {
  const { quoteContent, athlete, isEditAllowed, onEditClick: handleEditClick } = props;

  const blockStyles = useMemo(
    () => ({
      background: athlete?.primaryColor,
    }),
    [athlete],
  );

  const gradientStyles = useMemo(
    () => ({
      background: `linear-gradient(to bottom right, ${athlete?.secondaryColor}, transparent 50%), linear-gradient(to top left, ${athlete?.secondaryColor}, transparent 50%)`,
    }),
    [athlete],
  );

  const iconStyles = useMemo(
    () => ({
      color: athlete?.secondaryColor,
    }),
    [athlete],
  );

  const textPreviewId = useMemo(() => {
    const parsedContent = JSON.parse(quoteContent);

    return parsedContent?.root?.children[0]?.children[0]?.text ?? 'poll-quote';
  }, [quoteContent]);

  return (
    <div className={styles.QuoteContentWrapper}>
      <div className={styles.QuoteContent} style={blockStyles}>
        <div className={styles.QuoteContent__Gradient} style={gradientStyles} />
        <div className={styles.QuoteSign} style={iconStyles}>
          <IconFont name={IconFontName.Quote} size={IconFontSize.Big} />
        </div>
        <RichTextPreview
          id={textPreviewId}
          editorState={quoteContent}
          editorType={RichTextContentTypes.FullHeight}
        />
      </div>
      {isEditAllowed && <button onClick={handleEditClick}>Edit Message</button>}
    </div>
  );
});
