import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import { ISliderItem } from 'containers/posts/config/feed.config';

import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';
import { TEAM_ID_PARAM } from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { FeedsSelectSlider } from 'components/ui/feeds-select-slider/feeds-select-slider.component';

import styles from './team-stats-toggle.module.less';

enum PlayerStatsTabs {
  Scores = 'Scores',
  Stats = 'Stats',
}

export const TeamStatsToggleContainer = observer(() => {
  const navigate = useNavigate();

  const params = useParams<{
    [TEAM_ID_PARAM]: string;
  }>();

  const { teamId } = params;

  const sliderItems = useMemo<ISliderItem[]>(() => {
    let scoresLink = '';
    let statsLink = '';

    if (teamId) {
      scoresLink = getPath(paths.TEAM_SCORES, {
        [paths.TEAM_ID_PARAM]: teamId.toString(),
      });

      statsLink = getPath(paths.TEAM_STATS, {
        [paths.TEAM_ID_PARAM]: teamId.toString(),
      });
    }

    return [
      {
        label: 'Scores',
        action: {
          type: BarAction.Link,
          path: scoresLink,
        },
        isDisabled: false,
        value: PlayerStatsTabs.Scores,
        isEnableNestedToggle: false,
        isLeftSide: false,
      },
      {
        label: 'Stats',
        action: {
          type: BarAction.Link,
          path: statsLink,
        },
        isDisabled: false,
        value: PlayerStatsTabs.Stats,
        isEnableNestedToggle: false,
        isLeftSide: false,
      },
    ];
  }, [teamId]);

  const handleSliderItemClick = useCallback(
    (action: BarActionType) => {
      if (action.type === BarAction.Link) {
        navigate(action.path, {
          replace: true,
        });
      }
    },
    [navigate],
  );

  return (
    <div className={styles.TeamStatsSlider}>
      <FeedsSelectSlider
        fullWidth
        isForFeed
        items={sliderItems}
        onItemClick={handleSliderItemClick}
      />
    </div>
  );
});
