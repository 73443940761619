import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import { SEARCH_QUERY_ID_API_PARAM } from 'services/http/consts/api-endpoint-params.constants';
import { HttpService } from 'services/http/http.service';
import { IRecentSearchResponseItem } from 'services/search/interfaces/recent-search-response.interface';

import {
  ICreateNewRecentSearchItemPayload,
  IDeleteRecentSearchItemPayload,
  IGlobalSearchParams,
  ISearchResultsResponse,
} from 'stores/search/interfaces/search.interface';

import { TYPES } from 'configs/di-types.config';

import {
  DELETE_RECENT_SEARCH_ITEM,
  GLOBAL_SEARCH,
  RECENT_SEARCH,
} from '../http/consts/api-endpoints.constants';

@injectable()
export class SearchService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public loadRecentSearch(): Promise<IResponse<IRecentSearchResponseItem[]>> {
    return this.httpService.request<IRecentSearchResponseItem[]>({
      url: RECENT_SEARCH,
      headers: {
        'X-Version': '6',
      },
      method: 'GET',
    });
  }

  public deleteRecentItem(
    payload: IDeleteRecentSearchItemPayload,
  ): Promise<IResponse<IRecentSearchResponseItem>> {
    return this.httpService.request<IRecentSearchResponseItem, IDeleteRecentSearchItemPayload>({
      method: 'DELETE',
      headers: {
        'X-Version': '6',
      },
      url: DELETE_RECENT_SEARCH_ITEM,
      routeParams: { [SEARCH_QUERY_ID_API_PARAM]: payload.id },
    });
  }

  public createNewRecentSearch(
    payload: ICreateNewRecentSearchItemPayload,
  ): Promise<IResponse<IRecentSearchResponseItem>> {
    return this.httpService.request<IRecentSearchResponseItem, ICreateNewRecentSearchItemPayload>({
      url: RECENT_SEARCH,
      method: 'PUT',
      body: payload,
    });
  }

  public fetchSearch(
    pagination: IPagination,
    phrase: string,
    filter?: string,
  ): Promise<IResponse<ISearchResultsResponse>> {
    const { limit, page } = pagination;

    const paramsObject: IGlobalSearchParams = {
      limit: String(limit),
      page: String(page),
      phrase,
    };

    if (filter) {
      paramsObject.type = filter;
    }

    return this.httpService.request<ISearchResultsResponse>({
      method: 'GET',
      headers: {
        'X-Version': '6',
      },
      url: GLOBAL_SEARCH,
      params: new URLSearchParams({ ...paramsObject }),
    });
  }
}
