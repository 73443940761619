import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutHeaderLeftActionEnum } from 'stores/layout/interfaces/layout-header-left.interface';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { UserAchievementsContainer } from 'containers/user-achievements/user-achievements.container';

import { useAllowSwipeBack } from 'hooks/use-allow-swipe-back';
import { useLayoutEntity } from 'hooks/use-layout-entity';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const UserAchievementsRoute = () => {
  useLayoutEntity({ type: LayoutEntity.PageTitle, value: 'Achievements' });
  useLayoutEntity({
    type: LayoutEntity.HeaderLeft,
    value: { icon: IconFontName.ChevronLeft, type: LayoutHeaderLeftActionEnum.Back },
  });

  useAllowSwipeBack();

  return (
    <PageWrapperContainer>
      <Row layout={RowLayout.MainContentExtended}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <UserAchievementsContainer />
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
