import { ForwardedRef, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GridReadyEvent, PaginationChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { getValueByPath } from 'helpers/get-value-by-path.util';

import { IHighlightRowOptions } from 'components/base-table/interfaces/highlight-row-options.interface';
import { PAGE_PARAM } from 'components/base-table/tables.configs';

export const useTablePagination = (
  ref: ForwardedRef<AgGridReact>,
  onGridReady?: (event: GridReadyEvent) => void,
  withUrlParam = false,
  highlightRowOptions?: Maybe<IHighlightRowOptions>,
  autoScrollColumn?: string,
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState<Maybe<number>>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get(PAGE_PARAM);

  const handleGridReady = useCallback(
    (event: GridReadyEvent) => {
      onGridReady?.(event);

      if (withUrlParam && pageParam) {
        event.api.paginationGoToPage(Number(pageParam) - 1);
      }

      setTotalPages(event.api.paginationGetTotalPages());

      if (autoScrollColumn) {
        event.api.ensureColumnVisible(autoScrollColumn);
      }

      try {
        if (highlightRowOptions) {
          event.api.forEachNodeAfterFilter((node) => {
            if (
              getValueByPath(highlightRowOptions.field, node.data) === highlightRowOptions.value
            ) {
              setTimeout(() => {
                const element = document.querySelector('.ag-row-highlighted');

                if (element) {
                  element.scrollIntoView({ block: 'center', behavior: 'smooth' });
                }
              }, 100);

              throw new Error('Break');
            }
          });
        }
      } catch {
        /* empty */
      }
    },
    [onGridReady, pageParam, withUrlParam, highlightRowOptions, autoScrollColumn],
  );

  const handlePaginationChanged = useCallback((event: PaginationChangedEvent) => {
    setCurrentPage(event.api.paginationGetCurrentPage() + 1);
  }, []);

  const handlePageClick = useCallback(
    (item: number) => {
      if (ref && typeof ref === 'object') {
        ref.current?.api.paginationGoToPage(item - 1);
      }

      if (withUrlParam) {
        searchParams.set(PAGE_PARAM, String(item));
        setSearchParams(searchParams, { replace: true });
      }
    },
    [ref, searchParams, setSearchParams, withUrlParam],
  );

  return {
    currentPage,
    totalPages,
    handleGridReady,
    handlePageClick,
    handlePaginationChanged,
  };
};
