import { FC, useMemo } from 'react';
import cn from 'classnames';

import { IContactUsRequestPayload } from 'services/auth/interfaces/contact-us-request-payload.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useKeyboardTrigger } from 'hooks/use-keyboard-trigger';
import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { ContactUsForm } from 'components/forms/contact-us/contact-us-form.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import contactUsSvg from 'assets/images/svg/contact-us.svg';

import { SUB_TITLE, TITLE } from './constants/contact-us.constants';

import styles from './contact-us.module.less';

interface IContactUsProps {
  onBackClick: () => void;
  onSubmit: (contactUsRequestPayload: IContactUsRequestPayload) => void;
  onFormStateChange: (flag: boolean) => void;
}

export const ContactUs: FC<IContactUsProps> = (props) => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const { isNativeApp } = useMainProvider();
  const { isKeyboardOpen } = useKeyboardTrigger(isNativeApp);

  const classes = useMemo<string>(() => {
    return cn(styles.ContactUs, {
      [styles['ContactUs--keyboard']]: isKeyboardOpen,
    });
  }, [isKeyboardOpen]);

  if (!isDesktopPlus) {
    return (
      <div className={classes}>
        <div className={styles.ContactUsContainer}>
          <div className={styles.ContactUs__Header}>
            <IconButton
              theme={IconButtonTheme.Transparent}
              iconName={IconFontName.ChevronLeft}
              onClick={props.onBackClick}
            />
            <h4 className={styles.ContactUs__Header__Title}>{TITLE}</h4>
          </div>
          <div className={styles.ContactUsContent}>
            <p className={styles.ContactUs__Header__SubTitle}>{SUB_TITLE}</p>
            <div className={styles.ContactUs__Image}>
              <img src={contactUsSvg} alt="basketball field" />
            </div>
            <ContactUsForm
              onSubmit={props.onSubmit}
              submitText="Submit"
              onFormStateChange={props.onFormStateChange}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.ContactUs}>
      <div className={styles.ContactUs__Header}>
        <div className={styles.ContactUs__Header__Text}>
          <h1 className={styles.ContactUs__Header__Title}>{TITLE}</h1>
          <p className={styles.ContactUs__Header__SubTitle}>{SUB_TITLE}</p>
        </div>
        <img src={contactUsSvg} alt="basketball field" />
      </div>
      <ContactUsForm
        onSubmit={props.onSubmit}
        submitText="Submit"
        onFormStateChange={props.onFormStateChange}
      />
    </div>
  );
};
