import {
  IPlayerRankingsItemResponse,
  IPlayerRankingsResponse,
} from 'services/player-rankings/interfaces/player-rankings-response.interface';

import { teamInfoAdapter } from 'stores/game/adapters/game-adapter.util';

import { formatISODate } from 'helpers/format/format-iso-date.util';

import { IPlayerRankings, IPlayerRankingsItem } from '../interfaces/player-rankings.interface';

export function playerRankingsAdapter(response: IPlayerRankingsResponse): IPlayerRankings {
  const { date, performers, points, rebounds, assists, blocks, steals } = response;

  return {
    date: formatISODate(date),
    performers: performers.map(playerRankingsItemAdapter),
    points: points.map(playerRankingsItemAdapter),
    rebounds: rebounds.map(playerRankingsItemAdapter),
    assists: assists.map(playerRankingsItemAdapter),
    blocks: blocks.map(playerRankingsItemAdapter),
    steals: steals.map(playerRankingsItemAdapter),
  };
}

function playerRankingsItemAdapter(response: IPlayerRankingsItemResponse): IPlayerRankingsItem {
  const {
    player_info: playerInfo,
    team,
    fantasy_points: fantasyPoints,
    points,
    is_live: isLive,
    tot_reb: rebounds,
    assists,
    blocks,
    steals,
    position,
    fantasy_points_rank: fantasyPointsRank,
    points_rank: pointsRank,
    tot_reb_rank: reboundsRank,
    assists_rank: assistsRank,
    blocks_rank: blocksRank,
    steals_rank: stealsRank,
    game_id: gameId,
  } = response;

  const { firstname, lastname, slug, small_logo_url: smallLogoUrl, jersey } = playerInfo;

  return {
    team: teamInfoAdapter(team),
    playerInfo: {
      slug,
      smallLogoUrl,
      jersey,
      position,
      fullName: `${firstname.charAt(0)}. ${lastname}`,
    },
    gameId: gameId || null,
    fantasyPoints,
    points,
    isLive,
    rebounds,
    assists,
    blocks,
    steals,
    fantasyPointsRank,
    pointsRank,
    reboundsRank,
    assistsRank,
    blocksRank,
    stealsRank,
  };
}
