import { memo, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IHookFormInput } from '../hook-form-input.interface';

import styles from './toggle.module.less';

interface IToggleProps extends IHookFormInput<boolean> {
  label?: string;
  forFeed?: boolean;
}

export const Toggle = memo((props: IToggleProps) => {
  const { id, name, value, label, onChange, disabled, forFeed } = props;

  const handleOnChange = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
  }, []);

  const handleWrapperClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      if (onChange && !disabled) {
        onChange(!value, name);
      }
    },
    [onChange, value, name, disabled],
  );

  const leftClassNames = useMemo(() => cn(styles.Toggle__Input, styles['Toggle__Input--left']), []);

  const rightClassNames = useMemo(
    () => cn(styles.Toggle__Input, styles['Toggle__Input--right']),
    [],
  );

  const toggleButtonClassNames = useMemo(
    () =>
      cn(styles.Toggle__Button, {
        [styles['Toggle__Button--feed']]: forFeed,
      }),
    [forFeed],
  );

  return (
    <div className={styles.ToggleWrapper} role="presentation" onClick={handleWrapperClick}>
      {!!label && <div className={styles.ToggleWrapper__Label}>{label}</div>}
      <div className={styles.Toggle}>
        <label htmlFor={`${id}-left`} className={toggleButtonClassNames}>
          <span className={styles.Toggle__Label}>Turn off toggler</span>
          <input
            id={`${id}-left`}
            className={leftClassNames}
            name={name}
            type="radio"
            checked={!value}
            onClick={handleOnChange}
            onChange={handleOnChange}
          />
        </label>
        <label htmlFor={`${id}-right`} className={toggleButtonClassNames}>
          <span className={styles.Toggle__Label}>Turn on toggler</span>
          <input
            id={`${id}-right`}
            className={rightClassNames}
            name={name}
            type="radio"
            checked={value}
            onClick={handleOnChange}
            onChange={handleOnChange}
          />
        </label>
      </div>
    </div>
  );
});
