import { useCallback, useEffect, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { AuthStore } from 'src/stores/auth/auth.store';

import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { IUpdateUserParams } from 'stores/auth/interfaces/update-user.params.interface';
import { LayoutStore } from 'stores/layout/layout.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';

import { ProfileTeamAndPlayerSelectForm } from 'components/forms/profile-team-and-player-select-form/profile-team-and-player-select-form.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';
import { IInitialTeamAndPlayer } from 'components/user-details/reputation-box-item/interfaces/initial-team-and-player.interface';

import styles from './select-team-and-player.module.less';

export const SelectTeamAndPlayerContainer = observer(() => {
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const userPublicStore = useInjection<UserPublicStore>(TYPES.UserPublicStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);
  const publicUserData = userPublicStore.userPublic;

  const isAthlete = Boolean(publicUserData?.athlete);

  const { teamsThumbnails, players, playersSelectOptions } = teamsStatsStore;

  const myUserData = authStore.userMe;

  const isMyPage = useMemo(() => {
    return myUserData?.username === publicUserData?.username;
  }, [myUserData?.username, publicUserData?.username]);

  const handleUpdateUser = useCallback(
    async (updateUserParams: IUpdateUserParams) => {
      if (authStore.fetching) {
        return;
      }

      if (
        updateUserParams.favoriteTeamId &&
        myUserData?.profile.thumbnailUrl &&
        !teamsThumbnails.includes(myUserData.profile.thumbnailUrl)
      ) {
        authStore.setShouldApplyFavoriteTeamBackground(false);

        if (!layoutStore.isTeamBackgroundUpdateModalOpen) {
          if (updateUserParams.favoriteTeamId === myUserData?.profile?.favoriteTeamId) {
            layoutStore.setIsTeamBackgroundUpdateModalOpen(false);
          } else {
            layoutStore.setIsTeamBackgroundUpdateModalOpen(true);
          }
        }
      }

      await authStore.updateUserMe(updateUserParams);
      await userPublicStore.retrieveUser();
    },
    [
      authStore,
      myUserData?.profile.thumbnailUrl,
      myUserData?.profile?.favoriteTeamId,
      teamsThumbnails,
      layoutStore,
      userPublicStore,
    ],
  );

  useEffect(() => {
    GoogleAnalyticService.event({
      eventName: 'screen_custom_view',
      eventParams: {
        screen_type: ScreenNamesEnum.UserProfile,
      },
    });
  }, []);

  const favoritePlayer = useMemo(
    () => players.find((player) => publicUserData?.favoritePlayerId === player.id) || null,
    [players, publicUserData?.favoritePlayerId],
  );

  const favoriteTeam = useMemo(
    () => teamsStatsStore.teams.find((team) => team.id === publicUserData?.favoriteTeamId) || null,
    [publicUserData, teamsStatsStore.teams],
  );

  const handleMockCallback = useCallback(() => {}, []);

  const initialTeamAndPlayer = useMemo(() => {
    const teamAndPlayer: IInitialTeamAndPlayer = {
      teamId: null,
      playerId: null,
    };

    if (favoriteTeam) {
      teamAndPlayer.teamId = favoriteTeam.id;
    }
    if (favoritePlayer) {
      teamAndPlayer.playerId = favoritePlayer.id;
    }

    return teamAndPlayer;
  }, [favoriteTeam, favoritePlayer]);

  if (isAthlete) {
    return null;
  }

  const memoizedInitialPlayerColor = useMemo(() => {
    if (favoritePlayer) {
      return favoritePlayer.color;
    }
    return null;
  }, [favoritePlayer]);

  const teamsOptions = teamsStatsStore.getTeamsSelectOptions('Team');

  const playersOptions = teamsStatsStore.playersSelectOptions;

  const handleSubmitTeamAndPlayer = useCallback(
    (data: IInitialTeamAndPlayer) => {
      const payload = {
        favoriteTeamId: data.teamId,
        favoritePlayerId: data.playerId,
      };
      handleUpdateUser(payload);
    },
    [handleUpdateUser],
  );

  return isMyPage &&
    players.length &&
    teamsStatsStore.teams.length &&
    !!teamsOptions.length &&
    !!playersOptions.length ? (
    <ProfileTeamAndPlayerSelectForm
      teamsOptions={teamsOptions}
      playersOptions={playersSelectOptions}
      initialValues={initialTeamAndPlayer}
      initialFavPlayerColor={memoizedInitialPlayerColor}
      setDisabledScroll={layoutStore.setDisabledScroll}
      onSubmit={handleSubmitTeamAndPlayer}
    />
  ) : (
    <div className={styles.TeamWrapper}>
      <TeamPlayerLabel
        team={favoriteTeam}
        player={favoritePlayer}
        onTeamClick={handleMockCallback}
        onPlayerClick={handleMockCallback}
      />
    </div>
  );
});
