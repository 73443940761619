import { Outlet } from 'react-router-dom';

import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { UserPublicProfileContainer } from 'containers/user-public-profile/user-public-profile.container';
import { UserPublicProfileHeaderContainer } from 'containers/user-public-profile-header/user-public-profile-header.container';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const UserPublicRoute = () => {
  return (
    <PageWrapperContainer>
      <UserPublicProfileContainer />
      <UserPublicProfileHeaderContainer />
      <Row layout={RowLayout.PublicProfile} withSpacing={false}>
        <Column>
          <Outlet />
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
