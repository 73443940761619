export enum StorageField {
  SignUpWithEmailPreserved = 'SignUpWithEmailPreserved',
  CountdownTimeSignUp = 'CountdownTimeSignUp',
  CountdownTimeForgotPassword = 'CountdownTimeForgotPassword',
  Session = 'Session',
  AnonymousSession = 'AnonymousSession',
  ForgotPasswordPreserved = 'ForgotPasswordPreserved',
  DeeplinkBanner = 'DeeplinkBanner',
  AppVersionState = 'AppVersionState',
  FirstTimeIntroShown = 'FirstTimeIntroShown',
  CurrentAppVersion = 'CurrentAppVersion',
  IsAppVersionChangeHandled = 'IsAppVersionChangeHandled',
}
