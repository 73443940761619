import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IPlayerStatsMini } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStatsColors } from 'stores/teams-stats/interfaces/teams-stats.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { StatsBox, StatsBoxTheme } from '../stats-box/stats-box.component';

import styles from './player-card.module.less';

enum PlayerCardStatsThemeEnum {
  OneRow,
  TwoRows,
}

interface IPhysicalItem {
  label: string;
  slug: string;
  value: number | string;
  suffix?: string;
}

interface IPlayerCardProps {
  player: IPlayerStatsMini;
  teamColors: Maybe<ITeamsStatsColors>;
  theme?: PlayerCardStatsThemeEnum;
  onPlayerClick: (slug: string) => void;
}

export const PlayerCard: FC<IPlayerCardProps> = (props) => {
  const { player, teamColors, theme = PlayerCardStatsThemeEnum.OneRow, onPlayerClick } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const physicalItems = useMemo<Maybe<IPhysicalItem[]>>(() => {
    if (player.age && player.heightFt && player.weightLbs) {
      return [
        {
          label: 'Age',
          slug: 'Age',
          value: player.age,
        },
        {
          label: 'Height',
          slug: 'Ht',
          value: player.heightFt,
          suffix: `"`,
        },
        {
          label: 'Weight',
          slug: 'Wt',
          value: player.weightLbs.toFixed(0),
          suffix: 'lb',
        },
      ];
    }

    return null;
  }, [player.age, player.heightFt, player.weightLbs]);

  const statsData = useMemo(() => {
    return [
      { title: 'PPG', content: player.points.toFixed(1) },
      { title: 'RPG', content: player.totalRebounds.toFixed(1) },
      { title: 'APG', content: player.assists.toFixed(1) },
      { title: 'BPG', content: player.blocks.toFixed(1) },
    ];
  }, [player]);

  const background = useMemo(() => {
    if (teamColors?.main) {
      return {
        background: `linear-gradient(180deg, ${teamColors.main} 0%, transparent 100%)`,
      };
    }

    return {};
  }, [teamColors?.main]);

  const playerColorStyle = useMemo(() => {
    if (teamColors?.text) {
      return { color: teamColors?.text };
    }

    return {};
  }, [teamColors?.text]);

  const playerStatsClassNames = useMemo(
    () =>
      cn(styles.PlayerCard__Stats, {
        [styles['PlayerCard__Stats--two-rows']]:
          !!player.topPerformerBy || theme === PlayerCardStatsThemeEnum.TwoRows,
      }),
    [player.topPerformerBy, theme],
  );

  const handlePlayerClick = useCallback(() => {
    onPlayerClick(player.slug);
  }, [onPlayerClick, player.slug]);

  return (
    <button className={styles.PlayerCard} onClick={handlePlayerClick}>
      <div className={styles.PlayerCard__Background} style={background}>
        <div className={styles.PlayerCard__JerseyPositionWrapper}>
          {player.jersey !== null && (
            <span className={styles.PlayerCard__Jersey}>
              #
              <span className={styles.PlayerCard__JerseyNumber} style={playerColorStyle}>
                {player.jersey}
              </span>
            </span>
          )}
          <div className={styles.PlayerCard__Position}>{player.position}</div>
        </div>
        <img
          className={styles.PlayerCard__Image}
          src={player.mediumLogoUrl}
          alt={player.firstname}
        />
      </div>
      <div className={styles.PlayerCard__Info}>
        <div className={styles.PlayerCard__Name} style={playerColorStyle}>
          {player.firstname} {player.lastname}
        </div>
        <div className={styles.PlayerCard__Physical}>
          {physicalItems &&
            physicalItems.map((item) => (
              <div key={item.slug} className={styles.PlayerCard__PhysicalItem}>
                <span className={styles.PlayerCard__PhysicalItemLabel}>
                  {isDesktopPlus ? item.label : item.slug}:
                </span>
                <span>
                  {item.value}
                  {item.suffix}
                </span>
              </div>
            ))}
        </div>
        <div className={playerStatsClassNames}>
          {statsData.map(({ title, content }) => (
            <StatsBox
              key={title}
              theme={StatsBoxTheme.Light}
              title={title}
              content={content}
              borderColor={player.topPerformerBy === title ? teamColors?.text : null}
            />
          ))}
        </div>
      </div>
    </button>
  );
};
