import { FC, SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import { CloseButtonProps } from 'react-toastify/dist/components';
import { Haptics, NotificationType as HapticsType } from '@capacitor/haptics';
import cn from 'classnames';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ApplicationStore } from 'stores/application/application.store';
import { AuthStore } from 'stores/auth/auth.store';
import { CommentsStore } from 'stores/comments/comments.store';
import { FollowStore } from 'stores/follow/follow.store';
import { GalleryStore } from 'stores/gallery/gallery.store';
import { GameChatStore } from 'stores/game-chat/game-chat.store';
import { HeadlinesStore } from 'stores/headlines/headlines.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { PostsStore } from 'stores/posts/posts.store';

import { TYPES } from 'configs/di-types.config';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { Portal, PortalType } from 'components/ui/portal/portal.component';

// react-toastify required styles
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-toastify/dist/ReactToastify.css';
import styles from './notifications.module.less';

interface ICustomToast {
  title: string;
  message: string;
}

const CustomToast: FC<ICustomToast> = ({ message, title }) => (
  <div className={styles.Toast}>
    <div className={styles.Toast__Title}>{title}</div>
    <div className={styles.Toast__Message}>{message}</div>
  </div>
);

const CloseButton = ({ closeToast: handleCloseToast }: CloseButtonProps) => (
  <IconButton
    theme={IconButtonTheme.Transparent}
    iconName={IconFontName.Close}
    onClick={handleCloseToast as (event: SyntheticEvent) => void}
  />
);

const hapticsNotificationSuccess = async (isNativeApp: boolean) => {
  if (isNativeApp) {
    await Haptics.notification({
      type: HapticsType.Success,
    });
  }
};

const hapticsNotificationError = async (isNativeApp: boolean) => {
  if (isNativeApp) {
    await Haptics.notification({
      type: HapticsType.Error,
    });
  }
};

const hapticsNotificationWarning = async (isNativeApp: boolean) => {
  if (isNativeApp) {
    await Haptics.notification({
      type: HapticsType.Warning,
    });
  }
};

export type NotificationType = 'Error' | 'Warning' | 'Success' | 'Info';

export const NotificationsContainer = observer(() => {
  const applicationStore = useInjection<ApplicationStore>(TYPES.ApplicationStore);
  const authStore = useInjection<AuthStore>(TYPES.AuthStore);
  const postStore = useInjection<PostsStore>(TYPES.PostsStore);
  const commentsStore = useInjection<CommentsStore>(TYPES.CommentsStore);
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const galleryStore = useInjection<GalleryStore>(TYPES.GalleryStore);
  const gameChatStore = useInjection<GameChatStore>(TYPES.GameChatStore);
  const followStore = useInjection<FollowStore>(TYPES.FollowStore);
  const headlinesStore = useInjection<HeadlinesStore>(TYPES.HeadlinesStore);

  const { isNativeApp } = applicationStore;

  const createCustomToast = useCallback(
    (type: NotificationType, message: string, autoClose: number | false = false) => {
      const options: ToastOptions = {
        className: 'error-toast-message',
        position: 'bottom-right',
        closeButton: true,
        closeOnClick: false,
        autoClose,
      };

      if (type === 'Error') {
        toast.error(<CustomToast message={message} title="Error" />, options);
        hapticsNotificationError(isNativeApp);
      }

      if (type === 'Success') {
        toast.success(<CustomToast message={message} title="Success" />, options);
        hapticsNotificationSuccess(isNativeApp);
      }

      if (type === 'Warning') {
        toast.warning(<CustomToast message={message} title="Warning" />, options);
        hapticsNotificationWarning(isNativeApp);
      }

      if (type === 'Info') {
        toast.info(<CustomToast message={message} title="Info" />, options);
      }
    },
    [isNativeApp],
  );

  const notificationClassNames = useMemo(
    () =>
      cn(styles.Notifications, {
        [styles['Notifications--hidden-bottom-bar']]: layoutStore.isHiddenBottomBar,
        [styles['Notifications--shown-gallery']]: galleryStore.isGalleryOpen,
      }),
    [layoutStore.isHiddenBottomBar, galleryStore.isGalleryOpen],
  );

  useEffect(() => {
    authStore.setErrorMethod(createCustomToast);
    postStore.setErrorMethod(createCustomToast);
    commentsStore.setErrorMethod(createCustomToast);
    galleryStore.setErrorMethod(createCustomToast);
    gameChatStore.setErrorMethod(createCustomToast);
    followStore.setErrorMethod(createCustomToast);
    headlinesStore.setErrorMethod(createCustomToast);
  }, [
    postStore,
    authStore,
    galleryStore,
    commentsStore,
    gameChatStore,
    followStore,
    headlinesStore,
    createCustomToast,
  ]);

  return (
    <Portal type={PortalType.Modal}>
      <div className={notificationClassNames}>
        <ToastContainer autoClose={false} closeButton={CloseButton} hideProgressBar />
      </div>
    </Portal>
  );
});
