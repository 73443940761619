import { IReputationBoxItem } from 'components/user-details/reputation-box-item/interfaces/reputation-box-item.interface';
import { IReputationPoints } from 'components/user-details/reputation-box-item/interfaces/reputation-points.interface';

export const prepareReputationPointsItems = (points: IReputationPoints): IReputationBoxItem[] => {
  const BOX_STATS_LIMITS = {
    total: {
      low: 25,
      high: 200,
    },
  };

  return [
    {
      title: 'Total',
      content: points.total,
      rank: { value: points.totalRank, limits: BOX_STATS_LIMITS.total },
    },
    {
      title: 'Prediction',
      content: points.prediction,
    },
    {
      title: 'Athlete',
      content: points.athlete,
    },
    {
      title: 'Participation',
      content: points.participation,
    },
  ];
};
