import { inject, injectable } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { GameService } from 'services/game/game.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { IoMessage } from 'stores/io/enums/io-message.enum';
import { IoStore } from 'stores/io/io.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { playerPerformanceAdapterUtil } from 'stores/player-performance/adapters/player-performance-adapter.util';
import {
  IPlayerPerformance,
  IPlayerPerformanceResponse,
} from 'stores/player-performance/interfaces/player-performance.interface';

import { TYPES } from 'configs/di-types.config';

@injectable()
export class PlayerPerformanceStore extends ApiConnectedStore {
  private readonly REACTION_DELAY_MS = 1000;

  private readonly ioStore: IoStore;

  private readonly layoutStore: LayoutStore;

  private readonly gameService: GameService;

  public lastJoinedRoomName: Maybe<string>;

  public playerSlug: Maybe<string>;

  public gameId: Maybe<string>;

  public entry: Maybe<IPlayerPerformance>;

  constructor(
    @inject(TYPES.IoStore) ioStore: IoStore,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
    @inject(TYPES.GameService) gameService: GameService,
  ) {
    super();

    this.gameService = gameService;

    this.ioStore = ioStore;

    this.layoutStore = layoutStore;

    this.playerSlug = null;

    this.gameId = null;

    this.entry = null;

    this.lastJoinedRoomName = null;

    makeObservable(this, {
      entry: observable,
      gameId: observable,
      playerSlug: observable,
      lastJoinedRoomName: observable,

      setEntry: action.bound,
      setGameId: action.bound,
      setPlayerSlug: action.bound,
      setLastJoinedRoomName: action.bound,
      fetchByPullToRefresh: action.bound,
      retrievePlayerPerformance: action.bound,
    });

    reaction(
      () => [this.gameId, this.playerSlug, this.ioStore.socket],
      this.retrievePlayerPerformance,
      {
        delay: this.REACTION_DELAY_MS,
      },
    );
  }

  private handlePerformanceUpdated = (performance: IPlayerPerformanceResponse) => {
    this.setEntry(playerPerformanceAdapterUtil(performance));
  };

  public async fetchByPullToRefresh() {
    this.layoutStore.setPulledRefresher(false);
  }

  public retrievePlayerPerformance = async () => {
    if (this.gameId && this.playerSlug) {
      const response: IResponse<IPlayerPerformanceResponse> =
        await this.gameService.fetchPlayerPerformanceInGame(this.gameId, this.playerSlug);
      if (response.success) {
        this.setEntry(playerPerformanceAdapterUtil(response.data));
      } else {
        this.setErrors(response.errors);
      }
    } else {
      this.reset();
    }

    this.updateSubscription();
  };

  private updateSubscription = () => {
    if (this.lastJoinedRoomName) {
      this.ioStore.socket.emit('leave', { room: this.lastJoinedRoomName });
    }

    if (this.gameId && this.playerSlug) {
      const roomName = `games:id:${this.gameId}:players:slug:${this.playerSlug}:statistics`;

      this.ioStore.emit(IoMessage.Join, { room: roomName });

      this.ioStore.socket.on(roomName, this.handlePerformanceUpdated);

      this.setLastJoinedRoomName(roomName);
    }
  };

  public setPlayerSlug(slug: Maybe<string>) {
    this.setFetched(false);
    this.setEntry(null);
    this.playerSlug = slug;
  }

  public setGameId(id: Maybe<string>) {
    this.setFetched(false);
    this.setEntry(null);
    this.gameId = id;
  }

  public setEntry(entry: Maybe<IPlayerPerformance>) {
    this.entry = entry;
  }

  public setLastJoinedRoomName(name: Maybe<string>) {
    this.lastJoinedRoomName = name;
  }

  public reset() {
    super.reset();

    this.setPlayerSlug(null);
    this.setEntry(null);
  }
}
