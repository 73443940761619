import React, { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import styles from './helper-text.module.less';

export enum HelperTextTheme {
  Error,
  Weak,
  Medium,
  Strong,
  VeryStrong,
  Regular,
}

interface IHelperTextProps {
  children: string;
  theme?: Maybe<HelperTextTheme>;
}

export const HelperText: FC<IHelperTextProps> = memo((props: IHelperTextProps) => {
  const { theme = HelperTextTheme.Regular, children } = props;

  const helperClassNames = useMemo(
    () =>
      cn(styles.Helper, {
        [styles['Helper--error']]: theme === HelperTextTheme.Error,
        [styles['Helper--weak']]: theme === HelperTextTheme.Weak,
        [styles['Helper--medium']]: theme === HelperTextTheme.Medium,
        [styles['Helper--strong']]: theme === HelperTextTheme.Strong,
        [styles['Helper--very-strong']]: theme === HelperTextTheme.VeryStrong,
        [styles['Helper--regular']]: theme === HelperTextTheme.Regular,
      }),
    [theme],
  );
  return <p className={helperClassNames}>{children}</p>;
});
