import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import { IGameTeamInfo } from 'stores/game/interfaces/game.interface';

import { Avatar, AvatarSize, IconAvatarSize } from 'components/ui/avatar/avatar.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { TeamPentagon } from 'components/ui/team-pentagon/team-pentagon.component';

import styles from './share-team.module.less';

interface IShareTeamProps {
  theme: 'Home' | 'Visitor';
  team: IGameTeamInfo;
}

export const ShareTeam: FC<IShareTeamProps> = memo((props: IShareTeamProps) => {
  const { team, theme } = props;

  const teamClassNames = useMemo(
    () =>
      cn(styles.Team, {
        [styles['Team--home']]: theme === 'Home',
        [styles['Team--visitor']]: theme === 'Visitor',
      }),
    [theme],
  );

  const teamColorStyle = useMemo(
    () => ({ color: team.teamInfo.secondaryTextColor }),
    [team.teamInfo.secondaryTextColor],
  );

  return (
    <div className={teamClassNames}>
      <div className={styles.Team__Items}>
        <TeamPentagon color={team.teamInfo.primaryColor} />
        <div className={styles.Team__Name}>
          <div className={styles.Team__Avatar}>
            <Avatar
              size={AvatarSize.M}
              iconFontName={theme === 'Home' ? IconFontName.Mention : undefined}
              iconSize={IconAvatarSize.S}
              username={team.teamInfo.code}
              src={team.teamInfo.mediumLogoUrl}
            />
          </div>
          <div className={styles.TeamStaticInfo__Name} style={teamColorStyle}>
            {team.teamInfo.nickname}
          </div>
        </div>
      </div>
    </div>
  );
});
