import { FC, memo, PropsWithChildren, ReactNode, useCallback } from 'react';

import { BarActionType } from 'components/bars/bar-action.type';

import { ContextMenu } from '../context-menu/context-menu.component';
import { IContextMenuGroup } from '../context-menu/interfaces/context-menu-group.interface';
import { ITooltipProps, Tooltip, TooltipEventType } from '../tooltip/tooltip.component';

interface IContextMenuTooltipProps {
  children: ReactNode;
  groups: IContextMenuGroup[];
  selectedItem?: string;
  tooltipProps?: Partial<ITooltipProps>;
  toggleOnClick?: boolean;
  isNeedCloseTooltip?: boolean;
  setIsNeedCloseTooltip?: (value: boolean) => void;
  onItemClick: (barAction: BarActionType) => void;
}

export const ContextMenuTooltip: FC<IContextMenuTooltipProps> = memo(
  (props: PropsWithChildren<IContextMenuTooltipProps>) => {
    const {
      children,
      groups,
      tooltipProps,
      selectedItem,
      toggleOnClick = false,
      isNeedCloseTooltip = false,
      setIsNeedCloseTooltip,
      onItemClick,
    } = props;

    const handleItemClick = useCallback(onItemClick, [onItemClick]);

    return (
      <Tooltip
        {...tooltipProps}
        eventType={TooltipEventType.click}
        toggleOnClick={toggleOnClick}
        isNeedCloseTooltip={isNeedCloseTooltip}
        setIsNeedCloseTooltip={setIsNeedCloseTooltip}
        tooltipContent={
          <ContextMenu selectedItem={selectedItem} onItemClick={handleItemClick} groups={groups} />
        }
      >
        {children}
      </Tooltip>
    );
  },
);
