import { inject, injectable } from 'inversify';
import { action, autorun, computed, makeObservable, observable, reaction } from 'mobx';

import { TeamsService } from 'services/teams/teams.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { AuthStore } from 'stores/auth/auth.store';
import { FollowStore } from 'stores/follow/follow.store';
import { NBA_FEED_ID } from 'stores/posts/posts.config';
import { teamAdapter } from 'stores/teams/adapters/team.adapter';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';

import { ITeam } from './interfaces/team.interface';
import { ITeamsBySubscription } from './interfaces/teams-by-subscription.interface';

@injectable()
export class TeamsStore extends ApiConnectedStore {
  private readonly authStore: AuthStore;

  private readonly followStore: FollowStore;

  private readonly teamsService: TeamsService;

  private readonly teamsStatsStore: ConvertClassToType<TeamsStatsStore>;

  public teams: ITeam[];

  public teamId: Maybe<number>;

  public isIdInvalid: boolean;

  public isJoinModalVisible: boolean;

  public isFollowedTeam: boolean;

  public teamToFollow: Maybe<ITeam>;

  constructor(
    @inject(TYPES.AuthStore) authStore: AuthStore,
    @inject(TYPES.FollowStore) followStore: FollowStore,
    @inject(TYPES.TeamsService) teamsService: TeamsService,
    @inject(TYPES.TeamsStatsStore) teamsStatsStore: ConvertClassToType<TeamsStatsStore>,
  ) {
    super();

    this.authStore = authStore;

    this.followStore = followStore;

    this.teamsService = teamsService;

    this.teamsStatsStore = teamsStatsStore;

    this.teams = [];

    this.teamId = null;

    this.isFollowedTeam = false;

    this.isIdInvalid = false;

    this.isJoinModalVisible = false;

    this.teamToFollow = null;

    makeObservable(this, {
      teams: observable,
      teamId: observable,
      isFollowedTeam: observable,
      isIdInvalid: observable,
      isJoinModalVisible: observable,
      teamToFollow: observable,

      getTeamsBySubscription: computed,

      setTeams: action.bound,
      setTeamId: action.bound,
      setIsIdInvalid: action.bound,
      setJoinModalVisible: action.bound,
      setIsFollowedTeam: action.bound,
      setTeamToFollow: action.bound,
    });

    reaction(
      () => [this.teamsStatsStore.position, this.teamsStatsStore.seasonId, this.teamId],
      this.updateTeamsStatsByFilter,
    );

    reaction(() => this.authStore.isAuthorised, this.handleAuthorisationChange);

    reaction(() => this.followStore.follow, this.handleSyncFollowings);

    reaction(() => this.teamId, this.handleTeamIdChange);

    autorun(() => this.initialise());
  }

  private updateTeamsStatsByFilter = async () => {
    if (this.teamId) {
      this.teamsStatsStore.fetchFilteredStatsByTeamId(this.teamId);
    }
  };

  private handleSyncFollowings = () => {
    this.fetchTeams();
  };

  private handleTeamIdChange = async () => {
    if (this.teamId) {
      const followedTeam = this.teams.find((team) => team.teamId === this.teamId);

      this.setIsFollowedTeam(!!followedTeam?.joined);

      await Promise.all([
        this.teamsStatsStore.fetchTeamStats(this.teamId),
        this.teamsStatsStore.fetchTeamsStatsByTeamId(this.teamId),
      ]);
    }
  };

  private handleAuthorisationChange = async () => {
    await this.initialise();

    if (this.teamId) {
      await this.teamsStatsStore.fetchTeamsStatsByTeamId(this.teamId);
    }
  };

  public setTeams(teams: ITeam[]) {
    this.teams = teams;
  }

  public setTeamId(teamId: Maybe<number>) {
    this.teamId = teamId;
  }

  public setIsFollowedTeam(isFollowedTeam: boolean) {
    this.isFollowedTeam = isFollowedTeam;
  }

  public setIsIdInvalid(isIdInvalid: boolean) {
    this.isIdInvalid = isIdInvalid;
  }

  public setJoinModalVisible(isJoinModalVisible: boolean) {
    this.isJoinModalVisible = isJoinModalVisible;
  }

  public setTeamToFollow(teamToFollow: Maybe<ITeam>) {
    this.teamToFollow = teamToFollow;
  }

  public get getTeamsBySubscription() {
    const initialValue: ITeamsBySubscription = {
      joined: [],
      explore: [],
    };

    return this.teams.reduce((acc, team) => {
      const teams = acc;
      const category = team.joined ? 'joined' : 'explore';

      teams[category] = [...(teams[category] || []), team];

      return teams;
    }, initialValue);
  }

  public async fetchTeams() {
    const response = await this.teamsService.fetchTeams();

    if (response.success) {
      const teams = response.data.items.map(teamAdapter);

      this.setTeams(teams);
    }
  }

  public async followTeam(): Promise<void> {
    const teamId = this.teamsStatsStore.team?.id || this.teamToFollow?.teamId || null;

    if (!teamId) return;

    this.setErrors([]);
    this.setFetching(true);

    const response = await this.teamsService.followTeam(teamId);

    if (response.success) {
      this.setIsFollowedTeam(true);
      await this.followStore.loadFollowings();
    } else {
      this.setErrors(response.errors);
    }

    this.setFetching(false);
  }

  public async unfollowTeam() {
    if (!this.teamsStatsStore.team) return;

    this.setErrors([]);
    this.setFetching(true);

    const response = await this.teamsService.unfollowTeam(this.teamsStatsStore.team?.id);

    if (response.success) {
      this.setIsFollowedTeam(false);
      await this.followStore.loadFollowings();
    } else {
      this.setErrors(response.errors);
    }

    this.setFetching(false);
  }

  public isUserJoinedToTeam(id: string): boolean {
    if (NBA_FEED_ID === id) {
      return false;
    }

    const team = this.findTeamById(Number(id));

    if (team) {
      this.setTeamToFollow(team);
    }

    return !team?.joined;
  }

  public findTeamById(id: number): Maybe<ITeam> {
    return this.teams.find((team) => team.teamId === id) || null;
  }

  public async initialise() {
    if (this.fetched) {
      return;
    }

    await this.fetchTeams();
    this.handleTeamIdChange();
  }

  public reset() {
    super.reset();

    this.setJoinModalVisible(false);
    this.setIsIdInvalid(false);
    this.setTeamId(null);

    this.teamsStatsStore.setTeam(null);
  }
}
