import { IPagination } from 'interfaces/pagination.interface';
import { inject, injectable } from 'inversify';

import {
  CONTENT_CARDS,
  FAVORITES_CONTENT_CARDS,
} from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';

import { TYPES } from 'configs/di-types.config';

import { ContentCardsType } from './enums/content-cards-type.enum';

@injectable()
export class ContentCardsService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public async fetchContentCards<T>(
    pagination: IPagination,
    type: ContentCardsType,
    playerSlug: Maybe<string>,
    teamId: Maybe<number>,
  ) {
    const { limit, page } = pagination;

    const paramsObject: any = {
      limit: String(limit),
      page: String(page),
      type,
    };

    if (playerSlug) {
      paramsObject.player_slug = playerSlug;
    }

    if (teamId) {
      paramsObject.team_id = teamId;
    }

    return this.httpService.request<IResponseItems<T>>({
      method: 'GET',
      url: CONTENT_CARDS,
      params: new URLSearchParams({ ...paramsObject }),
    });
  }

  public async fetchFavoritesContentCards<T>(pagination: IPagination, type: ContentCardsType) {
    const { limit, page } = pagination;

    const paramsObject = {
      limit: String(limit),
      page: String(page),
      type,
    };

    return this.httpService.request<IResponseItems<T>>({
      method: 'GET',
      url: FAVORITES_CONTENT_CARDS,
      params: new URLSearchParams({ ...paramsObject }),
    });
  }
}
