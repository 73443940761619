import { inject, injectable } from 'inversify';

import { IAnonymousUserMeResponse } from 'services/auth/interfaces/anonymous-user-me-response.interface';
import { ICreateCustomLinkPayload } from 'services/auth/interfaces/create-custom-link-payload.interface';
import { ILoginByPasswordPayload } from 'services/auth/interfaces/login-payload.interface';
import {
  ILoginResponse,
  ILoginWithThirdPartyResponse,
} from 'services/auth/interfaces/login-response.interface';
import { ILoginWithThirdPartyPayload } from 'services/auth/interfaces/login-with-third-party-payload';
import { ISignUpWithEmailPayload } from 'services/auth/interfaces/register-payload.interface';
import { IRegisterResponse } from 'services/auth/interfaces/register-response.interface';
import { ISessionResponse } from 'services/auth/interfaces/session-response.interface';
import { ISignInAnonymousPayload } from 'services/auth/interfaces/sign-in-anonymous.interface';
import {
  ISingleSocialLink,
  IUpdateSocialLinksPayload,
} from 'services/auth/interfaces/social-links-payload.interface';
import { IUserMePayload } from 'services/auth/interfaces/user-me-payload.interface';
import { IUserMeResponse } from 'services/auth/interfaces/user-me-response.interface';
import { IVerificationConfirmPayload } from 'services/auth/interfaces/verification-confirm-payload.interface';
import { IVerificationRequestPayload } from 'services/auth/interfaces/verification-request-payload.interface';
import { IVerificationRevokePayload } from 'services/auth/interfaces/verification-revoke-payload.interface';
import { LINK_ID_API_PARAM } from 'services/http/consts/api-endpoint-params.constants';
import {
  ALL_ATHLETES,
  AUTH_ANONYMOUS_USERS_ME,
  AUTH_FORGOT_PASSWORD_VERIFICATION_REQUEST,
  AUTH_RESET_PASSWORD,
  AUTH_RESET_PASSWORD_VERIFICATION_CONFIRM,
  AUTH_SIGN_IN,
  AUTH_SIGN_IN_APPLE,
  AUTH_SIGN_IN_GOOGLE,
  AUTH_SIGN_UP,
  AUTH_SIGN_UP_ANONYMOUS,
  AUTH_SIGN_UP_VERIFICATION_CONFIRM,
  AUTH_SIGN_UP_VERIFICATION_REQUEST,
  AUTH_USERS_ME,
  AUTH_VERIFICATION_REVOKE,
  CONTACT_US,
  CUSTOM_LINKS,
  PRIVACY_POLICY,
  SOCIAL_LINKS,
  TERMS_OF_USE,
  UPDATE_CUSTOM_LINKS,
  USER_AGREEMENT,
} from 'services/http/consts/api-endpoints.constants';
import { HttpService } from 'services/http/http.service';
import { StorageField } from 'services/storage/enum/storage-field.enum';
import { StorageService } from 'services/storage/storage.service';

import { IAthleteResponse } from 'stores/auth/interfaces/athlete.interface';
import { ICountdownTime } from 'stores/auth/interfaces/countdown-time.interface';
import { IPublicLinkResponse } from 'stores/auth/interfaces/custom-link.interface';
import {
  IForgotPasswordPreserved,
  IResetPasswordVerificationResponse,
  ISetNewPasswordPayload,
  ISetNewPasswordResponse,
} from 'stores/auth/interfaces/forgot-password.interface';
import { ISignUpWithEmailPreserved } from 'stores/auth/interfaces/sign-up-preserved.interface';

import { TYPES } from 'configs/di-types.config';

import { IContactUsRequestPayload } from './interfaces/contact-us-request-payload.interface';
import { IContactUsResponse } from './interfaces/contact-us-response.interface';
import { IPolicyResponse } from './interfaces/policy-response.interface';

@injectable()
export class AuthService {
  private readonly httpService: HttpService;

  private readonly storageService: StorageService;

  constructor(
    @inject(TYPES.HttpService) httpService: HttpService,
    @inject(TYPES.StorageService) storageService: StorageService,
  ) {
    this.httpService = httpService;

    this.storageService = storageService;
  }

  public async getSession(): Promise<Maybe<ISessionResponse>> {
    return this.storageService.get<ISessionResponse>(StorageField.Session);
  }

  public async getAnonymousSession(): Promise<Maybe<ISessionResponse>> {
    return this.storageService.get<ISessionResponse>(StorageField.AnonymousSession);
  }

  public async getSignUpPreserved(): Promise<Maybe<ISignUpWithEmailPreserved>> {
    return this.storageService.get<ISignUpWithEmailPreserved>(
      StorageField.SignUpWithEmailPreserved,
      true,
      true,
    );
  }

  public fetchUserMe() {
    return this.httpService.request<IUserMeResponse>({
      method: 'GET',
      url: AUTH_USERS_ME,
      headers: {
        'X-Version': '2',
      },
    });
  }

  public fetchAnonymousUserMe() {
    return this.httpService.request<IAnonymousUserMeResponse>({
      method: 'GET',
      url: AUTH_ANONYMOUS_USERS_ME,
    });
  }

  public updateUser(payload: IUserMePayload): Promise<IResponse<IUserMeResponse>> {
    return this.httpService.request<IUserMeResponse, IUserMePayload>({
      method: 'PATCH',
      url: AUTH_USERS_ME,
      body: payload,
      headers: {
        'X-Version': '2',
      },
    });
  }

  public deleteUser(): Promise<IResponse<null>> {
    return this.httpService.request<null>({
      method: 'DELETE',
      url: AUTH_USERS_ME,
    });
  }

  public loginByPassword(payload: ILoginByPasswordPayload): Promise<IResponse<ILoginResponse>> {
    return this.httpService.request<ILoginResponse, ILoginByPasswordPayload>({
      method: 'POST',
      url: AUTH_SIGN_IN,
      body: payload,
    });
  }

  public async fetchRefreshedSession(
    session: ISessionResponse,
  ): Promise<IResponse<ISessionResponse>> {
    return this.httpService.fetchRefreshedSession(session);
  }

  public loginByGoogle(
    payload: ILoginWithThirdPartyPayload,
  ): Promise<IResponse<ILoginWithThirdPartyResponse>> {
    return this.httpService.request<ILoginWithThirdPartyResponse, ILoginWithThirdPartyPayload>({
      method: 'POST',
      url: AUTH_SIGN_IN_GOOGLE,
      body: payload,
    });
  }

  public loginByApple(
    payload: ILoginWithThirdPartyPayload,
  ): Promise<IResponse<ILoginWithThirdPartyResponse>> {
    return this.httpService.request<ILoginWithThirdPartyResponse, ILoginWithThirdPartyPayload>({
      method: 'POST',
      url: AUTH_SIGN_IN_APPLE,
      body: payload,
    });
  }

  public signUpWithEmail(payload: ISignUpWithEmailPayload): Promise<IResponse<IRegisterResponse>> {
    return this.httpService.request<IRegisterResponse, ISignUpWithEmailPayload>({
      url: AUTH_SIGN_UP,
      method: 'POST',
      body: payload,
    });
  }

  public signUpAnonymously(
    payload: ISignInAnonymousPayload,
  ): Promise<IResponse<IRegisterResponse>> {
    return this.httpService.request<IRegisterResponse, ISignInAnonymousPayload>({
      url: AUTH_SIGN_UP_ANONYMOUS,
      method: 'POST',
      body: payload,
    });
  }

  public verificationConfirm(payload: IVerificationConfirmPayload): Promise<IResponse> {
    return this.httpService.request<undefined, IVerificationConfirmPayload>({
      url: AUTH_SIGN_UP_VERIFICATION_CONFIRM,
      method: 'POST',
      body: payload,
    });
  }

  public verificationRevoke(payload: IVerificationRevokePayload): Promise<IResponse> {
    return this.httpService.request<undefined, IVerificationRevokePayload>({
      url: AUTH_VERIFICATION_REVOKE,
      method: 'POST',
      body: payload,
    });
  }

  public verificationRequest(payload: IVerificationRequestPayload): Promise<IResponse> {
    return this.httpService.request<undefined, IVerificationRequestPayload>({
      url: AUTH_SIGN_UP_VERIFICATION_REQUEST,
      method: 'POST',
      body: payload,
    });
  }

  public resetSignUpWithEmailPreserved(): void {
    this.storageService.remove(StorageField.SignUpWithEmailPreserved);
  }

  public resetSession(): void {
    this.storageService.remove(StorageField.Session);
  }

  public resetAnonymousSession(): void {
    this.storageService.remove(StorageField.AnonymousSession);
  }

  public saveSignUpWithEmailPreserved(signUpPreserved: ISignUpWithEmailPreserved): void {
    this.storageService.set<ISignUpWithEmailPreserved>(
      StorageField.SignUpWithEmailPreserved,
      signUpPreserved,
      true,
    );
  }

  public saveCountdownTimeSignUp(time: Maybe<ICountdownTime>): void {
    this.storageService.set<Maybe<ICountdownTime>>(StorageField.CountdownTimeSignUp, time, true);
  }

  public async getCountdownTimeSignUp(): Promise<Maybe<ICountdownTime>> {
    return this.storageService.get<ICountdownTime>(StorageField.CountdownTimeSignUp);
  }

  public saveCountdownTimeForgotPassword(time: Maybe<ICountdownTime>): void {
    this.storageService.set<Maybe<ICountdownTime>>(
      StorageField.CountdownTimeForgotPassword,
      time,
      true,
    );
  }

  public async getCountdownTimeForgotPassword(): Promise<Maybe<ICountdownTime>> {
    return this.storageService.get<ICountdownTime>(StorageField.CountdownTimeForgotPassword);
  }

  public saveSession(session: ISessionResponse): void {
    this.storageService.set(StorageField.Session, session);
  }

  public saveAnonymousSession(anonymousSession: ISessionResponse): void {
    this.storageService.set(StorageField.AnonymousSession, anonymousSession);
  }

  public async getForgotPasswordPreserved(): Promise<Maybe<IForgotPasswordPreserved>> {
    return this.storageService.get<IForgotPasswordPreserved>(
      StorageField.ForgotPasswordPreserved,
      true,
      true,
    );
  }

  public cleanForgotPasswordPreserved(): void {
    this.storageService.remove(StorageField.ForgotPasswordPreserved);
  }

  public saveForgotPasswordPreserved(forgotPasswordPreserved: IForgotPasswordPreserved): void {
    this.storageService.set<IForgotPasswordPreserved>(
      StorageField.ForgotPasswordPreserved,
      forgotPasswordPreserved,
      true,
    );
  }

  public forgotPasswordVerificationRequest(
    payload: IVerificationRequestPayload,
  ): Promise<IResponse> {
    return this.httpService.request<undefined, IVerificationRequestPayload>({
      url: AUTH_FORGOT_PASSWORD_VERIFICATION_REQUEST,
      method: 'POST',
      body: payload,
    });
  }

  public resetPasswordVerificationConfirm(
    payload: IVerificationConfirmPayload,
  ): Promise<IResponse<IResetPasswordVerificationResponse>> {
    return this.httpService.request<
      IResetPasswordVerificationResponse,
      IVerificationConfirmPayload
    >({
      url: AUTH_RESET_PASSWORD_VERIFICATION_CONFIRM,
      method: 'POST',
      body: payload,
    });
  }

  public setNewPassword(
    payload: ISetNewPasswordPayload,
  ): Promise<IResponse<ISetNewPasswordResponse>> {
    return this.httpService.request<ISetNewPasswordResponse, ISetNewPasswordPayload>({
      url: AUTH_RESET_PASSWORD,
      method: 'POST',
      body: payload,
    });
  }

  public fetchPrivacyPolicy(): Promise<IResponse<IPolicyResponse>> {
    return this.httpService.request<IPolicyResponse>({
      url: PRIVACY_POLICY,
      method: 'GET',
    });
  }

  public fetchTermsOfUse(): Promise<IResponse<IPolicyResponse>> {
    return this.httpService.request<IPolicyResponse>({
      url: TERMS_OF_USE,
      method: 'GET',
    });
  }

  public fetchUserAgreement(): Promise<IResponse<IPolicyResponse>> {
    return this.httpService.request<IPolicyResponse>({
      url: USER_AGREEMENT,
      method: 'GET',
    });
  }

  public fetchDigitsAthletes(): Promise<IResponse<IAthleteResponse[]>> {
    return this.httpService.request<IAthleteResponse[]>({
      url: ALL_ATHLETES,
      method: 'GET',
    });
  }

  public contactUsRequest(
    payload: IContactUsRequestPayload,
  ): Promise<IResponse<IContactUsResponse>> {
    return this.httpService.request<IContactUsResponse, IContactUsRequestPayload>({
      method: 'POST',
      url: CONTACT_US,
      body: payload,
    });
  }

  public createCustomLink(
    payload: ICreateCustomLinkPayload,
  ): Promise<IResponse<IPublicLinkResponse>> {
    return this.httpService.request<IPublicLinkResponse, ICreateCustomLinkPayload>({
      method: 'POST',
      url: CUSTOM_LINKS,
      body: payload,
    });
  }

  public updateCustomLink(
    id: string,
    payload: ICreateCustomLinkPayload,
  ): Promise<IResponse<IPublicLinkResponse>> {
    return this.httpService.request<IPublicLinkResponse, ICreateCustomLinkPayload>({
      method: 'PATCH',
      url: UPDATE_CUSTOM_LINKS,
      body: payload,
      routeParams: { [LINK_ID_API_PARAM]: id },
    });
  }

  public updateSocialLinks(
    payload: IUpdateSocialLinksPayload,
  ): Promise<IResponse<ISingleSocialLink[]>> {
    return this.httpService.request<ISingleSocialLink[], IUpdateSocialLinksPayload>({
      method: 'POST',
      url: SOCIAL_LINKS,
      body: payload,
    });
  }

  public deleteCustomLink(id: string): Promise<IResponse<IPublicLinkResponse>> {
    return this.httpService.request<IPublicLinkResponse, ICreateCustomLinkPayload>({
      method: 'DELETE',
      url: UPDATE_CUSTOM_LINKS,
      routeParams: { [LINK_ID_API_PARAM]: id },
    });
  }
}
