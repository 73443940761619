import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import { ISliderItem } from 'containers/posts/config/feed.config';

import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';
import { PLAYER_SLUG_PARAM } from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { FeedsSelectSlider } from 'components/ui/feeds-select-slider/feeds-select-slider.component';

import styles from './player-stats-toggle.module.less';

enum PlayerStatsTabs {
  Games = 'Games',
  Seasons = 'Seasons',
  Splits = 'Splits',
}

export const PlayerStatsToggleContainer = observer(() => {
  const navigate = useNavigate();

  const params = useParams<{
    [PLAYER_SLUG_PARAM]: string;
  }>();

  const { playerSlug } = params;

  const sliderItems = useMemo<ISliderItem[]>(() => {
    let gamesLink = '';
    let seasonsLink = '';
    let splitsLink = '';

    if (playerSlug) {
      gamesLink = getPath(paths.PLAYER_PROFILE_GAMES, {
        [paths.PLAYER_SLUG_PARAM]: playerSlug,
      });

      seasonsLink = getPath(paths.PLAYER_PROFILE_STATS, {
        [paths.PLAYER_SLUG_PARAM]: playerSlug,
      });

      splitsLink = getPath(paths.PLAYER_PROFILE_SPLITS, {
        [paths.PLAYER_SLUG_PARAM]: playerSlug,
      });
    }

    return [
      {
        label: 'Games',
        action: {
          type: BarAction.Link,
          path: gamesLink,
        },
        isDisabled: false,
        value: PlayerStatsTabs.Games,
        isEnableNestedToggle: false,
        isLeftSide: false,
      },
      {
        label: 'Seasons',
        action: {
          type: BarAction.Link,
          path: seasonsLink,
        },
        isDisabled: false,
        value: PlayerStatsTabs.Seasons,
        isEnableNestedToggle: false,
        isLeftSide: false,
      },
      {
        label: 'Splits',
        action: {
          type: BarAction.Link,
          path: splitsLink,
        },
        isDisabled: false,
        value: PlayerStatsTabs.Splits,
        isEnableNestedToggle: false,
        isLeftSide: false,
      },
    ];
  }, [playerSlug]);

  const handleSliderItemClick = useCallback(
    (action: BarActionType) => {
      if (action.type === BarAction.Link) {
        navigate(action.path, {
          replace: true,
        });
      }
    },
    [navigate],
  );

  return (
    <div className={styles.HeaderFeedSlider}>
      <FeedsSelectSlider
        fullWidth
        isForFeed
        items={sliderItems}
        onItemClick={handleSliderItemClick}
      />
    </div>
  );
});
