import { forwardRef, SyntheticEvent, useCallback } from 'react';

import { IFormField } from 'components/ui/form-fields/form-field.interface';
import { Input } from 'components/ui/form-fields/input/input.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export interface ITextInputProps extends IFormField {
  leftIconName?: Maybe<IconFontName>;
  type?: 'email' | 'text';
  onFocus?: () => void;
}

export const TextInput = forwardRef<HTMLInputElement, ITextInputProps>(
  (props: ITextInputProps, ref) => {
    const { type = 'text', onChange, onBlur, ...rest } = props;

    const handleChange = useCallback(
      (currentValue: string) => {
        if (onChange) {
          onChange(currentValue);
        }
      },
      [onChange],
    );

    const handleBlur = useCallback(
      (event: SyntheticEvent) => {
        if (onBlur) {
          onBlur(event);
        }
      },
      [onBlur],
    );

    return (
      <Input
        {...rest}
        ref={ref}
        type={type}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={props.onFocus}
      />
    );
  },
);
