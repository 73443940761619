const STATS_PROPERTIES_ABBREVIATIONS = {
  minutes: 'MIN',
  points: 'PTS',
  rebounds: 'REB',
  assists: 'AST',
  blocks: 'BLK',
  steals: 'STL',
  plusMinus: '+/-',
  tsp: 'TS%',
  fgm: 'FGM',
  fga: 'FGA',
  fgp: 'FG%',
  tpm: '3PT',
  tpa: '3PA',
  tpp: '3PT%',
  ftm: 'FT',
  fta: 'FTA',
  ftp: 'FT%',
  offReb: 'OREB',
  defReb: 'DREB',
  turnovers: 'TO',
  personalFouls: 'PF',
  fantasyPoints: 'FPS',
};

export type StatsPropertyAbbreviationsType = keyof typeof STATS_PROPERTIES_ABBREVIATIONS;

export const getStatsPropertyAbbreviationByKey = (key: StatsPropertyAbbreviationsType) => {
  return STATS_PROPERTIES_ABBREVIATIONS[key];
};
