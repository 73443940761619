import * as paths from 'routes/paths.constants';

export const SCROLL_TO_TOP_PATHS = [
  paths.HOME_SCORES,
  paths.HOME_STANDINGS,
  paths.HOME_STATS_PLAYERS,
  paths.HOME_STATS_PLAYERS_ALL,
  paths.HOME_STATS_TEAMS,
  paths.HOME_STATS_TEAMS_ALL,
];

export const SCROLL_TO_TOP_PARAM_PATHS = [
  paths.TEAM_SCORES,
  paths.PLAYER_PROFILE_FEED,
  paths.PLAYER_PROFILE_GAMES,
  paths.PLAYER_PROFILE_OVERVIEW,
  paths.PLAYER_PROFILE_SPLITS,
  paths.PLAYER_PROFILE_STATS,
];
