import { memo, useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { IGameContent } from 'stores/content-cards/interfaces/content-cards.interface';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import mentionIcon from 'assets/images/svg/mention.svg';

import { PostsTheme } from '../post-card/enums/posts-theme.enum';

import { PlayerBoard } from './player-board/player-board.component';
import { Scoreboard } from './scoreboard/scoreboard.component';

import styles from './game-content.module.less';

export interface IGameContentProps {
  theme?: PostsTheme;
  contentData: Omit<IGameContent, 'type'>;
  onClick?: (gameId: string, teamsName: string, gameDate: string) => void;
  isExpandByDefault: boolean;
}

export const GameContent = memo((props: IGameContentProps) => {
  const { theme = PostsTheme.Regular, contentData, onClick, isExpandByDefault } = props;
  const [isExpand, setIsExpand] = useState(isExpandByDefault);

  const handleExpandCardClick = useCallback(() => {
    setIsExpand(true);
  }, []);

  const {
    gameId,
    gameDate,
    gameStatus,
    isFinishedGame,
    homeTeamCode,
    homeTeamLogoUrl,
    homeTeamScore,
    homeTeamPlayers,
    visitorsTeamCode,
    visitorsTeamLogoUrl,
    visitorsTeamScore,
    visitorsTeamPlayers,
  } = contentData;

  const gameContentClasses = useMemo(
    () =>
      cn(styles.GameContent, {
        [styles['GameContent--default']]: !isExpand,
      }),
    [isExpand],
  );

  const gameContentHeaderClasses = useMemo(
    () =>
      cn(styles.GameContent__Header, {
        [styles['GameContent__Header--expand']]: isExpand,
      }),
    [isExpand],
  );

  const handleGameContentClick = useCallback(() => {
    onClick?.(String(gameId), `${visitorsTeamCode} vs ${homeTeamCode}`, gameDate);
  }, [gameId, visitorsTeamCode, homeTeamCode, gameDate, onClick]);

  return (
    <div className={gameContentClasses}>
      <div
        role="presentation"
        className={gameContentHeaderClasses}
        onClick={handleGameContentClick}
      >
        <div className={styles.GameContentTeam}>
          <div className={styles.GameContentTeam__Avatar}>
            <Avatar src={visitorsTeamLogoUrl} username={visitorsTeamCode} size={AvatarSize.L} />
          </div>
          <h4 className={styles.GameContentTeam__Name}>{visitorsTeamCode}</h4>
        </div>
        <Scoreboard
          isFinishedGame={isFinishedGame}
          gameStatus={gameStatus}
          homeTeamScore={homeTeamScore}
          visitorsTeamScore={visitorsTeamScore}
        />
        <div className={styles.GameContentTeam}>
          <h4 className={styles.GameContentTeam__Name}>{homeTeamCode}</h4>
          <div className={styles.GameContentTeam__Avatar}>
            <Avatar src={homeTeamLogoUrl} username={homeTeamCode} size={AvatarSize.L} />
            <div className={styles.GameContentTeam__Icon}>
              <img src={mentionIcon} alt={homeTeamCode} />
            </div>
          </div>
        </div>
      </div>
      {!isExpand ? (
        <Button
          iconName={IconFontName.ChevronDown}
          size={ButtonSize.Small}
          theme={ButtonTheme.Text}
          onClick={handleExpandCardClick}
          className={styles.GameContent__Expand}
        >
          Expand
        </Button>
      ) : (
        <>
          <div className={styles.GameContent__Row}>
            <span className={styles.GameContent__Text}>Starting lineup</span>
            <span className={styles.GameContent__Date}>{gameDate}</span>
          </div>
          <div className={styles.GameContent__Content}>
            <div className={styles.GameContent__Items}>
              {visitorsTeamPlayers.map((playerData) => (
                <PlayerBoard key={playerData.playerId} theme={theme} playerData={playerData} />
              ))}
            </div>
            <div className={styles.GameContent__Items}>
              {homeTeamPlayers.map((playerData) => (
                <PlayerBoard key={playerData.playerId} theme={theme} playerData={playerData} />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
});
