import { z } from 'zod';

const textAllowedSchema = z.boolean();

const mediaTypeSchema = z.enum(['image', 'video']).nullable();

export const groupPostResponseSchema = z.object({
  textAllowed: textAllowedSchema,
  media: mediaTypeSchema,
});
