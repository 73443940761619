import { createContext, ReactNode, useMemo } from 'react';
import { IMainProviderValue } from 'interfaces/main-provider-value';

import { MAIN_PROVIDER_DEFAULT_VALUE } from 'configs/main-provider-default-value';

interface IMainProviderProps extends IMainProviderValue {
  children: ReactNode;
}

export const MainProviderInstance = createContext<IMainProviderValue>(MAIN_PROVIDER_DEFAULT_VALUE);

export const MainProvider = (props: IMainProviderProps) => {
  const { children, isNativeApp, isNativeAndroidApp, isAuthorised, enableSignUpMode } = props;

  const contextValue = useMemo(() => {
    return {
      isNativeApp,
      isNativeAndroidApp,
      isAuthorised,
      enableSignUpMode,
    };
  }, [isNativeApp, isNativeAndroidApp, isAuthorised, enableSignUpMode]);

  return (
    <MainProviderInstance.Provider value={contextValue}>{children}</MainProviderInstance.Provider>
  );
};
