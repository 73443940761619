import { forwardRef, useCallback } from 'react';
import ReactCodeInput, { ReactCodeInputProps } from 'react-code-input';
import cn from 'classnames';

import { CODE_INPUT_FIELDS, CODE_INPUT_INPUT_MODE, CODE_INPUT_TYPE } from 'configs/controls.config';

import { FieldWrapper } from 'components/ui/form-fields/field-wrapper/field-wrapper.component';
import { IFormField } from 'components/ui/form-fields/form-field.interface';

import styles from './code-input.module.less';

export interface ICodeInputProps
  extends Pick<ReactCodeInputProps, 'fields' | 'inputMode' | 'type'>,
    IFormField {}

export const CodeInput = forwardRef<ReactCodeInput, ICodeInputProps>((props, ref) => {
  const {
    disabled = false,
    error,
    id,
    label,
    name,
    type = CODE_INPUT_TYPE,
    fields = CODE_INPUT_FIELDS,
    inputMode = CODE_INPUT_INPUT_MODE,
    value,
    onChange,
  } = props;

  const handleChange = useCallback(
    (currentValue: string) => {
      if (onChange) {
        onChange(currentValue);
      }
    },
    [onChange],
  );

  return (
    <FieldWrapper {...{ error, id, label }}>
      {({ isInvalid }) => (
        <ReactCodeInput
          {...{ disabled, fields, inputMode, name, type, value, ref }}
          autoFocus={!error}
          className={cn(styles.Input__container, {
            [styles['Input__container--invalid']]: isInvalid,
          })}
          onChange={handleChange}
        />
      )}
    </FieldWrapper>
  );
});
