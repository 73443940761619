import { IAthlete, IAthleteResponse } from 'stores/auth/interfaces/athlete.interface';

import { convertColorToRGB, convertColorToRGBA } from 'helpers/rgb-to-rgba.utils';

export function userProfileAthleteAdapter(athleteResponse: IAthleteResponse): Maybe<IAthlete> {
  if (!athleteResponse) {
    return null;
  }

  const {
    player_slug: playerSlug,
    medium_logo_url: mediumLogoUrl,
    lastname: lastName,
    firstname: firstName,
    small_logo_url: smallLogoUrl,
    primary_color: primaryColor,
    secondary_color: secondaryColor,
  } = athleteResponse;

  return {
    primaryColor: convertColorToRGB(primaryColor),
    primaryColorWithOpacity: convertColorToRGBA(primaryColor, 0.7),
    primaryColorWithZeroOpacity: convertColorToRGBA(primaryColor, 0),
    secondaryColor: convertColorToRGB(secondaryColor),
    secondaryColorWithOpacity: convertColorToRGBA(secondaryColor, 0.7),
    playerSlug,
    firstName,
    lastName,
    smallLogoUrl,
    mediumLogoUrl,
  };
}
