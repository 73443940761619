import { useCallback } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ReputationsPointsStore } from 'stores/reputations-points/reputations-points.store';
import { SeasonsStore } from 'stores/seasons/seasons.store';

import { TYPES } from 'configs/di-types.config';

import { UserAchievements } from 'components/user-achievements/user-achievements.component';

export const UserAchievementsContainer = observer(() => {
  const seasonsStore = useInjection<SeasonsStore>(TYPES.SeasonsStore);
  const reputationsPointsStore = useInjection<ReputationsPointsStore>(TYPES.ReputationsPointsStore);

  const handleSeasonIdChange = useCallback(
    (seasonId: string) => {
      reputationsPointsStore.setAchievementSeasonId(seasonId);
    },
    [reputationsPointsStore],
  );

  return (
    <UserAchievements
      seasonId={reputationsPointsStore.achievementSeasonId ?? ''}
      seasonsOptions={seasonsStore.reputationSeasonsSelectOptions}
      onSeasonIdChange={handleSeasonIdChange}
    />
  );
});
