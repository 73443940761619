import { memo, PropsWithChildren, useCallback, useMemo } from 'react';
import { Placement } from '@floating-ui/core';
import cn from 'classnames';

import { useMainProvider } from 'hooks/use-main-provider';

import { Tooltip, TooltipEventType } from 'components/ui/tooltip/tooltip.component';

import styles from './auth-tooltip.module.less';

interface IAuthTooltipProps {
  placement?: Placement;
  alignLeft?: boolean;
}
export const AUTH_TOOLTIP_CLASS = 'auth-tooltip-class';

export const AuthTooltip = memo((props: PropsWithChildren<IAuthTooltipProps>) => {
  const { children, placement = 'top', alignLeft } = props;
  const { isAuthorised, enableSignUpMode: handleEnableSignUpMode } = useMainProvider();

  const onAllowOpeningCheck = useCallback(() => {
    return !isAuthorised;
  }, [isAuthorised]);

  const classNames = useMemo(() => {
    return cn(styles.AuthTooltipContent, AUTH_TOOLTIP_CLASS, {
      [styles['AuthTooltipContent--top']]: placement === 'top',
      [styles['AuthTooltipContent--alignLeft']]: alignLeft,
    });
  }, [placement, alignLeft]);

  const tooltipOffset = useMemo(() => {
    return {
      ...(alignLeft ? { crossAxis: 35 } : {}),
    };
  }, [alignLeft]);

  return (
    <Tooltip
      tooltipContent={
        <div className={classNames}>
          <p>Log in to Participate</p>
          <button className={styles.AuthTooltipContent__Button} onClick={handleEnableSignUpMode}>
            Login/Sign up
          </button>
        </div>
      }
      eventType={TooltipEventType.click}
      allowOpeningCheck={onAllowOpeningCheck}
      closeOnContentClick
      placement={placement}
      withoutShadowEffect
      tooltipOffset={tooltipOffset}
    >
      {children}
    </Tooltip>
  );
});
