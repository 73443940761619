import { memo } from 'react';

import { SelectItemTheme } from 'components/ui/form-fields/bottom-sheet-select/bottom-sheet-select.component';
import { Select } from 'components/ui/form-fields/select/select.component';
import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';

import styles from './leaderboard-filters.module.less';

interface ILeaderboardFiltersProps {
  seasonId: string;
  bucketId: string;
  seasonsOptions: ISelectOption[];
  bucketsOptions: ISelectOption[];
  onSeasonIdChange: (seasonId: string) => void;
  onBucketIdChange: (bucketId: string) => void;
}

export const LeaderboardFilters = memo((props: ILeaderboardFiltersProps) => {
  const { seasonsOptions, bucketsOptions, seasonId, bucketId } = props;

  return (
    <div className={styles.LeaderboardFilters}>
      <Select
        id="leaderboard_season"
        name="LeaderboardSeason"
        options={seasonsOptions}
        value={seasonId}
        onChange={props.onSeasonIdChange}
      />
      <Select
        id="leaderboard_bucket"
        name="LeaderboardBucket"
        options={bucketsOptions}
        value={bucketId}
        onChange={props.onBucketIdChange}
        itemTheme={SelectItemTheme.ImageWithText}
      />
    </div>
  );
});
