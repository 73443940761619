export const isShowToggle = (isFeedSide: boolean, _isFanZoneActive: boolean) => {
  if (isFeedSide && _isFanZoneActive) {
    return false;
  }

  if (isFeedSide && !_isFanZoneActive) {
    return true;
  }

  if (!isFeedSide && !_isFanZoneActive) {
    return false;
  }

  return !isFeedSide && _isFanZoneActive;
};
