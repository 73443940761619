import { memo } from 'react';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './headline.module.less';

export interface IHeadlineProps {
  id: number;
  url: string;
  iconUrl: string;
  title: string;
  onClick: (url: string, id: number) => () => void;
}

export const Headline = memo((props: IHeadlineProps) => {
  const { id, url, iconUrl, title } = props;

  return (
    <div role="presentation" className={styles.Headline} onClick={props.onClick(url, id)}>
      <div className={styles.Headline__Source}>
        <img src={iconUrl} alt="Source" />
      </div>
      <p className={styles.Headline__Text}>{title}</p>
      <div className={styles.Headline__Icon}>
        <IconButton
          iconName={IconFontName.ExternalLink}
          theme={IconButtonTheme.Secondary}
          iconSize={IconFontSize.Small}
        />
      </div>
    </div>
  );
});
