import { FC, memo, useCallback } from 'react';

import { IPlayerPeriodPerformance } from 'stores/player-performance/interfaces/player-performance.interface';

import { PerformanceItem } from 'components/performance-stats/performance-item/performance-item.component';
import { performanceStatsConfig } from 'components/performance-stats/performance-stats.config';

import styles from './performance-stats.module.less';

interface IPerformanceStatsProps {
  periodsStats: IPlayerPeriodPerformance[];
  totalInGameStats: IPlayerPeriodPerformance;
  selectedPeriod: number;
}

export const PerformanceStats: FC<IPerformanceStatsProps> = memo(
  (props: IPerformanceStatsProps) => {
    const { periodsStats, totalInGameStats, selectedPeriod } = props;

    const performanceItemValue = useCallback(
      (itemKey: keyof IPlayerPeriodPerformance) => {
        if (selectedPeriod) {
          return periodsStats[selectedPeriod - 1][itemKey];
        }

        return totalInGameStats[itemKey];
      },
      [periodsStats, selectedPeriod, totalInGameStats],
    );

    return (
      <div className={styles.InGameStats}>
        {performanceStatsConfig.map((item) => {
          return (
            <PerformanceItem
              key={item.key}
              isPlusMinus={item.key === 'plusMinus'}
              label={item.label}
              value={performanceItemValue(item.key)}
            />
          );
        })}
      </div>
    );
  },
);
