import { FC, memo } from 'react';

import { BarActionType } from 'components/bars/bar-action.type';

import { ContextMenuItem } from '../context-menu-item/context-menu-item.component';
import { IContextMenuItem } from '../interfaces/context-menu-item.interface';

import styles from './context-menu-group.module.less';

interface IContextMenuGroupProps {
  selectedItem?: string;
  group: IContextMenuItem[];
  onItemClick: (barAction: BarActionType) => void;
}

export const ContextMenuGroup: FC<IContextMenuGroupProps> = memo(
  (props: IContextMenuGroupProps) => {
    const { selectedItem, group } = props;

    return (
      <ul className={styles.ContextMenuGroup}>
        {group.map((item) => (
          <ContextMenuItem
            key={item.text}
            selectedItem={selectedItem}
            item={item}
            onItemClick={props.onItemClick}
          />
        ))}
      </ul>
    );
  },
);
