import { inject, injectable } from 'inversify';
import { comparer, reaction } from 'mobx';

import { IPostResponse } from 'services/posts/interfaces/posts-response.interface';
import { PostsService } from 'services/posts/posts.service';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { interactedPostAdapter } from 'stores/feeds/adapters/interacted-post-adapter.util';
import { LayoutStore } from 'stores/layout/layout.store';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { IPost, IPostAdapter } from 'stores/posts/interfaces/post.interface';
import {
  IInteractedPostsResponse,
  IPostsResponse,
} from 'stores/posts/interfaces/post-response.inerface';
import { UserPublicStore } from 'stores/user-public/user-public.store';

import { TYPES } from 'configs/di-types.config';

import { PAGINATION_LIMIT } from './feeds.config';

@injectable()
export class PublicUserFeedStore extends AdvancedEntriesStore<
  IPost,
  IPostResponse,
  IPostsResponse
> {
  private readonly postsService: PostsService;

  private readonly layoutStore: LayoutStore;

  private readonly userPublicStore: UserPublicStore;

  constructor(
    @inject(TYPES.PostsService) postsService: PostsService,
    @inject(TYPES.UserPublicStore) userPublicStore: UserPublicStore,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
  ) {
    super(PAGINATION_LIMIT);

    this.postsService = postsService;

    this.userPublicStore = userPublicStore;

    this.layoutStore = layoutStore;

    reaction(
      () => JSON.stringify([this.layoutStore.activeFeed, this.userPublicStore.userSlug]),
      () => this.forceFetchToRefresh(),
      {
        equals: comparer.shallow,
      },
    );
  }

  public async fetchNext() {
    if (this.userPublicStore.userSlug && this.layoutStore.activeFeed === FeedTypes.PublicUser) {
      await this.retrieveNext(
        this.fetchPublicUserFeedPosts(this.userPublicStore.userSlug),
        <IPostAdapter>interactedPostAdapter,
      );
    } else {
      this.setEntries([]);
    }
  }

  public async fetchPublicUserFeedPosts(
    userSlug: string,
  ): Promise<IResponse<IInteractedPostsResponse>> {
    return this.postsService.fetchPublicUserPosts(this.pagination, userSlug);
  }

  public async forceFetchToRefresh() {
    if (this.userPublicStore.userSlug && this.layoutStore.activeFeed === FeedTypes.PublicUser) {
      await this.refresh();

      await this.forceRefresh(
        this.fetchPublicUserFeedPosts(this.userPublicStore.userSlug),
        <IPostAdapter>interactedPostAdapter,
        this.layoutStore.setPulledRefresher,
      );
    }
  }

  public async refresh() {
    this.setFetched(false);
    this.setFetching(false);

    this.setIsLastPage(false);
    this.setCurrentPage(1);

    await this.initialise();
  }
}
