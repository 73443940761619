import { memo, ReactNode, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import cn from 'classnames';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { IUserMe } from 'stores/auth/interfaces/user-me.interface';
import { SelectedHeaderTabType } from 'stores/layout/types/layout-entities.type';

import { GlobalSearchHeaderDesktopContainer } from 'containers/global-search-header-desktop/global-search-header-desktop.container';

import { IS_ENABLE_USER_RATING } from 'configs/feature.config';
import * as paths from 'routes/paths.constants';

import { Arrows } from 'components/ui/arrows/arrows.component';
import { AuthButtons } from 'components/ui/auth-buttons/auth-buttons.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import { IHeader } from '../header.interface';
import { HeaderTabs, IHeaderTab } from '../header-tabs/header-tabs.component';

import styles from './header-desktop.module.less';

interface IHeaderDesktopProps extends IHeader {
  isAuthorised: boolean;
  fetching: boolean;
  isAthlete: boolean;
  center: Maybe<ReactNode>;
  tabItems: IHeaderTab[];
  userMe: Maybe<IUserMe>;
  onBack: () => void;
  onForward: () => void;
  onSignIn: () => void;
  onSignUp: () => void;
  onTabClick: (tabSlug: SelectedHeaderTabType) => void;
  onAvatarClick: () => void;
  backgroundColor: Maybe<string>;
  textColor: Maybe<string>;
}

export const HeaderDesktop = memo((props: IHeaderDesktopProps) => {
  const {
    isAthlete,
    isAuthorised,
    title,
    center,
    fetching,
    tabItems,
    userMe,
    onBack,
    onForward,
    onTabClick,
    onSignIn,
    onSignUp,
    backgroundColor,
    textColor,
  } = props;

  // TODO just for demo. Value will be taken from user profile
  const userRating = 0;

  const location = useLocation();
  const navigate = useNavigate();

  const isAnyHistoryEntryExist = useMemo(() => {
    return location.key !== 'default';
  }, [location]);

  const handleLeftClick = useCallback(() => {
    if (isAnyHistoryEntryExist) {
      onBack();
    }
  }, [onBack, isAnyHistoryEntryExist]);

  const handleRightClick = useCallback(onForward, [onForward]);

  const handleContactUsClick = useCallback(() => {
    navigate(paths.CONTACT_US);
  }, [navigate]);

  const handleSignIn = useCallback(onSignIn, [onSignIn]);

  const handleSignUp = useCallback(onSignUp, [onSignUp]);

  const handleTabClick = useCallback(onTabClick, [onTabClick]);

  const handleRedirectToSettings = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.AccountProfileSettings,
      },
    });

    navigate(paths.SETTINGS);
  }, [navigate]);

  const backgroundColorStyles = useMemo(() => {
    if (backgroundColor) {
      return {
        background: `linear-gradient(90deg, ${backgroundColor} 0%, rgba(116, 50, 151, 0.00) 100%)`,
      };
    }

    return {};
  }, [backgroundColor]);

  const avatarClasses = useMemo<string>(
    () =>
      cn(styles.AvatarWrapper, {
        [styles['AvatarWrapper--athlete']]: isAthlete,
      }),
    [isAthlete],
  );

  return (
    <header style={backgroundColorStyles} className={styles.HeaderDesktop}>
      <div className={styles.HeaderDesktop__Left}>
        <div className={styles.HeaderDesktop__Arrows}>
          <Arrows
            isAnyHistoryEntryExist={isAnyHistoryEntryExist}
            onBack={handleLeftClick}
            onForward={handleRightClick}
            customColor={textColor}
          />
        </div>
        {!!center && <div className={styles.HeaderDesktop__Center}>{center}</div>}
        <h1 className={styles.HeaderDesktop__Title}>{title}</h1>
      </div>
      <div className={styles.HeaderDesktop__Right}>
        <GlobalSearchHeaderDesktopContainer />
        {isAuthorised || fetching ? (
          <>
            {userMe && (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={props.onAvatarClick}
                onClick={props.onAvatarClick}
                className={avatarClasses}
              >
                <Avatar
                  size={AvatarSize.S}
                  src={userMe.profile.smallAvatarUrl}
                  username={userMe.username}
                />
                {!isAthlete && IS_ENABLE_USER_RATING && (
                  <div className={styles.AvatarWrapper__Points}>
                    <IconButton
                      iconSize={IconFontSize.ExtraSmall}
                      iconName={IconFontName.StarFilled}
                      theme={IconButtonTheme.Secondary}
                    />
                    <span className={styles.AvatarWrapper__Rating}>{userRating}</span>
                  </div>
                )}
              </div>
            )}
            <IconButton
              iconName={IconFontName.Settings}
              theme={IconButtonTheme.Secondary}
              onClick={handleRedirectToSettings}
            />
            <HeaderTabs tabs={tabItems} onTabClick={handleTabClick} />
          </>
        ) : (
          <>
            <div className={styles.HeaderDesktop__AuthButton}>
              <div className={styles.HeaderDesktop__AuthButton__ContactUs}>
                <Button
                  size={ButtonSize.SmallSecondary}
                  theme={ButtonTheme.Text}
                  onClick={handleContactUsClick}
                >
                  Contact us
                </Button>
              </div>
              <AuthButtons onSignIn={handleSignIn} onSignUp={handleSignUp} />
            </div>
            <HeaderTabs tabs={tabItems} onTabClick={handleTabClick} />
          </>
        )}
      </div>
    </header>
  );
});
