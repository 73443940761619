import { useCallback } from 'react';
import cn from 'classnames';

import { isObject } from 'helpers/is-object.util';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import { hasLogo } from '../bottom-sheet-select.helpers';
import { IOption } from '../bottom-sheet-select.interface';

import styles from './item.module.less';

interface ITextItemProps<T extends IOption | null> {
  value: Maybe<T> | undefined;
  isSelected?: boolean;
  onPress: () => void;
}

export const ImageTextItem = <T extends IOption | null>(props: ITextItemProps<T>) => {
  const { value, onPress, isSelected } = props;

  const getAvatarUserName = useCallback((option: T): string => {
    if (isObject(option) && 'code' in option && typeof option.code === 'string') {
      return option.code;
    }

    if (isObject(option) && 'username' in option && typeof option.username === 'string') {
      return option.username;
    }

    return '';
  }, []);

  return (
    <li
      key={value?.value}
      className={cn(styles.ImageItem, isSelected ? styles['ImageItem--active'] : '')}
      role="tab"
      onClick={() => onPress()}
      onKeyDown={() => onPress()}
    >
      {hasLogo(value) && (
        <Avatar src={value.logo} size={AvatarSize.S} username={getAvatarUserName(value)} />
      )}
      {value?.label}
    </li>
  );
};
