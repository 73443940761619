import { ChangeEvent, FC, memo, ReactNode, useCallback, useEffect, useRef } from 'react';
import { Camera } from '@capacitor/camera';
import { Microphone } from '@mozartec/capacitor-microphone';

import { MAX_ORIGINAL_VIDEO_SIZE, VIDEO_SIZE_EXCEEDED_MESSAGE } from 'configs/controls.config';

import { useMainProvider } from 'hooks/use-main-provider';

import { postsLogger } from 'loggers/posts.logger';

import { handleIosCameraDenied } from 'components/forms/photo-camera-field-with-backend-processing/utils/handle-ios-camera-denied.util';
import { handleIosMicrophoneDenied } from 'components/forms/photo-camera-field-with-backend-processing/utils/handle-ios-microphone-denied.util';
import { IHookFormInput } from 'components/ui/form-fields/hook-form-input.interface';

import styles from './post-video-input.module.less';

interface IVideoInputProps extends IHookFormInput {
  onError?: (error: string) => void;
  accept?: string;
  onVideoLoad?: (fileData: File) => void;
  setIsVideoInputOpen: (isOpen: boolean) => void;
  isVideoInputOpen: boolean;
  setIsVideoPermissionsRequested: (value: boolean) => void;
  isVideoPermissionsRequested: boolean;
  children?: ReactNode;
}

export const PostVideoInput: FC<IVideoInputProps> = memo((props: IVideoInputProps) => {
  const {
    id,
    name,
    accept,
    isVideoInputOpen,
    setIsVideoInputOpen,
    onVideoLoad,
    onError,
    onBlur,
    setIsVideoPermissionsRequested,
    isVideoPermissionsRequested,
    children,
  } = props;
  const { isNativeAndroidApp, isNativeApp } = useMainProvider();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const checkPermissions = useCallback(async () => {
    const cameraPermission = await Camera.checkPermissions();

    if (cameraPermission.camera === 'denied') {
      await handleIosCameraDenied();
      return false;
    }

    const audioPermissions = await Microphone.requestPermissions();

    if (audioPermissions.microphone === 'denied') {
      await handleIosMicrophoneDenied();
      return false;
    }

    return true;
  }, []);

  const handleInputClick = useCallback(async () => {
    if (fileInputRef.current && !isVideoPermissionsRequested) {
      setIsVideoPermissionsRequested(true);

      if (isNativeApp && !isNativeAndroidApp) {
        if (!(await checkPermissions())) {
          return;
        }
      }

      fileInputRef.current.click();
      setIsVideoInputOpen(false);
      setIsVideoPermissionsRequested(false);
    }
  }, [
    isNativeApp,
    isNativeAndroidApp,
    isVideoPermissionsRequested,
    setIsVideoPermissionsRequested,
    checkPermissions,
    setIsVideoInputOpen,
  ]);

  useEffect(() => {
    if (isVideoInputOpen) {
      handleInputClick();
    }
  }, [isVideoInputOpen, handleInputClick]);

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { files },
      } = event;

      if (onVideoLoad) {
        if (files?.length) {
          const filesList: File[] = Array.from(files);

          postsLogger.debug({ msg: 'input file list', filesList });

          try {
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }

            const mmbFileSize = filesList[0].size / 1024 / 1024;

            if (mmbFileSize <= MAX_ORIGINAL_VIDEO_SIZE) {
              const chosenFile = filesList[0];
              postsLogger.debug({ msg: 'selected input file', chosenFile });
              onVideoLoad(chosenFile);
            } else {
              throw new Error(VIDEO_SIZE_EXCEEDED_MESSAGE);
            }
          } catch (error) {
            if (onError) {
              if (
                typeof error === 'object' &&
                error !== null &&
                'message' in error &&
                typeof error.message === 'string'
              )
                onError(error.message);
            }
          }
        }
      }

      if (onBlur) {
        onBlur(event);
        setIsVideoPermissionsRequested(false);
      }
    },
    [onBlur, onVideoLoad, onError, setIsVideoPermissionsRequested],
  );

  return (
    <div>
      {children}
      <input
        className={styles.FileInput}
        accept={accept}
        id={id}
        name={name}
        type="file"
        onBlur={props.onBlur}
        onChange={handleChange}
        ref={fileInputRef}
        onClick={handleInputClick}
        multiple={false}
      />
    </div>
  );
});
