import { inject } from 'inversify';
import { action, autorun, computed, makeObservable, observable } from 'mobx';

import { BucketsService } from 'services/buckets/buckets.service';
import { IBucketsResponse } from 'services/buckets/interfaces/buckets.interface';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { bucketAdapter } from 'stores/buckets/adapters/bucket-adapter.util';
import { bucketOptionAdapter } from 'stores/buckets/adapters/bucket-option-adapter.util';
import { GLOBAL_LEADERBOARD } from 'stores/buckets/buckets.config';
import { IBucket } from 'stores/buckets/interfaces/bucket.interface';

import { TYPES } from 'configs/di-types.config';

export class BucketsStore extends ApiConnectedStore {
  private readonly bucketsService: BucketsService;

  public buckets: IBucket[];

  constructor(@inject<BucketsService>(TYPES.BucketsService) bucketsService: BucketsService) {
    super();

    this.bucketsService = bucketsService;

    this.buckets = [];

    makeObservable(this, {
      buckets: observable,

      bucketsSelectOptions: computed,

      setBuckets: action.bound,
    });

    autorun(() => this.retrieve());
  }

  public setBuckets(buckets: IBucket[]) {
    this.buckets = buckets;
  }

  public get bucketsSelectOptions() {
    return this.buckets.map(bucketOptionAdapter);
  }

  public get defaultBucket() {
    return bucketOptionAdapter(GLOBAL_LEADERBOARD);
  }

  private async retrieve() {
    this.resetErrors();
    this.setFetched(false);

    const bucketsResponse: IResponse<IBucketsResponse> = await this.bucketsService.fetchBuckets();

    if (bucketsResponse.success) {
      const buckets = bucketsResponse.data.items.map(bucketAdapter);
      this.setBuckets([GLOBAL_LEADERBOARD, ...buckets]);
    } else {
      this.setErrors(bucketsResponse.errors);
    }

    this.setFetched(true);
  }
}
