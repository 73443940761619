import { FC, useMemo } from 'react';
import { components, ControlProps } from 'react-select';
import cn from 'classnames';

import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';

import styles from './team-control.module.less';

interface ITeamControlProps extends ControlProps<ITeamOption | null, false> {}

export const TeamControl: FC<ITeamControlProps> = ({ children, ...props }: ITeamControlProps) => {
  const { getValue, isDisabled } = props;

  const valueColor = getValue()[0]?.color;

  const memoizedTeamColor = useMemo(() => {
    const team = getValue();

    if (valueColor) {
      return {
        background: valueColor,
        caretColor: team[0]?.secondaryTextColor,
        color: team[0]?.secondaryTextColor,
      };
    }

    return {};
  }, [valueColor, getValue]);

  const teamControlClassNames = useMemo(
    () =>
      cn(styles.TeamControl, {
        [styles['TeamControl--disabled']]: isDisabled,
      }),
    [isDisabled],
  );

  return (
    <components.Control className={teamControlClassNames} {...props}>
      <div className={styles.TeamControl__InnerWrapper} style={memoizedTeamColor}>
        {children}
      </div>
    </components.Control>
  );
};
