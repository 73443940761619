// eslint-disable-next-line import/no-extraneous-dependencies
import heic2any from 'heic2any';

export const convertHeicToJpg = (file: File) => {
  return new Promise((resolve) => {
    heic2any({
      blob: file,
      toType: 'image/jpg',
    }).then((convertedFile) => {
      const result = convertedFile;
      // need name for correct uploading
      // @ts-ignore
      result.name = `${file.name.substring(0, file.name.lastIndexOf('.'))}.jpeg`;
      resolve(result);
    });
  });
};
