/* eslint-disable no-underscore-dangle */
// rule is disabled as according to lexical documentation, they suggest "__" prefix for custom fields
import {
  DOMConversionMap,
  ElementNode,
  NodeKey,
  ParagraphNode,
  SerializedParagraphNode,
  Spread,
} from 'lexical';

type SerializedTargetNode = Spread<{ type: 'target'; __id: string }, SerializedParagraphNode>;

export class TargetNode extends ElementNode {
  __id: string;

  constructor(id: string, key?: NodeKey) {
    super(key);

    this.__id = id;
  }

  static getType(): string {
    return 'target';
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('p');
    dom.setAttribute('id', this.getId());

    return dom;
  }

  updateDOM(): boolean {
    return false;
  }

  exportJSON(): SerializedTargetNode {
    return {
      ...super.exportJSON(),
      type: 'target',
      __id: this.getId(),
      version: 1,
      textFormat: 1,
      textStyle: '',
    };
  }

  static clone(node: TargetNode): TargetNode {
    return new TargetNode(node.__id, node.__key);
  }

  static importDOM(): DOMConversionMap | null {
    const importers = ParagraphNode.importDOM();

    return importers;
  }

  setId(id: string) {
    const self = this.getWritable();
    self.__id = id;
  }

  getId(): string {
    const self = this.getLatest();
    return self.__id;
  }

  static importJSON(serializedNode: SerializedTargetNode): TargetNode {
    return $createTargetNode(serializedNode.__id);
  }
}

function $createTargetNode(__id: string): TargetNode {
  const node = new TargetNode(__id);
  return node;
}
