import { Media, PhotoResponse } from '@capacitor-community/media';
import { injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { MediaType } from 'types/media-type.type';

import { NotificationsStore } from 'stores/notifications/notifications.store';
import { IInteractiveMediaAttachment } from 'stores/posts/interfaces/post.interface';

import { ALBUM_NAME, DOWNLOAD_NOTIFICATION_DELAY } from 'configs/download.config';

import { GalleryTheme } from 'components/modals/gallery-modal/gallery-modal.component';
import { IAuthor } from 'components/modals/share-modal/share-modal.component';

import { IWatermarkEntity } from './interfaces/watermark-entity.interface';

@injectable()
export class GalleryStore extends NotificationsStore {
  public isGalleryOpen: boolean;

  public galleryAttachments: IInteractiveMediaAttachment[];

  public currentAttachmentId: number;

  public author: Maybe<IAuthor>;

  public theme: GalleryTheme;

  public watermarkEntity: Maybe<IWatermarkEntity>;

  public attachmentDate: string;

  constructor() {
    super();

    this.isGalleryOpen = false;

    this.galleryAttachments = [];

    this.author = null;

    this.watermarkEntity = null;

    this.theme = GalleryTheme.Default;

    this.attachmentDate = '';

    this.currentAttachmentId = 0;

    makeObservable(this, {
      isGalleryOpen: observable,
      galleryAttachments: observable,
      author: observable,
      attachmentDate: observable,
      currentAttachmentId: observable,
      watermarkEntity: observable,
      theme: observable,

      setCurrentAttachmentId: action.bound,
      setAttachmentDate: action.bound,
      setIsGalleryOpen: action.bound,
      setGalleryAttachments: action.bound,
      setAuthor: action.bound,
      setWatermarkEntity: action.bound,
      setTheme: action.bound,
      closeGallery: action.bound,
      downloadMediaFileToGallery: action.bound,
      fetchMediaFile: action.bound,
    });
  }

  public setIsGalleryOpen(isGalleryOpen: boolean) {
    this.isGalleryOpen = isGalleryOpen;
  }

  public setCurrentAttachmentId(id: number) {
    this.currentAttachmentId = id;
  }

  public setGalleryAttachments(attachments: IInteractiveMediaAttachment[]) {
    this.galleryAttachments = attachments;
  }

  public setAuthor(author: Maybe<IAuthor>) {
    this.author = author;
  }

  public setAttachmentDate(date: string) {
    this.attachmentDate = date;
  }

  public setWatermarkEntity(entity: Maybe<IWatermarkEntity>) {
    this.watermarkEntity = entity;
  }

  public setTheme(theme: GalleryTheme) {
    this.theme = theme;
  }

  public closeGallery() {
    this.setIsGalleryOpen(false);
    this.setAuthor(null);
    this.setAttachmentDate('');
    this.setCurrentAttachmentId(0);
    this.setGalleryAttachments([]);
    this.setTheme(GalleryTheme.Default);
    this.setWatermarkEntity(null);
  }

  public async fetchMediaFile(path: string): Promise<string> {
    // Adding date is for invalidate browser caching
    const uniquePath = `${path}?${Date.now()}`;

    const response = await fetch(uniquePath);
    const image = await response.blob();
    const href = URL.createObjectURL(image);

    return href;
  }

  private async getIdentifierAlbum() {
    const { albums } = await Media.getAlbums();
    const album = albums.find((a) => a.name === ALBUM_NAME);

    return album ? album.identifier : null;
  }

  private async downloadMediaFile(path: string, albumIdentifier: string, mediaType: MediaType) {
    let response: Maybe<Promise<PhotoResponse>> = null;
    let successMessage = '';
    let errroMessage = '';

    if (mediaType === 'image') {
      response = Media.savePhoto({
        path,
        albumIdentifier,
      });
      successMessage = 'You saved the photo';
      errroMessage = 'Failed to save the photo';
    }

    if (mediaType === 'video') {
      response = Media.saveVideo({
        path,
        albumIdentifier,
      });
      successMessage = 'You saved the video';
      errroMessage = 'Failed to save the video';
    }

    if (mediaType === 'gif') {
      response = Media.savePhoto({
        path,
        albumIdentifier,
      });

      successMessage = 'You saved the gif';
      errroMessage = 'Failed to save the gif';
    }

    if (response) {
      try {
        await response;

        this.setSuccesses([
          {
            message: successMessage,
            delay: DOWNLOAD_NOTIFICATION_DELAY,
          },
        ]);
      } catch {
        this.setErrors([
          {
            message: errroMessage,
            delay: DOWNLOAD_NOTIFICATION_DELAY,
          },
        ]);
      }
    }
  }

  public async downloadMediaFileToGallery(path: string, mediaType: MediaType) {
    const identifierAlbum = await this.getIdentifierAlbum();

    if (identifierAlbum) {
      await this.downloadMediaFile(path, identifierAlbum, mediaType);
      return;
    }

    await Media.createAlbum({ name: ALBUM_NAME });

    const createdIdentifierAlbum = await this.getIdentifierAlbum();

    if (createdIdentifierAlbum) {
      await this.downloadMediaFile(path, createdIdentifierAlbum, mediaType);
    }
  }
}
