import { inject } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { CollaborationService } from 'services/collaboration/collaboration.service';
import {
  CollaborationMediaType,
  ICollaborationAdapter,
  ICollaborationImage,
  ICollaborationItem,
  ICollaborationItemPayload,
  ICollaborationItemResponse,
  ICollaborationItemsResponse,
  ICollaborationVideo,
} from 'services/collaboration/interfaces/collaboration.interface';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { ApplicationStore } from 'stores/application/application.store';
import {
  collaborationImageAdapter,
  collaborationItemAdapter,
  collaborationVideoAdapter,
} from 'stores/collaboration/adapters/collaboration-adapter.util';
import { findAndReplaceObjectByKey } from 'stores/collaboration/utils/utils';
import { LayoutStore } from 'stores/layout/layout.store';
import { PostsStore } from 'stores/posts/posts.store';

import { TYPES } from 'configs/di-types.config';
import { customFormData } from 'helpers/custom-form-data.util';

const COLLABORATION_ITEMS_LIMIT = 10;

export class CollaborationStore extends AdvancedEntriesStore<
  ICollaborationItem,
  ICollaborationItemResponse,
  ICollaborationItemsResponse
> {
  private readonly postsStore: PostsStore;

  private readonly layoutStore: LayoutStore;

  private readonly applicationStore: ApplicationStore;

  private readonly collaborationService: CollaborationService;

  public collaborationId: Maybe<number>;

  public postProcessingId: Maybe<string>;

  public newCollaborationItemMedia: Maybe<CollaborationMediaType>;

  public collaborationItemId: Maybe<number>;

  public collaborationItem: Maybe<ICollaborationItem>;

  public collaborationVideoLoading: boolean;

  constructor(
    @inject(TYPES.ApplicationStore) applicationStore: ApplicationStore,
    @inject<PostsStore>(TYPES.PostsStore) postsStore: PostsStore,
    @inject<CollaborationService>(TYPES.CollaborationService)
    collaborationService: CollaborationService,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
  ) {
    super(COLLABORATION_ITEMS_LIMIT, false);

    this.applicationStore = applicationStore;

    this.postsStore = postsStore;

    this.layoutStore = layoutStore;

    this.collaborationService = collaborationService;

    this.collaborationId = null;

    this.postProcessingId = null;

    this.collaborationItemId = null;

    this.collaborationVideoLoading = false;

    this.collaborationItem = null;

    this.newCollaborationItemMedia = null;

    makeObservable(this, {
      collaborationVideoLoading: observable,
      collaborationId: observable,
      newCollaborationItemMedia: observable,
      postProcessingId: observable,
      collaborationItemId: observable,
      collaborationItem: observable,

      uploadCollaborationVideo: action.bound,
      uploadCollaborationImage: action.bound,
      handleCreateCollaborationItem: action.bound,
      toggleLikeCollaborationItem: action.bound,
      initialiseCollaborationItems: action.bound,
      fetchNextCollaborationItems: action.bound,
      setCollaborationId: action.bound,
      setCollaborationItem: action.bound,
      setPostProcessingId: action.bound,
      setCollaborationItemId: action.bound,
      fetchCollaborationItem: action.bound,
      handlePostChange: action.bound,
      toggleBookmarkCollaborationItemById: action.bound,
      handleCollaborationItemIdChange: action.bound,
      toggleLikeCollaborationItemById: action.bound,
      deleteCollaborationItem: action.bound,
      setNewCollaborationItemMedia: action.bound,
      setCollaborationVideoLoading: action.bound,
    });

    reaction(() => this.postsStore.post, this.handlePostChange);
    reaction(
      () => [this.collaborationId, this.collaborationItemId],
      this.handleCollaborationItemIdChange,
    );
    reaction(() => this.collaborationId, this.initialiseCollaborationItems);
  }

  async initialiseCollaborationItems() {
    this.reset();
    await super.initialise();
    if (this.collaborationId) {
      await this.fetchNextCollaborationItems(this.collaborationId);
    }
  }

  public async fetchNextCollaborationItems(collaborationId: number): Promise<void> {
    await this.retrieveNext(
      this.collaborationService.fetchCollaborationItems(this.pagination, collaborationId),
      <ICollaborationAdapter>collaborationItemAdapter,
    );
  }

  public handlePostChange = async () => {
    if (this.postsStore.post?.collaboration?.id) {
      this.setCollaborationId(this.postsStore.post.collaboration.id);
    }
  };

  public handleCollaborationItemIdChange = async () => {
    if (this.collaborationId && this.collaborationItemId) {
      this.fetchCollaborationItem(this.collaborationId, this.collaborationItemId);
    } else {
      this.setCollaborationItem(null);
    }
  };

  public setCollaborationId(value: Maybe<number>) {
    this.collaborationId = value;
  }

  public setCollaborationVideoLoading(value: boolean) {
    this.collaborationVideoLoading = value;
  }

  public setCollaborationItemId(value: Maybe<number>) {
    this.collaborationItemId = value;
  }

  public setCollaborationItem(value: Maybe<ICollaborationItem>) {
    this.collaborationItem = value;
  }

  public setPostProcessingId(value: Maybe<string>) {
    this.postProcessingId = value;
  }

  public setNewCollaborationItemMedia(value: Maybe<CollaborationMediaType>) {
    this.newCollaborationItemMedia = value;

    if (!value) {
      this.setPostProcessingId(null);
    }
  }

  public async handleCreateCollaborationItem(payload: ICollaborationItemPayload) {
    const response = await this.collaborationService.createCollaborationItem(payload.id, payload);

    if (response.success) {
      const newItem = collaborationItemAdapter(response.data);
      this.setEntries([newItem, ...this.entries]);
      if (this.postsStore.currentPostId) {
        this.postsStore.fetchRegularPostById({ postId: this.postsStore.currentPostId });
      }
      this.postsStore.forceFetchToRefreshFeed();
      this.setNewCollaborationItemMedia(null);
      this.setPostProcessingId(null);
      return;
    }

    if (response.code === 500 || response.code === 504) {
      const error: INotificationMessage = {
        message: 'We were unable to create your submission. Please try again later',
      };
      this.setErrors([error]);
    } else {
      this.setErrors(response.errors);
    }
    this.setNewCollaborationItemMedia(null);
    this.setPostProcessingId(null);
  }

  public async toggleLikeCollaborationItem() {
    if (this.collaborationId && this.collaborationItemId) {
      const response = await this.collaborationService.likeCollaborationItem(
        this.collaborationId,
        this.collaborationItemId,
      );

      if (response.success) {
        this.setCollaborationItem(collaborationItemAdapter(response.data));
      }
    }
  }

  public async toggleLikeCollaborationItemById(
    collaborationId: number,
    collaborationItemId: number,
  ) {
    const response = await this.collaborationService.likeCollaborationItem(
      collaborationId,
      collaborationItemId,
    );

    if (response.success) {
      const newItem = collaborationItemAdapter(response.data);
      this.setCollaborationItem(newItem);

      const newEntries = findAndReplaceObjectByKey<ICollaborationItem>(
        [...this.entries],
        'id',
        collaborationItemId,
        newItem,
      );

      this.setEntries(newEntries);
    }

    return null;
  }

  public async toggleBookmarkCollaborationItem() {
    if (this.collaborationId && this.collaborationItemId) {
      const response = await this.collaborationService.bookmarkCollaborationItem(
        this.collaborationId,
        this.collaborationItemId,
      );

      if (response.success) {
        this.setCollaborationItem(collaborationItemAdapter(response.data));
      }
    }
  }

  public async fetchCollaborationItem(collaborationId: number, collaborationItemId: number) {
    const response = await this.collaborationService.getCollaborationItem(
      collaborationId,
      collaborationItemId,
    );

    if (response.success) {
      this.setCollaborationItem(collaborationItemAdapter(response.data));
      this.layoutStore.setPulledRefresher(false);
    }
  }

  public async toggleBookmarkCollaborationItemById(
    collaborationId: number,
    collaborationItemId: number,
  ): Promise<boolean> {
    const response = await this.collaborationService.bookmarkCollaborationItem(
      collaborationId,
      collaborationItemId,
    );

    return response.success;
  }

  public async deleteCollaborationItem(): Promise<boolean> {
    if (this.collaborationId && this.collaborationItemId) {
      const response = await this.collaborationService.deleteCollaborationItem(
        this.collaborationId,
        this.collaborationItemId,
      );

      return response.success;
    }
    return false;
  }

  public async uploadCollaborationVideo(
    payload: File,
    postId: Maybe<string>,
  ): Promise<Maybe<ICollaborationVideo>> {
    this.setPostProcessingId(postId);
    this.setCollaborationVideoLoading(true);
    const formData = await customFormData(
      payload,
      'file',
      'base64File',
      this.applicationStore.isNativeApp,
    );

    const response = await this.collaborationService.uploadCollaborationVideo(formData);

    if (response.success) {
      const newItem = collaborationVideoAdapter(response.data);
      this.setNewCollaborationItemMedia(newItem);
      this.setCollaborationVideoLoading(false);
      return newItem;
    }

    this.setErrors(response.errors);
    this.setPostProcessingId(null);
    this.setCollaborationVideoLoading(false);
    return null;
  }

  public async uploadCollaborationImage(
    payload: File,
    postId: Maybe<string>,
  ): Promise<Maybe<ICollaborationImage>> {
    this.setPostProcessingId(postId);
    const formData = await customFormData(
      payload,
      'file',
      'base64File',
      this.applicationStore.isNativeApp,
    );
    const response = await this.collaborationService.uploadCollaborationImage(formData);

    if (response.success) {
      const item = collaborationImageAdapter(response.data);

      this.setNewCollaborationItemMedia(item);
      return item;
    }

    this.setErrors(response.errors);

    this.setPostProcessingId(null);
    return null;
  }
}
