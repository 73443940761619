import { ColDef } from 'ag-grid-community';

import { IStandingsRowData } from 'stores/standings/interfaces/standings-row-data.interface';
import { nullValueFormatter } from 'stores/standings/utils/null-value-formatter.util';
import { streakColumnComparator } from 'stores/standings/utils/streak-column-comparator.util';
import { stringToNumberComparator } from 'stores/standings/utils/string-to-number-comparator.util';

import { capitalizeFirstLetter } from 'helpers/string/capitalize-first-letter.util';

import { objectValueFormatter } from 'components/base-table/helpers/object-value-formatter.util';
import { StandingsTeam } from 'components/standings/standings-team/standings-team.component';

import { StandingsMode } from './enums/standings-mode.enum';
import { StandingsType } from './enums/standings-type.enum';
import { StandingsAlwaysVisibleColumnsType } from './types/standings-always-visible-columns.type';

export const THEME_CLASS_NAME = 'ag-theme-standings';
const DIFF_POSITIVE_CLASS_NAME = 'ag-cell-diff-positive';
const DIFF_NEGATIVE_CLASS_NAME = 'ag-cell-diff-negative';
export const STANDINGS_CONFERENCE_MAX_INDEX_FOR_RANK = 9;

const MEDIUM_COLUMN_WIDTH = 55;

export const ALWAYS_VISIBLE_COL_DEF: ColDef[] = [
  {
    headerTooltip: 'Wins',
    field: 'wins',
    headerName: 'W',
    sortable: true,
    maxWidth: MEDIUM_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Losses',
    field: 'losses',
    headerName: 'L',
    sortable: true,
    maxWidth: MEDIUM_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Winning Percentage',
    field: 'winPercentage',
    headerName: 'PCT',
    sortable: true,
    comparator: stringToNumberComparator,
    maxWidth: MEDIUM_COLUMN_WIDTH,
  },
  {
    headerTooltip: 'Games Back',
    field: 'gamesBehind',
    headerName: 'GB',
    sortable: true,
    valueFormatter: nullValueFormatter,
    sort: 'asc',
    maxWidth: MEDIUM_COLUMN_WIDTH,
  },
];

export const DEFAULT_TEAM_COL_DEF: ColDef<IStandingsRowData> = {
  field: 'team',
  pinned: 'left',
  cellRenderer: StandingsTeam,
  valueFormatter: objectValueFormatter,
  headerClass: 'ag-standings-team-header',
  headerValueGetter: ({ api, column }) => {
    const rowNode = api.getRowNode('1');

    if (rowNode) {
      const teamValue = api.getValue('team', rowNode);

      if (column?.getColDef().cellRendererParams.mode === StandingsMode.Division) {
        return capitalizeFirstLetter(teamValue.division);
      }
    }

    return '';
  },
};

export const TEAM_COL_DESKTOP_WIDTH = 188;

export const TEAM_COL_MOBILE_WIDTH = 92;

export const STANDINGS_TYPE_COL_DEF: ColDef[] = [
  { headerTooltip: 'Home Record', field: 'homeRecord', headerName: 'Home' },
  { headerTooltip: 'Away Record', field: 'awayRecord', headerName: 'Away' },
  { headerTooltip: 'Division Record', field: 'divisionRecord', headerName: 'Div' },
  { headerTooltip: 'Conference Record', field: 'conferenceRecord', headerName: 'Conf' },
  {
    headerTooltip: 'Points Per Game',
    field: 'pointsPerGame',
    headerName: 'PPG',
    sortable: true,
    valueFormatter: ({ value }) => value.toFixed(1),
  },
  {
    headerTooltip: 'Opponent Points Per Game',
    field: 'opponentPointsPerGame',
    headerName: 'OPPG',
    sortable: true,
    valueFormatter: ({ value }) => value.toFixed(1),
  },
  {
    headerTooltip: 'Average Point Differential',
    field: 'differential',
    headerName: 'Diff',
    sortable: true,
    comparator: stringToNumberComparator,
    cellClassRules: {
      [DIFF_POSITIVE_CLASS_NAME]: ({ value }) => value > 0,
      [DIFF_NEGATIVE_CLASS_NAME]: ({ value }) => value < 0,
    },
  },
  {
    headerTooltip: 'Current Streak',
    field: 'streak',
    headerName: 'Strk',
    sortable: true,
    comparator: streakColumnComparator,
  },
  { headerTooltip: 'Record Last 10 Games', field: 'last10', headerName: 'L10' },
];

export const EXPANDED_TYPE_COL_DEF: ColDef[] = [
  {
    headerTooltip: 'Record in games decided by 3 points or less',
    field: 'games3pt',
    headerName: '3 PT Games',
    flex: 1.8,
    minWidth: 90,
  },
  {
    headerTooltip: 'Record in games decided by 10 points or more',
    field: 'games10pt',
    headerName: '10 PT Games',
    flex: 1.8,
    minWidth: 90,
  },
  {
    headerTooltip: 'Record against Teams .500 and Above',
    field: 'vs500Above',
    headerName: 'Vs. .500 and Above',
    flex: 2.45,
    minWidth: 145,
  },
  {
    headerTooltip: 'Record against Teams below .500',
    field: 'vs500Below',
    headerName: 'Vs. Below .500',
    flex: 2,
    minWidth: 100,
  },
];

export const VS_DIVISION_TYPE_COL_DEF: ColDef[] = [
  {
    headerTooltip: 'Record vs. Eastern Conference Teams',
    field: 'eastRecord',
    headerName: 'East',
  },
  {
    headerTooltip: 'Record vs. Atlantic Division Teams',
    field: 'atlanticRecord',
    headerName: 'Atl',
  },
  {
    headerTooltip: 'Record vs. Central Division Teams',
    field: 'centralRecord',
    headerName: 'Cen',
  },
  {
    headerTooltip: 'Record vs. Southeast Division Teams',
    field: 'southeastRecord',
    headerName: 'SE',
  },
  {
    headerTooltip: 'Record vs. Western Conference Teams',
    field: 'westRecord',
    headerName: 'West',
  },
  {
    headerTooltip: 'Record vs. Northwest Division Teams',
    field: 'northwestRecord',
    headerName: 'NW',
  },
  {
    headerTooltip: 'Record vs. Pacific Division Teams',
    field: 'pacificRecord',
    headerName: 'Pac',
  },
  {
    headerTooltip: 'Record vs. Southwest Division Teams',
    field: 'southwestRecord',
    headerName: 'SW',
  },
];

const ALWAYS_VISIBLE_COL_IDS: StandingsAlwaysVisibleColumnsType[] = [
  'rank',
  'team',
  'wins',
  'losses',
  'winPercentage',
  'gamesBehind',
];

export const isAlwaysVisibleColumn = (
  colId: string,
): colId is StandingsAlwaysVisibleColumnsType => {
  return ALWAYS_VISIBLE_COL_IDS.some((col) => col === colId);
};

export const STANDINGS_TYPE_FILTER_OPTIONS = [
  { label: 'Standings', value: StandingsType.Standings },
  { label: 'Expanded', value: StandingsType.Expanded },
  { label: 'Vs. Division', value: StandingsType.VsDivision },
];

export const STANDINGS_MODE_FILTER_OPTIONS = [
  {
    id: StandingsMode.League,
    element: 'League',
  },
  {
    id: StandingsMode.Conference,
    element: 'Conference',
  },
  {
    id: StandingsMode.Division,
    element: 'Division',
  },
];
