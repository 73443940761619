import { IAnonymousUserMeResponse } from 'services/auth/interfaces/anonymous-user-me-response.interface';

import { IAnonymousUserMeInterface } from 'stores/auth/interfaces/anonymous-user-me.interface';

export function anonymousUserMeAdapter(
  anonymousUserMeResponse: IAnonymousUserMeResponse,
): IAnonymousUserMeInterface {
  const { uuid, username, created_at: createdAt } = anonymousUserMeResponse;

  return {
    id: uuid,
    username,
    createdAt,
  };
}
