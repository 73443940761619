import { useEffect } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { LayoutStore } from 'src/stores/layout/layout.store';

import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';

import { PostsFeedContainer } from 'containers/posts/posts-feed/posts-feed-container';

import { TYPES } from 'configs/di-types.config';

export const PublicUserFeedContainer = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);

  useEffect(() => {
    layoutStore.setActiveFeed(FeedTypes.PublicUser);
  }, [layoutStore]);

  return <PostsFeedContainer />;
});
