import {
  ChangeEvent,
  forwardRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  useCallback,
  useMemo,
} from 'react';
import cn from 'classnames';

import { FieldWrapper } from 'components/ui/form-fields/field-wrapper/field-wrapper.component';
import { IFormField } from 'components/ui/form-fields/form-field.interface';

import styles from './input.module.less';

interface IInputProps extends IFormField {
  type: HTMLInputTypeAttribute & ('email' | 'password' | 'search' | 'text');
  maxLengthCount?: number;
  enterKeyHint?: InputHTMLAttributes<HTMLInputElement>['enterKeyHint'];
}

export const Input = forwardRef<HTMLInputElement, IInputProps>((props: IInputProps, ref) => {
  const {
    maxLengthCount,
    disabled,
    readOnly,
    id,
    error,
    helperTextContent,
    helperTextTheme,
    label,
    name,
    leftIconName,
    rightIconProps,
    textCount,
    placeholder,
    type,
    value = '',
    aside,
    enterKeyHint,
    onChange,
  } = props;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange],
  );

  const symbolsLeft = useMemo<Maybe<number>>(() => {
    if (!maxLengthCount) {
      return null;
    }

    if (!value || !value.length) {
      return maxLengthCount;
    }

    if (value.length >= maxLengthCount) {
      return 0;
    }

    return maxLengthCount - value.length;
  }, [value, maxLengthCount]);

  return (
    <FieldWrapper
      {...{
        error,
        helperTextContent,
        helperTextTheme,
        id,
        label,
        leftIconName,
        rightIconProps,
        aside,
        textCount,
        maxLengthCount,
        disabled,
      }}
    >
      {({ isInvalid, withLeftIcon, withRightIcon, withTextCount, withMaxLengthCount }) => (
        <>
          <input
            {...{ readOnly, id, name, type, disabled, placeholder, ref, value }}
            className={cn(styles.Input, {
              [styles['Input--invalid']]: isInvalid,
              [styles['Input--with-right-icon']]: withRightIcon,
              [styles['Input--with-left-icon']]: withLeftIcon,
              [styles['Input--with-text-count']]: withTextCount,
              [styles['Input--with-max-length-count']]: withMaxLengthCount && !withRightIcon,
              [styles['Input--with-right-icon-and-count']]: withMaxLengthCount && withRightIcon,
            })}
            enterKeyHint={enterKeyHint}
            autoComplete="off"
            onChange={handleChange}
            onBlur={props.onBlur}
            maxLength={maxLengthCount}
            onFocus={props.onFocus}
          />
          {Boolean(maxLengthCount) && <div className={styles.Counter}>{symbolsLeft}</div>}
        </>
      )}
    </FieldWrapper>
  );
});
