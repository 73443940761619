import { getPath } from 'helpers/get-path.util';
import * as path from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionLink } from 'components/bars/bar-action.type';
import { ITab } from 'components/ui/tabs/tabs.component';

export const getPlayerProfileRouteTabs = (playerSlug: string): ITab<BarActionLink>[] => {
  return [
    {
      id: 'player_profile_feed',
      title: 'Feed',
      action: {
        type: BarAction.Link,
        path: getPath(path.PLAYER_PROFILE_FEED, { [path.PLAYER_SLUG_PARAM]: playerSlug }),
      },
    },
    {
      id: 'player_profile_games',
      title: 'Games',
      action: {
        type: BarAction.Link,
        path: getPath(path.PLAYER_PROFILE_GAMES, { [path.PLAYER_SLUG_PARAM]: playerSlug }),
      },
    },
    {
      id: 'player_profile_stats',
      title: 'Seasons',
      action: {
        type: BarAction.Link,
        path: getPath(path.PLAYER_PROFILE_STATS, { [path.PLAYER_SLUG_PARAM]: playerSlug }),
      },
    },
    {
      id: 'player_profile_splits',
      title: 'Splits',
      action: {
        type: BarAction.Link,
        path: getPath(path.PLAYER_PROFILE_SPLITS, { [path.PLAYER_SLUG_PARAM]: playerSlug }),
      },
    },
    {
      id: 'player_profile_overview',
      title: 'Profile',
      action: {
        type: BarAction.Link,
        path: getPath(path.PLAYER_PROFILE_OVERVIEW, { [path.PLAYER_SLUG_PARAM]: playerSlug }),
      },
    },
  ];
};
