import { FC, useEffect } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { avatarAndThumbnailSchema } from 'validation/schemas/avatar-and-thumbnail.schema';

import { IForm } from 'components/forms/form.interface';
import { AvatarSize } from 'components/ui/avatar/avatar.component';
import { AvatarInput } from 'components/ui/form-fields/avatar-input/avatar-input.component';

const avatarFormSchema = z
  .object({
    avatar: avatarAndThumbnailSchema,
  })
  .strict();

type ProfileAvatarFormDataType = z.infer<typeof avatarFormSchema>;

interface IProfileAvatarFormProps extends Omit<IForm<ProfileAvatarFormDataType>, 'submitText'> {
  onSubmit: (data: FieldValues) => void;
  accept: string;
  username: string;
  avatarUrl: Maybe<string>;
  isCropModalVisible: boolean;
  forEditModal?: boolean;
  onSetCropModal: (isOpen: boolean) => void;
}

const fieldName = 'avatar';

export const ProfileAvatarForm: FC<IProfileAvatarFormProps> = (props) => {
  const { username, onSubmit, accept, avatarUrl, forEditModal = false } = props;

  const {
    watch,
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onTouched',
    resolver: zodResolver(avatarFormSchema),
  });

  const avatarData = watch(fieldName);

  useEffect(() => {
    if (avatarData && isDirty && isValid) {
      handleSubmit(onSubmit)();
    }
  }, [avatarData, handleSubmit, onSubmit, isDirty, isValid]);

  return (
    <form noValidate>
      <Controller
        name={fieldName}
        control={control}
        render={({ field: { ref, ...restField } }) => (
          <AvatarInput
            {...restField}
            src={avatarUrl}
            username={username}
            id={fieldName}
            name={fieldName}
            accept={accept}
            avatarSize={AvatarSize.XXL}
            isCropModalVisible={props.isCropModalVisible}
            onSetCropModal={props.onSetCropModal}
            forEditModal={forEditModal}
          />
        )}
      />
    </form>
  );
};
