import { useEffect } from 'react';
import { useInjection } from 'inversify-react';

import { LayoutStore } from 'stores/layout/layout.store';

import { TYPES } from 'configs/di-types.config';

export const useAllowSwipeBack = () => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);

  useEffect(() => {
    layoutStore.setAllowedSwipeNavBack(true);

    return () => {
      layoutStore.setAllowedSwipeNavBack(false);
    };
  }, [layoutStore]);
};
