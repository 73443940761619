import { FC } from 'react';

import { PlayerProfileHeaderInfoContainer } from 'containers/player-profile/player-profile-header/player-profile-header.container';
import { PlayerProfileOverviewContainer } from 'containers/player-profile/player-profile-overview/player-profile-overview.container';
import { PlayerProfileTabsContainer } from 'containers/player-profile/player-profile-tabs/player-profile-tabs.container';
import { PlayerProfileThumbnailContainer } from 'containers/player-profile/player-profile-thumbnail/player-profile-thumbnail.container';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const PlayerProfileOverviewRoute: FC = () => {
  return (
    <>
      <PlayerProfileThumbnailContainer isForProfile />
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <PlayerProfileHeaderInfoContainer />
          <PlayerProfileTabsContainer />
          <PlayerProfileOverviewContainer />
        </Column>
      </Row>
    </>
  );
};
