import { useCallback, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { ConvertImageItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { pollOptionsSchema } from 'validation/schemas/poll-options.schema';
import { postTitleSchema } from 'validation/schemas/post-title.schema';

import { EditorTheme } from 'components/editor/components/base-editor/base-editor.component';
import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE, DEFAULT_VALIDATION_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IPollAnswer } from 'components/ui/form-fields/poll-form/interfaces/poll.interface';
import { PollForm } from 'components/ui/form-fields/poll-form/poll-form.component';
import { TitleTextArea } from 'components/ui/form-fields/title-text-area/title-text-area.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './create-poll-post-form.module.less';

const createPollPostFormSchema = z.object({
  title: postTitleSchema,
  options: pollOptionsSchema,
});

export type CreatePollPostFormDataType = z.infer<typeof createPollPostFormSchema>;

interface ICreatePollPostFormProps extends IForm<CreatePollPostFormDataType> {
  onFormStateChange: (isDirty: boolean) => void;
  convertImageItemToAttachment: ConvertImageItemToAttachmentType;
}

export const CreatePollPostForm = (props: ICreatePollPostFormProps) => {
  const {
    processing,
    initialValues,
    submitText,
    onSubmit,
    onFormStateChange,
    convertImageItemToAttachment,
  } = props;

  const titleFieldRef = useRef<HTMLTextAreaElement>(null);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const { control, formState, handleSubmit, setValue } = useForm<CreatePollPostFormDataType>({
    defaultValues: initialValues,
    mode: DEFAULT_VALIDATION_MODE,
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(createPollPostFormSchema),
  });

  const { isValid, errors } = formState;

  const handlePollUpdate = useCallback(
    (pollOptions: IPollAnswer[]) => {
      setValue('options', pollOptions, { shouldValidate: true, shouldDirty: true });
    },
    [setValue],
  );

  useEffect(() => {
    onFormStateChange(formState.isDirty);
  }, [formState.isDirty, onFormStateChange]);

  useEffect(() => {
    if (!isDesktopPlus) {
      titleFieldRef.current?.focus();
    }
  }, [isDesktopPlus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.PostCreateForm}>
        <Controller
          name="title"
          control={control}
          render={({ field }) => (
            <TitleTextArea
              {...field}
              ref={titleFieldRef}
              placeholder="Create Title"
              id="title"
              name="title"
              label="Title"
              rows={1}
              error={errors.title?.message}
            />
          )}
        />
        <div className={styles.PostCreateForm__PollWrapper}>
          <PollForm
            theme={EditorTheme.BottomSheet}
            onPullUpdate={handlePollUpdate}
            convertImageItemToAttachment={convertImageItemToAttachment}
          />
        </div>
        <div className={styles.PostCreateForm__Footer}>
          <div className={styles.PostCreateForm__CreateBtn}>
            <Button
              type="submit"
              size={ButtonSize.Big}
              theme={ButtonTheme.Primary}
              disabled={!isValid || processing}
              iconName={IconFontName.SendFilled}
            >
              {submitText}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};
