import { inject, injectable } from 'inversify';
import { comparer, reaction } from 'mobx';

import { IPostResponse } from 'services/posts/interfaces/posts-response.interface';
import { PostsService } from 'services/posts/posts.service';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { publicationAdapter } from 'stores/posts/adapters/publication-adapter.util';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { IPost, IPostAdapter } from 'stores/posts/interfaces/post.interface';
import { IPostsResponse } from 'stores/posts/interfaces/post-response.inerface';
import { TeamsStore } from 'stores/teams/teams.store';

import { TYPES } from 'configs/di-types.config';

import { PAGINATION_LIMIT } from './feeds.config';

@injectable()
export class TeamFanZoneFeedStore extends AdvancedEntriesStore<
  IPost,
  IPostResponse,
  IPostsResponse
> {
  private readonly postsService: PostsService;

  private readonly teamsStore: TeamsStore;

  private readonly layoutStore: LayoutStore;

  constructor(
    @inject(TYPES.PostsService) postsService: PostsService,
    @inject(TYPES.TeamsStore) teamsStore: TeamsStore,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
  ) {
    super(PAGINATION_LIMIT);

    this.postsService = postsService;

    this.teamsStore = teamsStore;

    this.layoutStore = layoutStore;

    reaction(
      () => JSON.stringify([this.teamsStore.teamId, this.layoutStore.activeFeed]),
      () => this.forceFetchToRefresh(),
      {
        equals: comparer.shallow,
      },
    );
  }

  public async fetchNext() {
    if (this.teamsStore.teamId && this.layoutStore.activeFeed === FeedTypes.TeamFanZone) {
      await this.retrieveNext(
        this.fetchTeamFeedPosts(this.teamsStore.teamId),
        <IPostAdapter>publicationAdapter,
      );
    }
  }

  public async fetchTeamFeedPosts(teamId: number): Promise<IResponse<IPostsResponse>> {
    return this.postsService.fetchTeamPosts(teamId, this.pagination, false);
  }

  public async forceFetchToRefresh() {
    if (this.layoutStore.activeFeed === FeedTypes.TeamFanZone) {
      await this.refresh();

      if (this.teamsStore.teamId) {
        await this.forceRefresh(
          this.fetchTeamFeedPosts(this.teamsStore.teamId),
          <IPostAdapter>publicationAdapter,
          this.layoutStore.setPulledRefresher,
        );
      }
    } else {
      this.setEntries([]);
    }
  }

  public async refresh() {
    this.setFetched(false);
    this.setFetching(false);

    this.setIsLastPage(false);
    this.setCurrentPage(1);

    await this.initialise();
  }
}
