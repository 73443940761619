import { memo, SyntheticEvent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import cn from 'classnames';

import { IGame } from 'services/statistics/interface/game.interface';

import { IPlayerDetails } from 'stores/player/interfaces/player-profile.interfaces';

import { ENCODED_BULLET } from 'configs/encoded-symbols.config';
import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';
import { formatPastDate } from 'helpers/format/format-past-date.util';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import lossIcon from 'assets/images/svg/loss.svg';
import mentionIcon from 'assets/images/svg/mention.svg';
import winIcon from 'assets/images/svg/win.svg';

import styles from './team-avatar.module.less';

enum TeamAvatarTheme {
  Regular,
  RecentSplits,
}

interface ITeamAvatarProps {
  theme?: TeamAvatarTheme;
  isWinner: boolean;
  isHomePlayer: boolean;
  isCompactView: boolean;
  player: Maybe<IPlayerDetails>;
  onRowClick: (gameId: number, slug: string) => void;
  onTeamClick: (teamId: number) => void;
  game: IGame;
}

export const TeamAvatar = memo((props: ITeamAvatarProps) => {
  const {
    theme = TeamAvatarTheme.Regular,
    game,
    player,
    isWinner,
    isHomePlayer,
    onTeamClick,
    onRowClick,
    isCompactView,
  } = props;

  const resultClasses = useMemo(
    () =>
      cn(styles.Result, {
        [styles['Result--compact']]: isCompactView,
      }),
    [isCompactView],
  );

  const bottomRightNode = useMemo<JSX.Element>(() => {
    return <img className={resultClasses} src={isWinner ? winIcon : lossIcon} alt="result" />;
  }, [isWinner, resultClasses]);

  const leftBottomNode = useMemo<Maybe<JSX.Element>>(() => {
    if (isHomePlayer || isCompactView) {
      return null;
    }

    return <img alt="team" src={mentionIcon} className={styles.Home} />;
  }, [isHomePlayer, isCompactView]);

  const infoClasses = useMemo(
    () =>
      cn(styles.Info, TOUCH_IGNORE_CLASS, {
        [styles['Info--compact']]: isCompactView,
      }),
    [isCompactView],
  );
  const navigate = useNavigate();

  const handleScoreClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();

      navigate(
        getPath(paths.ARENA_GAME, {
          [paths.GAME_ID_PARAM]: String(game.id),
        }),
      );
    },
    [navigate, game.id],
  );

  const handleRowClick = useCallback(() => {
    if (player?.slug) {
      onRowClick(game.id, player.slug);
    }
  }, [onRowClick, game.id, player?.slug]);

  const handleOpponentClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      const opponentTeamId = isHomePlayer ? game.teams.visitors.id : game.teams.home.id;

      onTeamClick(opponentTeamId);
    },
    [onTeamClick, game, isHomePlayer],
  );

  const homeTeamClasses = useMemo<string>(
    () =>
      cn({
        [styles.Winner]: game.teams.visitors.score.points < game.teams.home.score.points,
      }),
    [game.teams],
  );

  const visitorsTeamClasses = useMemo<string>(
    () =>
      cn({
        [styles.Winner]: game.teams.visitors.score.points > game.teams.home.score.points,
      }),
    [game.teams],
  );

  const teamAvatarClasses = useMemo<string>(
    () =>
      cn(styles.TeamAvatar, {
        [styles['TeamAvatar--compact']]: isCompactView,
        [styles['TeamAvatar--recent-splits-theme']]: theme === TeamAvatarTheme.RecentSplits,
      }),
    [isCompactView, theme],
  );

  const logoClasses = useMemo<string>(
    () =>
      cn(styles.Logo, {
        [styles['Logo--compact']]: isCompactView,
      }),
    [isCompactView],
  );

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={handleRowClick}
      onClick={handleRowClick}
      className={teamAvatarClasses}
    >
      <div className={styles.TeamAvatar__Image}>
        {leftBottomNode}
        <img
          className={logoClasses}
          src={
            isHomePlayer
              ? game.teams.visitors.smallLogoUrl || ''
              : game.teams.home.smallLogoUrl || ''
          }
          alt="logo"
        />
        {bottomRightNode}
      </div>
      {player && (
        <div className={infoClasses}>
          <div className={styles.Info__Group}>
            <img
              className={styles.Info__PlayerAvatar}
              src={player.smallLogoUrl}
              alt={player.lastname}
            />
            <div className={styles.Info__Location}>{isHomePlayer ? '@' : 'vs'}</div>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={handleOpponentClick}
              onClick={handleOpponentClick}
              className={styles.Info__Opponent}
            >
              {isHomePlayer ? game.teams.visitors.name : game.teams.home.name}
            </div>
          </div>
          <div className={styles.Info__Splitter}>{ENCODED_BULLET}</div>
          <div
            role="button"
            tabIndex={0}
            className={styles.Info__Points}
            onKeyDown={handleScoreClick}
            onClick={handleScoreClick}
          >
            <span className={isHomePlayer ? homeTeamClasses : visitorsTeamClasses}>
              {isHomePlayer ? game.teams.home.score.points : game.teams.visitors.score.points}
            </span>
            <span>-</span>
            <span className={isHomePlayer ? visitorsTeamClasses : homeTeamClasses}>
              {isHomePlayer ? game.teams.visitors.score.points : game.teams.home.score.points}
            </span>
          </div>
          <div className={styles.Info__Splitter}>{ENCODED_BULLET}</div>
          <div className={styles.Info__Date}>{formatPastDate(game.dateStart, 'gameLogDate')}</div>
        </div>
      )}
    </div>
  );
});
