import { BookmarksContainer } from 'containers/bookmarks/bookmarks.container';
import { PublicUserFeedToggleContainer } from 'containers/public-user/public-user-feed-toggle/public-user-feed-toggle.container';
import { UserPublicProfileTabsContainer } from 'containers/user-public-profile-tabs/user-public-profile-tabs.container';

import style from './user-public-bookmarks.module.less';

export const UserPublicBookmarksRoute = () => {
  return (
    <div className={style.UserPublicBookmarks}>
      <UserPublicProfileTabsContainer />
      <PublicUserFeedToggleContainer />
      <BookmarksContainer isHeaderVisible={false} />
    </div>
  );
};
