export interface IRecentSearchItem {
  id: number;
  phrase: string;
}

export interface IDeleteRecentSearchItemPayload {
  id: string;
}

export interface ICreateNewRecentSearchItemPayload {
  phrase: string;
}

export interface IGlobalSearchParams {
  limit: string;
  page: string;
  type?: string;
  phrase: string;
}

export interface ISearchAdapter {
  <ISearchResponse, ISearchType>(res: ISearchResponse): ISearchType;
}

export interface ISearchResultsResponse {
  items: ISearchItemResponse[];
  count: number;
  limit: number;
  page: number;
  meta: {
    counters: {
      total: number;
      TEAM: number;
      PLAYER: number;
    };
  };
}

export interface ISearchItemResponse {
  id: number;
  image_src: string;
  options: ISearchTeamItemResponse | ISearchPlayerItemResponse;
}

interface ISearchTeamItemResponse {
  type: 'TEAM';
  conference: string;
  name: string;
  loss: number;
  win: number;
  rank: number;
  is_followed: boolean;
}

interface ISearchPlayerItemResponse {
  type: 'PLAYER';
  jersey: string;
  position: string;
  first_name: string;
  last_name: string;
  team_name: string;
  is_followed: boolean;
  slug: string;
}

export interface ISearchItem<T = ISearchTeamItem | ISearchPlayerItem> {
  id: number;
  name: string;
  imageSrc: string;
  options: T;
}

export interface ISearchTeamItem {
  type: SearchResultType.TEAM;
  conference: string;
  isFollowed: boolean;
  loss: number;
  win: number;
  rank: number;
  teamId: number;
}

export interface ISearchPlayerItem {
  type: SearchResultType.PLAYER;
  jersey: string;
  isFollowed: boolean;
  playerId: number;
  position: string;
  teamName: string;
  playerSlug: string;
}

export enum SearchResultType {
  TEAM,
  PLAYER,
}

export interface IPreparedSearchItem
  extends ISearchItem<IPreparedSearchTeamItem | ISearchPlayerItem> {}

interface IPreparedSearchTeamItem extends ISearchTeamItem {
  members: string;
}
