import { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { useRetrieveSafeAreas } from 'hooks/use-retrieve-safe-areas';

import { FILES_VIDEO_TYPES } from 'validation/validation.constants';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import { PostVideoInput } from 'components/ui/form-fields/post-video-input/post-video-input.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';

import videoRecord from 'assets/images/video-record.svg';

import styles from './short-video-bottom-sheet.module.less';

interface IPhotoCameraBottomSheetProps {
  visible: boolean;
  isLoading: boolean;
  onTakeVideo: () => void;
  onSelectFromLibrary: () => void;
  onClose: () => void;
  onVideoError?: (error: string) => void;
  onUploadVideo?: (video: File) => void;
}

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

export const ShortVideoBottomSheet = (props: IPhotoCameraBottomSheetProps) => {
  const { visible, onSelectFromLibrary, isLoading } = props;

  const [isVideoPermissionsRequested, setIsVideoPermissionsRequested] = useState(false);
  const [isVideoInputOpen, setIsVideoInputOpen] = useState(false);

  const handleOpenLibrary = useCallback(() => {
    if (!isLoading) {
      setIsVideoInputOpen(true);
      onSelectFromLibrary();
    }
  }, [onSelectFromLibrary, isLoading]);

  const safeAreaSizes = useRetrieveSafeAreas();

  const buttonClasses = useMemo(
    () =>
      cn(styles.Button, {
        [styles['Button--disabled']]: isLoading,
      }),
    [isLoading],
  );

  if (!safeAreaSizes) {
    return null;
  }

  return (
    <BaseIonBottomSheet
      visible={visible}
      isAutoHeight
      safeAreaBottom={safeAreaSizes.bottom}
      initialBreakpoint={INITIAL_BREAKPOINT}
      breakpoints={[MIN_BREAKPOINT, INITIAL_BREAKPOINT]}
      onClose={props.onClose}
    >
      <div className={styles.Buttons}>
        <div
          tabIndex={0}
          role="button"
          onKeyDown={props.onTakeVideo}
          onClick={props.onTakeVideo}
          className={buttonClasses}
        >
          <img src={videoRecord} alt="video record" />
          <span>Record a video</span>
        </div>

        <PostVideoInput
          isVideoInputOpen={isVideoInputOpen}
          setIsVideoInputOpen={setIsVideoInputOpen}
          accept={FILES_VIDEO_TYPES.join(', ')}
          onVideoLoad={props.onUploadVideo}
          id="attachVideo"
          name="attachVideo"
          onError={props.onVideoError}
          isVideoPermissionsRequested={isVideoPermissionsRequested}
          setIsVideoPermissionsRequested={setIsVideoPermissionsRequested}
        >
          <div
            tabIndex={0}
            role="button"
            onKeyDown={handleOpenLibrary}
            onClick={handleOpenLibrary}
            className={styles.Button}
          >
            {isLoading ? (
              <Loader isShow isLocal />
            ) : (
              <>
                <IconFont name={IconFontName.Image} size={IconFontSize.Small} />
                <span>Upload from Gallery</span>
              </>
            )}
          </div>
        </PostVideoInput>
      </div>
    </BaseIonBottomSheet>
  );
};
