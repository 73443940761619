import { UserPublicProfileTabsContainer } from 'containers/user-public-profile-tabs/user-public-profile-tabs.container';

import style from './user-public-stat.module.less';

export const UserPublicStatRoute = () => {
  return (
    <div className={style.UserPublicStats}>
      <UserPublicProfileTabsContainer />
      <div>Stat</div>
    </div>
  );
};
