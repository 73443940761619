import { inject, injectable } from 'inversify';

import { HttpService } from 'services/http/http.service';
import { PostsFeedType } from 'services/posts/enums/posts-feed-type.enum';

import { TYPES } from 'configs/di-types.config';

import { TEAM_ID_API_PARAM } from '../http/consts/api-endpoint-params.constants';
import { CORE_TEAMS, FOLLOW_TEAM } from '../http/consts/api-endpoints.constants';

import { ITeamFollowResponse } from './interfaces/team-follow-response.interface';
import { ITeamResponse } from './interfaces/team-response.interface';

@injectable()
export class TeamsService {
  private readonly httpService: HttpService;

  constructor(@inject(TYPES.HttpService) httpService: HttpService) {
    this.httpService = httpService;
  }

  public fetchTeams() {
    const params = {
      limit: '100',
      page: '1',
      type: PostsFeedType.Team,
    };

    return this.httpService.request<IResponseItems<ITeamResponse[]>>({
      method: 'GET',
      url: CORE_TEAMS,
      params: new URLSearchParams(params),
    });
  }

  public followTeam(teamId: number): Promise<IResponse<ITeamFollowResponse>> {
    return this.httpService.request<ITeamFollowResponse>({
      method: 'POST',
      url: FOLLOW_TEAM,
      routeParams: { [TEAM_ID_API_PARAM]: teamId.toString() },
    });
  }

  public unfollowTeam(teamId: number) {
    return this.httpService.request<ITeamFollowResponse>({
      method: 'DELETE',
      url: FOLLOW_TEAM,
      routeParams: { [TEAM_ID_API_PARAM]: teamId.toString() },
    });
  }
}
