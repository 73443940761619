import { FC, memo, useCallback, useState } from 'react';
import cn from 'classnames';

import {
  ConvertImageItemToAttachmentType,
  IUploadImageData,
} from 'services/application/interfaces/upload-image.interface';

import { POLL_INPUT_MAX_LENGTH } from 'configs/controls.config';

import { FILES_IMAGE_TYPES } from 'validation/validation.constants';

import {
  AttachmentsImageItem,
  AttachmentsImageTheme,
} from 'components/ui/attachments-image-item/attachment-image-item.component';
import { PostImageInput } from 'components/ui/form-fields/post-image-input/post-image-input.component';
import { InputImageDataType } from 'components/ui/form-fields/post-image-input/post-image-input.types';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Loader, LoaderSizeEnum, LoaderTheme } from 'components/ui/loader/loader.component';

import { Input } from '../../input/input.component';
import { IPollAnswer } from '../interfaces/poll.interface';

import styles from './poll-option-input.module.less';

interface IPollAnswerError {
  title?: {
    message?: string;
    type?: string;
  };
}

interface IPollAnswerControlProps {
  answer: IPollAnswer;
  isRemoveButtonActive: boolean;
  onAnswerRemove: () => void;
  onPollOptionChange: (
    value: IUploadImageData | string,
    order: number,
    field: 'title' | 'imageData',
  ) => void;
  onPollOptionBlur: (order: number, field: 'title' | 'imageData') => void;
  withMedia?: boolean;
  convertImageItemToAttachment?: ConvertImageItemToAttachmentType;
  errors?: IPollAnswerError;
}

export const PollAnswerControl: FC<IPollAnswerControlProps> = memo(
  (props: IPollAnswerControlProps) => {
    const {
      answer,
      isRemoveButtonActive,
      onAnswerRemove,
      onPollOptionChange,
      onPollOptionBlur,
      withMedia,
      convertImageItemToAttachment,
      errors,
    } = props;

    const [isMediaLoading, setIsMediaLoading] = useState(false);

    const handleTitleChange = useCallback(
      (value: string) => {
        onPollOptionChange(value, answer.order, 'title');
      },
      [onPollOptionChange, answer],
    );

    const handleTitleBlur = useCallback(() => {
      onPollOptionBlur(answer.order, 'title');
    }, [onPollOptionBlur, answer]);

    const onMediaLoading = useCallback((values: string[]) => {
      setIsMediaLoading(values.length > 0);
    }, []);

    const handleImageUploaded = useCallback(
      (data: InputImageDataType) => {
        const imageData = data[0];

        if ('uuid' in imageData) {
          onPollOptionChange(imageData, answer.order, 'imageData');
        }
      },
      [onPollOptionChange, answer],
    );

    return (
      <div className={styles.PollOptionInput}>
        {withMedia && (
          <div
            className={cn(styles.PollOptionInput__MediaBox, {
              [styles.PollOptionInput__MediaBox__WithImage]: !!answer?.imageData,
            })}
          >
            {answer?.imageData && (
              <div className={styles.PollOptionInput__MediaPreview}>
                <AttachmentsImageItem
                  alt="image"
                  imageUrl={answer.imageData.url}
                  mimeType={answer.imageData.mimeType}
                  theme={AttachmentsImageTheme.MediumPreview}
                />
              </div>
            )}
            <PostImageInput
              isDisabled={false}
              accept={FILES_IMAGE_TYPES.join(',')}
              onImageLoad={handleImageUploaded}
              id={`pollImage__${answer.id}`}
              name="pollImage"
              setLoadingImages={onMediaLoading}
              convertImageItemToAttachment={convertImageItemToAttachment}
            >
              {isMediaLoading ? (
                <Loader size={LoaderSizeEnum.XS} theme={LoaderTheme.Blue} isShow />
              ) : (
                <IconFont name={IconFontName.AddImage} size={IconFontSize.Big} />
              )}
            </PostImageInput>
          </div>
        )}
        <div className={styles.PollOptionInput__InputBox}>
          <Input
            id={`pollAnswer__${answer.id}`}
            type="text"
            name="pollAnswer"
            placeholder={`Option ${answer.order + 1}`}
            onChange={handleTitleChange}
            onBlur={handleTitleBlur}
            value={answer.title}
            rightIconProps={{
              name: isRemoveButtonActive ? IconFontName.Close : null,
              onClick: onAnswerRemove,
              size: IconFontSize.Small,
              className: styles.PollOptionInput__InputBox__RemoveIcon,
            }}
            maxLengthCount={POLL_INPUT_MAX_LENGTH}
            error={errors?.title?.message}
          />
        </div>
      </div>
    );
  },
);
