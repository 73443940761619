import { memo, useMemo } from 'react';
import cn from 'classnames';

import { UserActivityStatus } from 'stores/auth/enums/user-activity-status.enum';

import { AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './avatar-status.module.less';

interface IAvatarStatusProps {
  size: AvatarSize;
  userActivityStatus?: UserActivityStatus;
}

export const AvatarStatus = memo((props: IAvatarStatusProps) => {
  const { size, userActivityStatus } = props;

  const avatarStatusClassNames = useMemo(
    () =>
      cn(styles.AvatarStatus, {
        [styles['AvatarStatus--size-mega']]: size === AvatarSize.MEGA,
        [styles['AvatarStatus--size-xxxl']]: size === AvatarSize.XXXL,
        [styles['AvatarStatus--size-xxl']]: size === AvatarSize.XXL,
        [styles['AvatarStatus--size-l']]: size === AvatarSize.L,
        [styles['AvatarStatus--size-m']]: size === AvatarSize.M,
        [styles['AvatarStatus--size-s']]: size === AvatarSize.S,

        [styles['AvatarStatus--online']]: userActivityStatus === UserActivityStatus.Online,
        [styles['AvatarStatus--away']]: userActivityStatus === UserActivityStatus.Away,
        [styles['AvatarStatus--do-not-disturb']]:
          userActivityStatus === UserActivityStatus.DoNotDisturb,
        [styles['AvatarStatus--invisible']]: userActivityStatus === UserActivityStatus.Invisible,
      }),
    [size, userActivityStatus],
  );

  return (
    <div className={avatarStatusClassNames}>
      {size === AvatarSize.MEGA && (
        <IconFont name={IconFontName.ChevronDown} size={IconFontSize.Big} />
      )}
    </div>
  );
});
