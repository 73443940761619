import { memo, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { IntoStateNavigation } from 'containers/intro/enums/intro-state-navigation.enum';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import * as paths from 'routes/paths.constants';

import { useResponsive } from 'hooks/use-responsive';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { Portal, PortalType } from 'components/ui/portal/portal.component';

import introBackground from 'assets/images/intro-bg.webp';
import introLargeBackground from 'assets/images/intro-large-bg.webp';

import styles from './intro-popup.module.less';

interface IIntroPopupProps {
  onSetFirstTimeIntroPopUpShown: () => void;
}

export const IntroPopup = memo((props: IIntroPopupProps) => {
  const { onSetFirstTimeIntroPopUpShown } = props;

  const navigate = useNavigate();

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const backgroundImage = useMemo(() => {
    if (isDesktopPlus) {
      return introLargeBackground;
    }

    return introBackground;
  }, [isDesktopPlus]);

  const handleClickPickMyFavorites = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.OnboardingStartPickFavorites,
      },
    });

    onSetFirstTimeIntroPopUpShown();
    navigate(paths.FOLLOWING, { replace: true, state: IntoStateNavigation.FirstTimeOpened });
  }, [navigate, onSetFirstTimeIntroPopUpShown]);

  const handleClickNotNow = useCallback(() => {
    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.OnboardingStartNotNow,
      },
    });

    onSetFirstTimeIntroPopUpShown();
  }, [onSetFirstTimeIntroPopUpShown]);

  useEffect(() => {
    GoogleAnalyticService.event({
      eventName: 'screen_custom_view',
      eventParams: {
        screen_type: ScreenNamesEnum.OnboardingStart,
      },
    });
  }, []);

  return (
    <Portal type={PortalType.IntroPopup}>
      <div className={styles.IntroPopup}>
        <div className={styles.IntroPopup__Dialog}>
          <div className={styles.IntroPopup__Content}>
            <div className={styles.IntroPopup__Picture}>
              <img src={backgroundImage} className={styles.IntroPopup__Image} alt="IntroPopup" />
            </div>
            <h2 className={styles.IntroPopup__Title}>Unlock Digits Sports</h2>
            <p className={styles.IntroPopup__Text}>
              When you follow your favorite teams & players, you&apos;ll have a personalized Digits
              experience & get the latest NBA updates all in one place.
            </p>
            <div className={styles.IntroPopup__Buttons}>
              <Button
                theme={ButtonTheme.Primary}
                size={ButtonSize.Big}
                onClick={handleClickPickMyFavorites}
              >
                Pick My Favorites
              </Button>
              <Button theme={ButtonTheme.Text} size={ButtonSize.Big} onClick={handleClickNotNow}>
                Not Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
});
