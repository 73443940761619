import { FC } from 'react';

import { GamesDetailedSelectTeamContainer } from 'containers/games-detailed-select-team/games-detailed-select-team.container';
import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { ScoreboardContainer } from 'containers/scoreboard/scoreboard.container';
import { TeamProfileHeaderInfoContainer } from 'containers/team/team-profile-header/team-profile-header.container';
import { TeamProfileTabsContainer } from 'containers/team/team-profile-tabs/team-profile-tabs';
import { TeamStatsToggleContainer } from 'containers/team/team-stats-toggle/team-stats-toggle.container';
import { TeamProfileThumbnailContainer } from 'containers/team-profile/team-profile-thumbnail/team-profile-thumbnail.container';
import { WeekBarContainer } from 'containers/weeks-bar/week-bar.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import styles from 'routes/home/home-scores/home-scores.module.less';

import { useResponsive } from 'hooks/use-responsive';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const TeamScoresRoute: FC = () => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  return (
    <PageWrapperContainer>
      <TeamProfileThumbnailContainer />
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <TeamProfileHeaderInfoContainer />
          <TeamProfileTabsContainer />
          {!isDesktopPlus && <TeamStatsToggleContainer />}
          <div className={styles.Scores}>
            <div className={styles.Scores__Header}>
              {isDesktopPlus && <h3 className={styles.Scores__Title}>NBA Scoreboard</h3>}
              <GamesDetailedSelectTeamContainer />
            </div>
            <div className={styles.Scores__WeekBar}>
              <WeekBarContainer />
            </div>
            <div className={styles.Scores__Games}>
              <ScoreboardContainer />
            </div>
          </div>
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
