import { FC, memo } from 'react';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './image-crop-modal-header.module.less';

interface IImageCropModalHeaderProps {
  text: string;
  onButtonClick: () => void;
}

export const ImageCropModalHeader: FC<IImageCropModalHeaderProps> = memo(
  (props: IImageCropModalHeaderProps) => {
    const { text } = props;

    return (
      <div className={styles.Header}>
        <h4 className={styles.Header__Title}>{text}</h4>
        <div className={styles.Header__Close}>
          <IconButton
            theme={IconButtonTheme.Transparent}
            iconName={IconFontName.Close}
            onClick={props.onButtonClick}
          />
        </div>
      </div>
    );
  },
);
