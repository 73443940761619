import { FC } from 'react';

import { GlobalSearchInputContainer } from 'containers/global-search-input/global-search-input.container';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './global-search-header-mobile.module.less';

interface IGlobalSearchHeaderMobileProps {
  onFocus: () => void;
  onBackButtonClick: () => void;
}

export const GlobalSearchHeaderMobile: FC<IGlobalSearchHeaderMobileProps> = (props) => {
  return (
    <div className={styles.SearchHeader}>
      <IconButton
        theme={IconButtonTheme.Transparent}
        iconName={IconFontName.ChevronLeft}
        onClick={props.onBackButtonClick}
      />
      <GlobalSearchInputContainer
        isEnableSearchButton
        key="globalSearch"
        onFocusCallback={props.onFocus}
      />
    </div>
  );
};
