import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';

import { IReputationSeason, ISeason } from '../interfaces/season.interface';

export const seasonOptionAdapter = (seasonResponse: ISeason | IReputationSeason): ISelectOption => {
  const { id, label } = seasonResponse;

  return {
    label,
    value: String(id),
  };
};
