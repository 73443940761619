import { FC } from 'react';

import { PlayerProfileHeaderInfoContainer } from 'containers/player-profile/player-profile-header/player-profile-header.container';
import { PlayerProfileStatsContainer } from 'containers/player-profile/player-profile-stats/player-profile-stats.container';
import { PlayerProfileTabsContainer } from 'containers/player-profile/player-profile-tabs/player-profile-tabs.container';
import { PlayerProfileThumbnailContainer } from 'containers/player-profile/player-profile-thumbnail/player-profile-thumbnail.container';
import { PlayerStatsToggleContainer } from 'containers/player-stats-toggle/player-stats-toggle.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const PlayerProfileStatsRoute: FC = () => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  return (
    <>
      <PlayerProfileThumbnailContainer isForProfile />
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <PlayerProfileHeaderInfoContainer />
          <PlayerProfileTabsContainer />
          {!isDesktopPlus && <PlayerStatsToggleContainer />}
          <PlayerProfileStatsContainer />
        </Column>
      </Row>
    </>
  );
};
