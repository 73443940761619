import { FC, useCallback, useMemo } from 'react';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { LayoutStore } from 'stores/layout/layout.store';
import { PostsStore } from 'stores/posts/posts.store';
import { TeamsStatsStore } from 'stores/teams-stats/teams-stats.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';
import { UserDetails } from 'components/user-details/user-details.component';

import styles from './user-details-container.module.less';

export const UserDetailsContainer: FC = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const postsStore = useInjection<PostsStore>(TYPES.PostsStore);
  const teamsStatsStore = useInjection<TeamsStatsStore>(TYPES.TeamsStatsStore);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handleClosePopUp = useCallback(() => {
    layoutStore.setIsUserDetailsPopUpOpen(false);
    postsStore.setUserDetails(null);
  }, [layoutStore, postsStore]);

  const favoriteTeam = useMemo(
    () =>
      teamsStatsStore.teams.find((team) => team.id === postsStore.userDetails?.favoriteTeamId) ||
      null,
    [postsStore.userDetails, teamsStatsStore.teams],
  );

  const favoritePlayer = useMemo(
    () =>
      teamsStatsStore.players.find(
        (player) => player.id === postsStore.userDetails?.favoritePlayerId,
      ) || null,
    [postsStore.userDetails, teamsStatsStore.players],
  );

  if (isDesktopPlus || !postsStore.userDetails) {
    return null;
  }

  return (
    <div className={styles.UserDetails}>
      <BaseIonBottomSheet
        isAutoHeight
        isOverflowVisible
        visible={layoutStore.isUserDetailsPopUpOpen}
        safeAreaBottom={0}
        breakpoints={[0, 1]}
        initialBreakpoint={1}
        onClose={handleClosePopUp}
      >
        <UserDetails
          reputationPoints={null} // should be real value in future
          avatarSrc={postsStore.userDetails.avatarUrl}
          player={favoritePlayer}
          team={favoriteTeam}
          thumbnailURL={postsStore.userDetails.smallThumbnailUrl}
          username={postsStore.userDetails.username}
          realName={postsStore.userDetails.name}
          // onClose={handleClosePopUp}
        />
      </BaseIonBottomSheet>
    </div>
  );
});
