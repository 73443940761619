import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import { z } from 'zod';

import { ConvertImageItemToAttachmentType } from 'services/application/interfaces/upload-image.interface';
import { IVideoResponse } from 'services/posts/interfaces/posts-response.interface';

import { useKeyboardTrigger } from 'hooks/use-keyboard-trigger';
import { useMainProvider } from 'hooks/use-main-provider';

import { editorSchemaComment } from 'validation/schemas/editor.schema';

import {
  EditorDataType,
  EditorLoadDataType,
  EditorTheme,
} from 'components/editor/components/base-editor/base-editor.component';
import { Editor } from 'components/editor/editor.component';
import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE, VALIDATION_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './comment-create-form.module.less';

const commentCreateSchema = z
  .object({
    editor: editorSchemaComment,
  })
  .strict();

export type CommentCreateDataType = z.infer<typeof commentCreateSchema>;

interface ICommentCreateFormProps extends IForm<CommentCreateDataType> {
  isEditorExpand: boolean;
  isCreateCommentSuccess?: boolean;
  isFixedPosition: boolean;
  isNeedActivateEditor: boolean;
  withLoader?: boolean;
  isVideoLoading: boolean;
  postVideo: Maybe<IVideoResponse>;
  setIsCreateCommentSuccess?: (isSuccess: boolean) => void;
  setIsNeedActivateEditor?: (activeEditor: boolean) => void;
  setIsEditorExpand?: (isExpand: boolean) => void;
  setFocusedCommentReplyField?: (focusedField: boolean) => void;
  convertImageItemToAttachment: ConvertImageItemToAttachmentType;
  onUploadVideo: (video: File) => void;
  onRemoveVideo: () => void;
  onVideoError?: (error: string) => void;
}

export const CommentCreateForm = (props: ICommentCreateFormProps) => {
  const {
    isCreateCommentSuccess = false,
    processing,
    initialValues,
    isEditorExpand,
    isNeedActivateEditor,
    isFixedPosition,
    postVideo,
    isVideoLoading,
    withLoader = true,
    setIsCreateCommentSuccess,
    setIsEditorExpand,
    setIsNeedActivateEditor,
    setFocusedCommentReplyField,
    onSubmit,
  } = props;

  const { isNativeApp } = useMainProvider();
  const { isKeyboardOpen } = useKeyboardTrigger(isNativeApp);

  const [commentEditor, setCommentEditor] = useState<EditorLoadDataType>();
  const [isEditorActive, setIsEditorActive] = useState<boolean>(false);
  const [loadingImages, setLoadingImages] = useState<string[]>([]);
  const [isHighlighted, setIsHighlighted] = useState<boolean>(isEditorExpand);

  const { control, formState, handleSubmit, reset } = useForm<CommentCreateDataType>({
    defaultValues: initialValues,
    mode: VALIDATION_MODE,
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(commentCreateSchema),
  });

  const { errors, isSubmitSuccessful, isValid } = formState;

  const handleEditorChange = useCallback(
    // Any type picked from Hook Form types
    (onChange: (...event: any[]) => void) => (value: EditorDataType) => onChange(value),
    [],
  );

  const handleSetEditor = useCallback((editor: EditorLoadDataType) => {
    setCommentEditor(editor);
  }, []);

  const handleEditorFocus = useCallback(() => {
    setIsEditorActive(true);
    setIsHighlighted(true);
    setIsEditorExpand?.(true);
    setIsNeedActivateEditor?.(true);
  }, [setIsEditorExpand, setIsNeedActivateEditor]);

  const handleEditorBlur = useCallback(() => {
    setIsEditorActive(false);
    setIsHighlighted(false);
    setIsNeedActivateEditor?.(false);
  }, [setIsNeedActivateEditor]);

  useEffect(() => {
    if (isNeedActivateEditor) {
      handleEditorFocus();
    }
  }, [isNeedActivateEditor, handleEditorFocus]);

  useEffect(() => {
    setFocusedCommentReplyField?.(!isFixedPosition && isKeyboardOpen);
  }, [setFocusedCommentReplyField, isFixedPosition, isKeyboardOpen]);

  useEffect(() => {
    if (!processing && isSubmitSuccessful && isCreateCommentSuccess) {
      commentEditor?.clear();
      reset();

      // Use setTimeout because commentEditor.update set focus on form that
      // can be removed in next render
      setTimeout(() => {
        setIsEditorActive(false);
        setIsHighlighted(false);
        setIsEditorExpand?.(false);
      });

      setIsCreateCommentSuccess?.(false);
    }
  }, [
    commentEditor,
    isSubmitSuccessful,
    processing,
    isCreateCommentSuccess,
    reset,
    setIsEditorExpand,
    setIsCreateCommentSuccess,
  ]);

  const formClasses = useMemo<string>(() => {
    return cn(styles.Wrapper, {
      [styles['Wrapper--processing']]: processing,
      [styles['Wrapper--keyboard']]: isFixedPosition && isKeyboardOpen,
    });
  }, [processing, isKeyboardOpen, isFixedPosition]);

  return (
    <>
      <Form className={formClasses} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="editor"
          control={control}
          render={({ field }) => (
            <Editor
              theme={EditorTheme.Grey}
              placeholder="Add comment"
              id="editor"
              name="editor"
              isHighlighted={isHighlighted}
              disabled={!isValid || processing}
              isExpand={isEditorActive || isEditorExpand}
              isActive={isEditorActive}
              isEnabledAutoScrolling={!isFixedPosition}
              value={field.value}
              error={errors.editor?.content?.length?.message}
              postVideo={postVideo}
              loadingImages={loadingImages}
              isVideoLoading={isVideoLoading}
              setLoadingImages={setLoadingImages}
              onLoad={handleSetEditor}
              onEditorFocus={handleEditorFocus}
              onBlur={handleEditorBlur}
              onUploadVideo={props.onUploadVideo}
              onRemoveVideo={props.onRemoveVideo}
              onVideoError={props.onVideoError}
              onChange={handleEditorChange(field.onChange)}
              convertImageItemToAttachment={props.convertImageItemToAttachment}
            />
          )}
        />
      </Form>
      {withLoader && <Loader isShow={!!processing} />}
    </>
  );
};
