import { FC } from 'react';
import { components, OptionProps } from 'react-select';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './avatar-with-name-option.module.less';

interface IAvatarItemProps {
  avatarUrl: string;
  label: string;
}

export const AvatarWithNameOption: FC<OptionProps<IAvatarItemProps>> = (props) => {
  const { data } = props;
  const { avatarUrl, label } = data;

  return (
    <components.Option {...props}>
      <div className={styles.Avatar}>
        <Avatar size={AvatarSize.XS} username={label} src={avatarUrl} />
        <div className={styles.Avatar__Name}>{label}</div>
      </div>
    </components.Option>
  );
};
