import { FC, memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';

import { IFollow } from 'stores/follow/interfaces/follow.interface';
import { ITeam } from 'stores/teams/interfaces/team.interface';

import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionLink } from 'components/bars/bar-action.type';
import { SidebarFollowingItem } from 'components/sidebars/sidebar-following-item/sidebar-following-item.component';
import { SidebarItem, SidebarItemTheme } from 'components/ui/sidebar-item/sidebar-item.component';

import styles from './sidebar-communities-list.module.less';

interface ICommunitiesListProps {
  isMobile?: boolean;
  teamId: Maybe<number>;
  isExploreList?: boolean;
  isNoJoined?: boolean;
  activePath: string;
  teams: ITeam[];
  followingPlayers?: IFollow[];
  onClick: (actionLink: BarActionLink) => void;
  onJoinTeam?: () => void;
}

export const SidebarCommunitiesList: FC<ICommunitiesListProps> = memo(
  (props: ICommunitiesListProps) => {
    const {
      isMobile = false,
      isExploreList = false,
      teamId,
      activePath,
      teams,
      isNoJoined = false,
      followingPlayers = [],
      onClick,
    } = props;

    const navigate = useNavigate();

    const preparedTeams = useMemo<Array<ITeam & { isActive: boolean }>>(
      () =>
        teams.map((team) => ({
          ...team,
          isActive: activePath.includes(
            getPath(paths.TEAM_FEED, { [paths.TEAM_ID_PARAM]: team.teamId.toString() }),
          ),
        })),
      [activePath, teams],
    );

    const handlePlayerClick = useCallback(
      (slug: string) => {
        navigate(
          getPath(paths.PLAYER_PROFILE, {
            [paths.PLAYER_SLUG_PARAM]: slug,
          }),
        );
      },
      [navigate],
    );

    const handleClick = useCallback(
      (currentTeamId: Maybe<number>, currentTeamName: string) => () => {
        if (teamId !== currentTeamId) {
          GoogleAnalyticService.event({
            eventName: 'button_custom_tap',
            eventParams: {
              button_tap_type: ButtonTapsEnum.ExploreTeam,
              team: currentTeamName,
            },
          });

          if (currentTeamId) {
            onClick({
              type: BarAction.Link,
              path: getPath(paths.TEAM, { [paths.TEAM_ID_PARAM]: currentTeamId.toString() }),
            });
          }
        }
      },
      [onClick, teamId],
    );

    return (
      <ul className={styles.SidebarCommunitiesList}>
        {preparedTeams.map((team) => (
          <SidebarItem
            isMobile={isMobile}
            isNeedPlusButton={isExploreList && teamId === team.teamId}
            key={team.teamId}
            isActive={team.isActive}
            isNoJoined={isNoJoined}
            followers={team.followers}
            imageSrc={team.thumbnailUrl}
            name={team.name}
            theme={SidebarItemTheme.Image}
            isRoot={team.isActive}
            onClick={handleClick(team.teamId, team.name)}
            onJoinTeam={props.onJoinTeam}
          />
        ))}
        {followingPlayers.map((item) => (
          <SidebarFollowingItem
            key={item.id}
            isSmall
            imageSrc={item.thumbnailUrl}
            name={item.label}
            onClick={handlePlayerClick}
            slug={item.slug}
          />
        ))}
      </ul>
    );
  },
);
