import { FC, useMemo } from 'react';
import cn from 'classnames';

import {
  IPreparedSearchItem,
  ISearchPlayerItem,
  ISearchTeamItem,
} from 'stores/search/interfaces/search.interface';

import { EmptyState } from 'components/empty-state/empty-state.component';
import { GlobalSearchItem } from 'components/global-search/global-search-item/global-search-item.component';
import { Loader, LoaderSizeEnum } from 'components/ui/loader/loader.component';

import styles from './suggestion-list.module.less';

interface ISuggestionListProps {
  items: IPreparedSearchItem[];
  searchValue: string;
  loading: boolean;
  fetched: boolean;
  isBlackBackground?: boolean;
  forHeader?: boolean;
  onItemClick: (options: ISearchTeamItem | ISearchPlayerItem) => void;
}

export const SuggestionList: FC<ISuggestionListProps> = (props) => {
  const {
    items,
    searchValue,
    loading,
    fetched,
    isBlackBackground = false,
    forHeader = false,
  } = props;

  const suggestionListClasses = useMemo(
    () =>
      cn(styles.Suggestion, {
        [styles['Suggestion--small']]: forHeader && !items.length && !loading && fetched,
      }),
    [forHeader, items, loading, fetched],
  );

  return (
    <div className={suggestionListClasses}>
      {loading && <Loader size={LoaderSizeEnum.M} isShow />}

      {!loading &&
        items.map((item) => (
          <GlobalSearchItem
            key={item.id}
            item={item}
            isShowFollowButton={false}
            onItemClick={props.onItemClick}
          />
        ))}

      {forHeader && !items.length && !loading && fetched && (
        <div className={styles.EmptyState}>
          <span className={styles['EmptyState__Text--label']}>{`No results for "`}</span>
          <span className={styles['EmptyState__Text--query']}>{searchValue}</span>
          <span className={styles['EmptyState__Text--label']}>{`" found`}</span>
        </div>
      )}

      {!items.length && !loading && fetched && !forHeader && (
        <EmptyState
          isBlackBackground={isBlackBackground}
          message={`No results for '${searchValue}' found`}
        />
      )}
    </div>
  );
};
