import { FC, memo, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IAthlete } from 'stores/auth/interfaces/athlete.interface';
import { IPlayerStats } from 'stores/teams-stats/interfaces/players-stats.interface';
import { ITeamsStats } from 'stores/teams-stats/interfaces/teams-stats.interface';

import {
  BOOKMARK_COLLABORATION_ITEM_GROUPS,
  MY_MESSAGES_MENU_GROUPS,
} from 'configs/context-menu-groups.config';

import { BarActionType } from 'components/bars/bar-action.type';
import { PostsTheme } from 'components/posts/post-card/enums/posts-theme.enum';
import { IFormattedDates } from 'components/posts/post-card/interfaces/post-info.interface';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { ContextMenuTooltip } from 'components/ui/context-menu-tooltip/context-menu-tooltip.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { TeamPlayerLabel } from 'components/ui/team-player-label/team-player-label.component';

import styles from './collaboration-item-header.module.less';

interface ICollaborationItemHeaderProps {
  userSourceAvatar: Maybe<string>;
  userSourceUsername: string;
  createdDates: IFormattedDates;
  favoriteTeam: Maybe<ITeamsStats>;
  favoritePlayer: Maybe<IPlayerStats>;
  onTeamClick: (teamId: number) => void;
  onPlayerClick: (slug: string) => void;
  athlete: Maybe<IAthlete>;
  theme: PostsTheme;
  isNeedCloseTooltip?: boolean;
  isMyPost: boolean;
  setIsNeedCloseTooltip?: (value: boolean) => void;
  onContextMenuClick: (barAction: BarActionType) => void;
}

export const CollaborationItemHeader: FC<ICollaborationItemHeaderProps> = memo(
  (props: ICollaborationItemHeaderProps) => {
    const {
      theme,
      isMyPost,
      userSourceAvatar,
      userSourceUsername,
      createdDates,
      favoriteTeam,
      favoritePlayer,
      athlete,
      isNeedCloseTooltip,
      setIsNeedCloseTooltip,
    } = props;

    const handleContextMenuAnchorClick = useCallback((event: SyntheticEvent) => {
      event.preventDefault();
    }, []);

    const headerClasses = useMemo(
      () =>
        cn(styles.CollaborationItemHeader, {
          [styles['CollaborationItemHeader--bookmark-theme']]: theme === PostsTheme.Bookmark,
        }),
      [theme],
    );

    const contextMenuItems = useMemo(() => {
      if (theme === PostsTheme.Bookmark) {
        return BOOKMARK_COLLABORATION_ITEM_GROUPS;
      }

      if (isMyPost) {
        return MY_MESSAGES_MENU_GROUPS;
      }

      return [];
    }, [isMyPost, theme]);

    return (
      <div className={headerClasses}>
        <Avatar src={userSourceAvatar} size={AvatarSize.M} username={userSourceUsername} />
        <div className={styles.CollaborationItemHeader__Wrapper}>
          <div className={styles.CollaborationItemHeaderInfo}>
            <div className={styles.CollaborationItemHeaderInfo__Top}>
              <span className={styles.CollaborationItemHeaderInfo__Username}>
                {userSourceUsername}
              </span>
            </div>
            {!athlete && (favoriteTeam || favoritePlayer) && (
              <div className={styles.CollaborationItemHeaderInfo__Bottom}>
                <TeamPlayerLabel
                  team={favoriteTeam}
                  player={favoritePlayer}
                  onTeamClick={props.onTeamClick}
                  onPlayerClick={props.onPlayerClick}
                />
              </div>
            )}
          </div>
          <div className={styles.CollaborationItemHeaderInfo__Date}>
            {createdDates.relativeShort}
          </div>
        </div>
        {!!contextMenuItems.length && (
          <div className={styles.PostCardHeader__Tooltip}>
            <ContextMenuTooltip
              toggleOnClick
              groups={contextMenuItems}
              tooltipProps={{ tooltipOffset: 8, placement: 'bottom-end' }}
              isNeedCloseTooltip={isNeedCloseTooltip}
              setIsNeedCloseTooltip={setIsNeedCloseTooltip}
              onItemClick={props.onContextMenuClick}
            >
              <IconButton
                iconName={IconFontName.More}
                theme={IconButtonTheme.Secondary}
                iconSize={IconFontSize.Big}
                onClick={handleContextMenuAnchorClick}
              />
            </ContextMenuTooltip>
          </div>
        )}
      </div>
    );
  },
);
