import { FC, PropsWithChildren, useCallback } from 'react';
import { Placement } from '@floating-ui/core';

import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { TextTooltip } from 'components/ui/text-tooltip/text-tooltip.component';
import { TooltipEventType } from 'components/ui/tooltip/tooltip.component';

export interface IModalHeaderButtonProps {
  text: string;
  icon?: IconFontName;
  tooltip?: {
    eventType: TooltipEventType;
    text: string;
    placement?: Placement;
    offset?: number;
    disappearTime?: number;
  };
  onClick: () => void;
}

export const ModalHeaderButtonComponent: FC<IModalHeaderButtonProps> = (
  props: PropsWithChildren<IModalHeaderButtonProps>,
) => {
  const { text, icon, tooltip, onClick } = props;

  const handleButtonClick = useCallback(() => onClick(), [onClick]);

  if (tooltip) {
    return (
      <TextTooltip
        tooltipOffset={tooltip.offset}
        tooltipDisappearTime={tooltip.disappearTime}
        eventType={tooltip.eventType}
        text={tooltip.text}
        placement={tooltip.placement}
      >
        <Button
          theme={ButtonTheme.Text}
          size={ButtonSize.Small}
          iconName={icon}
          onClick={handleButtonClick}
        >
          {text}
        </Button>
      </TextTooltip>
    );
  }

  return (
    <Button
      theme={ButtonTheme.Text}
      size={ButtonSize.Small}
      iconName={icon}
      onClick={handleButtonClick}
    >
      {text}
    </Button>
  );
};
