import { Camera } from '@capacitor/camera';
import { Microphone } from '@mozartec/capacitor-microphone';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AudioPermissions } from 'capacitor-audio-permission';

import { handleIosCameraDenied } from 'components/forms/photo-camera-field-with-backend-processing/utils/handle-ios-camera-denied.util';
import { handleIosMicrophoneDenied } from 'components/forms/photo-camera-field-with-backend-processing/utils/handle-ios-microphone-denied.util';

enum PermissionState {
  Denied = 'denied',
  Granted = 'granted',
  Prompt = 'prompt',
}

export const checkPermissions = async (isNativeApp: boolean, isNativeAndroidApp: boolean) => {
  if (isNativeApp) {
    if (!isNativeAndroidApp) {
      // for IOS
      const cameraPermission = await Camera.checkPermissions();

      if (cameraPermission.camera === PermissionState.Denied) {
        await handleIosCameraDenied();
        return false;
      }

      const audioPermissions = await Microphone.requestPermissions();

      if (audioPermissions.microphone === PermissionState.Denied) {
        await handleIosMicrophoneDenied();
        return false;
      }

      return true;
    }
    // for android
    const cameraPermission = await Camera.checkPermissions();
    const audioPermissions = await AudioPermissions.checkPermissions();
    const microphonePermissions = await Microphone.checkPermissions();

    if (
      cameraPermission.camera === PermissionState.Granted &&
      audioPermissions.audio === PermissionState.Granted &&
      microphonePermissions.microphone === PermissionState.Granted
    ) {
      return true;
    }

    if (cameraPermission.camera === PermissionState.Denied) {
      return false;
    }

    if (cameraPermission.camera === PermissionState.Prompt) {
      await Camera.requestPermissions({ permissions: ['camera', 'photos'] });
    }

    if (audioPermissions.audio === PermissionState.Denied) {
      return false;
    }

    if (audioPermissions.audio === PermissionState.Prompt) {
      await AudioPermissions.requestPermissions();
    }

    if (microphonePermissions.microphone === PermissionState.Denied) {
      return false;
    }

    if (microphonePermissions.microphone === PermissionState.Prompt) {
      await Microphone.requestPermissions();
    }

    return true;
  }
  return true;
};
