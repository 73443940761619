import { FC, memo, useMemo } from 'react';

import { Avatar, AvatarSize } from '../avatar/avatar.component';

import { getColumnChartFlex } from './get-column-chart-flex.util';

import styles from './column-chart.module.less';

interface IColumnChartProps {
  avatarUrl: Maybe<string>;
  name: string;
  value: number;
  color: string;
  maxValue: number;
  minValue: number;
}

export const ColumnChart: FC<IColumnChartProps> = memo((props: IColumnChartProps) => {
  const { avatarUrl, name, value, color, maxValue, minValue } = props;

  const backgroundStyles = useMemo(() => {
    return {
      flex: getColumnChartFlex(value, maxValue, minValue),
      background: color,
    };
  }, [color, maxValue, minValue, value]);

  return (
    <div className={styles.ColumnChart}>
      <div className={styles.ColumnChart__Value}>{value}</div>
      <div className={styles.ColumnChart__Background} style={backgroundStyles} />
      <div className={styles.ColumnChart__Avatar}>
        <Avatar size={AvatarSize.XS} username={name} src={avatarUrl} />
      </div>
    </div>
  );
});
