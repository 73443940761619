import { memo, useCallback, useMemo } from 'react';

import { SettingsScreenType } from 'stores/settings/enums/settings-screen-type.enum';

import { NATIVE_APP_SETTINGS, WEB_APP_SETTINGS } from 'configs/item-line-list.config';
import { SOCIAL_LINKS } from 'configs/settings.config';

import { BarActionType } from 'components/bars/bar-action.type';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { ItemLineList } from 'components/ui/item-line-list/item-line-list.component';

import styles from './main-settings.module.less';

interface IMainSettingsProps {
  currentScreenType: SettingsScreenType;
  onItemClick: (barAction: BarActionType) => void;
  onSocialIconClick: (url: string) => void;
  isNativeApp: boolean;
  isAuthorisedAnonymously: boolean;
}

export const MainSettings = memo((props: IMainSettingsProps) => {
  const { currentScreenType, onSocialIconClick, isNativeApp, isAuthorisedAnonymously } = props;

  const activeItemPayload = useMemo<string>(() => {
    switch (currentScreenType) {
      case SettingsScreenType.PushNotifications:
        return 'notifications';
      case SettingsScreenType.Account:
        return 'account';
      case SettingsScreenType.Privacy:
        return 'privacy';
      default:
        return '';
    }
  }, [currentScreenType]);

  const itemsWithActiveItem = useMemo(() => {
    const settingsItems = (isNativeApp ? NATIVE_APP_SETTINGS : WEB_APP_SETTINGS).filter((item) => {
      return isAuthorisedAnonymously ? item.isVisibleAnonymously : true;
    });

    return settingsItems.map((item) => {
      const assignedItem = item;
      const isActiveItem = assignedItem.item.actionType.payload === activeItemPayload;

      assignedItem.item.active = isActiveItem;

      return assignedItem;
    });
  }, [activeItemPayload, isNativeApp, isAuthorisedAnonymously]);

  const handleSocialIconClick = useCallback(
    (url: string) => () => {
      onSocialIconClick(url);
    },
    [onSocialIconClick],
  );

  return (
    <div className={styles.MainSettings}>
      <ItemLineList items={itemsWithActiveItem} onItemClick={props.onItemClick} />
      <div className={styles.MainSettings__Social}>
        <h4>Follow us on Social</h4>
        <div className={styles.MainSettings__Buttons}>
          {SOCIAL_LINKS.map((link) => (
            <IconButton
              key={link.id}
              fluid
              iconName={link.icon}
              theme={IconButtonTheme.Tertiary}
              onClick={handleSocialIconClick(link.url)}
            />
          ))}
        </div>
      </div>
    </div>
  );
});
