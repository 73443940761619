import { v4 } from 'uuid';

export const SORT_BY_SECTION_CONFIG = [
  {
    label: 'Newest first',
    value: 'DESC',
    id: '0',
  },
  {
    label: 'Oldest first',
    value: 'ASC',
    id: '1',
  },
];

export const BOOKMARKS_FILTER_TYPES_CONFIG = [
  {
    id: v4(),
    label: 'Post',
    value: 'post',
    selected: false,
  },
  {
    id: v4(),
    label: 'Comment',
    value: 'post_comment',
    selected: false,
  },
  {
    id: v4(),
    label: 'Collaboration Item',
    value: 'collaboration_item',
    selected: false,
  },
];

export const NO_BOOKMARKS_TITLE = 'No Bookmarks Yet';
export const NO_BOOKMARKS_MESSAGE = 'Tap the bookmark icon in the toolbar to organise your list.';
export const NO_RESULTS_TITLE = 'No result found';
export const NO_RESULTS_MESSAGE = `It seems we can't find any results based on your search.`;
