// <editor-fold desc="Auth">

export const MAX_NAME_LENGTH = 70;

export const MIN_NAME_LENGTH = 2;

export const MAX_EMAIL_LENGTH = 70;

export const MIN_PASSWORD_LENGTH = 8;

export const MAX_PASSWORD_LENGTH = 32;

export const VERIFICATION_CODE_LENGTH = 4;

export const FILES_IMAGE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/webp',
  'image/heic',
  'image/heif',
  'image/gif',
];

export const FILES_VIDEO_TYPES = ['video/mp4', 'video/quicktime', 'video/webm'];

export const MIN_POST_TITLE_LENGTH = 3;

export const MAX_POST_TITLE_LENGTH = 300;

export const MIN_CONTENT_LENGTH = 2;

export const MIN_ATTACHMENTS_LENGTH = 1;

export const MIN_POLL_TITLE_LENGTH = 1;

export const MAX_POLL_TITLE_LENGTH = 100;

export const MIN_POLL_ANSWERS_LENGTH = 2;

export const MAX_POLL_ANSWERS_LENGTH = 4;

// </editor-fold>

export const MIN_SUBJECT_TITLE_LENGTH = 3;
export const MAX_SUBJECT_TITLE_LENGTH = 300;

export const MIN_DESCRIPTION_LENGTH = 3;
export const MAX_DESCRIPTION_LENGTH = 1000;

export const MIN_LINK_TITLE_LENGTH = 2;
export const MAX_LINK_TITLE_LENGTH = 32;

export const MAX_LINK_DESCRIPTION_LENGTH = 64;
export const MIN_LINK_URL_LENGTH = 2;
