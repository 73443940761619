import { memo, useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './board-item.module.less';

interface IBoardItemProps {
  teamId: string;
  imageUrl: Maybe<string>;
  name: string;
  isJoined: boolean;
  followers: string;
  onVisitClick: (teamId: string) => void;
}

export const BoardItem = memo((props: IBoardItemProps) => {
  const { imageUrl, followers, teamId, isJoined, onVisitClick, name } = props;

  const [isHovered, setIsHovered] = useState(false);

  const handleHideLabel = useCallback(() => {
    setIsHovered(false);
  }, [setIsHovered]);

  const handleShowLabel = useCallback(() => {
    setIsHovered(true);
  }, [setIsHovered]);

  const handleVisitClick = useCallback(() => {
    onVisitClick(teamId);
  }, [onVisitClick, teamId]);

  const boardItemClasses = useMemo(
    () =>
      cn(styles.BoardItem, {
        [styles['BoardItem--active']]: isHovered,
      }),
    [isHovered],
  );

  const detailsNameClasses = useMemo(
    () =>
      cn(styles.Details__Name, {
        [styles['Details__Name--active']]: isHovered,
      }),
    [isHovered],
  );

  return (
    <button
      className={boardItemClasses}
      onClick={handleVisitClick}
      onMouseEnter={handleShowLabel}
      onMouseLeave={handleHideLabel}
    >
      <div className={styles.BoardItem__Info}>
        <div className={styles.Avatar}>
          <Avatar src={imageUrl} size={AvatarSize.M} username={name} />
        </div>
        <div className={styles.Details}>
          <div className={detailsNameClasses}>{name}</div>
          <div className={styles.Details__Followers}>{`${followers} members`}</div>
        </div>
      </div>
      <div className={styles.BoardItem__Actions}>
        {!isJoined && isHovered && <div className={styles.Visited}>Visit</div>}
        {isJoined && !isHovered && (
          <div className={styles.Joined}>
            <div className={styles['Joined--icon']}>
              <IconFont name={IconFontName.Confirm} size={IconFontSize.Small} />
            </div>
          </div>
        )}
        {isJoined && isHovered && (
          <div className={styles.JoinedWithText}>
            <div className={styles['JoinedWithText--icon']}>
              <IconFont name={IconFontName.Confirm} size={IconFontSize.Small} />
            </div>
            <div className={styles['JoinedWithText--text']}>Followed</div>
          </div>
        )}
      </div>
    </button>
  );
});
