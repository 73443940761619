import { CellClassParams, ColDef } from 'ag-grid-community';

import { SortParams } from 'stores/leaderboard/leaderboard.store';

import { LeaderboardHeaderCell } from 'components/leaderboard/leaderboard-header-cell/leaderboard-header-cell';
import { LeaderboardUser } from 'components/leaderboard/leaderboard-user/leaderboard-user.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const THEME_CLASS_NAME = 'ag-theme-leaderboard';
export const HIGHLIGHTED_CELL_CLASS_NAME = 'ag-cell--highlighted';

export const USER_DESKTOP_COL_WIDTH = 300;
export const USER_MOBILE_COL_WIDTH = 178;
export const DEFAULT_COL_WIDTH = 52;

export enum LeaderboardColumns {
  User = 'user',
  Total = 'totalPoints',
  Player = 'playerPoints',
  Participation = 'participationPoints',
  Predictions = 'predictionPoints',
}

export const DEFAULT_USER_COL_DEF: ColDef = {
  field: LeaderboardColumns.User,
  headerName: 'User',
  pinned: 'left',
  cellRenderer: LeaderboardUser,
  headerClass: 'ag-leaderboard-user-header',
};

export const DEFAULT_TOTAL_COL_DEF: ColDef = {
  field: LeaderboardColumns.Total,
  headerComponent: LeaderboardHeaderCell,
  headerComponentParams: { iconName: IconFontName.Star, sortParam: SortParams.TOTAL_POINTS },
  sort: 'desc',
  cellClassRules: {
    [HIGHLIGHTED_CELL_CLASS_NAME]: (params: CellClassParams) => {
      const state = params.columnApi.getColumn(LeaderboardColumns.Total);
      return !!state?.getSort();
    },
  },
};

export const DEFAULT_PLAYER_COL_DEF: ColDef = {
  field: LeaderboardColumns.Player,
  headerComponent: LeaderboardHeaderCell,
  headerComponentParams: {
    iconName: IconFontName.SuperLikeFilled,
    sortParam: SortParams.PLAYER_POINTS,
  },
  cellClassRules: {
    [HIGHLIGHTED_CELL_CLASS_NAME]: (params: CellClassParams) => {
      const state = params.columnApi.getColumn(LeaderboardColumns.Player);
      return !!state?.getSort();
    },
  },
};

export const DEFAULT_PARTICIPATION_COL_DEF: ColDef = {
  field: LeaderboardColumns.Participation,
  headerComponent: LeaderboardHeaderCell,
  headerComponentParams: {
    iconName: IconFontName.Like,
    sortParam: SortParams.PARTICIPATION_POINTS,
  },
  cellClassRules: {
    [HIGHLIGHTED_CELL_CLASS_NAME]: (params: CellClassParams) => {
      const state = params.columnApi.getColumn(LeaderboardColumns.Participation);
      return !!state?.getSort();
    },
  },
};

export const DEFAULT_PREDICTIONS_COL_DEF: ColDef = {
  field: LeaderboardColumns.Predictions,
  headerComponent: LeaderboardHeaderCell,
  headerComponentParams: { iconName: IconFontName.Chip, sortParam: SortParams.PREDICTION_POINTS },
  cellClassRules: {
    [HIGHLIGHTED_CELL_CLASS_NAME]: (params: CellClassParams) => {
      const state = params.columnApi.getColumn(LeaderboardColumns.Predictions);
      return !!state?.getSort();
    },
  },
};

export const RANK_FIELD_MAPPER: Record<
  SortParams,
  'totalPointsRank' | 'playerPointsRank' | 'participationPointsRank' | 'predictionPointsRank'
> = {
  [SortParams.TOTAL_POINTS]: 'totalPointsRank',
  [SortParams.PLAYER_POINTS]: 'playerPointsRank',
  [SortParams.PARTICIPATION_POINTS]: 'participationPointsRank',
  [SortParams.PREDICTION_POINTS]: 'predictionPointsRank',
};
