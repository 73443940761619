import { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import styles from './switcher-slider-item.module.less';

interface ISwitcherSliderItemProps {
  element: ReactNode;
  active: boolean;
  disabled?: boolean;
  id: string;
  onClick: (id: string) => void;
}

export const SwitcherSliderItem: FC<ISwitcherSliderItemProps> = memo(
  (props: ISwitcherSliderItemProps) => {
    const { disabled, element, active, onClick, id } = props;

    const handleClick = useCallback(() => {
      onClick(id);
    }, [onClick, id]);

    const itemClassNames = useMemo(
      () =>
        classNames(styles.SwitcherSliderItem, {
          [styles['SwitcherSliderItem--active']]: active,
          [styles['SwitcherSliderItem--disabled']]: disabled,
        }),
      [active, disabled],
    );

    return (
      <li className={itemClassNames}>
        <button
          disabled={disabled}
          className={styles.SwitcherSliderItem__Button}
          onClick={handleClick}
          onKeyDown={handleClick}
        >
          {element}
        </button>
      </li>
    );
  },
);
