import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { ButtonTapsEnum } from 'services/google-analytic/enums/buttom-taps.enum';
import { ScreenNamesEnum } from 'services/google-analytic/enums/screen-names.enum';
import GoogleAnalyticService from 'services/google-analytic/google-analytic.service';
import { PlayerRankingsCategoryEnum } from 'services/player-rankings/interfaces/player-rankings-response.interface';

import { LayoutEntity } from 'stores/layout/enums/layout-entity.enum';
import { LayoutStore } from 'stores/layout/layout.store';
import { PlayerRankingsStore } from 'stores/player-rankings/player-rankings.store';
import { ShareStore } from 'stores/share/share.store';

import {
  NO_RANKINGS_MESSAGE,
  NO_RANKINGS_TITLE,
  RANKING_CATEGORIES,
} from 'containers/player-rankings/player-rankings.config';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useLayoutEntity } from 'hooks/use-layout-entity';
import { useMainProvider } from 'hooks/use-main-provider';
import { useNativeShare } from 'hooks/use-native-share';
import { useResponsive } from 'hooks/use-responsive';

import { PerformerCalcIonBottomSheet } from 'components/bottom-sheet/performer-calc-ion-bottom-sheet/performer-calc-ion-bottom-sheet.component';
import { EmptyState } from 'components/empty-state/empty-state.component';
import { PerformerCalcModal } from 'components/modals/performer-calc-modal/performer-calc-modal.component';
import { ShareRankingsModal } from 'components/modals/share-rankings-modal/share-rankings-modal.component';
import { PlayerRankingsCategory } from 'components/player-rankings/player-rankings-category/player-rankings-category.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';

import styles from './player-rankings.module.less';

export const PlayerRankingsContainer: FC = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);
  const playerRankingsStore = useInjection<PlayerRankingsStore>(TYPES.PlayerRankingsStore);
  const shareStore = useInjection<ShareStore>(TYPES.ShareStore);

  const navigate = useNavigate();
  const { isNativeApp } = useMainProvider();
  const { shareByNativeModal } = useNativeShare();
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const [isHintPopupOpen, setIsHintPopupOpen] = useState(false);

  useEffect(() => {
    GoogleAnalyticService.event({
      eventName: 'screen_custom_view',
      eventParams: {
        screen_type: ScreenNamesEnum.RankingsView,
      },
    });
  }, []);

  useEffect(() => {
    if (layoutStore.isPulledRefresher) {
      playerRankingsStore.fetchAllStatsByPullToRefresh();
    }
  }, [playerRankingsStore, layoutStore.isPulledRefresher]);

  const renderHeader = useCallback(() => {
    return (
      <div className={styles.CustomSection}>
        <div className={styles.CustomSection__Title}>Rankings</div>
        <div className={styles.CustomSection__Description}>
          {playerRankingsStore.playerRankings?.date || ''}
        </div>
      </div>
    );
  }, [playerRankingsStore.playerRankings]);

  useLayoutEntity({
    type: LayoutEntity.HeaderCenter,
    value: renderHeader,
  });

  useEffect(() => {
    playerRankingsStore.fetchPlayerRankings();
    playerRankingsStore.setFilter(null);

    return () => {
      playerRankingsStore.setPlayerRankings(null);
    };
  }, [playerRankingsStore]);

  const handleOpenTopPerformerHint = useCallback(() => {
    setIsHintPopupOpen(true);
  }, []);

  const handleCloseTopPerformerHint = useCallback(() => {
    setIsHintPopupOpen(false);
  }, []);

  const handleCopySharedLink = useCallback(() => {
    shareStore.copyLink();
  }, [shareStore]);

  const handleResetSharing = useCallback(() => {
    shareStore.reset();
  }, [shareStore]);

  const handlePlayerClick = useCallback(
    (slug: string, gameId: Maybe<string>) => {
      if (gameId) {
        navigate(
          getPath(paths.PLAYER_PERFORMANCE, {
            [paths.GAME_ID_PARAM]: gameId,
            [paths.PLAYER_SLUG_PARAM]: slug,
          }),
        );
      } else {
        navigate(
          getPath(paths.PLAYER_PROFILE, {
            [paths.PLAYER_SLUG_PARAM]: slug,
          }),
        );
      }
    },
    [navigate],
  );

  const handleSeeMoreClick = useCallback(
    (category: string) => {
      navigate(
        getPath(paths.PLAYERS_RANKINGS_DETAILS, {
          [paths.CATEGORY_PARAM]: category,
        }),
      );
    },
    [navigate],
  );

  const handleSharePlayersRankings = useCallback(async () => {
    await shareStore.fetchSharePlayersRankings();

    GoogleAnalyticService.event({
      eventName: 'button_custom_tap',
      eventParams: {
        button_tap_type: ButtonTapsEnum.RankingsShare,
      },
    });

    if (isNativeApp && shareStore.shareData) {
      shareByNativeModal(shareStore.shareData.link, 'Rankings');
    } else {
      shareStore.setIsShareModalVisible(true);
    }
  }, [isNativeApp, shareStore, shareByNativeModal]);

  return (
    <div className={styles.PlayerRankings}>
      {isDesktopPlus && (
        <div className={styles.PlayerRankings__Header}>
          <div className={styles.LeftSide}>
            <div className={styles.LeftSide__Title}>Rankings</div>
            <div className={styles.LeftSide__Date}>
              {playerRankingsStore.playerRankings?.date || ''}
            </div>
          </div>
          <IconButton
            iconName={IconFontName.Share}
            onClick={handleSharePlayersRankings}
            theme={IconButtonTheme.Transparent}
          />
        </div>
      )}
      {!playerRankingsStore.playerRankings && playerRankingsStore.isFetchingPlayerRankings && (
        <Loader isShow />
      )}
      {!playerRankingsStore.playerRankings && !playerRankingsStore.isFetchingPlayerRankings && (
        <EmptyState title={NO_RANKINGS_TITLE} isBlackBackground message={NO_RANKINGS_MESSAGE} />
      )}
      {playerRankingsStore.playerRankings &&
        RANKING_CATEGORIES.map((item) => {
          if (playerRankingsStore.playerRankings) {
            return (
              <PlayerRankingsCategory
                key={item.key}
                categorySlug={item.key}
                title={item.label}
                players={playerRankingsStore.playerRankings[item.key]}
                isHintEnable={item.key === PlayerRankingsCategoryEnum.performers}
                onHintClick={handleOpenTopPerformerHint}
                onPlayerClick={handlePlayerClick}
                onSeeMoreClick={handleSeeMoreClick}
              />
            );
          }

          return null;
        })}
      {isDesktopPlus ? (
        <PerformerCalcModal visible={isHintPopupOpen} onClose={handleCloseTopPerformerHint} />
      ) : (
        <PerformerCalcIonBottomSheet
          visible={isHintPopupOpen}
          onClose={handleCloseTopPerformerHint}
        />
      )}
      {!isNativeApp && shareStore.isShareModalVisible && (
        <ShareRankingsModal
          title="Rankings"
          visible={shareStore.isShareModalVisible}
          onCopyLink={handleCopySharedLink}
          onClose={handleResetSharing}
        />
      )}
    </div>
  );
});
