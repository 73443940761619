import { inject } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { BookmarksService } from 'services/bookmarks/bookmarks.service';
import { CommentsService } from 'services/comments/comments.service';
import {
  ITogglePostBookmarkPayload,
  ITogglePostCommentBookmarkPayload,
} from 'services/posts/interfaces/create-post-payload.interface';
import { PostsService } from 'services/posts/posts.service';

import { AuthStore } from 'stores/auth/auth.store';
import {
  bookmarksAdapter,
  bookmarksUsersAdapter,
} from 'stores/bookmarks/adapters/bookmarks-adapter.utils';
import { IBookmarksAdapter, IBookmarkUser } from 'stores/bookmarks/interfaces/bookmarks.interface';
import {
  BookmarksFilterType,
  BookmarksResponseType,
  BookmarksType,
} from 'stores/bookmarks/types/bookmarks.type';
import { EntriesStore } from 'stores/entries/entries.store';

import { TYPES } from 'configs/di-types.config';

import {
  BOOKMARKS_FILTER_TYPES_CONFIG,
  SORT_BY_SECTION_CONFIG,
} from 'components/bookmarks/bookmarks.config';
import { ISearchFormData } from 'components/forms/search-form/search-form.component';

import { BookmarkType } from './enums/bookmark-post-type.enum';

const ITEMS_LIMIT = 10;

export class BookmarksStore extends EntriesStore<BookmarksType, BookmarksResponseType> {
  private readonly postsService: PostsService;

  private readonly commentsService: CommentsService;

  private readonly authStore: AuthStore;

  private readonly bookmarksService: BookmarksService;

  public searchValue: ISearchFormData;

  public sortBy: string;

  public isFilterOpen: boolean;

  public types: BookmarksFilterType;

  public selectedBookmarksUsers: Array<IBookmarkUser>;

  public users: Array<IBookmarkUser>;

  constructor(
    @inject(TYPES.AuthStore) authStore: AuthStore,
    @inject<BookmarksService>(TYPES.BookmarksService) bookmarksService: BookmarksService,
    @inject<PostsService>(TYPES.PostsService) postsService: PostsService,
    @inject<CommentsService>(TYPES.CommentsService) commentsService: CommentsService,
  ) {
    super(ITEMS_LIMIT, false);

    this.authStore = authStore;

    this.commentsService = commentsService;

    this.bookmarksService = bookmarksService;

    this.postsService = postsService;

    this.isFilterOpen = false;

    this.searchValue = { searchPhrase: '' };

    this.sortBy = SORT_BY_SECTION_CONFIG[0].value;

    this.types = BOOKMARKS_FILTER_TYPES_CONFIG;

    this.users = [];

    this.selectedBookmarksUsers = [];

    makeObservable(this, {
      searchValue: observable,
      sortBy: observable,
      types: observable,
      users: observable,
      selectedBookmarksUsers: observable,
      isFilterOpen: observable,

      setSearchValue: action.bound,
      setSortBy: action.bound,
      setTypes: action.bound,
      setUsers: action.bound,
      setSelectedBookmarksUsers: action.bound,

      setIsFilterOpen: action.bound,
      resetBookmarks: action.bound,
      applyFilters: action.bound,
      getBookmarks: action.bound,
      getAllBookmarkedUsers: action.bound,
      togglePostBookmark: action.bound,
      togglePostCommentBookmark: action.bound,
    });

    reaction(() => this.authStore.isAuthorised, this.handleIsAuthorisedChange);
  }

  private handleIsAuthorisedChange = async () => {
    if (!this.authStore.isAuthorised) {
      await this.resetBookmarks();
    } else {
      await this.initialise();
    }
  };

  private static prepareTypes = (types: BookmarksFilterType) => {
    if (!types.length) {
      return [];
    }

    return types.filter((item) => item.selected).map((type) => type.value);
  };

  private static prepareOwners = (owners: Array<IBookmarkUser>) => {
    if (!owners.length) {
      return [];
    }

    return owners.map((owner) => owner.id);
  };

  public setSearchValue(value: ISearchFormData) {
    this.searchValue = value;
    this.refresh();
  }

  public resetSearchValue() {
    this.searchValue = { searchPhrase: '' };
  }

  public setSortBy(value: string) {
    this.sortBy = value;
  }

  public setIsFilterOpen(value: boolean) {
    this.isFilterOpen = value;
  }

  public setTypes(value: BookmarksFilterType) {
    this.types = value;
  }

  public setUsers(value: Array<IBookmarkUser>) {
    this.users = value;
  }

  public setSelectedBookmarksUsers(value: Array<IBookmarkUser>) {
    this.selectedBookmarksUsers = value;
  }

  public async applyFilters(
    sortOrder: string,
    bookmarksTypes: BookmarksFilterType,
    selectedUsers: Array<IBookmarkUser>,
  ) {
    this.setSortBy(sortOrder);
    this.setTypes(bookmarksTypes);
    this.setSelectedBookmarksUsers(selectedUsers);

    await this.refresh();
  }

  public async getAllBookmarkedUsers() {
    const usersResponse = await this.bookmarksService.getAllBookmarkedUsers();
    if (usersResponse.success) {
      this.setUsers(bookmarksUsersAdapter(usersResponse.data));
    }
  }

  public async getBookmarks() {
    const payload = {
      page: this.pagination.page,
      types: BookmarksStore.prepareTypes(this.types),
      order: this.sortBy,
      limit: this.pagination.limit,
      search: this.searchValue.searchPhrase,
      ownerUuids: BookmarksStore.prepareOwners(this.selectedBookmarksUsers),
    };

    const bookmarksResponse = this.bookmarksService.getAllBookmarks(payload);

    await this.retrieveNext(bookmarksResponse, <IBookmarksAdapter>bookmarksAdapter);
  }

  public async togglePostBookmark(payload: ITogglePostBookmarkPayload): Promise<void> {
    const postsResponse = await this.postsService.togglePostBookmark(payload);
    if (postsResponse.success) {
      const { id } = payload;

      const newEntries = this.entries.filter((item) => {
        if (item.type === 'post') {
          return item.post.uuid !== id;
        }

        return true;
      });

      this.setEntries(newEntries);
      this.setUsers([]);

      await this.getAllBookmarkedUsers();
    }
  }

  public async togglePostCommentBookmark(
    payload: ITogglePostCommentBookmarkPayload,
  ): Promise<void> {
    const response = await this.commentsService.togglePostCommentBookmark(payload);

    if (response.success) {
      const { commentId } = payload;

      const newEntries = this.entries.filter((item) => {
        if (item.type === BookmarkType.Comment) {
          return item.comment.uuid !== commentId;
        }

        return true;
      });

      this.setEntries(newEntries);
      this.setUsers([]);

      await this.getAllBookmarkedUsers();
    }
  }

  public async resetFilters() {
    this.setSortBy(SORT_BY_SECTION_CONFIG[0].value);
    this.setTypes(BOOKMARKS_FILTER_TYPES_CONFIG);
    this.setSelectedBookmarksUsers([]);
  }

  public async resetBookmarks() {
    this.setEntries([]);
    await this.resetFilters();
    this.resetSearchValue();
    this.setFetching(false);
  }

  public async initialise() {
    await this.getBookmarks();
    await this.getAllBookmarkedUsers();
  }

  public async fetchNext(): Promise<void> {
    await this.getBookmarks();
  }
}
