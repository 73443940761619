import { BiographyContainer } from 'containers/biography/biography.container';
import { CustomLinksContainer } from 'containers/custom-links/custom-links.container';
import { SocialLinksContainer } from 'containers/social-links/social-links.container';
import { UserPublicProfileTabsContainer } from 'containers/user-public-profile-tabs/user-public-profile-tabs.container';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

export const UserPublicProfileRoute = () => {
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  return (
    <>
      <UserPublicProfileTabsContainer />
      {!isDesktopPlus && <SocialLinksContainer />}
      <CustomLinksContainer />
      <BiographyContainer />
    </>
  );
};
