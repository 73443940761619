import { FC, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { ICountdownTime } from 'stores/auth/interfaces/countdown-time.interface';
import { ISignUpWithEmailPreserved } from 'stores/auth/interfaces/sign-up-preserved.interface';

import { CODE_EXPIRATION_INTERVAL_IN_MILLISECONDS } from 'configs/date.config';

import { emailSchema } from 'validation/schemas/email.schema';
import { termsSchema } from 'validation/schemas/terms.schema';
import { verificationCodeSchema } from 'validation/schemas/verification-code.schema';

import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { SubmitSection } from 'components/forms/submit-section/submit-section.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { CodeInput } from 'components/ui/form-fields/code-input/code-input.component';
import { TextInput } from 'components/ui/form-fields/text-input/text-input.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import { ConfirmEmailCheckbox } from './confirm-email-checkbox.component.tsx/confirm-email-checkbox.component';
import { ConfirmEmailCountdownTimer } from './confirm-email-countdown-timer/confirm-email-countdown-timer.component';

import formsStyles from '../forms.module.less';

const ConfirmEmailFormSchema = z
  .object({
    email: emailSchema,
    verificationCode: verificationCodeSchema,
    terms: termsSchema,
  })
  .strict();

export type ConfirmEmailFormDataType = z.infer<typeof ConfirmEmailFormSchema>;

interface ISignUpWithEmailFormProps extends IForm<ConfirmEmailFormDataType> {
  isSignUp?: boolean;
  preserved: Maybe<ISignUpWithEmailPreserved>;
  countdownTime: Maybe<ICountdownTime>;
  onSetCountdownTime: (time: Maybe<ICountdownTime>) => void;
  onGetCode: (email: string) => void;
}

export const ConfirmEmailForm: FC<ISignUpWithEmailFormProps> = (props) => {
  const {
    isSignUp = false,
    processing,
    countdownTime,
    submitText,
    initialValues,
    onGetCode,
    onSubmit,
    preserved,
    onSetCountdownTime,
  } = props;

  const { control, formState, getValues, handleSubmit, trigger, setValue } =
    useForm<ConfirmEmailFormDataType>({
      defaultValues: preserved ? { email: preserved.email } : initialValues,
      mode: 'onTouched',
      reValidateMode: DEFAULT_REVALIDATE_MODE,
      resolver: zodResolver(ConfirmEmailFormSchema),
    });

  const { isValid, errors } = formState;

  const handleGetCodeClick = useCallback(async () => {
    if (await trigger('email')) {
      onGetCode(getValues('email'));
      onSetCountdownTime({ minutes: 1, seconds: 0 });
    }
  }, [getValues, onGetCode, trigger, onSetCountdownTime]);

  const handleEmailBlur = useCallback(async () => {
    await trigger('email');
  }, [trigger]);

  const countdownEndTimestamp = useMemo<number>(() => {
    const { minutes, seconds } = countdownTime || {};

    if (typeof minutes === 'number' && typeof seconds === 'number') {
      const milliseconds = (minutes * 60 + seconds) * 1000;

      return milliseconds;
    }

    return CODE_EXPIRATION_INTERVAL_IN_MILLISECONDS;
  }, [countdownTime]);

  const handleCheckboxChange = useCallback(
    // Any type picked from Hook Form types
    (onChange: (...event: any[]) => void, currentValue: boolean) => () => {
      onChange(!currentValue);
    },
    [],
  );

  const isGetCodeDisabled = useCallback(
    (value: string) =>
      isSignUp ? !value || !!errors.email?.message : !value || !!errors.email?.message,
    [errors.email?.message, isSignUp],
  );

  useEffect(() => {
    if (!isSignUp) {
      setValue('terms', true);
    }
  }, [isSignUp, setValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={formsStyles.FormContent}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => {
            return (
              <TextInput
                {...field}
                error={errors.email?.message}
                onBlur={handleEmailBlur}
                id="email"
                label="Email"
                leftIconName={IconFontName.Mention}
                type="email"
                aside={
                  preserved?.email !== field.value || !preserved ? (
                    <Button
                      theme={ButtonTheme.Secondary}
                      size={ButtonSize.Big}
                      onClick={handleGetCodeClick}
                      disabled={isGetCodeDisabled(field.value)}
                    >
                      Get code
                    </Button>
                  ) : (
                    <ConfirmEmailCountdownTimer
                      processing={!!processing}
                      onClick={handleGetCodeClick}
                      onSetCountdownTime={props.onSetCountdownTime}
                      countdownEndTimestamp={countdownEndTimestamp}
                    />
                  )
                }
              />
            );
          }}
        />
        {!!preserved && (
          <>
            <Controller
              name="verificationCode"
              control={control}
              render={({ field }) => (
                <CodeInput
                  {...field}
                  error={errors.verificationCode?.message}
                  label="Enter verification code"
                  inputMode="numeric"
                  id="verificationCode"
                />
              )}
            />

            {isSignUp && (
              <Controller
                name="terms"
                control={control}
                render={({ field }) => (
                  <ConfirmEmailCheckbox
                    id="terms"
                    selected={!!field.value}
                    onChange={handleCheckboxChange(field.onChange, !!field.value)}
                  />
                )}
              />
            )}
          </>
        )}
      </div>
      <hr />
      <SubmitSection disabled={processing} isFormValid={isValid} buttonText={submitText} />
    </Form>
  );
};
