import { format, isAfter, isSameDay } from 'date-fns';
import { format as formatTz } from 'date-fns-tz';
import { inject, injectable } from 'inversify';
import { action, autorun, makeObservable, observable, reaction } from 'mobx';

import { GameService } from 'services/game/game.service';
import { IGameResponse } from 'services/game/interfaces/game-response.interface';
import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { IGame, IGameMiniResponse } from 'services/statistics/interface/game.interface';
import { StatisticsService } from 'services/statistics/statistics.service';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';
import { boxScoresAdapter, gameAdapter } from 'stores/game/adapters/game-adapter.util';
import { IBoxScores, IGameWithStats } from 'stores/game/interfaces/game.interface';
import { IoMessage } from 'stores/io/enums/io-message.enum';
import { IoStore } from 'stores/io/io.store';
import { gamesAdapter } from 'stores/statistics/adapters/games-adapter.util';
import { singleGameMiniAdapter } from 'stores/statistics/adapters/single-game-mini-adapter.util';

import { DATE_FORMAT, TIME_ZONE_FORMAT } from 'configs/date.config';
import { TYPES } from 'configs/di-types.config';

@injectable()
export class GameStore extends ApiConnectedStore {
  private readonly REACTION_DELAY_MS = 1000;

  private readonly ioStore: IoStore;

  private readonly gameService: GameService;

  private readonly statisticsService: StatisticsService;

  public id: Maybe<number>;

  public isLoadingMiniSchedule: boolean;

  public arenaFirstGameIds: number[];

  public gamesForArena: IGame[];

  public entry: Maybe<IGameWithStats>;

  public lastJoinedRoomId: Maybe<number>;

  public firstNextNotFinishedGameId: Maybe<number>;

  constructor(
    @inject(TYPES.IoStore) ioStore: IoStore,
    @inject(TYPES.GameService) gameService: GameService,
    @inject(TYPES.StatisticsService) statisticsService: StatisticsService,
  ) {
    super();

    this.statisticsService = statisticsService;

    this.isLoadingMiniSchedule = false;

    this.gameService = gameService;

    this.ioStore = ioStore;

    this.gamesForArena = [];

    this.arenaFirstGameIds = [];

    this.id = null;

    this.firstNextNotFinishedGameId = null;

    this.entry = null;

    this.lastJoinedRoomId = null;

    makeObservable(this, {
      id: observable,
      isLoadingMiniSchedule: observable,
      entry: observable,
      lastJoinedRoomId: observable,
      arenaFirstGameIds: observable,
      gamesForArena: observable,
      firstNextNotFinishedGameId: observable,

      setFirstNextNotFinishedGameId: action.bound,
      setArenaFirstGameIds: action.bound,
      setGamesForArena: action.bound,
      setEntry: action.bound,
      setId: action.bound,
      setLastJoinedRoomId: action.bound,
      leaveCurrentRoom: action.bound,
      updateSubscriptions: action.bound,
      handleGameStatisticsMiniUpdated: action.bound,
      setIsLoadingMiniSchedule: action.bound,
    });

    reaction(() => this.id, this.handleIdChange, { delay: this.REACTION_DELAY_MS });

    reaction(() => [this.ioStore.socket], this.handleIdChange);
    reaction(() => [this.ioStore.socket], this.updateSubscriptions);

    autorun(() => this.initializeArenaGames());
    autorun(() => this.fetchNextGameId());
  }

  public async fetchNextGameId(): Promise<void> {
    this.resetErrors();
    const timezone = formatTz(new Date(), TIME_ZONE_FORMAT);

    const response: IResponse<IGameMiniResponse[]> = await this.statisticsService.retrieve(
      timezone,
    );

    if (response.success) {
      const data = gamesAdapter(response.data);

      const newGames: IGame[] = data.filter((game) => {
        return (
          isSameDay(new Date(game.dateStart), new Date()) ||
          isAfter(new Date(game.dateStart), new Date()) ||
          game.status === GameStatus.Halftime ||
          game.status === GameStatus.Live
        );
      });

      const dates: string[] = [];

      newGames.forEach((game) => {
        const formattedDate = format(new Date(game.dateStart), DATE_FORMAT);

        if (game.status !== GameStatus.Finished && !this.firstNextNotFinishedGameId) {
          this.setFirstNextNotFinishedGameId(game.id);
        }

        if (dates.includes(formattedDate)) {
          return;
        }

        dates.push(formattedDate);
      });
    }
  }

  public async initializeArenaGames(): Promise<void> {
    this.resetErrors();
    this.updateSubscriptions();
  }

  private handleGameUpdated = (game: IGameResponse) => {
    this.setEntry(gameAdapter(game));
  };

  private handleIdChange = async () => {
    if (this.lastJoinedRoomId) {
      this.leaveCurrentRoom();
    }

    if (this.id) {
      this.setIsLoadingMiniSchedule(true);

      const statisticsResponse: IResponse<IGameMiniResponse[]> =
        await this.statisticsService.retrieveByGameId(this.id);

      if (statisticsResponse.success) {
        const newGamesForArena = gamesAdapter(statisticsResponse.data);
        const ids: number[] = [];
        const dates: string[] = [];

        newGamesForArena.forEach((game) => {
          const formattedDate = format(new Date(game.dateStart), DATE_FORMAT);
          if (dates.includes(formattedDate)) {
            return;
          }

          dates.push(formattedDate);
          ids.push(game.id);
        });

        this.setArenaFirstGameIds(ids);
        this.setGamesForArena(newGamesForArena);
      }

      await this.retrieveWithFetching(this.id);

      this.setLastJoinedRoomId(this.id);

      const roomName = `games:id:${this.id}:statistics`;

      this.ioStore.emit(IoMessage.Join, { room: roomName });

      this.ioStore.socket.on(roomName, this.handleGameUpdated);

      this.setIsLoadingMiniSchedule(false);
    } else {
      this.reset();
    }
  };

  public updateSubscriptions(): void {
    this.ioStore.socket.on(
      IoMessage.GameStatisticsMiniUpdated,
      this.handleGameStatisticsMiniUpdated,
    );
  }

  public handleGameStatisticsMiniUpdated = (rawGame: IGameMiniResponse) => {
    const newGame = singleGameMiniAdapter(rawGame);

    this.setGamesForArena(
      this.gamesForArena.map((existingGame) => {
        if (existingGame.id === newGame.id) {
          return newGame;
        }

        return existingGame;
      }),
    );
  };

  protected async getBoxScoresByTeamId(id: number): Promise<Maybe<IBoxScores>> {
    this.setErrors([]);
    const response = await this.gameService.fetchTeamBoxScores(id);

    if (response.success) {
      return boxScoresAdapter(response.data);
    }

    this.setErrors(response.errors);
    return null;
  }

  protected async retrieveWithFetching(id: number): Promise<void> {
    this.setFetching(true);

    await this.retrieve(id);

    this.setFetching(false);
    this.setFetched(true);
  }

  protected async retrieve(id: number): Promise<void> {
    this.setErrors([]);
    const response = await this.gameService.fetchGameDetails(id);

    if (response.success) {
      const game = gameAdapter(response.data);

      if (game.status === GameStatus.Scheduled) {
        const homeTeamStats = await this.getBoxScoresByTeamId(game.home.teamInfo.id);
        const visitorsTeamStats = await this.getBoxScoresByTeamId(game.visitors.teamInfo.id);

        if (homeTeamStats && visitorsTeamStats) {
          game.homeTeamStartersStats = homeTeamStats.startStats;
          game.homeTeamBenchStats = homeTeamStats.benchStats;
          game.homeTeamTotalStats = homeTeamStats.totalStats;
          game.visitorsTeamStartersStats = visitorsTeamStats.startStats;
          game.visitorsTeamBenchStats = visitorsTeamStats.benchStats;
          game.visitorsTeamTotalStats = visitorsTeamStats.totalStats;
        }
      }
      this.setEntry(game);
    } else {
      this.setErrors(response.errors);
    }
  }

  public get isFirstGameInSameDay() {
    if (this.entry && this.firstNextNotFinishedGameId === this.entry.id) {
      const dateInstanse = new Date(this.entry.gameDateStart);
      const currentDate = new Date();

      return isSameDay(dateInstanse, currentDate);
    }

    return false;
  }

  public async fetchByPullToRefresh() {
    if (this.id) {
      await this.retrieve(this.id);
    }

    await this.initializeArenaGames();
    await this.fetchNextGameId();
  }

  public setId(id: Maybe<number>) {
    this.setFetched(false);
    this.setEntry(null);
    this.id = id;
  }

  public setEntry(entry: Maybe<IGameWithStats>) {
    this.entry = entry;
  }

  public setIsLoadingMiniSchedule(value: boolean) {
    this.isLoadingMiniSchedule = value;
  }

  public setLastJoinedRoomId(id: Maybe<number>) {
    this.lastJoinedRoomId = id;
  }

  public leaveCurrentRoom() {
    const roomName = `games:id:${this.lastJoinedRoomId}:statistics`;
    this.ioStore.socket.emit('leave', { room: roomName });
  }

  public reset() {
    super.reset();

    this.setId(null);
    this.setEntry(null);
  }

  public setFirstNextNotFinishedGameId(gameId: Maybe<number>) {
    this.firstNextNotFinishedGameId = gameId;
  }

  public setArenaFirstGameIds(ids: number[]) {
    this.arenaFirstGameIds = ids;
  }

  public setGamesForArena(games: IGame[]) {
    this.gamesForArena = games;
  }
}
