import { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import {
  IGameContent,
  IPlayerContent,
} from 'stores/content-cards/interfaces/content-cards.interface';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';

import * as path from 'routes/paths.constants';

import { GameContent } from 'components/posts/game-content/game-content.component';
import { PlayerContent } from 'components/posts/player-content/player-content.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './content-cards-group.module.less';

interface IContentCardsGroupProps {
  activeFeed: Maybe<FeedTypes>;
  contentGames: IGameContent[];
  contentPlayers: IPlayerContent[];
  onGameContentClick: (gameId: string, teamsName: string, gameDate: string) => void;
  onPlayerContentClick: (
    slug: string,
    gameId: string,
    playerName: string,
    gameDate: string,
  ) => void;
}

export const ContentCardsGroup = memo((props: IContentCardsGroupProps) => {
  const { activeFeed, contentGames, contentPlayers } = props;

  const isActiveFeedValid = useMemo(() => {
    return (
      activeFeed === FeedTypes.YourFeed ||
      activeFeed === FeedTypes.Player ||
      activeFeed === FeedTypes.Team ||
      activeFeed === FeedTypes.GlobalFavorites
    );
  }, [activeFeed]);

  const navigate = useNavigate();

  const handleSeeAllGamesClick = useCallback(async () => {
    navigate(path.HOME_SCORES);
  }, [navigate]);

  const handleSeeAllPlayersClick = useCallback(async () => {
    navigate(path.PLAYERS_RANKINGS);
  }, [navigate]);

  const isShowPlayersCards = useMemo(() => {
    return activeFeed !== FeedTypes.Team;
  }, [activeFeed]);

  if (!isActiveFeedValid) {
    return null;
  }

  return (
    <>
      {isShowPlayersCards && !!contentPlayers.length && (
        <div className={styles.ContentCards}>
          <div className={styles.ContentCards__Header}>
            <span className={styles.ContentCards__Title}>
              {activeFeed === FeedTypes.Player ? 'Player performers' : 'Top performers'}
            </span>
            <Button
              size={ButtonSize.Big}
              theme={ButtonTheme.Text}
              onClick={handleSeeAllPlayersClick}
            >
              See All
            </Button>
          </div>
          {contentPlayers.map((content) => (
            <PlayerContent
              key={`${content.gameId}_${content.playerId}`}
              contentData={content}
              onClick={props.onPlayerContentClick}
            />
          ))}
        </div>
      )}
      {!!contentGames.length && (
        <div className={styles.ContentCards}>
          <div className={styles.ContentCards__Header}>
            <span className={styles.ContentCards__Title}>Your Recent Games</span>
            <Button size={ButtonSize.Big} theme={ButtonTheme.Text} onClick={handleSeeAllGamesClick}>
              See All
            </Button>
          </div>
          {contentGames.map((content) => (
            <GameContent
              isExpandByDefault={activeFeed === FeedTypes.Team}
              key={content.gameId}
              contentData={content}
              onClick={props.onGameContentClick}
            />
          ))}
        </div>
      )}
    </>
  );
});
