import { differenceInDays, differenceInHours } from 'date-fns';

interface ITimeLeft {
  timeLeft: number;
  displayText: string;
}

export const getTimeLeft = (expiresAt: number): ITimeLeft => {
  const currentDate = new Date().getTime();
  let diff = differenceInDays(expiresAt, currentDate);

  if (diff === 0) {
    diff = differenceInHours(expiresAt, currentDate);

    return { timeLeft: diff, displayText: 'hour(s)' };
  }

  return { timeLeft: diff, displayText: 'days' };
};
