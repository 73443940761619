import { useEffect } from 'react';

import { debounce } from 'helpers/debounce.util';

export const useViewportHeight = (debounceTime: number = 500): void => {
  useEffect(() => {
    const setViewportHeightVariable = () => {
      const vhValue = `${(window.innerHeight / 100).toFixed(2)}px`;
      document.documentElement.style.setProperty('--vh', vhValue);
    };

    const handleWindowResize = debounce(setViewportHeightVariable, debounceTime);

    setViewportHeightVariable();

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [debounceTime]);
};
