import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router';
import cn from 'classnames';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';

import { LayoutStore } from 'stores/layout/layout.store';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';

import { GLOBAL_FEED_SLIDER_ITEMS, ISliderItem } from 'containers/posts/config/feed.config';

import { SCROLL_TOP_ELEMENT } from 'configs/controls.config';
import { TYPES } from 'configs/di-types.config';
import { IS_ENABLE_ATHLETE_POST_CREATION } from 'configs/feature.config';
import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import {
  FanZoneFilterTabs,
  FeedFiltersTabs,
} from 'components/feed-filter-tabs/feed-filter-tabs.component';
import { FeedsSelectSliderWithToggle } from 'components/ui/feeds-select-slider-with-toggle/feeds-select-slider-with-toggle.component';

import styles from './header-feed.module.less';

export const HeaderFeedContainer = observer(() => {
  const layoutStore = useInjection<LayoutStore>(TYPES.LayoutStore);

  const navigate = useNavigate();
  const params = useParams<{
    [paths.PLAYER_SLUG_PARAM]: string;
    [paths.TEAM_ID_PARAM]: string;
  }>();

  const { playerSlug, teamId } = params;

  const isGlobalFanZoneActive = useMemo(() => {
    return Boolean(
      layoutStore.activeFeed === FeedTypes.GlobalFanZone ||
        layoutStore.activeFeed === FeedTypes.GlobalFanZoneFavorites,
    );
  }, [layoutStore.activeFeed]);

  const isPlayerFanZoneActive = useMemo(() => {
    return Boolean(
      layoutStore.activeFeed === FeedTypes.PlayerFanZone ||
        layoutStore.activeFeed === FeedTypes.PlayerFanZoneFavorites,
    );
  }, [layoutStore.activeFeed]);

  const isTeamFanZoneActive = useMemo(() => {
    return Boolean(layoutStore.activeFeed === FeedTypes.TeamFanZone);
  }, [layoutStore.activeFeed]);

  const headerFeedSliderClasses = useMemo(
    () =>
      cn(styles.HeaderFeedSlider, {
        [styles['HeaderFeedSlider--with-tabs']]: isGlobalFanZoneActive || isPlayerFanZoneActive,
      }),
    [isGlobalFanZoneActive, isPlayerFanZoneActive],
  );

  const handleSliderItemClick = useCallback(
    (action: BarActionType, value: string) => {
      if (action.type === BarAction.Link) {
        layoutStore.setActiveFeed(value as FeedTypes);

        document.getElementById(SCROLL_TOP_ELEMENT)?.scrollIntoView();

        navigate(action.path);
      }
    },
    [layoutStore, navigate],
  );

  const handleToggleGlobalFeed = useCallback(() => {
    if (layoutStore.activeFeed === FeedTypes.YourFeed) {
      layoutStore.setActiveFeed(FeedTypes.GlobalFavorites);
    } else {
      layoutStore.setActiveFeed(FeedTypes.YourFeed);
    }
  }, [layoutStore]);

  const handleToggleFavoritesFanZoneFeed = useCallback(() => {
    if (layoutStore.activeFeed === FeedTypes.GlobalFanZone) {
      layoutStore.setActiveFeed(FeedTypes.GlobalFanZoneFavorites);
    } else {
      layoutStore.setActiveFeed(FeedTypes.GlobalFanZone);
    }
  }, [layoutStore]);

  const handleTogglePlayerFavoritesFanZoneFeed = useCallback(() => {
    if (layoutStore.activeFeed === FeedTypes.PlayerFanZone) {
      layoutStore.setActiveFeed(FeedTypes.PlayerFanZoneFavorites);
    } else {
      layoutStore.setActiveFeed(FeedTypes.PlayerFanZone);
    }
  }, [layoutStore]);

  const handleChangeFanZoneFilter = useCallback(
    (value: FanZoneFilterTabs) => {
      layoutStore.setFanZoneFilter(value);
    },
    [layoutStore],
  );

  useEffect(() => {
    return () => {
      layoutStore.resetFanZoneFilter();
    };
  }, [layoutStore]);

  const playerFeedItems = useMemo<ISliderItem[]>(() => {
    let feedLink = '';
    let fanZoneLink = '';

    if (playerSlug) {
      feedLink = getPath(paths.PLAYER_PROFILE_FEED, {
        [paths.PLAYER_SLUG_PARAM]: playerSlug,
      });

      fanZoneLink = getPath(paths.PLAYER_PROFILE_FAN_ZONE, {
        [paths.PLAYER_SLUG_PARAM]: playerSlug,
      });
    }

    return [
      {
        label: 'Feed',
        action: {
          type: BarAction.Link,
          path: feedLink,
        },
        value: FeedTypes.Player,
        isEnableNestedToggle: false,
        isLeftSide: true,
      },
      {
        label: 'Fan Zone',
        action: {
          type: BarAction.Link,
          path: fanZoneLink,
        },
        value: FeedTypes.PlayerFanZone,
        isEnableNestedToggle: IS_ENABLE_ATHLETE_POST_CREATION,
        isLeftSide: false,
      },
    ];
  }, [playerSlug]);

  const teamFeedItems = useMemo<ISliderItem[]>(() => {
    let feedLink = '';
    let fanZoneLink = '';

    if (teamId) {
      feedLink = getPath(paths.TEAM_FEED, {
        [paths.TEAM_ID_PARAM]: teamId.toString(),
      });

      fanZoneLink = getPath(paths.TEAM_FEED_FAN_ZONE, {
        [paths.TEAM_ID_PARAM]: teamId.toString(),
      });
    }

    return [
      {
        label: 'Feed',
        action: {
          type: BarAction.Link,
          path: feedLink,
        },
        value: FeedTypes.Team,
        isEnableNestedToggle: false,
        isLeftSide: true,
      },
      {
        label: 'Fan Zone',
        action: {
          type: BarAction.Link,
          path: fanZoneLink,
        },
        value: FeedTypes.TeamFanZone,
        isEnableNestedToggle: false,
        isLeftSide: false,
      },
    ];
  }, [teamId]);

  return (
    <div className={styles.HeaderFeed}>
      <div className={headerFeedSliderClasses}>
        {teamId && (
          <FeedsSelectSliderWithToggle
            fullWidth
            isRightSideSelected={isTeamFanZoneActive}
            items={teamFeedItems}
            onItemClick={handleSliderItemClick}
            isLeftSideToggleOn={false}
            isRightSideToggleOn={false}
          />
        )}
        {playerSlug && (
          <FeedsSelectSliderWithToggle
            fullWidth
            isRightSideSelected={isPlayerFanZoneActive}
            items={playerFeedItems}
            onItemClick={handleSliderItemClick}
            isLeftSideToggleOn={false}
            isRightSideToggleOn={layoutStore.activeFeed === FeedTypes.PlayerFanZoneFavorites}
            onRightSideToggleClick={handleTogglePlayerFavoritesFanZoneFeed}
          />
        )}
        {!playerSlug && !teamId && (
          <FeedsSelectSliderWithToggle
            fullWidth
            isRightSideSelected={isGlobalFanZoneActive}
            items={GLOBAL_FEED_SLIDER_ITEMS}
            onItemClick={handleSliderItemClick}
            isLeftSideToggleOn={layoutStore.activeFeed === FeedTypes.GlobalFavorites}
            isRightSideToggleOn={layoutStore.activeFeed === FeedTypes.GlobalFanZoneFavorites}
            onLeftSideToggleClick={handleToggleGlobalFeed}
            onRightSideToggleClick={handleToggleFavoritesFanZoneFeed}
          />
        )}
        {(isGlobalFanZoneActive || isPlayerFanZoneActive) && (
          <FeedFiltersTabs
            activeFilter={layoutStore.fanZoneFilter}
            onTabChange={handleChangeFanZoneFilter}
          />
        )}
      </div>
    </div>
  );
});
