import * as paths from 'routes/paths.constants';

import { BarAction } from 'components/bars/bar-action.enum';
import { IContextMenuGroup } from 'components/ui/context-menu/interfaces/context-menu-group.interface';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

export const AUTHOR_CARD_GROUPS: IContextMenuGroup[] = [
  {
    id: 1,
    group: [
      {
        icon: IconFontName.Profile,
        text: 'View Profile',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
      {
        icon: IconFontName.AddUser,
        text: 'Add to Friends',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
      {
        icon: IconFontName.Comment,
        text: 'Message',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
    ],
  },
  {
    id: 2,
    group: [
      {
        icon: IconFontName.Bookmark,
        text: 'Bookmark',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
      {
        icon: IconFontName.Share,
        text: 'Share Contact',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
      {
        icon: IconFontName.Copy,
        text: 'Copy Username',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
    ],
  },
  {
    id: 3,
    group: [
      {
        icon: IconFontName.Report,
        text: 'Report',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
      {
        icon: IconFontName.Block,
        text: 'Block User',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
    ],
  },
];

export const POST_CARD_GROUPS: IContextMenuGroup[] = [
  {
    id: 1,
    group: [
      {
        icon: IconFontName.Thread,
        text: 'Reply in Thread',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
    ],
  },
  {
    id: 2,
    group: [
      {
        icon: IconFontName.Bookmark,
        text: 'Bookmark',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
      {
        icon: IconFontName.Share,
        text: 'Share',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
      {
        icon: IconFontName.Copy,
        text: 'Copy Text',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
      {
        icon: IconFontName.Search,
        text: 'Find Similar Posts',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
      {
        icon: IconFontName.Report,
        text: 'Report',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
      {
        icon: IconFontName.EyeOff,
        text: 'Hide',
        actionType: { type: BarAction.Click },
        isDisabled: false,
      },
    ],
  },
  {
    id: 3,
    group: [
      {
        icon: IconFontName.Remove,
        text: 'Delete',
        actionType: { type: BarAction.Click },
        isDelete: true,
        isDisabled: false,
      },
    ],
  },
];

export const BOOKMARK_POST_CARD_GROUPS: IContextMenuGroup[] = [
  {
    id: 1,
    group: [
      {
        icon: IconFontName.Bookmark,
        iconActive: IconFontName.BookmarkFilled,
        text: 'Bookmark',
        actionType: { type: BarAction.Click, payload: 'bookmark' },
        isDisabled: false,
        isActive: true,
      },
      {
        icon: IconFontName.Share,
        text: 'Share',
        actionType: { type: BarAction.Click, payload: 'share' },
        isDisabled: false,
      },
    ],
  },
];

export const BOOKMARK_COLLABORATION_ITEM_GROUPS: IContextMenuGroup[] = [
  {
    id: 1,
    group: [
      {
        icon: IconFontName.Bookmark,
        iconActive: IconFontName.BookmarkFilled,
        text: 'Bookmark',
        actionType: { type: BarAction.Click, payload: 'bookmark' },
        isDisabled: false,
        isActive: true,
      },
    ],
  },
];

export const POST_REPORT_MENU_GROUPS: IContextMenuGroup[] = [
  {
    id: 1,
    group: [
      {
        icon: IconFontName.Report,
        text: 'Report Post',
        actionType: { type: BarAction.Click, payload: 'report' },
        isDisabled: false,
        isActive: false,
      },
    ],
  },
  {
    id: 2,
    group: [
      {
        icon: IconFontName.ReportUser,
        text: 'Report User',
        actionType: { type: BarAction.Click, payload: 'user-report' },
        isDisabled: false,
        isActive: false,
      },
    ],
  },
];

export const MY_POST_MENU_GROUPS: IContextMenuGroup[] = [
  {
    id: 1,
    group: [
      {
        icon: IconFontName.Remove,
        text: 'Delete',
        actionType: { type: BarAction.Click, payload: 'delete' },
        isDisabled: false,
        isActive: false,
        isDelete: true,
      },
    ],
  },
];

export const MY_MESSAGES_MENU_GROUPS: IContextMenuGroup[] = [
  {
    id: 1,
    group: [
      {
        icon: IconFontName.Remove,
        text: 'Delete',
        actionType: { type: BarAction.Click, payload: 'delete' },
        isDelete: true,
        isDisabled: false,
      },
    ],
  },
];

export const OTHER_MESSAGES_MENU_GROUPS: IContextMenuGroup[] = [
  {
    id: 1,
    group: [
      {
        icon: IconFontName.Report,
        text: 'Report Message',
        actionType: { type: BarAction.Click, payload: 'report' },
        isDisabled: false,
        isActive: false,
      },
    ],
  },
  {
    id: 2,
    group: [
      {
        icon: IconFontName.ProfileBlocked,
        text: 'Report User',
        actionType: { type: BarAction.Click, payload: 'user-report' },
        isDisabled: false,
        isActive: false,
      },
    ],
  },
];

export const HEADER_AVATAR_GROUPS: IContextMenuGroup[] = [
  {
    id: 2,
    group: [
      {
        icon: IconFontName.Document,
        text: 'Privacy policy',
        actionType: {
          type: BarAction.Link,
          path: paths.PRIVACY_POLICY,
          target: '_blank',
        },
        isDisabled: false,
      },
      {
        icon: IconFontName.Post,
        text: 'Terms of use',
        actionType: {
          type: BarAction.Link,
          path: paths.TERMS_OF_USE,
          target: '_blank',
        },
        isDisabled: false,
      },
      {
        icon: IconFontName.Document,
        text: 'User Agreement',
        actionType: {
          type: BarAction.Link,
          path: paths.USER_AGREEMENT,
          target: '_blank',
        },
        isDisabled: false,
      },
      {
        icon: IconFontName.Feedback,
        text: 'Contact us',
        actionType: { type: BarAction.Link, path: paths.CONTACT_US },
        isDisabled: false,
      },
      {
        icon: IconFontName.Feedback3,
        text: 'Send Feedback',
        actionType: { type: BarAction.Click, payload: 'feedback' },
        isDisabled: false,
      },
    ],
  },
  {
    id: 3,
    group: [
      {
        icon: IconFontName.Logout,
        text: 'Logout',
        actionType: { type: BarAction.Click, payload: 'logout' },
        isDisabled: false,
      },
    ],
  },
];

export const LISTS_GROUPS: IContextMenuGroup[] = [
  {
    id: 1,
    group: [
      {
        icon: IconFontName.ListNumbered,
        text: 'Ordered List',
        actionType: { type: BarAction.Click, payload: 'number' },
        isDisabled: false,
      },
      {
        icon: IconFontName.List,
        text: 'Bulleted List',
        actionType: { type: BarAction.Click, payload: 'bullet' },
        isDisabled: false,
      },
    ],
  },
];

export const TEAM_HEADER_INFO_GROUPS: IContextMenuGroup[] = [
  {
    id: 1,
    group: [
      {
        icon: IconFontName.Logout,
        text: 'Unfollow Team',
        actionType: { type: BarAction.Click, payload: 'leave' },
        isDisabled: false,
      },
    ],
  },
];

export const PLAYER_PROFILE_HEADER_INFO_GROUPS: IContextMenuGroup[] = [
  {
    id: 1,
    group: [
      {
        icon: IconFontName.Logout,
        text: 'Unfollow player',
        actionType: { type: BarAction.Click, payload: 'leave' },
        isDisabled: false,
      },
    ],
  },
];

export const TEAM_PROFILE_HEADER_INFO_GROUPS: IContextMenuGroup[] = [
  {
    id: 1,
    group: [
      {
        icon: IconFontName.Logout,
        text: 'Unfollow team',
        actionType: { type: BarAction.Click, payload: 'leave' },
        isDisabled: false,
      },
    ],
  },
];
