import { FC } from 'react';

import { GameContainer } from 'containers/game/game.container';
import { GameHeaderContainer } from 'containers/game-header/game-header.container';
import { PageWrapperContainer } from 'containers/page-wrapper/page-wrapper.container';
import { WelcomePopupArenaContainer } from 'containers/welcome-popup/welcome-popup-arena.container';

import { useEnableRefresher } from 'hooks/use-enable-refresher';
import { useMobileLayoutStructure } from 'hooks/use-mobile-layout-structure';

import { Column } from 'components/ui/grid/column/column.component';
import { Row, RowLayout } from 'components/ui/grid/row/row.component';

export const ArenaRoute: FC = () => {
  useEnableRefresher();

  useMobileLayoutStructure({
    isHiddenHeader: true,
  });

  return (
    <PageWrapperContainer>
      <WelcomePopupArenaContainer />
      <GameHeaderContainer />
      <Row layout={RowLayout.MainContentExtended} withSpacing={false}>
        <Column mobile={1} desktop={{ start: 1, end: 3 }}>
          <GameContainer />
        </Column>
      </Row>
    </PageWrapperContainer>
  );
};
