import { FC, SyntheticEvent, useCallback } from 'react';

import {
  IPreparedSearchItem,
  ISearchPlayerItem,
  ISearchTeamItem,
  SearchResultType,
} from 'stores/search/interfaces/search.interface';

import { ENCODED_BULLET } from 'configs/encoded-symbols.config';

import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';

import styles from './global-search-item.module.less';

interface IGlobalSearchItemProps {
  item: IPreparedSearchItem;
  isShowFollowButton: boolean;
  onFollowClick?: (resultId: number, type: SearchResultType, entityId: number) => Promise<void>;
  onItemClick: (options: ISearchTeamItem | ISearchPlayerItem) => void;
}

export const GlobalSearchItem: FC<IGlobalSearchItemProps> = (props) => {
  const { item, isShowFollowButton, onFollowClick, onItemClick } = props;

  const handleItemClick = useCallback(() => {
    onItemClick(item.options);
  }, [item, onItemClick]);

  const handleFollowClick = useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation();
      if (item.options.type === SearchResultType.PLAYER) {
        onFollowClick?.(item.id, SearchResultType.PLAYER, item.options.playerId);
      }

      if (item.options.type === SearchResultType.TEAM) {
        onFollowClick?.(item.id, SearchResultType.TEAM, item.options.teamId);
      }
    },
    [item, onFollowClick],
  );

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={handleItemClick}
      onClick={handleItemClick}
      className={styles.GlobalSearchItem}
    >
      <div className={styles.GlobalSearchItem__MainSection}>
        {item.options.type === SearchResultType.TEAM ? (
          <>
            <Avatar src={item.imageSrc} size={AvatarSize.L} username={item.name} />
            <div className={styles.Information}>
              <div className={styles.Information__Name}>{item.name}</div>
              <div className={styles.Information__Members}>{item.options.members} Members</div>
              <div className={styles.Information__Stats}>
                <span className={styles['Information__Stats--position']}>#{item.options.rank}</span>
                <span className={styles['Information__Stats--conference']}>
                  {item.options.conference}
                </span>
                <span className={styles['Information__Stats--dot']}>{ENCODED_BULLET}</span>
                <span className={styles['Information__Stats--bracket']}>(</span>
                <span className={styles['Information__Stats--points']}>
                  {item.options.win} - {item.options.loss}
                </span>
                <span className={styles['Information__Stats--bracket']}>)</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <Avatar src={item.imageSrc} size={AvatarSize.L} username={item.name} />
            <div className={styles.Information}>
              <div className={styles.Information__Name}>{item.name}</div>
              <div className={styles.Information__Members}>{item.options.teamName}</div>
              <div className={styles.Information__Stats}>
                {!!item.options.jersey && (
                  <span className={styles['Information__Stats--position']}>
                    #{item.options.jersey}
                  </span>
                )}
                {!!item.options.jersey && !!item.options.position && (
                  <span className={styles['Information__Stats--dot']}>{ENCODED_BULLET}</span>
                )}
                {!!item.options.position && (
                  <span className={styles['Information__Stats--scores']}>
                    {item.options.position}
                  </span>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {isShowFollowButton && (
        <Button
          fluid
          disabled={item.options.isFollowed}
          size={ButtonSize.Small}
          theme={ButtonTheme.Primary}
          onClick={handleFollowClick}
        >
          {item.options.isFollowed ? 'Following' : 'Follow'}
        </Button>
      )}
    </div>
  );
};
