import { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { checkPermissions } from 'helpers/check-permissions.util';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import {
  BaseModalComponent,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import { Loader } from 'components/ui/loader/loader.component';

import recordStartImage from 'assets/images/record-start-button.svg';
import recordStopImage from 'assets/images/record-stop-button.svg';

import styles from './record-video-post-modal.module.less';

interface ICreateGroupPostModalProps {
  isVisible: boolean;
  isLoading: boolean;
  onClose: () => void;
  onUploadVideo: (video: File) => void;
}

enum CameraDirection {
  USER = 'user',
  ENVIRONMENT = 'environment',
}

const getSupportedMimeType = () => {
  const types = [
    'video/webm;codecs=vp9',
    'video/webm;codecs=vp8',
    'video/webm;codecs=H264',
    'video/mp4',
  ];

  // eslint-disable-next-line no-restricted-syntax
  for (const i in types) {
    if (MediaRecorder.isTypeSupported(types[i])) {
      return types[i];
    }
  }

  throw new Error('No supported video type was found');
};

export const RecordVideoModal = (props: ICreateGroupPostModalProps) => {
  const { isVisible, isLoading, onUploadVideo } = props;
  const { isNativeApp, isNativeAndroidApp } = useMainProvider();
  const [isVideoPermissionsRequested, setIsVideoPermissionsRequested] = useState(false);
  const [recording, setRecording] = useState<boolean>(false);
  const [recordedChunks, setRecordedChunks] = useState<BlobPart[]>([]);
  const [cameraDirection, setCameraDirection] = useState<CameraDirection>(
    CameraDirection.ENVIRONMENT,
  );
  const isCameraOn = useRef<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const streamRef = useRef<MediaStream | null>(null);

  const toggleCamera = useCallback(() => {
    isCameraOn.current = false;
    setCameraDirection((prevDirection) =>
      prevDirection === CameraDirection.ENVIRONMENT
        ? CameraDirection.USER
        : CameraDirection.ENVIRONMENT,
    );
  }, []);

  const startCamera = useCallback(async () => {
    if (isCameraOn.current) {
      return;
    }

    isCameraOn.current = true;
    if (!isVideoPermissionsRequested) {
      setIsVideoPermissionsRequested(true);

      if (isNativeApp) {
        if (!(await checkPermissions(isNativeApp, isNativeAndroidApp))) {
          return;
        }
      }
    }

    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }

    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: cameraDirection },
      audio: true,
    });

    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }

    streamRef.current = stream;
  }, [cameraDirection, isNativeAndroidApp, isNativeApp, isVideoPermissionsRequested]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      startCamera();
    }, 500);

    return () => {
      clearTimeout(timeout);
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(async (track) => {
          await track.stop();
        });
      }
    };
  }, [startCamera]);

  const handleStartRecording = useCallback(async () => {
    if (!videoRef.current || !videoRef.current.srcObject) return;

    const options = { mimeType: getSupportedMimeType() };
    const stream = streamRef.current as MediaStream;
    const mediaRecorder = new MediaRecorder(stream, options);

    mediaRecorder.ondataavailable = (event) => {
      setRecordedChunks((prev) => prev.concat(event.data));
    };

    mediaRecorder.start(100);
    setRecording(true);
    mediaRecorderRef.current = mediaRecorder;
  }, []);

  const handleStopRecording = useCallback(async () => {
    if (mediaRecorderRef.current) mediaRecorderRef.current.stop();
    setRecording(false);

    const blob = new Blob(recordedChunks, {
      type: 'video/mp4',
    });

    const videoFile = new File([blob], 'video.mp4', { type: 'video/mp4' });
    onUploadVideo(videoFile);
  }, [onUploadVideo, recordedChunks]);

  const handleVideoRecording = useCallback(async () => {
    if (recording) {
      await handleStopRecording();
      return;
    }

    await handleStartRecording();
  }, [recording, handleStopRecording, handleStartRecording]);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  return (
    <BaseModalComponent
      size={ModalWindowSize.M}
      onClose={props.onClose}
      title={null}
      visible={isVisible}
      isFullScreen={!isDesktopPlus}
      disableModalHeader
    >
      <div className={styles.VideoPostModal}>
        <Loader isShow={isLoading} />
        <video
          playsInline
          className={cn(styles.Video, {
            [styles['Video--flip']]: cameraDirection === CameraDirection.USER,
          })}
          ref={videoRef}
          muted
          autoPlay
          controlsList="nofullscreen"
        />

        <div className={styles.Actions}>
          <IconButton
            iconSize={IconFontSize.ExtraBig}
            iconName={IconFontName.Close}
            onClick={props.onClose}
            theme={IconButtonTheme.Transparent}
          />
          <div
            tabIndex={0}
            role="button"
            className={styles.RecordingWrapper}
            onKeyDown={handleVideoRecording}
            onTouchStart={handleVideoRecording}
          >
            {recording ? (
              <img className={styles.Recording} src={recordStopImage} alt="Stop recording" />
            ) : (
              <img className={styles.Recording} src={recordStartImage} alt="Start recording" />
            )}
          </div>
          <IconButton
            theme={IconButtonTheme.Transparent}
            disabled={recording || isLoading}
            iconSize={IconFontSize.ExtraBig}
            iconName={IconFontName.Repeat}
            onClick={toggleCamera}
          />
        </div>
      </div>
    </BaseModalComponent>
  );
};
