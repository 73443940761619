import { memo, useCallback, useState } from 'react';

import { DEFAULT_GLOBAL_STATS_PLAYER_FILTERS } from 'stores/global-stats/global-stats.config';
import { IGlobalStatsPlayersFilters } from 'stores/global-stats/interfaces/global-stats-players-filters.interface';
import { isPositionsTypeValue } from 'stores/player/utils/is-positions-type-value';

import { ITeamOption } from 'containers/profile/profile-info/interfaces/team-option.interface';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import { BaseSidePanel } from 'components/side-panels/base-side-panel/base-side-panel.component';
import { PLAYER_POSITION_FILTER_OPTIONS } from 'components/team-stats/team-stats.config';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { Select } from 'components/ui/form-fields/select/select.component';
import { SelectTeam } from 'components/ui/form-fields/select-team/select-team.component';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import styles from './stats-players-filters.module.less';

interface IStatsPlayersFiltersProps {
  playersFilters: IGlobalStatsPlayersFilters;
  teamsOptions: ITeamOption[];
  onPlayersFiltersChange: (filters: IGlobalStatsPlayersFilters) => void;
  setDisabledScroll: (isDisabledScroll: boolean) => void;
  onResetClick: () => void;
}

export const StatsPlayersFilters = memo((props: IStatsPlayersFiltersProps) => {
  const { playersFilters, teamsOptions, onPlayersFiltersChange, setDisabledScroll, onResetClick } =
    props;

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const [notAppliedFilters, setNotAppliedFilters] = useState(playersFilters);

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const handleFilterButtonClick = useCallback(() => {
    setMobileFiltersOpen(true);
  }, []);

  const handleCloseFilters = useCallback(() => {
    setMobileFiltersOpen(false);
    setNotAppliedFilters(playersFilters);
  }, [playersFilters]);

  const handleResetButtonClick = useCallback(() => {
    onResetClick();
    setMobileFiltersOpen(false);
    setNotAppliedFilters(DEFAULT_GLOBAL_STATS_PLAYER_FILTERS);
  }, [onResetClick]);

  const handleApplyClick = useCallback(() => {
    onPlayersFiltersChange(notAppliedFilters);
    setMobileFiltersOpen(false);
  }, [notAppliedFilters, onPlayersFiltersChange]);

  const handlePositionChange = useCallback(
    (value: string) => {
      if (isPositionsTypeValue(value)) {
        if (isDesktopPlus) {
          onPlayersFiltersChange({ ...playersFilters, position: value });
        } else {
          setNotAppliedFilters((prev) => ({ ...prev, position: value }));
        }
      }
    },
    [isDesktopPlus, onPlayersFiltersChange, playersFilters],
  );

  const handleTeamChange = useCallback(
    (value: Maybe<number>) => {
      if (isDesktopPlus) {
        onPlayersFiltersChange({ ...playersFilters, teamId: value });
      } else {
        setNotAppliedFilters((prev) => ({ ...prev, teamId: value }));
      }
    },
    [isDesktopPlus, onPlayersFiltersChange, playersFilters],
  );

  return (
    <div className={styles.Filters}>
      {isDesktopPlus ? (
        <>
          <Select
            id="player-position"
            name="PlayerPosition"
            options={PLAYER_POSITION_FILTER_OPTIONS}
            value={playersFilters.position}
            onChange={handlePositionChange}
          />
          <div className={styles.Filters__TeamSelect}>
            <SelectTeam
              id="Teams"
              name="Teams"
              placeholder="All Teams"
              options={teamsOptions}
              value={playersFilters.teamId}
              onChange={handleTeamChange}
              setDisabledScroll={setDisabledScroll}
            />
          </div>
        </>
      ) : (
        <IconButton
          iconName={IconFontName.Filter}
          theme={IconButtonTheme.Secondary}
          onClick={handleFilterButtonClick}
        />
      )}

      <BaseSidePanel isOpen={mobileFiltersOpen} isFullScreen>
        <div className={styles.FiltersMobile}>
          <div className={styles.FiltersMobile__Header}>
            <h4 className={styles.FiltersMobileHeader__Title}>Filter</h4>
            <IconButton
              iconName={IconFontName.Close}
              theme={IconButtonTheme.Secondary}
              onClick={handleCloseFilters}
            />
          </div>
          <div className={styles.FiltersMobile__Content}>
            <div className={styles.FiltersMobileContent__Type}>Position</div>
            <Select
              id="player_position"
              name="PlayerPosition"
              options={PLAYER_POSITION_FILTER_OPTIONS}
              value={notAppliedFilters.position}
              isWebSelectOnMobile
              onChange={handlePositionChange}
            />
            <div className={styles.FiltersMobileContent__Type}>Team</div>
            <SelectTeam
              id="Teams"
              name="Teams"
              placeholder="All Teams"
              options={teamsOptions}
              value={notAppliedFilters.teamId}
              onChange={handleTeamChange}
              setDisabledScroll={setDisabledScroll}
            />
          </div>
        </div>
        <div className={styles.FiltersMobile__Buttons}>
          <Button
            fluid
            theme={ButtonTheme.Secondary}
            size={ButtonSize.SmallSecondary}
            onClick={handleResetButtonClick}
          >
            Reset
          </Button>
          <Button
            fluid
            theme={ButtonTheme.Primary}
            size={ButtonSize.SmallSecondary}
            onClick={handleApplyClick}
          >
            Apply
          </Button>
        </div>
      </BaseSidePanel>
    </div>
  );
});
