import {
  ISingleGameSplitResponse,
  ISplitPlayerStatsResponse,
  ISplitsMonthsResponse,
} from 'services/player/interfaces/player-splits.interface';

import { singleGameMiniAdapter } from 'stores/statistics/adapters/single-game-mini-adapter.util';

import { roundToSingleDecimal } from 'helpers/round-to-single-decimal.util';

import {
  ISingleSplitItem,
  ISplitForOneMonth,
  ISplitStats,
} from 'components/player-profile/player-profile-games/player-profile-games.interface';

export function splitsSingleMonthsAdapter(
  splitsInGameResponse: ISplitsMonthsResponse,
  index: number,
): ISplitForOneMonth {
  const {
    month,
    games_total: gamesTotal,
    home_total: homeTotal,
    visitors_total: visitorsTotal,
    win_total: winTotal,
    loss_total: lossTotal,
    average,
    total,
    games,
  } = splitsInGameResponse;

  return {
    id: `split${index}`,
    average: baseStatsAdapter(average),
    away: visitorsTotal,
    games: games.map(baseGameAdapter),
    home: homeTotal,
    loss: lossTotal,
    total: baseStatsAdapter(total),
    totalGames: gamesTotal,
    win: winTotal,
    month,
  };
}

function baseStatsAdapter(response: ISplitPlayerStatsResponse): ISplitStats {
  const {
    fgm,
    ftp,
    fga,
    fgp,
    tpm,
    tpp,
    tpa,
    ftm,
    fta,
    tsp,
    fantasy_points: fantasyPoints,
    minutes,
    assists,
    blocks,
    steals,
    turnovers,
    points,
    personal_fouls: personalFouls,
    off_reb: offensiveReb,
    def_reb: defensiveReb,
    tot_reb: totalRebounds,
    plus_minus: plusMinus,
  } = response;

  return {
    minutes: Math.round(minutes),
    fantasyPoints: roundToSingleDecimal(fantasyPoints),
    points: roundToSingleDecimal(points),
    rebounds: roundToSingleDecimal(totalRebounds),
    assists: roundToSingleDecimal(assists),
    steals: roundToSingleDecimal(steals),
    blocks: roundToSingleDecimal(blocks),
    plusMinus: roundToSingleDecimal(plusMinus),
    tsp: roundToSingleDecimal(tsp),
    fgm: roundToSingleDecimal(fgm),
    fga: roundToSingleDecimal(fga),
    fgp: roundToSingleDecimal(fgp),
    tpa: roundToSingleDecimal(tpa),
    tpm: roundToSingleDecimal(tpm),
    tpp: roundToSingleDecimal(tpp),
    ftm: roundToSingleDecimal(ftm),
    fta: roundToSingleDecimal(fta),
    ftp: roundToSingleDecimal(ftp),
    offReb: roundToSingleDecimal(offensiveReb),
    defReb: roundToSingleDecimal(defensiveReb),
    turnovers: roundToSingleDecimal(turnovers),
    personalFouls: roundToSingleDecimal(personalFouls),
  };
}

function baseGameAdapter(response: ISingleGameSplitResponse): ISingleSplitItem {
  const {
    fgm,
    ftp,
    fga,
    fgp,
    tpm,
    tpp,
    tpa,
    ftm,
    fta,
    tsp,
    fantasy_points: fantasyPoints,
    minutes,
    assists,
    blocks,
    steals,
    turnovers,
    points,
    personal_fouls: personalFouls,
    off_reb: offensiveReb,
    def_reb: defensiveReb,
    tot_reb: totalRebounds,
    plus_minus: plusMinus,
    is_home_player: isHomePlayer,
    is_winning_team: isWinningTeam,
    game,
  } = response;

  return {
    isHomePlayer,
    isWinner: isWinningTeam,
    game: singleGameMiniAdapter(game),
    minutes: Math.round(minutes),
    fantasyPoints: roundToSingleDecimal(fantasyPoints),
    points: roundToSingleDecimal(points),
    rebounds: roundToSingleDecimal(totalRebounds),
    assists: roundToSingleDecimal(assists),
    steals: roundToSingleDecimal(steals),
    blocks: roundToSingleDecimal(blocks),
    plusMinus: roundToSingleDecimal(plusMinus),
    tsp: roundToSingleDecimal(tsp),
    fgm: roundToSingleDecimal(fgm),
    fga: roundToSingleDecimal(fga),
    fgp: roundToSingleDecimal(fgp),
    tpa: roundToSingleDecimal(tpa),
    tpm: roundToSingleDecimal(tpm),
    tpp: roundToSingleDecimal(tpp),
    ftm: roundToSingleDecimal(ftm),
    fta: roundToSingleDecimal(fta),
    ftp: roundToSingleDecimal(ftp),
    offReb: roundToSingleDecimal(offensiveReb),
    defReb: roundToSingleDecimal(defensiveReb),
    turnovers: roundToSingleDecimal(turnovers),
    personalFouls: roundToSingleDecimal(personalFouls),
  };
}
