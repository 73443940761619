import { IPagination } from 'interfaces/pagination.interface';
import { injectable } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';

import { ApiConnectedStore } from 'stores/api-connected/api-connected.store';

import { filterShown } from './helpers/filter-shown.util';

@injectable()
export abstract class BaseEntriesStore<Y, K> extends ApiConnectedStore {
  public paginationLimit: number;

  public hasUuid: boolean;

  protected instanceId: string;

  public isLastPage: boolean;

  public isLoadMore: boolean;

  public currentPage: number;

  public entries: Array<Y>;

  constructor(paginationLimit: number = 10, hasUuid: boolean = true) {
    super();

    this.paginationLimit = paginationLimit;

    this.hasUuid = hasUuid;

    this.instanceId = v4();

    this.isLastPage = false;

    this.isLoadMore = false;

    this.currentPage = 1;

    this.entries = [];

    makeObservable(this, {
      isLastPage: observable,
      currentPage: observable,
      entries: observable,
      isLoadMore: observable,

      pagination: computed,

      setLoadMore: action.bound,
      setIsLastPage: action.bound,
      setCurrentPage: action.bound,
      setEntries: action.bound,
    });
  }

  public async initialise(): Promise<void> {
    this.instanceId = v4();
  }

  public get pagination(): IPagination {
    return {
      limit: this.paginationLimit,
      page: this.currentPage,
    };
  }

  public get initialPagination(): IPagination {
    return {
      limit: this.paginationLimit,
      page: 1,
    };
  }

  public async refresh(): Promise<void> {
    this.reset();

    await this.initialise();
  }

  public abstract retrieveNext(
    res: Promise<IResponse<K>>,
    adapter: <T, U>(res: T) => U,
  ): Promise<void>;

  public reset() {
    super.reset();

    this.setIsLastPage(false);
    this.setCurrentPage(1);
    this.setEntries([]);
  }

  public setIsLastPage(isLastPage: boolean) {
    this.isLastPage = isLastPage;
  }

  public setCurrentPage(currentPage: number) {
    this.currentPage = currentPage;
  }

  public setEntries(entries: Array<Y>) {
    if (this.hasUuid) {
      this.entries = filterShown(entries);
    } else {
      this.entries = entries;
    }
  }

  public setLoadMore(isLoadMore: boolean) {
    this.isLoadMore = isLoadMore;
  }
}
