import { FC } from 'react';
import { components, OptionProps } from 'react-select';

import { IPlayerOption } from 'containers/profile/profile-info/interfaces/player-option.interface';

import styles from './player-option.module.less';

export const PlayerOption: FC<OptionProps<Maybe<IPlayerOption>, false>> = (props) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div className={styles.Player}>
        {data?.logo && <img className={styles.Player__Logo} src={data.logo} alt={data.label} />}
        {data?.label && <div className={styles.Player__Name}>{data.label}</div>}
      </div>
    </components.Option>
  );
};
