export const POST_ID_API_PARAM = 'postId';
export const COMMENT_ID_API_PARAM = 'commentId';
export const TEAM_ID_API_PARAM = 'teamId';
export const GAME_ID_API_PARAM = 'gameId';
export const PLAYER_ID_API_PARAM = 'playerId';
export const SEARCH_QUERY_ID_API_PARAM = 'searchQueryId';
export const LINK_ID_API_PARAM = 'linkId';
export const PLAYER_SLUG_API_PARAM = 'playerSlug';
export const GAME_ID_PARAM = 'gameId';
export const GAME_CHAT_ITEM_ID_PARAM = 'gameChatItemId';
export const RANKING_CATEGORY_ID_API_PARAM = 'categoryId';
export const COLLABORATION_ID_API_PARAM = 'collaborationId';
export const COLLABORATION_ITEM_ID_API_PARAM = 'collaborationItemId';
export const COLLABORATION_ITEM_COMMENT_ID_API_PARAM = 'collaborationItemCommentId';
export const USER_SLUG_API_PARAM = 'userSlug';
