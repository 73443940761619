import { PublicUserFeedToggleContainer } from 'containers/public-user/public-user-feed-toggle/public-user-feed-toggle.container';
import { UserPublicProfileTabsContainer } from 'containers/user-public-profile-tabs/user-public-profile-tabs.container';

import { Predictions } from 'components/predictions/predictions.component';

import style from './user-public-predictions.module.less';

export const UserPublicPredictionsRoute = () => {
  return (
    <div className={style.UserPublicPredictions}>
      <UserPublicProfileTabsContainer />
      <PublicUserFeedToggleContainer />
      <Predictions />
    </div>
  );
};
