import { useCallback } from 'react';

import { BaseIonBottomSheet } from 'components/bottom-sheet/base-ion-bottom-sheet/base-ion-bottom-sheet.component';

import { ImageTextItem } from './bottom-sheet-select-item/image-text-item.component';
import { TextItem } from './bottom-sheet-select-item/text-item.component';
import { IOption } from './bottom-sheet-select.interface';

import styles from './bottom-sheet-select.module.less';

const MIN_BREAKPOINT = 0;
const INITIAL_BREAKPOINT = 1;

export enum SelectItemTheme {
  Text,
  ImageWithText,
}

interface IBottomSheetSelectProps<T extends IOption | null> {
  visible: boolean;
  options: T[];
  value: Maybe<T> | undefined;
  breakpoints?: number[];
  theme?: SelectItemTheme;
  onChange: (value: T | null) => void;
  onClose: () => void;
}

export const BottomSheetSelect = <T extends IOption | null>(props: IBottomSheetSelectProps<T>) => {
  const {
    options,
    value,
    visible,
    onChange,
    breakpoints = [MIN_BREAKPOINT, INITIAL_BREAKPOINT],
    theme = SelectItemTheme.Text,
  } = props;

  const renderItemComponent = useCallback(
    (option: T, index: number) => {
      switch (theme) {
        case SelectItemTheme.Text:
          return (
            <TextItem
              key={index}
              value={option}
              isSelected={option?.value === value?.value}
              onPress={() => onChange(option)}
            />
          );
        case SelectItemTheme.ImageWithText:
          return (
            <ImageTextItem
              key={index}
              value={option}
              isSelected={option?.value === value?.value}
              onPress={() => onChange(option)}
            />
          );
        default:
          return null;
      }
    },
    [onChange, theme, value],
  );

  return (
    <BaseIonBottomSheet
      visible={visible}
      safeAreaBottom={0}
      breakpoints={breakpoints}
      initialBreakpoint={INITIAL_BREAKPOINT}
      isAutoHeight
      onClose={props.onClose}
    >
      <div className={styles.BottomSheetSelect}>
        <ul className={styles.BottomSheetSelect__List}>
          {options.map((option, index) => renderItemComponent(option, index))}
        </ul>
      </div>
    </BaseIonBottomSheet>
  );
};
