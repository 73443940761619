import { FC, memo, PropsWithChildren, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IButton } from 'components/ui/button/button.interface';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

import styles from './icon-button.module.less';

export enum IconButtonTheme {
  Primary,
  Secondary,
  Tertiary,
  Alert,
  Navigation,
  AlertBig,
  AttachmentAction,
  Transparent,
}

export interface IIconButtonProps extends IButton {
  selected?: boolean;
  iconName: IconFontName;
  iconSize?: IconFontSize;
  textLabel?: string;
  theme?: IconButtonTheme;
  active?: boolean;
  menuActive?: boolean;
  dataId?: string;
  customColor?: Maybe<string>;
  withDot?: boolean;
  dotType?: 'red' | 'blue';
}

export const ICON_BUTTON_CLASS = 'icon-button-class';

export const IconButton: FC<IIconButtonProps> = memo(
  (props: PropsWithChildren<IIconButtonProps>) => {
    const {
      customColor,
      active,
      disabled = false,
      pseudoDisabled,
      selected = false,
      menuActive = false,
      iconName,
      iconSize = IconFontSize.Big,
      textLabel = '',
      theme = IconButtonTheme.Primary,
      type,
      dataId,
      fluid,
      withDot = false,
      onClick,
      onTouchEnd,
      dotType,
    } = props;

    const classNames = useMemo(
      () =>
        cn(styles.IconButton, ICON_BUTTON_CLASS, {
          [styles['IconButton--fluid']]: fluid,
          [styles['IconButton--theme-primary']]: theme === IconButtonTheme.Primary,
          [styles['IconButton--theme-secondary']]: theme === IconButtonTheme.Secondary,
          [styles['IconButton--theme-tertiary']]: theme === IconButtonTheme.Tertiary,
          [styles['IconButton--theme-alert']]: theme === IconButtonTheme.Alert,
          [styles['IconButton--theme-navigation']]: theme === IconButtonTheme.Navigation,
          [styles['IconButton--theme-alert-big']]: theme === IconButtonTheme.AlertBig,
          [styles['IconButton--theme-attachment-action']]:
            theme === IconButtonTheme.AttachmentAction,
          [styles['IconButton--theme-transparent']]: theme === IconButtonTheme.Transparent,
          [styles['IconButton--with-dot']]: withDot,
          [styles['IconButton--pseudo-disabled']]: pseudoDisabled,
          [styles['IconButton--selected']]: selected,
          [styles['IconButton--active']]: active,
          [styles['IconButton--menu-active']]: menuActive,
        }),
      [fluid, theme, withDot, pseudoDisabled, selected, active, menuActive],
    );

    const iconButtonDotClassNames = useMemo(
      () =>
        cn(styles.IconButton__Dot, {
          [styles['IconButton__Dot--red']]: dotType === 'red',
          [styles['IconButton__Dot--blue']]: dotType === 'blue',
        }),
      [dotType],
    );

    const customStyle = useMemo(() => {
      if (customColor) {
        return {
          color: customColor,
        };
      }
      return {};
    }, [customColor]);

    const handleClick = useCallback(
      (event: SyntheticEvent) => {
        if (onClick) {
          onClick(event);
        }
      },
      [onClick],
    );

    const handleTouchEnd = useCallback(
      (event: SyntheticEvent) => {
        if (onTouchEnd) {
          onTouchEnd(event);
        }
      },
      [onTouchEnd],
    );

    return (
      <button
        disabled={disabled}
        aria-label={textLabel}
        className={classNames}
        type={type || 'button'}
        onClick={handleClick}
        onTouchEnd={handleTouchEnd}
        data-id={dataId}
        style={customStyle}
      >
        <IconFont dataId={dataId} name={iconName} size={iconSize} />
        {withDot && <div className={iconButtonDotClassNames} />}
      </button>
    );
  },
);
