import { FC, useCallback, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import { z } from 'zod';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useMainProvider } from 'hooks/use-main-provider';
import { useResponsive } from 'hooks/use-responsive';

import { descriptionSchema } from 'validation/schemas/description.schema';
import { emailSchema } from 'validation/schemas/email.schema';
import { subjectSchema } from 'validation/schemas/subject.schema';
import { MAX_DESCRIPTION_LENGTH } from 'validation/validation.constants';

import { OPTIONAL } from 'components/contact-us/constants/contact-us.constants';
import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE, DEFAULT_VALIDATION_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import formsStyles from 'components/forms/forms.module.less';
import { Button, ButtonSize, ButtonTheme } from 'components/ui/button/button.component';
import { TextArea } from 'components/ui/form-fields/text-area/text-area.component';
import { TextInput } from 'components/ui/form-fields/text-input/text-input.component';

import styles from './contact-us-form.module.less';

const contactUsFormSchema = z
  .object({
    email: emailSchema,
    subject: subjectSchema,
    description: descriptionSchema,
  })
  .strict();

type ContactUsFormDataType = z.infer<typeof contactUsFormSchema>;

interface IContactUsFormProps extends IForm<ContactUsFormDataType> {
  onFormStateChange: (flag: boolean) => void;
}

export const ContactUsForm: FC<IContactUsFormProps> = (props) => {
  const { submitText, initialValues, onSubmit, onFormStateChange } = props;
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const { isNativeApp } = useMainProvider();
  const subjectFieldRef = useRef<HTMLInputElement>(null);
  const descriptionFieldRef = useRef<HTMLTextAreaElement>(null);

  const { control, formState, handleSubmit, resetField } = useForm({
    defaultValues: initialValues,
    mode: DEFAULT_VALIDATION_MODE,
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(contactUsFormSchema),
  });

  const { isValid, errors } = formState;

  const handleSubjectFocus = useCallback(() => {
    let timeoutInstanse: NodeJS.Timeout;

    if (isNativeApp) {
      timeoutInstanse = setTimeout(() => {
        subjectFieldRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    }

    return () => {
      if (isNativeApp) {
        clearTimeout(timeoutInstanse);
      }
    };
  }, [isNativeApp]);

  const handleDescriptionFocus = useCallback(() => {
    let timeoutInstanse: NodeJS.Timeout;

    if (isNativeApp) {
      timeoutInstanse = setTimeout(() => {
        descriptionFieldRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    }

    return () => {
      if (isNativeApp) {
        clearTimeout(timeoutInstanse);
      }
    };
  }, [isNativeApp]);

  const handleSubjectChange = useCallback(
    // Any type picked from Hook Form types
    (onChange: (...event: any[]) => void) => (value: string) => {
      if (value.length) {
        onChange(value);
      } else {
        resetField('subject');
      }
    },
    [resetField],
  );

  useEffect(() => {
    onFormStateChange(formState.isDirty);
  }, [formState.isDirty, onFormStateChange]);

  return (
    <Form
      className={cn(styles.ContactUsForm, {
        [formsStyles.FormContent]: isDesktopPlus,
      })}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.ContactUsForm__Content}>
        <div className={styles.ContactUsForm__Fields}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextInput
                {...field}
                error={errors.email?.message}
                id="email"
                placeholder="Email address"
                label="Email address"
              />
            )}
          />
          <Controller
            name="subject"
            control={control}
            render={({ field }) => (
              <div className={styles.ContactUsForm__Subject}>
                <span className={styles.ContactUsForm__Optional}>{OPTIONAL}</span>
                <TextInput
                  {...field}
                  ref={subjectFieldRef}
                  onChange={handleSubjectChange(field.onChange)}
                  error={errors.subject?.message}
                  id="subject"
                  placeholder="Subject"
                  label="Subject"
                  onFocus={handleSubjectFocus}
                />
              </div>
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                ref={descriptionFieldRef}
                error={errors.description?.message}
                id="description"
                label="Description"
                placeholder="Description"
                rows={4}
                onFocus={handleDescriptionFocus}
                maxLength={MAX_DESCRIPTION_LENGTH}
              />
            )}
          />
        </div>

        <div className={styles.ContactUsForm__Button}>
          <Button
            fluid={!isDesktopPlus}
            disabled={!formState.isDirty || !isValid}
            theme={ButtonTheme.Primary}
            size={ButtonSize.Big}
            type="submit"
          >
            {submitText}
          </Button>
        </div>
      </div>
    </Form>
  );
};
