import { memo, SyntheticEvent, useCallback, useMemo, useRef, useState } from 'react';
import { OnChangeValue } from 'react-select';

import { IPlayerOption } from 'containers/profile/profile-info/interfaces/player-option.interface';

import { IHookFormInput } from 'components/ui/form-fields/hook-form-input.interface';

import { ResponsiveSelect } from '../responsive-select/responsive-select.component';

import { PlayerControl } from './player-control/player-control.component';
import { PlayerDropdownIndicator } from './player-dropdown-indicator/player-dropdown-indicator.component';
import { PlayerMenuList } from './player-menu-list/player-menu-list.component';
import { PlayerOption } from './player-option/player-option.component';
import { PlayerSingleValue } from './player-single-value/player-single-value.component';

import './select-player.less';

const selectPlayerComponents = {
  DropdownIndicator: PlayerDropdownIndicator,
  Control: PlayerControl,
  Option: PlayerOption,
  SingleValue: PlayerSingleValue,
  MenuList: PlayerMenuList,
  IndicatorSeparator: () => null,
};

interface ISelectPlayerProps extends IHookFormInput<Maybe<number>> {
  options: IPlayerOption[];
  rightAligned?: boolean;
  onFocusCallback?: () => void;
  setDisabledScroll: (isDisabledScroll: boolean) => void;
}

export const SelectPlayer = memo((props: ISelectPlayerProps) => {
  const {
    rightAligned = false,
    options,
    onChange,
    value,
    placeholder,
    onBlur,
    onFocusCallback,
    setDisabledScroll,
  } = props;

  const inputElementRef = useRef<Maybe<HTMLInputElement>>(null);

  const handleChange = useCallback(
    (playerOption: OnChangeValue<IPlayerOption | null, false>) => {
      if (onChange && playerOption) {
        const newValue = playerOption.value;
        onChange(newValue);
      }
    },
    [onChange],
  );

  const currentOption = useMemo<Maybe<IPlayerOption>>(
    () =>
      options.find(({ value: currentValue }) => {
        if (value) {
          return currentValue === value;
        }
        return null;
      }) || null,
    [options, value],
  );

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (event: SyntheticEvent) => {
    if (!inputElementRef.current) {
      inputElementRef.current = event.target as HTMLInputElement;
    }

    setDisabledScroll(true);
    setIsFocused(true);

    if (onFocusCallback) {
      onFocusCallback();
    }
  };

  const handleBlur = (event: SyntheticEvent) => {
    setIsFocused(false);
    setDisabledScroll(false);

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleMenuClose = () => {
    if (inputElementRef.current) {
      inputElementRef.current.blur();
    }
  };

  const setDefaultStyles = useMemo(() => {
    const defaultStyles = {
      container: () => ({}),
      control: () => ({}),
      menu: () => ({}),
      option: () => ({}),
      indicator: () => ({}),
      input: () => ({}),
      placeholder: () => ({}),
      valueContainer: () => ({}),
      singleValue: () => ({}),
    };

    if (rightAligned) {
      defaultStyles.menu = () => ({
        position: 'absolute',
        right: 0,
      });
    }

    return defaultStyles;
  }, [rightAligned]);

  return (
    <ResponsiveSelect<Maybe<IPlayerOption>>
      unstyled
      name="player"
      isMulti={false}
      components={selectPlayerComponents}
      className="PlayerSelect__container"
      menuPortalTarget={document.body}
      menuPosition="absolute"
      menuPlacement="bottom"
      styles={setDefaultStyles}
      classNamePrefix="PlayerSelect"
      placeholder={isFocused ? '' : placeholder}
      options={options}
      value={currentOption}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMenuClose={handleMenuClose}
      closeMenuOnSelect
      isWebSelectOnMobile
    />
  );
});
