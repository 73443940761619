import { format } from 'date-fns';

import { GameStatus } from 'services/games-detailed/enums/game-status.enum';
import { IGame, IGameMiniResponse } from 'services/statistics/interface/game.interface';

import { getFormattedPeriod } from 'stores/game/adapters/get-formatted-period.util';

import { convertRGBToHEX } from 'helpers/string/rbg-to-hex.utils';

type GameGroupType = { [key: string]: IGame[] };

type StatusWeightsType = { [key: string]: number };

export const statusWeights: StatusWeightsType = {
  [GameStatus.Live]: 3,
  [GameStatus.Halftime]: 2,
  [GameStatus.Finished]: 1,
  [GameStatus.Scheduled]: 0,
};

export const gamesAdapter = (games: IGameMiniResponse[]): IGame[] => {
  const parsedGames = games.map((game): IGame => {
    const {
      id,
      status,
      clock_formatted: clockFormatted,
      current_period: currentPeriod,
      date_time_end: dateEnd,
      date_time_start: dateStart,
      duration_formatted: durationFormatted,
      home_result: home,
      total_periods: totalPeriods,
      visitors_result: visitors,
    } = game;

    return {
      id,
      status,
      formattedPeriod: getFormattedPeriod(status, currentPeriod),
      periods: {
        current: currentPeriod,
        total: totalPeriods,
      },
      clockFormatted,
      dateEnd,
      dateStart,
      durationFormatted,
      teams: {
        home: {
          id: home.team_info.id,
          code: home.team_info.code,
          color: convertRGBToHEX(home.team_info.primary_color),
          secondaryTextColor: convertRGBToHEX(home.team_info.secondary_text_color),
          mediumLogoUrl: home.team_info.medium_logo_url,
          name: home.team_info.name,
          nickname: home.team_info.nickname,
          score: {
            loss: home.loss,
            points: home.points,
            win: home.win,
          },
          smallLogoUrl: home.team_info.small_logo_url,
          totalLoss: home.team_stat?.loss_total,
          totalWin: home.team_stat?.win_total,
        },
        visitors: {
          id: visitors.team_info.id,
          code: visitors.team_info.code,
          color: convertRGBToHEX(visitors.team_info.primary_color),
          secondaryTextColor: convertRGBToHEX(visitors.team_info.secondary_text_color),
          mediumLogoUrl: visitors.team_info.medium_logo_url,
          name: visitors.team_info.name,
          nickname: visitors.team_info.nickname,
          score: {
            loss: visitors.loss,
            points: visitors.points,
            win: visitors.win,
          },
          smallLogoUrl: visitors.team_info.small_logo_url,
          totalLoss: visitors.team_stat?.loss_total,
          totalWin: visitors.team_stat?.win_total,
        },
      },
    };
  });

  const gamesByDay = parsedGames.reduce<GameGroupType>((acc, game) => {
    const day = format(new Date(game.dateStart), 'yyyy-MM-dd');
    if (!acc[day]) acc[day] = [];
    acc[day].push(game);

    return acc;
  }, {});

  Object.values(gamesByDay).forEach((gameGroup) => {
    gameGroup.sort((a, b) => statusWeights[b.status] - statusWeights[a.status]);
  });

  return Object.values(gamesByDay).flat();
};
