import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';

import { EmptyState } from 'components/empty-state/empty-state.component';

import styles from './feed-empty-state.module.less';

interface IFeedEmptyStateProps {
  activeFeed: Maybe<FeedTypes>;
}

export const FeedEmptyState: FC<IFeedEmptyStateProps> = memo((props: IFeedEmptyStateProps) => {
  const { activeFeed } = props;

  const emptyStateContent = useMemo(() => {
    const content = {
      title: 'No results by Filters',
      message:
        'It seems like there are no results for the filter set you selected. Edit filters and turn ON something.',
    };

    if (activeFeed === FeedTypes.Player) {
      content.title = 'No posts yet';
      content.message = "You can find more up-to-date information on the player's Profile Page";
    }

    if (activeFeed === FeedTypes.GlobalFavorites) {
      content.title = 'No Favorites to Show';
      content.message = 'Tap the plus button above to select favorites';
    }

    return content;
  }, [activeFeed]);

  const feedEmptyStateClasses = useMemo(
    () =>
      cn(styles.FeedEmptyState, {
        [styles['FeedEmptyState--player-feed']]: activeFeed === FeedTypes.Player,
      }),
    [activeFeed],
  );

  return (
    <div className={feedEmptyStateClasses}>
      <EmptyState message={emptyStateContent.message} title={emptyStateContent.title} />
    </div>
  );
});
