import { forwardRef } from 'react';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import cn from 'classnames';

import { TOUCH_IGNORE_CLASS } from 'configs/swipe-navigation.config';

import { DEFAULT_GRID_OPTIONS, WRAPPER_CLASS_NAME } from 'components/base-table/tables.configs';

import 'ag-grid-community/styles/ag-grid.css';
import './ag-theme-base-table.less';

interface IBaseLazyTableGridProps extends AgGridReactProps {
  gridOptions: GridOptions;
  onGridReady?: (event: GridReadyEvent) => void;
  onRowClick?: (event: { data: any }) => void;
}

export const BaseLazyTableGrid = forwardRef<AgGridReact, IBaseLazyTableGridProps>((props, ref) => {
  const { gridOptions } = props;

  return (
    <div className={cn(WRAPPER_CLASS_NAME, TOUCH_IGNORE_CLASS)}>
      <AgGridReact
        ref={ref}
        {...props}
        gridOptions={{
          ...DEFAULT_GRID_OPTIONS,
          ...gridOptions,
        }}
        onGridReady={props.onGridReady}
        onRowClicked={props.onRowClick}
        cacheOverflowSize={props.cacheOverflowSize || 5}
        maxConcurrentDatasourceRequests={props.maxConcurrentDatasourceRequests || 1}
        infiniteInitialRowCount={props.infiniteInitialRowCount || 5}
        maxBlocksInCache={props.maxBlocksInCache || 100}
      />
    </div>
  );
});
