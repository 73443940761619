import { FC, memo, SyntheticEvent, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { IPeriodTab } from 'components/performance-tabs/performance-tabs.component';

import styles from './performance-tab.module.less';

interface IPerformanceTabProps {
  setCurrentTab: (value: number) => void;
  tab: IPeriodTab;
}

export const PerformanceTab: FC<IPerformanceTabProps> = memo((props: IPerformanceTabProps) => {
  const { setCurrentTab, tab } = props;

  const handleClick = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      setCurrentTab(tab.id);
    },
    [setCurrentTab, tab.id],
  );

  const classNames = useMemo(
    () =>
      cn(styles.Tab, {
        [styles['Tab--active']]: tab.isActive,
        [styles['Tab--disabled']]: tab.isDisable,
      }),
    [tab.isActive, tab.isDisable],
  );

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={handleClick}
      className={classNames}
      onClick={handleClick}
    >
      {tab.label}
    </div>
  );
});
