import { z } from 'zod';

import {
  MAX_LINK_DESCRIPTION_LENGTH,
  MAX_LINK_TITLE_LENGTH,
  MAX_NAME_LENGTH,
  MIN_LINK_TITLE_LENGTH,
  MIN_LINK_URL_LENGTH,
  MIN_NAME_LENGTH,
} from 'validation/validation.constants';

const linkTLDRegex = /\.[a-zA-Z]{2,}([/?#].*)?$/;

export const linkTitleSchema = z
  .string({ required_error: 'Title is required' })
  .max(MAX_NAME_LENGTH, `Max title length is ${MAX_LINK_TITLE_LENGTH}`)
  .min(MIN_NAME_LENGTH, `Min title length is ${MIN_LINK_TITLE_LENGTH}`);

export const linkDescriptionSchema = z
  .string()
  .max(MAX_NAME_LENGTH, `Max description length is ${MAX_LINK_DESCRIPTION_LENGTH}`)
  .optional();

export const linkUrlSchema = z
  .string({ required_error: 'Link is required' })
  .min(MIN_LINK_URL_LENGTH, `Min name length is ${MIN_LINK_URL_LENGTH}`)
  .url({ message: 'Valid Link Required' })
  .regex(linkTLDRegex, { message: 'Valid Link Required' });

export const socialLinkUrlSchema = z
  .string()
  .trim()
  .optional()
  .refine((val) => !val || z.string().url().safeParse(val).success, {
    message: 'Valid Link Required',
  });
