import { memo, useCallback } from 'react';

import { Select } from 'components/ui/form-fields/select/select.component';
import { ISelectOption } from 'components/ui/form-fields/select/select-option.interface';

import styles from './user-achievements.module.less';

interface IUserAchievementsProps {
  seasonsOptions: ISelectOption[];
  seasonId: string;
  onSeasonIdChange: (seasonId: string) => void;
}

export const UserAchievements = memo((props: IUserAchievementsProps) => {
  const { seasonsOptions, seasonId, onSeasonIdChange } = props;

  const handleSeasonChange = useCallback(
    (value: string) => {
      onSeasonIdChange(value);
    },
    [onSeasonIdChange],
  );

  return (
    <div className={styles.UserAchievements}>
      <div className={styles.UserAchievements__Filter}>
        <Select
          id="user_achievement_season"
          name="UserAchievementSeason"
          options={seasonsOptions}
          value={seasonId}
          onChange={handleSeasonChange}
        />
      </div>
      <div className={styles.UserAchievements__List}>
        {/* TODO: map achievements when backend is ready */}
        {/* <AchievementCard */}
        {/*  type={AchievementType.Streak} */}
        {/*  title="Day Streak" */}
        {/*  maxDays={365} */}
        {/*  currentDaysProgress={2} */}
        {/* /> */}
      </div>
    </div>
  );
});
