import { FC, SyntheticEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import {
  CollaborationMediaEnum,
  ICollaborationItem,
} from 'services/collaboration/interfaces/collaboration.interface';

import { getPath } from 'helpers/get-path.util';
import * as paths from 'routes/paths.constants';

import { useInfiniteScroll } from 'hooks/use-infinite-scroll';

import {
  AUTH_TOOLTIP_CLASS,
  AuthTooltip,
} from 'components/auth/auth-tooltip/auth-tooltip.component';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';
import {
  ICON_BUTTON_CLASS,
  IconButton,
  IconButtonTheme,
} from 'components/ui/icon-button/icon-button.component';
import { IconFont, IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';
import {
  RichTextContentTypes,
  RichTextPreview,
} from 'components/ui/rich-text-preview/rich-text-preview.component';

import styles from './collaboration-images-list.module.less';

interface ICollaborationItemsListProps {
  selectedItemId: Maybe<number>;
  itemsTotal: number;
  items: ICollaborationItem[];
  onLoadMore: () => void;
  onLikesClick: (id: number) => void;
  hasMore: boolean;
  postId: string;
  media: Maybe<CollaborationMediaEnum>;
  textAllowed: boolean;
}

const ITEMS_AMOUNT_BEFORE_PRE_LOAD = 5;

export const CollaborationItemsList: FC<ICollaborationItemsListProps> = (props) => {
  const {
    items,
    selectedItemId,
    itemsTotal,
    hasMore,
    onLoadMore,
    postId,
    media,
    textAllowed,
    onLikesClick,
  } = props;

  const navigate = useNavigate();

  const setIntersectionObserver = useInfiniteScroll(hasMore, onLoadMore);

  const handleNavigateToCollaborationItemPage = useCallback(
    (event: SyntheticEvent, collaborationItemId: number) => {
      const isLikeButtonClicked = (event.target as HTMLElement).closest(`.${ICON_BUTTON_CLASS}`);
      const isAuthTooltipClicked = (event.target as HTMLElement).closest(`.${AUTH_TOOLTIP_CLASS}`);

      // If the target is the like button or auth tooltip, ignore the click event
      if (isLikeButtonClicked || isAuthTooltipClicked) {
        return;
      }

      if (postId) {
        navigate(
          getPath(paths.HOME_FEED_POST_COLLABORATION, {
            [paths.POST_ID_PARAM]: postId,
            [paths.COLLABORATION_ITEM_ID_PARAM]: collaborationItemId.toString(),
          }),
        );
      }
    },
    [navigate, postId],
  );

  const handleLike = useCallback(
    (id: number) => {
      onLikesClick(id);
    },
    [onLikesClick],
  );

  return (
    <div className={styles.CollaborationImagesList}>
      {!!itemsTotal && (
        <div className={styles.CollaborationImagesList__Header}>
          <span className={styles.TotalItems}>
            {itemsTotal} {textAllowed ? 'response' : 'medias'} added
          </span>
        </div>
      )}
      {media && !textAllowed && (
        <div className={styles.CollaborationImagesList__MediaItems}>
          {items.map(
            ({ id, image, video, commentsTotal, likesTotal, user, isLiked }, index: number) => {
              const url = image?.smallLogoUrl || video?.smallPreviewUrl || '';

              return (
                <div
                  tabIndex={0}
                  role="button"
                  onClick={(event) => handleNavigateToCollaborationItemPage(event, id)}
                  onKeyDown={(event) => handleNavigateToCollaborationItemPage(event, id)}
                  ref={
                    items.length < index + ITEMS_AMOUNT_BEFORE_PRE_LOAD
                      ? setIntersectionObserver
                      : undefined
                  }
                  key={id}
                  className={cn(styles.CollaborationItem, {
                    [styles['CollaborationItem--selected']]: selectedItemId === id,
                  })}
                >
                  {id === selectedItemId && (
                    <div className={styles.LabelSelected}>
                      <IconFont name={IconFontName.Confirm} size={IconFontSize.Big} />
                    </div>
                  )}
                  <img className={styles.Attachment} src={url} alt={`${id}-preview`} />
                  {video?.smallPreviewUrl && (
                    <div className={styles.VideoLabel}>
                      <IconFont name={IconFontName.Play} size={IconFontSize.Small} />
                    </div>
                  )}
                  <div className={styles.UserAvatar}>
                    <Avatar size={AvatarSize.S} username={user.name} src={user.smallAvatarUrl} />
                  </div>
                  <div className={styles.Shadow} />
                  <div className={styles.Likes}>
                    <AuthTooltip alignLeft>
                      <IconButton
                        theme={IconButtonTheme.Transparent}
                        iconName={isLiked ? IconFontName.LikeFiled : IconFontName.Like}
                        iconSize={IconFontSize.Small}
                        onClick={() => handleLike(id)}
                        active={isLiked}
                      />
                    </AuthTooltip>
                    {!!likesTotal && <span>{likesTotal}</span>}
                  </div>
                  <div className={styles.Comments}>
                    <IconFont name={IconFontName.Comment} size={IconFontSize.Small} />
                    {!!commentsTotal && <span>{commentsTotal}</span>}
                  </div>
                </div>
              );
            },
          )}
        </div>
      )}

      {!media && textAllowed && (
        <div className={styles.CollaborationImagesList__TextItems}>
          {items.map(
            ({ id, commentsTotal, likesTotal, user, textContent, isLiked }, index: number) => {
              return (
                <div
                  tabIndex={0}
                  role="button"
                  onClick={(event) => handleNavigateToCollaborationItemPage(event, id)}
                  onKeyDown={(event) => handleNavigateToCollaborationItemPage(event, id)}
                  ref={
                    items.length < index + ITEMS_AMOUNT_BEFORE_PRE_LOAD
                      ? setIntersectionObserver
                      : undefined
                  }
                  key={id}
                  className={cn(styles.CollaborationItem, styles['CollaborationItem--text'], {
                    [styles['CollaborationItem--selected']]: selectedItemId === id,
                  })}
                >
                  {id === selectedItemId && (
                    <div className={styles.LabelSelected}>
                      <IconFont name={IconFontName.Confirm} size={IconFontSize.Big} />
                    </div>
                  )}
                  <div className={styles.TextItemHeader}>
                    <div className={styles.TextItemHeader__UserAvatar}>
                      <Avatar size={AvatarSize.S} username={user.name} src={user.smallAvatarUrl} />
                    </div>
                    <div className={styles.TextItemHeader__Username}>{user.username}</div>
                  </div>
                  {textContent && (
                    <RichTextPreview
                      id={id.toString()}
                      editorType={RichTextContentTypes.FullHeight}
                      editorState={textContent}
                    />
                  )}
                  <div className={styles.TextItemFooter}>
                    <div className={styles.TextItemFooter__Section}>
                      <AuthTooltip alignLeft>
                        <IconButton
                          theme={IconButtonTheme.Transparent}
                          iconName={isLiked ? IconFontName.LikeFiled : IconFontName.Like}
                          iconSize={IconFontSize.Small}
                          onClick={() => handleLike(id)}
                          active={isLiked}
                        />
                      </AuthTooltip>
                      {!!likesTotal && <span>{likesTotal}</span>}
                    </div>
                    <div className={styles.TextItemFooter__Section}>
                      <IconFont name={IconFontName.Comment} size={IconFontSize.Small} />
                      {!!commentsTotal && <span>{commentsTotal}</span>}
                    </div>
                  </div>
                </div>
              );
            },
          )}
        </div>
      )}

      {media && textAllowed && (
        <div className={styles.CollaborationImagesList__MediaItems}>
          {items.map(
            (
              { id, image, video, commentsTotal, likesTotal, user, textContent, isLiked },
              index: number,
            ) => {
              const url = image?.smallLogoUrl || video?.smallPreviewUrl || '';

              return (
                <div
                  tabIndex={0}
                  role="button"
                  onClick={(event) => handleNavigateToCollaborationItemPage(event, id)}
                  onKeyDown={(event) => handleNavigateToCollaborationItemPage(event, id)}
                  ref={
                    items.length < index + ITEMS_AMOUNT_BEFORE_PRE_LOAD
                      ? setIntersectionObserver
                      : undefined
                  }
                  key={id}
                  className={cn(styles.CardWrapper, {
                    [styles['CardWrapper--selected']]: selectedItemId === id,
                  })}
                >
                  <div className={styles.CollaborationItemVertical}>
                    {id === selectedItemId && (
                      <div className={styles.LabelSelected}>
                        <IconFont name={IconFontName.Confirm} size={IconFontSize.Big} />
                      </div>
                    )}
                    <img className={styles.Attachment} src={url} alt={`${id}-preview`} />
                    {video?.smallPreviewUrl && (
                      <div className={styles.VideoLabel}>
                        <IconFont name={IconFontName.Play} size={IconFontSize.Small} />
                      </div>
                    )}
                    <div className={styles.UserAvatar}>
                      <Avatar size={AvatarSize.S} username={user.name} src={user.smallAvatarUrl} />
                    </div>
                    <div className={styles.Shadow} />
                    <div className={styles.Likes}>
                      <AuthTooltip alignLeft>
                        <IconButton
                          theme={IconButtonTheme.Transparent}
                          iconName={isLiked ? IconFontName.LikeFiled : IconFontName.Like}
                          iconSize={IconFontSize.Small}
                          onClick={() => handleLike(id)}
                          active={isLiked}
                        />
                      </AuthTooltip>
                      {!!likesTotal && <span>{likesTotal}</span>}
                    </div>
                    <div className={styles.Comments}>
                      <IconFont name={IconFontName.Comment} size={IconFontSize.Small} />
                      {!!commentsTotal && <span>{commentsTotal}</span>}
                    </div>
                  </div>
                  <div className={styles.TextContent}>
                    {textContent && (
                      <RichTextPreview
                        id={id.toString()}
                        editorType={RichTextContentTypes.FullHeight}
                        editorState={textContent}
                      />
                    )}
                  </div>
                </div>
              );
            },
          )}
        </div>
      )}
    </div>
  );
};
