import { inject, injectable } from 'inversify';
import { comparer, reaction } from 'mobx';

import { IPostResponse } from 'services/posts/interfaces/posts-response.interface';
import { PostsService } from 'services/posts/posts.service';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { LayoutStore } from 'stores/layout/layout.store';
import { PlayerStore } from 'stores/player/player.store';
import { publicationAdapter } from 'stores/posts/adapters/publication-adapter.util';
import { FeedTypes } from 'stores/posts/interfaces/feed-types.enum';
import { IPost, IPostAdapter } from 'stores/posts/interfaces/post.interface';
import { IPostsResponse } from 'stores/posts/interfaces/post-response.inerface';

import { TYPES } from 'configs/di-types.config';

import { FanZoneFilterTabs } from 'components/feed-filter-tabs/feed-filter-tabs.component';

import { PAGINATION_LIMIT } from './feeds.config';

@injectable()
export class PlayerFanZoneFavoritesFeedStore extends AdvancedEntriesStore<
  IPost,
  IPostResponse,
  IPostsResponse
> {
  private readonly postsService: PostsService;

  private readonly layoutStore: LayoutStore;

  private readonly playerStore: PlayerStore;

  constructor(
    @inject(TYPES.PostsService) postsService: PostsService,
    @inject(TYPES.PlayerStore) playerStore: PlayerStore,
    @inject(TYPES.LayoutStore) layoutStore: LayoutStore,
  ) {
    super(PAGINATION_LIMIT);

    this.postsService = postsService;

    this.playerStore = playerStore;

    this.layoutStore = layoutStore;
    reaction(
      () =>
        JSON.stringify([
          this.playerStore.playerDetails,
          this.layoutStore.activeFeed,
          this.layoutStore.fanZoneFilter,
        ]),
      () => this.forceFetchToRefresh(),
      {
        equals: comparer.shallow,
      },
    );
  }

  public async fetchNext(): Promise<void> {
    if (
      this.layoutStore.activeFeed === FeedTypes.PlayerFanZoneFavorites &&
      this.playerStore.playerDetails?.id
    ) {
      await this.retrieveNext(
        this.fetchPlayerFanZoneFavoritesFeedPosts(
          this.playerStore.playerDetails.id,
          this.layoutStore.fanZoneFilter,
        ),
        <IPostAdapter>publicationAdapter,
      );
    } else {
      this.setEntries([]);
    }
  }

  public async fetchPlayerFanZoneFavoritesFeedPosts(
    playerId: number,
    filter: FanZoneFilterTabs,
  ): Promise<IResponse<IPostsResponse>> {
    return this.postsService.fetchPlayerPosts(
      this.pagination,
      playerId.toString(),
      false,
      true,
      filter,
    );
  }

  public async forceFetchToRefresh() {
    if (
      this.layoutStore.activeFeed === FeedTypes.PlayerFanZoneFavorites &&
      this.playerStore.playerDetails?.id
    ) {
      await this.refresh();

      await this.forceRefresh(
        this.fetchPlayerFanZoneFavoritesFeedPosts(
          this.playerStore.playerDetails.id,
          this.layoutStore.fanZoneFilter,
        ),
        <IPostAdapter>publicationAdapter,
        this.layoutStore.setPulledRefresher,
      );
    }
  }

  public async refresh() {
    this.setFetched(false);
    this.setFetching(false);

    this.setIsLastPage(false);
    this.setCurrentPage(1);

    await this.initialise();
  }
}
