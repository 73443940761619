import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactSelect, {
  ActionMeta,
  GroupBase,
  Props as ReactSelectProps,
  SingleValue,
} from 'react-select';

import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { isObject } from 'helpers/is-object.util';

import { useResponsive } from 'hooks/use-responsive';

import {
  BottomSheetSelect,
  SelectItemTheme,
} from '../bottom-sheet-select/bottom-sheet-select.component';
import { IOption } from '../bottom-sheet-select/bottom-sheet-select.interface';

interface ResponsiveSelectProps<Option extends IOption | null>
  extends Omit<ReactSelectProps<Option, false>, 'options' | 'value' | 'onChange'> {
  options: Option[];
  isWebSelectOnMobile?: boolean;
  value?: SingleValue<Option>;
  onChange?: (value: Option | null, action: ActionMeta<Option>) => void;
  itemTheme?: SelectItemTheme;
  styles?: any;
}

export const ResponsiveSelect = <Option extends IOption | null>(
  props: ResponsiveSelectProps<Option>,
) => {
  const {
    value,
    onChange,
    options,
    isDisabled = false,
    itemTheme,
    isWebSelectOnMobile,
    ...restProps
  } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);
  const [bottomSheetVisible, setBottomSheetVisible] = useState(false);

  const previousValueRef = useRef<Option | null>(value || null);

  useEffect(() => {
    previousValueRef.current = value || null;
  }, [value]);

  const handleOpenBottomSheet = useCallback(() => {
    if (!isDisabled) {
      setBottomSheetVisible(true);
    }
  }, [isDisabled]);

  const handleCloseBottomSheet = useCallback(() => {
    setBottomSheetVisible(false);
  }, []);

  const handleChange = useCallback(
    (selectedOption: Option | null) => {
      if (onChange) {
        onChange(selectedOption, {
          action: selectedOption ? 'select-option' : 'clear',
          option: selectedOption || undefined,
          removedValues:
            !selectedOption && previousValueRef.current ? [previousValueRef.current] : [],
        });
      }
      if (!isDesktopPlus) {
        handleCloseBottomSheet();
      }
    },
    [onChange, isDesktopPlus, handleCloseBottomSheet],
  );

  const flattenOptions = useMemo(() => {
    return options.flatMap((option) => {
      if (isObject(option) && 'options' in option && Array.isArray(option.options)) {
        return option.options;
      }
      return option;
    });
  }, [options]);

  const selectProps: ReactSelectProps<Option, false, GroupBase<Option>> = {
    ...restProps,
    value,
    onChange: handleChange,
    isDisabled,
    options,
  };

  if (isDesktopPlus || isWebSelectOnMobile) {
    return <ReactSelect<Option, false, GroupBase<Option>> {...selectProps} />;
  }

  return (
    <>
      <ReactSelect<Option, false, GroupBase<Option>>
        {...selectProps}
        menuIsOpen={false}
        onMenuOpen={handleOpenBottomSheet}
      />
      <BottomSheetSelect<Option>
        visible={bottomSheetVisible}
        options={flattenOptions}
        value={value || null}
        onChange={handleChange}
        onClose={handleCloseBottomSheet}
        theme={itemTheme}
      />
    </>
  );
};
