import { FC, PropsWithChildren, ReactNode, useCallback, useState } from 'react';

import { DateRange } from 'components/ui/calendar/calendar.interface';

import { Calendar } from '../calendar/calendar.component';
import { Tooltip, TooltipEventType } from '../tooltip/tooltip.component';

interface ICalendarTooltipProps {
  children: ReactNode;
  eventType: TooltipEventType;
  calendarValue: Date;
  onChangeCalendar: (date: Maybe<Date>) => void;
}

export const CalendarTooltip: FC<ICalendarTooltipProps> = (
  props: PropsWithChildren<ICalendarTooltipProps>,
) => {
  const { children, eventType, calendarValue, onChangeCalendar } = props;

  const [isNeedCloseTooltip, setIsNeedCloseTooltip] = useState(false);

  const handleCalendarChange = useCallback(
    (date: DateRange) => {
      onChangeCalendar(date as Maybe<Date>);
      setIsNeedCloseTooltip(true);
    },
    [onChangeCalendar],
  );

  return (
    <Tooltip
      toggleOnClick
      eventType={eventType}
      isNeedCloseTooltip={isNeedCloseTooltip}
      setIsNeedCloseTooltip={setIsNeedCloseTooltip}
      tooltipContent={<Calendar onChange={handleCalendarChange} value={calendarValue} />}
    >
      {children}
    </Tooltip>
  );
};
