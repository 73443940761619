import { FC, useCallback, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

import { IUpdateUserParams } from 'stores/auth/interfaces/update-user.params.interface';
import { IUserMe } from 'stores/auth/interfaces/user-me.interface';

import { ACCEPT_FILES } from 'configs/controls.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';

import { useResponsive } from 'hooks/use-responsive';

import {
  EditProfileForm,
  EditProfileFormDataType,
} from 'components/forms/edit-profile/edit-profile-form.component';
import { ProfileAvatarForm } from 'components/forms/profile-avatar/profile-avatar-form.component';
import { ProfileThumbnailForm } from 'components/forms/profile-thumbnail/profile-thumbnail-form.component';
import {
  BaseModalComponent,
  IModal,
  ModalWindowSize,
} from 'components/modals/base-modal/base-modal.component';
import { Loader } from 'components/ui/loader/loader.component';

import defaultThumbnail from 'assets/images/svg/user-default-background.svg';

import styles from './edit-profile-modal.module.less';

export interface IEditProfileModalProps extends IModal {
  loading: boolean;
  initialValues?: EditProfileFormDataType;
  userData: Maybe<IUserMe>;
  isCropAvatarModalVisible: boolean;
  isCropBackgroundModalVisible: boolean;
  onSubmit: (data: IUpdateUserParams, closeModal?: boolean) => void;
  onSetAvatarCropModal: (isOpen: boolean) => void;
  onSetBackgroundCropModal: (isOpen: boolean) => void;
}

export const EditProfileModal: FC<IEditProfileModalProps> = (props: IEditProfileModalProps) => {
  const { loading, userData, visible, initialValues, onClose, onSubmit } = props;

  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const bannerUrl = useMemo(
    () => userData?.profile.thumbnailUrl || defaultThumbnail,
    [userData?.profile.thumbnailUrl],
  );

  const handleClose = useCallback(onClose, [onClose]);

  const handleSubmit = useCallback(
    (data: EditProfileFormDataType) => {
      onSubmit({ userName: data.username, realName: data.realname, biography: data.biography });
    },
    [onSubmit],
  );

  const handleSubmitAvatar = useCallback(
    (avatarData: FieldValues) => {
      const cleanedBase64 = avatarData.avatar.value.split(',')[1];
      onSubmit({ avatar: cleanedBase64 }, false);
    },
    [onSubmit],
  );

  const handleSubmitThumbnail = useCallback(
    (thumbnailData: FieldValues) => {
      const cleanedBase64 = thumbnailData.thumbnail.value.split(',')[1];
      onSubmit({ thumbnail: cleanedBase64 }, false);
    },
    [onSubmit],
  );

  return (
    <BaseModalComponent
      size={ModalWindowSize.M}
      visible={visible}
      title="Edit Profile"
      onClose={handleClose}
      isFullScreen={!isDesktopPlus}
    >
      {userData && (
        <div className={styles.ImagesWrapper}>
          {!loading ? (
            <>
              <ProfileThumbnailForm
                accept={ACCEPT_FILES}
                onSubmit={handleSubmitThumbnail}
                thumbnailUrl={bannerUrl}
                thumbnailLogoUrl={userData.profile.thumbnailLogoUrl}
                thumbnailNicknameUrl={userData.profile.thumbnailNicknameUrl}
                isCropModalVisible={props.isCropBackgroundModalVisible}
                onSetCropModal={props.onSetBackgroundCropModal}
                forEditModal
              />
              <div className={styles.AvatarWrapper}>
                <ProfileAvatarForm
                  username={userData.username}
                  onSubmit={handleSubmitAvatar}
                  accept={ACCEPT_FILES}
                  avatarUrl={userData.profile.avatarUrl}
                  isCropModalVisible={props.isCropAvatarModalVisible}
                  onSetCropModal={props.onSetAvatarCropModal}
                  forEditModal
                />
              </div>
            </>
          ) : (
            <Loader isShow />
          )}
        </div>
      )}
      <EditProfileForm onSubmit={handleSubmit} submitText="Save" initialValues={initialValues} />
    </BaseModalComponent>
  );
};
