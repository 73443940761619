import { inject } from 'inversify';
import { action, makeObservable, observable, reaction } from 'mobx';

import { CollaborationService } from 'services/collaboration/collaboration.service';
import {
  ICollaborationAdapter,
  ICollaborationComment,
  ICollaborationCommentResponse,
  ICollaborationCommentsResponse,
} from 'services/collaboration/interfaces/collaboration.interface';
import { ICommentCreatePayload } from 'services/posts/interfaces/create-comment-payload.interface';

import { AdvancedEntriesStore } from 'stores/advanced-entries/advanced-entries.store';
import { ApplicationStore } from 'stores/application/application.store';
import { collaborationCommentAdapter } from 'stores/collaboration/adapters/collaboration-adapter.util';
import { CollaborationStore } from 'stores/collaboration/collaboration.store';
import { findAndReplaceObjectByKey } from 'stores/collaboration/utils/utils';

import { TYPES } from 'configs/di-types.config';

const COLLABORATION_COMMENTS_LIMIT = 5;

export class CollaborationCommentsStore extends AdvancedEntriesStore<
  ICollaborationComment,
  ICollaborationCommentResponse,
  ICollaborationCommentsResponse
> {
  private readonly collaborationStore: CollaborationStore;

  private readonly applicationStore: ApplicationStore;

  private readonly collaborationService: CollaborationService;

  public fetchingCreateComment: boolean;

  constructor(
    @inject<ApplicationStore>(TYPES.ApplicationStore) applicationStore: ApplicationStore,
    @inject<CollaborationStore>(TYPES.CollaborationStore) collaborationStore: CollaborationStore,
    @inject<CollaborationService>(TYPES.CollaborationService)
    collaborationService: CollaborationService,
  ) {
    super(COLLABORATION_COMMENTS_LIMIT, false);

    this.applicationStore = applicationStore;

    this.collaborationStore = collaborationStore;

    this.collaborationService = collaborationService;

    this.fetchingCreateComment = false;

    makeObservable(this, {
      fetchingCreateComment: observable,

      createCollaborationComment: action.bound,
      deleteCollaborationComment: action.bound,
      toggleLikeCollaborationComment: action.bound,
      initialiseCollaborationComments: action.bound,
      fetchNextCollaborationComments: action.bound,
      setFetchingCreateComment: action.bound,
    });

    reaction(
      () => [this.collaborationStore.collaborationItemId, this.collaborationStore.collaborationId],
      this.initialiseCollaborationComments,
    );
  }

  public async initialiseCollaborationComments() {
    this.reset();
    await super.initialise();
    if (this.collaborationStore.collaborationId && this.collaborationStore.collaborationItemId) {
      await this.fetchNextCollaborationComments(
        this.collaborationStore.collaborationId,
        this.collaborationStore.collaborationItemId,
      );
    }
  }

  public async fetchNextCollaborationComments(
    collaborationId: number,
    collaborationItemId: number,
  ): Promise<void> {
    await this.retrieveNext(
      this.collaborationService.fetchCollaborationItemComments(
        this.pagination,
        collaborationId,
        collaborationItemId,
      ),
      <ICollaborationAdapter>collaborationCommentAdapter,
    );
  }

  public async createCollaborationComment(payload: ICommentCreatePayload) {
    if (this.collaborationStore.collaborationId && this.collaborationStore.collaborationItemId) {
      this.setFetchingCreateComment(true);

      const response = await this.collaborationService.createCollaborationItemComment(
        this.collaborationStore.collaborationId,
        this.collaborationStore.collaborationItemId,
        payload,
      );

      if (response.success) {
        const newComment = collaborationCommentAdapter(response.data);
        this.setEntries([newComment, ...this.entries]);
        this.setFetchingCreateComment(false);

        return;
      }

      if (response.code === 500 || response.code === 504) {
        const error: INotificationMessage = {
          message: 'We were unable to create your comment. Please try again later',
        };
        this.setErrors([error]);
      } else {
        this.setErrors(response.errors);
      }

      this.setFetchingCreateComment(false);
    }
  }

  public async toggleLikeCollaborationComment(collaborationCommentId: number) {
    if (this.collaborationStore.collaborationId && this.collaborationStore.collaborationItemId) {
      const response = await this.collaborationService.likeCollaborationItemComment(
        this.collaborationStore.collaborationId,
        this.collaborationStore.collaborationItemId,
        collaborationCommentId,
      );

      const currentItem = this.entries.find(({ id }) => id === collaborationCommentId);

      if (currentItem && response.success) {
        currentItem.likes = response.data.likes_total;
        currentItem.isLiked = response.data.is_liked;

        const newEntries = findAndReplaceObjectByKey<ICollaborationComment>(
          [...this.entries],
          'id',
          collaborationCommentId,
          currentItem,
        );

        this.setEntries(newEntries);
      }
    }
  }

  public async deleteCollaborationComment(collaborationCommentId: number) {
    if (this.collaborationStore.collaborationId && this.collaborationStore.collaborationItemId) {
      const response = await this.collaborationService.deleteCollaborationItemComment(
        this.collaborationStore.collaborationId,
        this.collaborationStore.collaborationItemId,
        collaborationCommentId,
      );

      if (response.success) {
        const newComments = [...this.entries].filter((obj) => obj.id !== collaborationCommentId);
        this.setEntries(newComments);
      }
    }
  }

  public setFetchingCreateComment(fetchingCreateComment: boolean) {
    this.fetchingCreateComment = fetchingCreateComment;
  }
}
