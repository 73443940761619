import { FC, memo, useMemo } from 'react';
import cn from 'classnames';

import { IBarItem } from 'components/bars/bar-item.interface';
import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';

import styles from './sub-bottom-bar-item.module.less';

interface IBottomBarItemProps extends Pick<IBarItem, 'name' | 'icon'> {
  isActive: boolean;
  onClick: () => void;
}

export const SubBottomBarItem: FC<IBottomBarItemProps> = memo((props: IBottomBarItemProps) => {
  const { isActive, icon, name } = props;

  const itemClassNames = useMemo(
    () =>
      cn(styles.Item, {
        [styles['Item--active']]: isActive,
        [styles['Item--last']]: !name,
      }),
    [isActive, name],
  );

  return (
    <li
      role="tab"
      tabIndex={0}
      className={itemClassNames}
      onClick={props.onClick}
      onKeyDown={props.onClick}
    >
      <IconButton
        iconName={isActive ? icon.active : icon.default}
        theme={IconButtonTheme.Transparent}
      />
      <span>{name}</span>
    </li>
  );
});
