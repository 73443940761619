import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { socialLinkUrlSchema } from 'validation/schemas/link.schema';

import { Form } from 'components/forms/form.component';
import { DEFAULT_REVALIDATE_MODE } from 'components/forms/form.config';
import { IForm } from 'components/forms/form.interface';
import { SubmitSection } from 'components/forms/submit-section/submit-section.component';
import { TextInput } from 'components/ui/form-fields/text-input/text-input.component';
import { IconFontName } from 'components/ui/icon-font/icon-font.component';

import formsStyles from '../forms.module.less';

const socialLinksFormSchema = z.object({
  instagram: socialLinkUrlSchema,
  twitter: socialLinkUrlSchema,
  youtube: socialLinkUrlSchema,
  tiktok: socialLinkUrlSchema,
  snapchat: socialLinkUrlSchema,
  discord: socialLinkUrlSchema,
});

export type SocialLinksFormDataType = z.infer<typeof socialLinksFormSchema>;

export const SocialLinksForm: FC<IForm<SocialLinksFormDataType>> = (props) => {
  const { submitText, initialValues, onSubmit } = props;

  const { control, formState, handleSubmit } = useForm({
    defaultValues: initialValues,
    mode: 'onTouched',
    reValidateMode: DEFAULT_REVALIDATE_MODE,
    resolver: zodResolver(socialLinksFormSchema),
  });

  const { isValid, errors } = formState;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className={formsStyles.FormContent}>
        <Controller
          name="instagram"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.instagram?.message}
              type="text"
              id="instagram"
              label="Instagram Link"
              leftIconName={IconFontName.Insta}
            />
          )}
        />
        <Controller
          name="twitter"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.twitter?.message}
              type="text"
              id="twitter"
              label="X Link"
              leftIconName={IconFontName.Twitter}
            />
          )}
        />
        <Controller
          name="youtube"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.youtube?.message}
              type="text"
              id="youtube"
              label="Youtube Link"
              leftIconName={IconFontName.Youtube}
            />
          )}
        />
        <Controller
          name="tiktok"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.tiktok?.message}
              type="text"
              id="tiktok"
              label="Tiktok Link"
              leftIconName={IconFontName.TikTok}
            />
          )}
        />
        <Controller
          name="snapchat"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.snapchat?.message}
              type="text"
              id="snapchat"
              label="Snapchat Link"
              leftIconName={IconFontName.Snapchat}
            />
          )}
        />
        <Controller
          name="discord"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              error={errors.discord?.message}
              type="text"
              id="discord"
              label="Discord Link"
              leftIconName={IconFontName.Discord}
            />
          )}
        />
      </div>
      <SubmitSection disabled={!formState.isDirty} isFormValid={isValid} buttonText={submitText} />
    </Form>
  );
};
