export interface IFileData {
  value: string;
  meta: {
    size: number;
    type: string;
    filename: string;
    hash?: string;
  };
}

export const toBase64 = (file: Blob, hash: string = ''): Promise<IFileData | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    let name = 'default';

    if (file instanceof File && file.name) {
      name = file.name;
    }

    const { size, type } = file;

    const filename = name.replace(/\.\w{1,5}$/, '');

    reader.readAsDataURL(file);

    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve({
          value: reader.result,
          meta: {
            size,
            type,
            filename,
            hash,
          },
        });
      }

      return resolve(null);
    };

    reader.onerror = (error) => reject(error);
  });
