import { FC } from 'react';
import { ClearIndicatorProps, components } from 'react-select';

import { IconButton, IconButtonTheme } from 'components/ui/icon-button/icon-button.component';
import { IconFontName, IconFontSize } from 'components/ui/icon-font/icon-font.component';

export const CustomClearIndicatorComponent: FC<ClearIndicatorProps> = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <div>
        <IconButton
          iconName={IconFontName.Close}
          theme={IconButtonTheme.Transparent}
          iconSize={IconFontSize.Small}
        />
      </div>
    </components.ClearIndicator>
  );
};
