import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { observer } from 'mobx-react-lite';
import { TeamsStore } from 'src/stores/teams/teams.store';

import { TYPES } from 'configs/di-types.config';
import { MIN_DESKTOP_WIDTH } from 'configs/responsive.configs';
import { getTeamRouteTabs } from 'helpers/get-team-route-tabs.util';

import { useResponsive } from 'hooks/use-responsive';

import { BarAction } from 'components/bars/bar-action.enum';
import { BarActionType } from 'components/bars/bar-action.type';
import { Tabs } from 'components/ui/tabs/tabs.component';

export const TeamProfileTabsContainer = observer(() => {
  const teamsStore = useInjection<TeamsStore>(TYPES.TeamsStore);
  const [isDesktopPlus] = useResponsive([MIN_DESKTOP_WIDTH]);

  const location = useLocation();

  const navigate = useNavigate();
  const handleItemClick = useCallback(
    (action: BarActionType) => {
      if (action.type === BarAction.Link) {
        navigate(action.path);
      }
    },
    [navigate],
  );

  const teamProfileTabs = useMemo(() => {
    if (!isDesktopPlus || !teamsStore?.teamId) {
      return null;
    }
    return getTeamRouteTabs(teamsStore.teamId);
  }, [isDesktopPlus, teamsStore.teamId]);

  const activeTab = useMemo<Maybe<string>>(
    () => teamProfileTabs?.find((tab) => location.pathname.includes(tab.action.path))?.id || null,
    [location.pathname, teamProfileTabs],
  );

  if (!teamProfileTabs) {
    return null;
  }

  return (
    <Tabs tabs={teamProfileTabs} activeTab={activeTab} onClick={handleItemClick} forPlayerProfile />
  );
});
