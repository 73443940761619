import { IBucketResponse } from 'services/buckets/interfaces/buckets.interface';

import { IBucket } from 'stores/buckets/interfaces/bucket.interface';

export const bucketAdapter = (bucket: IBucketResponse): IBucket => {
  const { id, data, created_at: createdAt, updated_at: updatedAt } = bucket;

  return {
    id,
    data: {
      type: data.type,
      name: data.name,
      logoSrc: data.logo_src_key,
    },
    createdAt: new Date(createdAt),
    updatedAt: new Date(updatedAt),
  };
};
