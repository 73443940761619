import { memo, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';

import { IPlayerContent } from 'stores/content-cards/interfaces/content-cards.interface';

import { PostsTheme } from 'components/posts/post-card/enums/posts-theme.enum';
import { Avatar, AvatarSize } from 'components/ui/avatar/avatar.component';

import styles from './player-board.module.less';

interface IPlayerBoardProps {
  theme: PostsTheme;
  playerData: Omit<IPlayerContent, 'type'>;
}

export const PlayerBoard = memo((props: IPlayerBoardProps) => {
  const { theme, playerData } = props;

  const {
    avatarUrl,
    fullName,
    jersey,
    position,
    points,
    rebounds,
    assistants,
    blocks,
    steals,
    plusMinus,
    primaryColor,
  } = playerData;

  const refPlayerBoard = useRef<HTMLDivElement>(null);

  const playerStatistic = useMemo(
    () => [
      { label: 'PTS', value: points },
      { label: 'REB', value: rebounds },
      { label: 'AST', value: assistants },
      { label: 'BLK', value: blocks },
      { label: 'STL', value: steals },
      { label: '+/-', value: plusMinus },
    ],
    [points, rebounds, assistants, blocks, steals, plusMinus],
  );

  const playerBoardClasses = useMemo(
    () =>
      cn(styles.PlayerBoard, {
        [styles['PlayerBoard--detail-theme']]: theme === PostsTheme.Detail,
      }),
    [theme],
  );

  useEffect(() => {
    const wrapperElement = refPlayerBoard.current;

    if (wrapperElement) {
      wrapperElement.style.setProperty('--player-board-primary-color', primaryColor);
    }
  }, [primaryColor]);

  return (
    <div ref={refPlayerBoard} className={playerBoardClasses}>
      <div className={styles.PlayerBoard__Header}>
        <div className={styles.PlayerBoard__Avatar}>
          <Avatar src={avatarUrl} username={fullName} size={AvatarSize.XS} />
        </div>
        <span className={styles.PlayerBoard__Name}>{fullName}</span>
        <div className={styles.PlayerBoard__Group}>
          <span className={styles.PlayerBoard__Jersey}>#{jersey}</span>
          <span className={styles.PlayerBoard__Position}>{position}</span>
        </div>
      </div>
      <div className={styles.PlayerBoard__Content}>
        {playerStatistic.map((statistic) => (
          <div key={statistic.label} className={styles.PlayerBoardPoint}>
            <span className={styles.PlayerBoardPoint__Value}>{statistic.value}</span>
            <span className={styles.PlayerBoardPoint__Label}>{statistic.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
});
